import { Modal, Typography } from "antd";
import { RoutingRuleEditorContext } from "components/Routing/store/RoutingRuleEditorContext";
import { EditableList } from "components/common/EditableList";
import { isEqual } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ApprovalRule } from "shared/types/workflow/types";
import { hasApprovalOptions } from "shared/types/workflow/util";

import { EditorBackButton } from "../EditorBackButton";
import { ApprovalOptionsPreview } from "../previews/ApprovalOptionsPreview";
import { ApprovalPreview } from "../previews/ApprovalPreview";
import { AccessRuleEditor } from "./AccessRuleEditor";

const { confirm } = Modal;

export const AccessEditor: React.FC = () => {
  const { approval, setApproval } = useContext(RoutingRuleEditorContext);
  const [isAddingNewRule, setIsAddingNewRule] = useState(false);
  const [editingRuleIndex, setEditingRuleIndex] = useState<number | null>(null);

  const [localRule, setLocalRule] = useState<ApprovalRule>();
  const isDirty = useMemo(() => {
    return (
      isAddingNewRule ||
      (editingRuleIndex !== null &&
        !isEqual(approval[editingRuleIndex], localRule))
    );
  }, [localRule, isAddingNewRule, editingRuleIndex, approval]);

  const backToAccessControl = useCallback(() => {
    setIsAddingNewRule(false);
    setEditingRuleIndex(null);
    setLocalRule(undefined);
  }, []);

  const handleStartAddNewAccessRule = useCallback(() => {
    setIsAddingNewRule(true);
    setEditingRuleIndex(null);
    setLocalRule({ type: "p0", options: {} });
  }, []);

  const handleStartEditAccessRule = useCallback(
    (index: number) => {
      setIsAddingNewRule(false);
      setEditingRuleIndex(index);
      setLocalRule(approval[index]);
    },
    [approval]
  );

  const handleEditAccessRule = useCallback(
    (rule: ApprovalRule) => {
      if (editingRuleIndex === null) {
        return;
      }

      const newApproval = [...approval];
      newApproval[editingRuleIndex] = rule;
      setApproval(newApproval);
      backToAccessControl();
    },
    [approval, backToAccessControl, editingRuleIndex, setApproval]
  );

  const handleAddNewAccessRule = useCallback(
    (rule: ApprovalRule) => {
      setApproval([...approval, rule]);
      backToAccessControl();
    },
    [approval, backToAccessControl, setApproval]
  );

  const handleDeleteAccessRule = useCallback(
    (index: number) => {
      confirm({
        title: "Delete access rule?",
        content: "This action cannot be undone",
        onOk() {
          backToAccessControl();
          const newApproval = approval.filter((_, i) => i !== index);
          setApproval(newApproval);
        },
      });
    },
    [approval, setApproval, backToAccessControl]
  );

  const deleteRuleAtIndex = useCallback(
    () => editingRuleIndex && handleDeleteAccessRule(editingRuleIndex),
    [editingRuleIndex, handleDeleteAccessRule]
  );

  const renderApprovalRule = useCallback(
    (rule: ApprovalRule) => (
      <>
        <ApprovalPreview approval={rule} />
        {hasApprovalOptions(rule) && (
          <div style={{ marginLeft: 10 }}>
            <ApprovalOptionsPreview approvalOptions={rule.options} />
          </div>
        )}
      </>
    ),
    []
  );

  return (
    <>
      <Typography.Title level={4}>Access Control</Typography.Title>
      {(isAddingNewRule || editingRuleIndex !== null) && (
        <EditorBackButton
          onBack={backToAccessControl}
          isDirty={isDirty}
          isAdding={isAddingNewRule}
          name="Access Rule"
        />
      )}
      {isAddingNewRule ? (
        <LocalAccessRuleContext.Provider
          value={{ localRule, setLocalRule, isDirty, isNew: true }}
        >
          <AccessRuleEditor onSave={handleAddNewAccessRule} />
        </LocalAccessRuleContext.Provider>
      ) : editingRuleIndex !== null ? (
        <LocalAccessRuleContext.Provider
          value={{ localRule, setLocalRule, isDirty, isNew: false }}
        >
          <AccessRuleEditor
            onSave={handleEditAccessRule}
            onDelete={deleteRuleAtIndex}
          />
        </LocalAccessRuleContext.Provider>
      ) : (
        <EditableList
          items={approval}
          onEditItem={handleStartEditAccessRule}
          onDeleteItem={handleDeleteAccessRule}
          onAddItem={handleStartAddNewAccessRule}
          renderItem={renderApprovalRule}
          addButtonText="Add New Access Rule"
        />
      )}
    </>
  );
};

type LocalAccessRule = {
  localRule: ApprovalRule | undefined;
  setLocalRule: (rule: ApprovalRule) => void;
  isDirty: boolean;
  isNew: boolean;
};

export const LocalAccessRuleContext = createContext<LocalAccessRule>({
  localRule: { type: "p0", options: {} },
  setLocalRule: () => {},
  isDirty: false,
  isNew: false,
});

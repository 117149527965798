import { CodeOutlined, DesktopOutlined } from "@ant-design/icons";
import { Button, Spin, Tabs, Typography } from "antd";
import { VerticalSpacedDiv } from "components/divs";
import { compact } from "lodash";
import { ReactNode, useMemo } from "react";
import { Instructions as InstructionsType } from "shared/install/types";

import { CommandDisplay } from "../CommandDisplay";
import { Logo } from "../Logo";

type InstructionsProps = {
  instructions: InstructionsType<ReactNode>;
  isFetching: boolean;
  onNext: () => void;
};

const TerraformLogo = (
  <Logo logo={"https://www.terraform.io/favicon.ico"} title={"Terraform"} />
);

export const Instructions = ({
  instructions,
  isFetching,
  onNext,
}: InstructionsProps) => {
  const { commands, help } = instructions;

  const tabOptions = useMemo(
    () =>
      compact([
        commands?.console && {
          label: (
            <>
              <DesktopOutlined />
              Console
            </>
          ),
          key: "console",
          children: commands.console,
        },
        commands?.shell && {
          label: (
            <>
              <CodeOutlined />
              Shell
            </>
          ),
          key: "shell",
          children: commands.shell.map(({ header, command }, ix) => (
            <div key={ix}>
              {header ?? (
                <Typography.Paragraph>
                  Run these shell commands, then click &quot;Next&quot;:
                </Typography.Paragraph>
              )}
              <CommandDisplay commands={command} maxRows={20} />
            </div>
          )),
        },
        commands?.iac && {
          label: <>{TerraformLogo}&nbsp;Terraform</>,
          key: "iac",
          children: commands.iac.map(({ header, command }, ix) => (
            <div key={ix}>
              {header ?? (
                <Typography.Paragraph>
                  Add the following to your Terraform config, then apply, then
                  click &quot;Next&quot;:
                </Typography.Paragraph>
              )}
              <CommandDisplay commands={command} maxRows={20} />
            </div>
          )),
        },
      ]),
    [commands]
  );

  return isFetching ? (
    <Spin />
  ) : (
    <VerticalSpacedDiv style={{ marginBottom: "14px" }}>
      <div>
        {(help as ReactNode) ?? "To continue installation, run the following:"}
      </div>
      {tabOptions?.length ? <Tabs items={tabOptions} /> : null}
      <div>
        <Button type="primary" onClick={onNext}>
          Next
        </Button>
      </div>
    </VerticalSpacedDiv>
  );
};

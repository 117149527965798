import { CaretRightFilled } from "@ant-design/icons";
import { deleteDoc, doc, setDoc, updateDoc } from "@firebase/firestore";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { size } from "lodash";
import pluralize from "pluralize";
import { useCallback, useContext, useState } from "react";

import { useGuardedEffect } from "../../../hooks/useGuardedEffect";
import { DB, useFirestoreDoc } from "../../../providers/FirestoreProvider";
import {
  AzureAdIntegration,
  DEFAULT_EMAIL_FIELD,
  EmailField,
  UUID_PATTERN,
} from "../../../shared/integrations/directories/azure-ad/types";
import { ErrorDisplay } from "../../Error";
import { useAuthFetch, useHasRole } from "../../Login/hook";
import { Tenant } from "../../Login/util";
import { IntegrationCard, OAuth2IntegrationCard } from "../IntegrationCard";

const EMAIL_FIELD_OPTIONS = [
  <Select.Option key="userPrincipalName">userPrincipalName</Select.Option>,
  <Select.Option key="mail">mail</Select.Option>,
];

const msAuthUrl = (adTenantId: string) =>
  `https://login.microsoftonline.com/${adTenantId}/adminconsent`;

export const EntraIdIconUrl =
  "https://learn.microsoft.com/en-us/entra/media/index/microsoft-entra-id-color.png";

/** Collects user's AD identifier (note that this is called a "tenant ID" by Microsoft) */
const PreInstall: React.FC<{ path: string }> = ({ path }) => {
  const [adTenantId, setAdTenantId] = useState<string>();
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateAdTenantId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setIsError(false);
      setAdTenantId(ev.target.value);
    },
    [setAdTenantId]
  );

  const submitAdTenantId = useCallback(() => {
    if (!adTenantId?.match(UUID_PATTERN)) {
      return setIsError(true);
    }
    setIsSubmitting(true);
    setDoc(doc(DB, path), { adTenantId, authUrl: msAuthUrl(adTenantId) }).catch(
      console.error
    );
  }, [adTenantId, path]);

  const input = (
    <Input
      onChange={updateAdTenantId}
      status={isError ? "error" : undefined}
      value={adTenantId}
    />
  );

  return isSubmitting ? (
    <Spin />
  ) : (
    <Row gutter={[10, 10]}>
      <Col span={24}>To install Entra ID, enter your directory tenant ID:</Col>
      <Col flex="auto">
        {isError ? <Tooltip title="Expected a UUID">{input}</Tooltip> : input}
      </Col>
      <Col flex="0px">
        <Button
          type="primary"
          icon={<CaretRightFilled />}
          onClick={submitAdTenantId}
        >
          Begin installation
        </Button>
      </Col>
    </Row>
  );
};

/** Azure AD integration
 */
export const AzureAd: React.FC<object> = () => {
  // Azure is similar to other OAuth integrations, but has notable
  // distinctions that require a partially different implementation:
  // - The OAuth endpoints depend on the AD tenant ID, so we must
  //   collect this ID prior to beginning the OAuth flow
  // - The returned access_token from the OAuth flow is useless, authorization
  //   is instead handled entirely by the backend without using the OAuth grant
  //   code
  //
  // The effects on the implementation here:
  // - Use a "PreInstall" component to collect the tenant ID
  // - When deleting the integration, delete both the install doc and the
  //   integration doc
  const tenantId = useContext(Tenant);
  const configPath = `/o/${tenantId}/integrations/azure-ad-install`;
  const config = useFirestoreDoc<{ adTenantId: string }>(configPath, {
    live: true,
  });
  const installation = useFirestoreDoc<AzureAdIntegration>(
    `/o/${tenantId}/integrations/azure-ad`,
    { live: true }
  );
  const [data, setData] = useState<object[]>();
  const [error, setError] = useState<any>();
  const authFetch = useAuthFetch(setError);
  const canRefresh = useHasRole("owner");

  const onRemove = useCallback(async () => {
    await Promise.all([
      deleteDoc(config.ref),
      deleteDoc(installation.ref),
    ]).catch(console.error);
  }, [config.ref, installation.ref]);

  const onUpdateEmailField = useCallback(
    (emailField: EmailField) => {
      updateDoc(installation.ref, { emailField }).catch(console.error);
    },
    [installation.ref]
  );

  useGuardedEffect(
    (cancellation) => async () => {
      if (!canRefresh) return;
      if (installation.doc === undefined) return;

      const response = await authFetch(`integrations/azure-ad/list`, {
        method: "GET",
      });
      if (response) {
        const data = await response.json();
        cancellation.guard(setData)(data.groups);
      }
    },
    [authFetch, canRefresh, installation.doc],
    setError
  );

  const logo = <img src={EntraIdIconUrl} width={20} alt="Entra ID" />;

  const sharedProps = {
    canRemove: !!config.doc || !!installation.doc,
    logo,
    integration: "azure-ad",
    onRemove,
    title: "Entra ID",
  };

  return !config.doc ? (
    <IntegrationCard {...sharedProps}>
      <PreInstall path={configPath} />
    </IntegrationCard>
  ) : (
    <OAuth2IntegrationCard
      {...sharedProps}
      integrationDoc={installation?.doc}
      mode="client_credentials"
      preamble={
        !!config.doc && !installation.doc ? (
          <div>
            Installing to directory{" "}
            <Typography.Text code>
              {config.doc?.data.adTenantId}
            </Typography.Text>
          </div>
        ) : undefined
      }
    >
      {data !== undefined ? (
        <Row gutter={[10, 20]} align="middle" justify="start">
          <Col span={24}>
            <div data-testid="azure-ad-groups-loaded">
              Detected {size(data)} user {pluralize("group", size(data))}
            </div>
          </Col>
          <Col flex="auto">
            <span style={{ marginRight: "0.5em" }}>
              Select the user column that contains each user&apos;s email:
            </span>
            <Select<EmailField>
              style={{ minWidth: "164px" }} // Size to longest option
              onChange={onUpdateEmailField}
              defaultValue={
                installation.doc?.data?.emailField ?? DEFAULT_EMAIL_FIELD
              }
            >
              {...EMAIL_FIELD_OPTIONS}
            </Select>
          </Col>
        </Row>
      ) : error !== undefined ? (
        <ErrorDisplay
          title="Could not detect Active Directory groups"
          error={error}
        />
      ) : canRefresh ? (
        <Spin />
      ) : (
        <div>Installed</div>
      )}
    </OAuth2IntegrationCard>
  );
};

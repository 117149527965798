import { Descriptions, List } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { sortBy } from "lodash";
import { useContext } from "react";
import {
  ALL_SCOPE_SENTINEL,
  integrationTitles,
} from "shared/assessment/constants";
import { grantNodeToPermissionSet } from "shared/assessment/render";
import { GrantNode } from "shared/types/assessment/data";
import styled from "styled-components";

import { ActionListDisplay } from "../../Action";
import { ConditionDisplay } from "../../cells/Condition";
import { NodeLink } from "../../cells/NodeLink";
import { RiskGroupedPermissionList } from "../../cells/PermissionAggregate";
import {
  PrincipalCell,
  PrincipalExternalLink,
  PrincipalLink,
} from "../../cells/Principal";
import { Resource } from "../../cells/Resource";
import { NodeDescriptions } from "../NodeDescriptions";
import { TrustPolicyDisplay } from "../principal/PrincipalNode";
import { RollupGrantDrilldownLink } from "./RollupGrantDrilldownLink";

const { Item } = Descriptions;

const DisplayList = styled(List)`
  max-height: "280px";
  padding-right: "15px";
  overflow-y: "auto";
`;

export const GrantNodeDisplay: React.FC<{
  node: GrantNode;
}> = ({ node }) => {
  const { scopeKey } = useContext(ScopeContext);

  return (
    <>
      <NodeDescriptions>
        <Item label="Principal">
          <PrincipalExternalLink data={node.data} id={node.data.principal} />
        </Item>
        <Item
          label={
            integrationTitles[node.data.integration ?? ALL_SCOPE_SENTINEL][
              "permissionSet"
            ]
          }
        >
          {node.data.type === "rollup" && (
            <>
              <RollupGrantDrilldownLink node={node} />
              &nbsp;
            </>
          )}
          {grantNodeToPermissionSet(node)}
        </Item>
        <Item label="Resources">
          <DisplayList>
            {node.data.resources.map((r, ix) => (
              <List.Item key={ix}>
                <NodeLink node={{ key: r, type: "resource" }} />
                &nbsp;
                <Resource resource={node} index={ix} />
              </List.Item>
            ))}
          </DisplayList>
        </Item>
        {node.data.condition && typeof node.data.condition === "object" && (
          <Item label="Condition">
            <ConditionDisplay condition={node.data.condition} />
          </Item>
        )}
        {node.data.actions && (
          <Item label="Actions">
            <ActionListDisplay actions={node.data.actions} />
          </Item>
        )}
        {scopeKey !== ALL_SCOPE_SENTINEL && (
          <Item label="Risks">
            <RiskGroupedPermissionList
              permissions={node.aggregates.permissions}
              integration={node.data.integration}
              showControl
            />
          </Item>
        )}
        <Item label="Accessible by">
          <DisplayList>
            {sortBy(node.aggregates.principals, ["principalType", "id"]).map(
              (p, ix) => (
                <List.Item key={ix}>
                  <PrincipalLink data={p} type="short" show="grant" />{" "}
                  <PrincipalCell principalData={p} />
                </List.Item>
              )
            )}
          </DisplayList>
        </Item>
      </NodeDescriptions>

      {node.data.trustedPrincipals ? (
        <TrustPolicyDisplay
          trustedPrincipals={node.data.trustedPrincipals}
        ></TrustPolicyDisplay>
      ) : null}
    </>
  );
};

import { Tenant } from "components/Login";
import { useFirestoreDoc } from "providers/FirestoreProvider";
import { useContext } from "react";
import { JiraIntegration } from "shared/integrations/trackers/jira/types";

export const useTracker = () => {
  const tenantId = useContext(Tenant);
  const jira = useFirestoreDoc<JiraIntegration>(
    `o/${tenantId}/integrations/jira`
  );

  return jira.doc?.data.state === "installed" ? jira.doc.data : undefined;
};

import { ReactNode } from "react";

export const FirstPlus = <T,>({
  items,
  first,
}: {
  items: T[];
  first: (value: T) => ReactNode;
}) => (
  <span>
    {first(items[0])}
    {items.length > 1 ? ` and ${items.length - 1} more` : ""}
  </span>
);

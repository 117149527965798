import Typography from "antd/lib/typography";
import Link from "antd/lib/typography/Link";
import { CommandDisplay } from "components/Integrations/CommandDisplay";
import { FrontendInstallContext } from "install/types";
import { compact, entries, keys } from "lodash";
import { getEnvProjectId } from "providers/FirestoreProvider";
import { ReactNode } from "react";
import { installedItems } from "shared/install/installed";
import { Instructions } from "shared/install/types";
import {
  GCLOUD_CLOUD_RUN_REGION,
  GCLOUD_COMPONENT_SETUP,
  GCLOUD_SECURITY_PERIMETER_SETUP_IMAGES,
  serviceAccountForSecurityPerimeter,
} from "shared/integrations/resources/gcloud/constants";
import {
  GcloudIntegration,
  GcloudSecurityPerimeterComponentKey,
} from "shared/integrations/resources/gcloud/types";
import {
  installedSecurityPerimeter,
  rootConfig,
} from "shared/integrations/resources/gcloud/util";
import { getEnvironment } from "utils/environment";

import { GcloudInstaller } from "./types";

const { Text, Paragraph } = Typography;

/**
 * Generates instructions for granting permissions to the security perimeter
 * service account to manage iam in the target project
 * @param context front end install context
 * @param data security perimeter setup data
 * @param targetProjectId target project id
 * @returns
 */
export const securityPerimeterSaInstructions = (
  context: FrontendInstallContext<GcloudIntegration>,
  data: (typeof GCLOUD_COMPONENT_SETUP)["iam-write-2"]["securityPerimeter"],
  targetProjectId: string
) => {
  const { projectId } = installedSecurityPerimeter(context.config);
  const { predefinedRole, customRole, requiredPermissions } = data;
  const { email: p0securityPerimeterServiceAccount } =
    serviceAccountForSecurityPerimeter(projectId);
  const writeRolePath = `projects/${targetProjectId}/roles/${customRole.id}`;
  return `# creates security perimeter custom role
gcloud iam roles create ${customRole.id} \\
  --stage=GA \\
  --project=${targetProjectId} \\
  --title="${customRole.name}" \\
  --description="Role used by security perimeter service account to manage IAM in ${targetProjectId}"

# updates permissions for the security perimeter custom role
gcloud iam roles update ${customRole.id} \\
  --project=${targetProjectId} \\
  --permissions=${requiredPermissions.join(",\\\n")}
  
# gives the p0 security perimeter service account access to the role just created
gcloud projects add-iam-policy-binding ${targetProjectId} \\
  --member="serviceAccount:${p0securityPerimeterServiceAccount}" \\
  --condition=None \\
  --role="${writeRolePath}"
  
# provides predefine role access to the p0 security perimeter service account 
gcloud projects add-iam-policy-binding ${targetProjectId}  \\
  --member="serviceAccount:${p0securityPerimeterServiceAccount}" \\
  --condition=None \\
  --role="${predefinedRole}"`;
};

/**
 * These are commands for migrating old customers to security perimeter
 * @param context front end install context
 * @returns
 */
const permissionCommands = (
  context: FrontendInstallContext<GcloudIntegration>
) => ({
  "iam-write-security-perimeter": {
    itemCommands: (
      p0securityPerimeterServiceAccount: string,
      serviceAccountEmail: string
    ) => {
      const {
        customRole: readerRole,
        predefinedRole: readerPredefinedRole,
        requiredPermissions: readerRequiredPermissions,
      } = GCLOUD_COMPONENT_SETUP["iam-write-2"];
      const {
        customRole: securityPerimeterRole,
        predefinedRole: securityPerimeterPredefinedRole,
        requiredPermissions: securityPerimeterRequiredPermissions,
      } = GCLOUD_COMPONENT_SETUP["iam-write-2"]["securityPerimeter"];

      const { customRole: iamAdminRole } = GCLOUD_COMPONENT_SETUP["iam-write"];

      // TODO: remove all the iam-write specific instructions once all the clients are moved to the new security-perimeter
      const projectItems = installedItems("iam-write", context.config);
      return entries(projectItems).length
        ? `
# migrate existing installs to the new security perimeter
for project in ${keys(projectItems).join(" ")}; do
  
  writeRolePath="projects/$project/roles/${securityPerimeterRole.id}"
  adminRolePath="projects/$project/roles/${iamAdminRole.id}"
  readerRolePath="projects/$project/roles/${readerRole.id}"
  
  # creates reader role
  gcloud iam roles create ${readerRole.id} \\
    --stage=GA \\
    --project=$project \\
    --title="${readerRole.name}" \\
    --description="Role used by p0 service account to read iam in $project"
  
  # adds permissions to the reader role
  gcloud iam roles update ${readerRole.id} \\
    --project=$project \\
    --add-permissions=${readerRequiredPermissions.join(",\\\n")}

  # gives the p0 service account access to the reader role just created
  gcloud projects add-iam-policy-binding $project \\
    --member="serviceAccount:${serviceAccountEmail}" \\
    --condition=None \\
    --role="$readerRolePath"

  # provides predefined role access to the p0 service account
  gcloud projects add-iam-policy-binding $project \\
    --member="serviceAccount:${serviceAccountEmail}" \\
    --condition=None \\
    --role="${readerPredefinedRole}"

  # creates writer role
  gcloud iam roles create ${securityPerimeterRole.id} \\
    --stage=GA \\
    --project=$project \\
    --title="${securityPerimeterRole.name}" \\
    --description="Role used by p0 security perimeter service account to manage iam in $project"

  # updates permissions for the writer role
  gcloud iam roles update ${securityPerimeterRole.id}  \\
    --project=$project \\
    --permissions=${securityPerimeterRequiredPermissions.join(",\\\n")}
   
  # gives the security perimeter service account access to the writer role just created
  gcloud projects add-iam-policy-binding $project \\
    --member="serviceAccount:${p0securityPerimeterServiceAccount}" \\
    --condition=None \\
    --role="$writeRolePath"

  # provides predefine role access to the security perimeter service account
  gcloud projects add-iam-policy-binding $project \\
    --member="serviceAccount:${p0securityPerimeterServiceAccount}" \\
    --condition=None \\
    --role="${securityPerimeterPredefinedRole}"

  # removes the p0 service account from the old admin role
  gcloud projects remove-iam-policy-binding $project \\
    --member="serviceAccount:${serviceAccountEmail}" \\
    --condition=None \\
    --role="$adminRolePath"
  
  # removes the p0 service account from the predefined role from older installations
  gcloud projects remove-iam-policy-binding $project \\
    --member="serviceAccount:${serviceAccountEmail}" \\
    --condition=None \\
    --role="${securityPerimeterPredefinedRole}"


done
  `
        : undefined;
    },
  },
});

/**
 *
 * @param config
 * @returns
 */
export const securityPerimeterHelperCommands = (config: {
  context: FrontendInstallContext<GcloudIntegration>;
  component: GcloudSecurityPerimeterComponentKey;
  securityPerimeterServiceAccount: string;
  serviceAccountEmail: string;
}) =>
  permissionCommands(config.context)[config.component].itemCommands(
    config.securityPerimeterServiceAccount,
    config.serviceAccountEmail
  );

export const securityPerimeterCommands = (
  context: FrontendInstallContext<GcloudIntegration>,
  component: GcloudSecurityPerimeterComponentKey,
  item: {
    projectId: string;
    allowedDomains: string;
    imageDigest: string;
  }
) => {
  const { cloudRunNameFor, imageName } =
    GCLOUD_SECURITY_PERIMETER_SETUP_IMAGES[component];
  const { environment } = getEnvironment();
  const envProjectId = getEnvProjectId();
  const cloudRunName = cloudRunNameFor(environment);
  const { serviceAccountEmail } = rootConfig(context.config);
  const { projectId, allowedDomains, imageDigest } = item;
  const { name: securityPerimeterName, email: securityPerimeterEmail } =
    serviceAccountForSecurityPerimeter(projectId);
  const commands = securityPerimeterHelperCommands({
    context,
    component,
    securityPerimeterServiceAccount: securityPerimeterEmail,
    serviceAccountEmail,
  });
  const { customRole, requiredPermissions, services } =
    GCLOUD_COMPONENT_SETUP["iam-write-security-perimeter"];

  return compact([
    {
      header: `Please execute the following commands ${
        !commands ? "and click 'Next'" : ""
      }:`,
      command: `# creates service account for p0 security perimeter
gcloud iam service-accounts create ${securityPerimeterName} \\
  --display-name="P0 Security Perimeter Service Account" \\
  --description="Service account to manage p0 security perimeter" \\
  --project=${projectId}

# enable iam and cloud run services
${services
  .map((service) => `gcloud services enable ${service} --project=${projectId}`)
  .join("\n")}

# deploys p0 security perimeter
gcloud run deploy ${cloudRunName} --image=${imageName}:${imageDigest} \\
  --service-account=${securityPerimeterEmail} \\
  --project=${projectId} \\
  --region=${GCLOUD_CLOUD_RUN_REGION} --port=8080 \\
  --no-allow-unauthenticated \\
  --set-env-vars DOMAIN_ALLOW_PATTERN=${allowedDomains} \\
  --set-env-vars GCLOUD_PROJECT=${envProjectId} 
  
# creates p0 security perimeter invoker role, this is assumed by p0 service account
gcloud iam roles create ${customRole.id} \\
  --stage=GA \\
  --project=${projectId} \\
  --title="P0 security perimeter invoker" \\
  --description="${customRole.name}"
# adds permissions to the security perimeter invoker role
gcloud iam roles update ${customRole.id} \\
  --project=${projectId} \\
  --add-permissions=${requiredPermissions.join(",\\\n")}
      
# gives the p0 service account access to the role just created
gcloud run services add-iam-policy-binding ${cloudRunName} \\
  --region=${GCLOUD_CLOUD_RUN_REGION} \\
  --project=${projectId} \\
  --member="serviceAccount:${serviceAccountEmail}" \\
  --role="projects/${projectId}/roles/${customRole.id}"
  `,
    },
    commands
      ? {
          header: `Please execute the following commands to grant permissions for existing installs and click 'Next'`,
          command: commands,
        }
      : undefined,
  ]);
};

export const securityPerimeterInstructions: (
  component: GcloudSecurityPerimeterComponentKey
) => GcloudInstaller["iam-write-security-perimeter"]["instructions"] =
  (component) =>
  (context, id, item, _field): Instructions<ReactNode> => ({
    help: (
      <Paragraph>
        To install security perimeter on this account, provision P0&apos;s
        access using{" "}
        <Link
          href={`https://console.cloud.google.com/cloudshelleditor?project=${id}&cloudshell=true`}
          target="_blank"
          rel="noopener"
        >
          Cloud Shell
        </Link>
      </Paragraph>
    ),
    commands: {
      shell: [
        ...securityPerimeterCommands(context, component, {
          ...item,
          projectId: id,
        }),
      ],
    },
  });

export const cloudRunUrlInstaller: GcloudInstaller["iam-write-security-perimeter"]["items"]["cloudRunUrl"] =
  {
    labeler: (
      context: FrontendInstallContext<GcloudIntegration>,
      id: string
    ) => {
      const name = GCLOUD_SECURITY_PERIMETER_SETUP_IMAGES[
        "iam-write-security-perimeter"
      ].cloudRunNameFor(context.environment);
      return (
        <>
          Fetch the cloud run url from service description with{" "}
          <Text code>gcloud</Text>:
          <br />
          <CommandDisplay
            commands={`gcloud run services describe ${name} --project=${id} --platform managed --region ${GCLOUD_CLOUD_RUN_REGION} --format 'value(status.url)'`}
          />
        </>
      );
    },
  };

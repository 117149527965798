import { DatabaseFilled, ToolFilled } from "@ant-design/icons";
import { Badge, Card, Divider, List, Typography } from "antd";
import useFlags from "launchdarkly-react-client-sdk/lib/useFlags";
import { useCallback } from "react";
import styled from "styled-components";

import { Page } from "../App/Page";
import { Heading } from "../Heading";
import { Logo } from "../Integrations/Logo";
import {
  approvers,
  directories,
  notifiers,
  resourceIntegrations,
} from "../Integrations/constants";
import { IntegrationData } from "../Integrations/types";

const StyledParagraph = styled(Typography.Paragraph)`
  &:last-child {
    margin-bottom: 0;
  }
`;

export const IntegrationsSandbox: React.FC = () => {
  const flags = useFlags();
  const renderCard = useCallback(
    (item: IntegrationData) => <SandboxIntegrationCard item={item} />,
    []
  );
  return (
    <Page title="All of P0's Integrations">
      <Heading title="All of P0's Integrations" />
      <Divider orientation="left">Cloud Resources</Divider>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={resourceIntegrations(flags)}
        renderItem={renderCard}
      />
      <Divider orientation="left">Directories</Divider>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={directories}
        renderItem={renderCard}
      />
      <Divider orientation="left">Notifiers</Divider>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={notifiers(flags)}
        renderItem={renderCard}
      />
      <Divider orientation="left">Approvers</Divider>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={approvers}
        renderItem={renderCard}
      />
    </Page>
  );
};

const SandboxIntegrationCard: React.FC<{ item: IntegrationData }> = ({
  item,
}) => {
  const baseCard = () => (
    <Card
      title={
        <>
          <span style={{ marginRight: 10 }}>
            <Logo logo={item.icon} title={item.label} />
          </span>
          {item.label}
        </>
      }
    >
      <StyledParagraph>
        <a href={item.docs.install} target="_blank" rel="noreferrer">
          <ToolFilled style={{ marginRight: 10 }} />
          Installation
        </a>
      </StyledParagraph>
      {item.docs.request && (
        <StyledParagraph>
          <a href={item.docs.request} target="_blank" rel="noreferrer">
            <DatabaseFilled style={{ marginRight: 10 }} />
            How to make an access request
          </a>
        </StyledParagraph>
      )}
    </Card>
  );
  return (
    <List.Item>
      {item.isPro ? (
        <Badge.Ribbon text="Pro" color="green">
          {baseCard()}
        </Badge.Ribbon>
      ) : (
        baseCard()
      )}
    </List.Item>
  );
};

import { Descriptions, Grid } from "antd";

export const NodeDescriptions: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const { md } = Grid.useBreakpoint();

  return (
    <Descriptions
      bordered
      column={1}
      labelStyle={{ fontWeight: 700, maxWidth: md ? "175px" : undefined }}
      layout={md ? "horizontal" : "vertical"}
    >
      {children}
    </Descriptions>
  );
};

import { DevEnv } from "../../../../types/environment";
import { AwsPolicyOptions } from "./policies";
import { roleInstallCommands, roleTerraform } from "./shared";

export const inventoryInstallCommands = (
  context: DevEnv,
  options: AwsPolicyOptions,
  args: {
    gcloudServiceAccountId: string;
    awsAccountId: string;
  }
) => roleInstallCommands("inventory", context, options, args);

export const inventoryTerraform = (
  context: DevEnv,
  options: AwsPolicyOptions,
  gcloudServiceAccountId: string
) => roleTerraform("inventory", context, options, gcloudServiceAccountId);

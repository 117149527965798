import { ExtendedIntegrationData } from "../types";

enum ResourceTypes {
  unlimited = -1,
}
export const canNavigateToAddIntegration = (
  record: ExtendedIntegrationData,
  numInstalled: number,
  numberOfResourcesSupported: number
): boolean => {
  // Allow any resource click if none are installed
  if (numInstalled === 0) {
    return true;
  }

  // Allow all resource click if the plan supports unlimited resources
  if (numberOfResourcesSupported === ResourceTypes.unlimited) {
    return true;
  }

  // Allow a single resource if its already installed
  if (record.counts.installed >= 1 || record.counts.inProgress >= 1) {
    return true;
  }

  // Allow up to the number of resources supported by the plan
  // or the number of installed resources, whichever is greater
  return numInstalled < numberOfResourcesSupported;
};

import { AppRoutes } from "components/App/routeConstants";
import { AuthzButton } from "components/common/AuthzButton";
import { VerticalSpacedDiv } from "components/divs";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";

import { Heading } from "../../Heading";
import { AssessmentGraph } from "../components/AssessmentGraph";
import { NewMonitorModal } from "../components/MonitorAdmin";
import { ScopeSelect } from "../components/ScopeSelect";
import { DetailsColumn } from "../components/cells/DetailsColumn";
import { ScopeContext } from "../contexts/ScopeContext";
import { useControls } from "../hooks/useControls";

export const Explore: React.FC = () => {
  const { orgSlug, assessmentId } = useParams();
  const { controls, setControls } = useControls();
  const [modalOpen, setModalOpen] = useState(false);
  const { graph, scopeKey, step } = useContext(ScopeContext);
  const { runMetaGraph } = useFlags();

  const action = useMemo(() => {
    if (!assessmentId || !orgSlug || !scopeKey) return undefined;
    return [
      DetailsColumn((data) => {
        const base = `/o/${orgSlug}/${AppRoutes.IamAssessment}/${assessmentId}/explore`;
        const end = `/${encodeURIComponent(data.type)}/${encodeURIComponent(
          data.key
        )}?where=${encodeURIComponent(
          controls.where
        )}&show=${encodeURIComponent(controls.show)}&scope=${scopeKey}`;
        return {
          key: data.key,
          to: `${base}${end}`,
        };
      }),
    ];
  }, [assessmentId, controls.show, controls.where, orgSlug, scopeKey]);

  const createDisabled = useMemo(() => {
    return !graph || controls.where === "";
  }, [graph, controls.where]);

  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);
  const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);

  return (
    <>
      <Heading title="IAM Data" />
      {!createDisabled && (
        <div style={{ marginBottom: "0.7em" }}>
          <AuthzButton roles={["owner", "iamOwner"]} onClick={openModal}>
            Create a new monitor from this search
          </AuthzButton>
        </div>
      )}
      <VerticalSpacedDiv>
        <ScopeSelect includeAll={runMetaGraph} />
        <AssessmentGraph
          graph={graph ?? { nodes: [] }}
          scopeKey={scopeKey}
          controls={controls}
          onControls={setControls}
          extraColumns={action}
          step={step}
        />
      </VerticalSpacedDiv>
      <NewMonitorModal
        open={modalOpen}
        footer={false}
        closeModal={closeModal}
        searchTerm={controls.where}
        show={controls.show}
      />
    </>
  );
};

import { Heading } from "../../Heading";
import { FindingsSelect } from "../components/FindingsSelect";
import { AssessmentSummary } from "../components/JobSummary";
import { MonitorList } from "../components/MonitorList";

export const Findings: React.FC = () => {
  return (
    <>
      <Heading title="IAM Assessment Findings" />
      {/* Monitors */}
      <div style={{ maxWidth: 800 }}>
        {/* Overview controls */}
        <div
          style={{
            backgroundColor: "#f5f5f5",
            maxWidth: 800,
            marginBottom: 32,
            padding: "16px 24px",
          }}
        >
          <AssessmentSummary />
        </div>
        {/* Monitors */}
        <div style={{ marginBottom: "8px" }}>
          <FindingsSelect includeAll includeExport />
        </div>
        <MonitorList actionTypes={[]} filterFindings />
      </div>
    </>
  );
};

import { Tabs, Typography } from "antd";

import { ActiveEvidence } from "./ActiveEvidencePage";
import { ActiveRequests } from "./ActiveRequestsPage";

export const TabbedActivity: React.FC = () => {
  return (
    <div>
      <Typography.Title level={2}>Activity</Typography.Title>
      <Tabs
        defaultActiveKey="permission-requests"
        items={[
          {
            key: "permission-requests",
            label: "Access Requests",
            children: <ActiveRequests hideHeader />,
          },
          {
            key: "evidence",
            label: "Pre-Approved Access",
            children: <ActiveEvidence />,
          },
        ]}
      />
    </div>
  );
};

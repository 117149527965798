import { Button, ButtonProps } from "antd";
import { UserAuthz, UserRole } from "components/Login";
import React, { useContext, useMemo } from "react";

interface AuthzButtonProps extends ButtonProps {
  roles: UserRole | UserRole[] | "all";
  disabledProps?: boolean;
}

export const AuthzButton: React.FC<AuthzButtonProps> = ({
  roles,
  disabledProps,
  children,
  ...otherProps
}) => {
  const userAuthz = useContext(UserAuthz);
  const hasRole = useMemo(() => {
    if (roles === "all") {
      return true;
    }
    if (Array.isArray(roles)) {
      return roles.some((r) => userAuthz.has(r));
    }
    return userAuthz.has(roles);
  }, [roles, userAuthz]);

  return (
    <Button disabled={!hasRole || disabledProps} {...otherProps}>
      {children}
    </Button>
  );
};

import { SlackOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { AppRoutes } from "components/App/routeConstants";
import { formatDistance } from "date-fns";
import { FirestoreDoc } from "providers/FirestoreProvider";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { isa } from "shared/types/is";
import { PermissionRequest } from "shared/types/permission";
import { RequestStatuses } from "shared/types/request-status";
import styled from "styled-components";

import { SLACK_TLD, requestDescription } from "../requestUtils";
import { PermissionLocation } from "./PermissionLocation";
import { RequestStatusTag } from "./RequestStatusTag";
import "./Requests.less";

const StyledTable = styled(Table<PermissionRequest>)`
  .ant-table-thead > tr > th {
    text-wrap: balance;
    // Avoid break in middle of words
    overflow-wrap: normal;
  }
  td {
    .ant-typography {
      // Prevent overly aggressive Location break
      word-break: normal;
    }
  }
`;

export const conversationLink = (link: string) => {
  const url = new URL(link);
  // Prevent most XSS with this sanitizer; note that this is theoretically redundant
  // as our backend should only write the link with trusted data from Slack anyway
  const isValid = url.hostname.endsWith(SLACK_TLD);
  return (
    isValid && (
      <Button
        type="link"
        href={link}
        icon={<SlackOutlined />}
        target="_blank"
        rel="noopener noreferrer"
      />
    )
  );
};

const columns: ColumnsType<PermissionRequest> = [
  {
    title: "Status",
    dataIndex: ["status"],
    render: (status: string, data) => {
      if (!isa(RequestStatuses, status)) return null;
      return (
        <>
          <div style={{ marginBottom: 4 }}>
            <RequestStatusTag status={status} />
          </div>
          {data.lastUpdatedTimestamp && (
            <div className="sub-text">
              {formatDistance(data.lastUpdatedTimestamp, Date.now(), {
                addSuffix: true,
              })}
            </div>
          )}
        </>
      );
    },
  },
  {
    title: "Description",
    render: (_, data) => (
      <>
        <div>
          {requestDescription(data)}
          {data.notifications?.slack?.approvalConversationUrl &&
            conversationLink(data.notifications.slack.approvalConversationUrl)}
        </div>
        <div className="sub-text">{data.principal}</div>
      </>
    ),
  },
  {
    title: "Location",
    render: (_, data) => <PermissionLocation permission={data} />,
  },
  {
    title: "Actions",
    render: (data) => (
      <Button
        type="link"
        size="small"
        // Memoization performed by Table
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => data.showRequestInfo(data.id)}
      >
        Details
      </Button>
    ),
  },
];

type Props = {
  requests: FirestoreDoc<PermissionRequest>[];
};

export const Requests: React.FC<Props> = ({ requests }) => {
  const navigate = useNavigate();
  const { orgSlug } = useParams();
  const showRequestInfo = useCallback(
    (id: string) => {
      navigate(`/o/${orgSlug}/${AppRoutes.Requests}/${id}`);
    },
    [navigate, orgSlug]
  );
  const data = useMemo(
    () => requests.map((doc) => ({ id: doc.id, ...doc.data, showRequestInfo })),
    [requests, showRequestInfo]
  );

  return (
    <StyledTable
      size="small"
      columns={columns}
      dataSource={data}
      rowKey="id"
      scroll={{ y: `calc(100vh - 285px)`, x: true }}
    />
  ); // Each row must have a unique ID; silence the runtime warning by adding the rowKey attribute
};

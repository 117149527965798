import { Typography } from "antd";
import { formatDistance } from "date-fns";
import { Permission } from "shared/types/permission";

import { requestDescription } from "../requestUtils";
import { PermissionLocation } from "./PermissionLocation";

export const PermissionPreview: React.FC<{
  requestor: string;
  verb: string;
  reason?: string;
  permission: Permission;
  createdAt?: number;
  approver?: string;
}> = ({
  requestor,
  verb,
  reason,
  permission,
  createdAt: approvedAt,
  approver,
}) => {
  return (
    <div>
      <Typography.Paragraph style={!reason ? { marginBottom: 0 } : {}}>
        <Typography.Text code>{requestor}</Typography.Text> {verb}{" "}
        <Typography.Text strong>
          {requestDescription(permission)}
        </Typography.Text>{" "}
        in <PermissionLocation permission={permission} />.
        {approvedAt && (
          <Typography.Text>
            {" "}
            Approved {formatDistance(approvedAt, Date.now())} ago by{" "}
            <Typography.Text code>{approver}</Typography.Text>.
          </Typography.Text>
        )}
      </Typography.Paragraph>
      {reason && (
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          <Typography.Text type="secondary">Reason: {reason}</Typography.Text>
        </Typography.Paragraph>
      )}
    </div>
  );
};

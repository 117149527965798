import { isAwsService } from "./constants";
import { AwsService } from "./types";

export const policyName = (permissionSet: string) =>
  permissionSet.includes("/") ? permissionSet.split("/").at(-1) : permissionSet;

export const samlProviderArn = (accountId: string, samlProviderName: string) =>
  `arn:aws:iam::${accountId}:saml-provider/${samlProviderName}`;

export const nameFromQualified = (qualifiedRole: string) =>
  qualifiedRole.split("/").pop() || qualifiedRole;

const ARN_PATTERN =
  /^arn:(aws|aws-us-gov|aws-cn|aws-iso|aws-iso-b):(.*):(.*):(.*):(.*)$/;

export const isArn = (value: string) => value.match(ARN_PATTERN);

type BaseArnInfo = {
  region?: string;
  accountId?: string;
  resourceType?: string;
  name: string;
};

type ValidServiceArnInfo = {
  service: AwsService;
} & BaseArnInfo;

type AnyServiceArnInfo = {
  service: string;
} & BaseArnInfo;

/**
 * The general format for an ARN looks like this:
 * arn:partition:service:region:account-id:resource-id
 * arn:partition:service:region:account-id:resource-type/resource-id
 * arn:partition:service:region:account-id:resource-type:resource-id
 *
 * See https://towardsthecloud.com/amazon-resource-names-arns
 *
 * Region and accountId are optional because some services don't require them. E.g. S3 ARN: arn:aws:s3:::p0-test-bucket-1
 *
 * @param validate If true then validates that the parsed service is supported by P0 for AWS resource-based access.
 */
export function arnInfo(arn?: string): ValidServiceArnInfo;
export function arnInfo(arn: string, skipValidation: true): AnyServiceArnInfo;
export function arnInfo(
  arn?: string,
  skipValidation?: boolean
): AnyServiceArnInfo | ValidServiceArnInfo {
  const parts = arn?.split(":") || [];
  const service = parts[2];
  if (!skipValidation && !isAwsService(service)) {
    throw new Error(`Service ${service} is not supported`);
  }
  return {
    service,
    region: parts[3] === "" ? undefined : parts[3],
    accountId: parts[4] === "" ? undefined : parts[4],
    resourceType:
      parts.length == 7
        ? parts[5] // arn:partition:service:region:account-id:resource-type:resource-id
        : parts[5].includes("/")
        ? parts[5].split("/")[0] // arn:partition:service:region:account-id:resource-type/resource-id
        : undefined, // arn:partition:service:region:account-id:resource-id
    name:
      parts.length == 7
        ? parts[6] // arn:partition:service:region:account-id:resource-type:resource-id
        : parts[5].includes("/")
        ? parts[5].split("/")[1] // arn:partition:service:region:account-id:resource-type/resource-id
        : parts[5], // arn:partition:service:region:account-id:resource-id
  };
}

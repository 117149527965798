import { assertNever, assertNeverError } from "../../../types";
import { GrantEntry } from "../../../types/assessment/data";
import { KUBE_SYSTEM_NAMESPACES } from "./constants";
import { Api, K8sPrivilege, KubernetesRole } from "./types";

// The separator does not occur in Kubernetes object names and IDs
// See https://kubernetes.io/docs/concepts/overview/working-with-objects/names/
export const K8S_SEPARATOR = "/";

export const KUBE_SYSTEM_ROLE_BINDING_PREFIXES = KUBE_SYSTEM_NAMESPACES.map(
  (namespace: string) =>
    // the beginning of the output of roleBindingToPermissionSet function
    ["Role", namespace, ""].join(K8S_SEPARATOR)
);

export const KUBE_SYSTEM_PRINCIPAL_PREFIXES = KUBE_SYSTEM_NAMESPACES.map(
  // the beginning of the output of toEntryPrincipal function
  (namespace: string) => [namespace, ""].join(K8S_SEPARATOR)
);

export const grantKey = (entry: GrantEntry) => {
  const { principalType, principal, permissionSet } = entry;
  return `${principalType}:${principal}:${permissionSet}`;
};

export const bindingToRole = (binding: Api.Binding): KubernetesRole => {
  switch (binding.kind) {
    case "ClusterRoleBinding":
      return {
        kind: "ClusterRole",
        name: binding.roleRef.name,
      };
    case "RoleBinding":
      return binding.roleRef.kind === "Role"
        ? {
            kind: "Role",
            namespace: binding.metadata.namespace,
            name: binding.roleRef.name,
          }
        : binding.roleRef.kind === "ClusterRole"
        ? {
            kind: "ClusterRole",
            name: binding.roleRef.name,
          }
        : assertNever(binding.roleRef.kind);
    default:
      throw assertNeverError(binding);
  }
};

export const toEntryPermissionSet = (
  binding: Api.Binding,
  role: KubernetesRole,
  ruleIndex: number
) => {
  // You cannot assign a namespaced Role via a non-namespaced ClusterRoleBinding. 
  // We likely have bug somewhere in the processing if we end up with this combination,
  // meaning our graph will be flawed. Fail the assessment rather than showing wrong data.
  if (binding.kind === "ClusterRoleBinding" && role.kind === "Role") {
    throw new Error("ClusterRoleBinding cannot reference a Role");
  }
  return `${roleIdentifier(role)}:${ruleIndex}`;
};

export const toEntryPrincipalType: (
  kind: Api.SubjectKind
) => GrantEntry["principalType"] = (kind) => {
  switch (kind) {
    case "Group":
      return "group";
    case "ServiceAccount":
      return "service-account";
    case "User":
      return "user";
    default:
      throw assertNeverError(kind);
  }
};

export const toEntryPrincipal = (name: string, namespace?: string) =>
  namespace ? [namespace, name].join(K8S_SEPARATOR) : name;

export const friendlyApiGroup = (apiGroup: string) =>
  apiGroup === "" ? "core" : apiGroup;

export const toEntryResources = (rule: Api.Rule, resourceNamespace: string) =>
  // If `resourceNames` is missing it means that the rule applies to all resources.
  // Replace with a `*` to indicate this, even though it is not a valid resource name.
  (rule.resourceNames ?? ["*"]).flatMap((resourceName) =>
    rule.resources.flatMap((resourceType) =>
      // apiGroups must be present at this point - we don't iterate over rules that don't have it
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rule.apiGroups!.map((apiGroup) =>
        [
          friendlyApiGroup(apiGroup),
          resourceType,
          resourceNamespace,
          resourceName,
        ].join(K8S_SEPARATOR)
      )
    )
  );

/** The part after the apiGroup corresponds to GCP's dot-separated GKE permissions.
 * E.g. "container.pods.create" - except that the first "container" part is omitted here. */
export const toEntryPrivilege = (p: K8sPrivilege) =>
  `${p.apiGroup}/${p.resourceType}.${p.verb}`;

export function roleIdentifier(role: KubernetesRole): string;
export function roleIdentifier(role: Api.Role): string;
export function roleIdentifier(role: Api.Role | KubernetesRole): string {
  // Api.Role
  if ("metadata" in role) {
    return role.kind === "ClusterRole"
      ? [role.kind, role.metadata.name].join(K8S_SEPARATOR)
      : role.kind === "Role"
      ? [role.kind, role.metadata.namespace, role.metadata.name].join(
          K8S_SEPARATOR
        )
      : assertNever(role);
  }
  // KubernetesRole
  return role.kind === "ClusterRole"
    ? [role.kind, role.name].join(K8S_SEPARATOR)
    : role.kind === "Role"
    ? [role.kind, role.namespace, role.name].join(K8S_SEPARATOR)
    : assertNever(role);
}

import { assertNever } from "../../../../types";
import { AppContext, DevEnv, Environment } from "../../../../types/environment";
import {
  CREATED_BY_CLOUD_SHELL,
  MANAGED_BY_TERRAFORM,
  P0_TAG_NAME,
} from "../constants";
import { AwsPolicyUse } from "../types";
import {
  AwsPolicyOptions,
  iamAssessorPolicy,
  iamManagerPolicy,
  resourceInventoryPolicy,
} from "./policies";

export const ENV_TO_P0_AWS_SUFFIX: { [key in Environment]: string } = {
  dev: "Dev",
  test: "Dev",
  "prod-cna-central": "",
  "prod-splunk-live": "",
  "prod-splunk-nonprod": "",
  prod: "",
  stage: "Stage",
};

export const p0GrantsPathSegment = (context: DevEnv) =>
  `p0-grants${
    context.environment === "dev" ? `-dev-${context.developer}` : ""
  }`;

export const awsSuffix = (context: DevEnv) =>
  "developer" in context
    ? context.developer
        .split("-")
        // can't use lodash since this is imported to FE
        .map((n) => n.slice(0, 1).toUpperCase() + n.slice(1).toLowerCase())
        .join("")
    : "";

export const awsRoleName = (context: DevEnv, use: AwsPolicyUse): string =>
  `P0RoleIam${
    use === "iam-assessment"
      ? "Assessor"
      : use === "iam-write"
      ? "Manager"
      : use === "inventory"
      ? "ResourceLister"
      : assertNever(use)
  }${
    "developer" in context
      ? `${ENV_TO_P0_AWS_SUFFIX.dev}${awsSuffix(context)}`
      : ENV_TO_P0_AWS_SUFFIX[context.environment]
  }`;

/** Trust policy for all roles assumed by the P0 backend */
export const p0TrustPolicy = (gcloudServiceAccountId: string) => ({
  Version: "2012-10-17",
  Statement: [
    {
      Effect: "Allow",
      Principal: {
        Federated: "accounts.google.com",
      },
      Action: "sts:AssumeRoleWithWebIdentity",
      Condition: {
        StringEquals: {
          "accounts.google.com:aud": gcloudServiceAccountId,
        },
      },
    },
  ],
});

export const p0InstallCommands = (args: {
  gcloudServiceAccountId: string;
  awsAccountId: string;
  policy: object;
  roleName: string;
  use: AwsPolicyUse;
}) => {
  const trustPolicy = p0TrustPolicy(args.gcloudServiceAccountId);
  const trustPolicyJson = JSON.stringify(trustPolicy, undefined, 2);
  const commands = `
export CALLER=$(aws sts get-caller-identity --query Arn --output text) && \\
aws iam create-role \\
  --tags Key="${P0_TAG_NAME}",Value="${CREATED_BY_CLOUD_SHELL} by \${CALLER}" \\
  --role-name "${args.roleName}" \\
  --assume-role-policy-document '${trustPolicyJson}' \\
  && \\
aws iam put-role-policy \\
  --role-name "${args.roleName}" \\
  --policy-name "${args.roleName}Policy" \\
  --policy-document '${JSON.stringify(args.policy, undefined, 2)}'
`;
  return commands.trim();
};

export const p0InstallTerraform = (args: {
  gcloudServiceAccountId: string;
  policy: object;
  roleName: string;
  use: AwsPolicyUse;
}) => {
  const resourceId = args.use.replace("-", "_");
  const trustPolicy = p0TrustPolicy(args.gcloudServiceAccountId);
  const hcl = `data "aws_caller_identity" "current" {}

locals {
  aws_account_id = data.aws_caller_identity.current.account_id
}

resource "aws_iam_role" "p0_aws_${resourceId}_role" {
  name = "${args.roleName}"

  assume_role_policy = <<EOF
${JSON.stringify(trustPolicy, undefined, 2)}
EOF

  inline_policy {
    name = "${args.roleName}Policy"
    policy = <<EOF
${JSON.stringify(args.policy, undefined, 2)}
EOF
  }

  tags = {
    ${P0_TAG_NAME} = "${MANAGED_BY_TERRAFORM}"
  }
}`;
  return hcl;
};

/** Default policy statements for users to resume and terminate their sessions */
export const p0SharedSshPolicyStatements = (accountId: string) => [
  {
    Action: ["ssm:TerminateSession"],
    Effect: "Allow",
    Resource: `arn:aws:ssm:*:${accountId}:session/*`,
    Condition: {
      StringLike: {
        "ssm:resourceTag/aws:ssmmessages:session-id": ["${aws:userid}*"],
      },
    },
  },
  // AWS docs state that "ssm:ResumeSession" also supports target-id and session-id tags (like "ssm:TerminateSession" above)
  // but in practice that doesn't work. See https://docs.aws.amazon.com/systems-manager/latest/userguide/getting-started-restrict-access-examples.html#restrict-access-example-user-sessions
  // We can only constrain resumes to sessions that the user started on any instance.
  // The below pattern of ${aws:username} variable as the Resource is not supported for federated users.
  {
    Action: ["ssm:ResumeSession"],
    Effect: "Allow",
    Resource: [`arn:aws:ssm:*:${accountId}:session/` + "${aws:username}-*"],
  },
];

/** Default policy for users to resume and terminate their sessions */
export const p0GrantRoleSshPolicy = (accountId: string) => ({
  Version: "2012-10-17",
  Statement: p0SharedSshPolicyStatements(accountId),
});

const useToPolicyGenerator: Record<
  AwsPolicyUse,
  (args: {
    context: DevEnv;
    awsAccountId: string;
    roleName: string;
    options: AwsPolicyOptions;
  }) => object
> = {
  inventory: resourceInventoryPolicy,
  "iam-assessment": iamAssessorPolicy,
  "iam-write": iamManagerPolicy,
};

export const roleInstallCommands = (
  use: AwsPolicyUse,
  context: DevEnv,
  options: AwsPolicyOptions,
  args: {
    gcloudServiceAccountId: string;
    awsAccountId: string;
  }
) => {
  const roleName = awsRoleName(context, use);
  const policy = useToPolicyGenerator[use]({
    ...args,
    context,
    roleName,
    options,
  });
  return p0InstallCommands({
    ...args,
    policy,
    roleName,
    use,
  });
};

export const roleTerraform = (
  use: AwsPolicyUse,
  context: DevEnv,
  options: AwsPolicyOptions,
  gcloudServiceAccountId: string
) => {
  const roleName = awsRoleName(context, use);
  const policy = useToPolicyGenerator[use]({
    context,
    awsAccountId: "${local.aws_account_id}",
    options,
    roleName,
  });
  return p0InstallTerraform({
    gcloudServiceAccountId,
    policy,
    roleName,
    use: "iam-write",
  });
};

export const expectedPolicy = (
  use: AwsPolicyUse,
  context: AppContext,
  options: AwsPolicyOptions,
  awsAccountId: string
) =>
  useToPolicyGenerator[use]({
    context,
    awsAccountId,
    options,
    roleName: awsRoleName(context, use),
  });

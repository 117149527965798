import { doc, setDoc } from "@firebase/firestore";
import { Col, Row, Switch, Typography } from "antd";
import { TenantAwareLink } from "components/common/TenantAwareLink";
import { useCallback, useContext } from "react";

import { DB, useFirestoreDoc } from "../../providers/FirestoreProvider";
import { Workflow } from "../../shared/types";
import { Tenant } from "../Login/util";
import { CustomExpiryOptions } from "./CustomExpiryOptions";

export const ApprovalRequirements: React.FC<{ isSandbox?: boolean }> = ({
  isSandbox,
}) => {
  const tenantId = useContext(Tenant);
  const path = `o/${tenantId}/workflows/`;
  const defaultWorkflow = useFirestoreDoc<Workflow>(
    !isSandbox ? `${path}_default_` : undefined,
    {
      live: true,
    }
  );
  const latestWorkflow = useFirestoreDoc<Workflow>(
    !isSandbox ? `${path}latest` : undefined,
    {
      live: true,
    }
  );
  const isDisabled = isSandbox || !!latestWorkflow?.doc;
  const allowOneParty = !!defaultWorkflow?.doc?.data.allowOneParty;
  const updateOneParty = useCallback(
    (value: boolean) =>
      setDoc(
        doc(DB, `${path}_default_`),
        { allowOneParty: value },
        { merge: true }
      ),
    [path]
  );
  const requireReason = !!defaultWorkflow?.doc?.data.requireReason;
  const updateReasonRequired = useCallback(
    (value: boolean) =>
      setDoc(
        doc(DB, `${path}_default_`),
        { requireReason: value },
        { merge: true }
      ),
    [path]
  );
  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <Typography.Title level={4}>Approvals</Typography.Title>
        {latestWorkflow?.doc && (
          <p>
            {" "}
            <Typography.Text type="warning">
              {" "}
              You have enabled a custom routing rule. Approval settings are now
              controlled through{" "}
              <TenantAwareLink to={"routing"}>routing.</TenantAwareLink>
            </Typography.Text>
          </p>
        )}
        <div>
          Allow one-party approvals?
          <br />
          <Typography.Text type="secondary">
            One-party approvals allow anyone with &ldquo;approver&rdquo; access
            to approve their own requests. This setting does not affect
            PagerDuty auto-approvals.
          </Typography.Text>
          <div style={{ display: "flex", columnGap: 12, marginTop: "1em" }}>
            <Switch
              checked={allowOneParty}
              onChange={updateOneParty}
              disabled={isDisabled}
            />
            {allowOneParty ? "Allowed" : "Not allowed "}
          </div>
        </div>
        <br />
        <div>
          Mandatory Reason?
          <br />
          <Typography.Text type="secondary">
            Requestor will be required to provide a reason for the request.
          </Typography.Text>
          <div style={{ display: "flex", columnGap: 12, marginTop: "1em" }}>
            <Switch
              checked={requireReason}
              onChange={updateReasonRequired}
              disabled={isDisabled}
            />
            {requireReason ? "Required" : "Not Required "}
          </div>
        </div>
      </Col>
      <Col span={24}>
        <CustomExpiryOptions />
      </Col>
    </Row>
  );
};

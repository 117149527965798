import { Tooltip, TooltipProps } from "antd";
import { ReactNode } from "react";

const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const GraphTooltip: React.FC<{ width?: string } & TooltipProps> = (
  props
) => (
  <Tooltip
    {...props}
    title={<div onClick={stopPropagation}>{props.title as ReactNode}</div>}
    overlayInnerStyle={{
      width: props.width,
      color: "#000000",
      fontSize: "13px",
    }}
    color="#ffffff"
  />
);

import { FrontendInstallContext } from "install/types";
import { sortBy } from "lodash";
import { KnownFirestoreDoc } from "providers/FirestoreProvider";
import { installedItems } from "shared/install/installed";
import { ConfigOf } from "shared/install/types";

import { ExtendedIntegrationData } from "../types";

export const numberOfResourceInstalled = (
  extendedData: ExtendedIntegrationData[]
) =>
  extendedData.filter((d) =>
    Object.values(d).some(
      (v) => typeof v === "object" && "installed" in v && v?.installed > 0
    )
  ).length;

/**
 * Intended to be used as the optionProvider for a dynamic field of a `FrontendInstaller`, this function enumerates
 * available installations for one or more integrations and returns a list of options. For example, if an integration
 * component has a dynamic field so that the user can select an AWS account or GCP project, this function can be used.
 *
 * @param existingComponent The component for the item currently being installed, to identify the existing selection of
 * the dynamic field if any. For example, if this is being used as the optionProvider for a dynamic field in the
 * `"access-management"` component of an integration, this should be `"access-management"`.
 * @param integrations An array of objects describing the integrations to list installations for. Each object contains
 * the following properties:
 * - `firestoreRef`: A reference to the Firestore document containing the installation data for the integration, perhaps
 *   retrieved using `useFirestoreDoc()`.
 * - `component`: The component of the integration to list installations for. E.g., for AWS and GCP, this would likely
 *   be `"iam-write"`.
 * - `labelTitle`: The title to use in the label for each installation, usually a human-readable name for the
 *   integration. E.g., "Google Cloud".
 * - `keyPrefix`: An optional prefix to prepend to the identifying key of each installation, with a colon automatically
 *   inserted after the prefix. This can be useful in cases where multiple integrations are listed in the same dynamic
 *   field (e.g., AWS and GCP), to distinguish between those integrations. **Caution: May cause unexpected behavior if
 *   changed after the integration has been installed by someone.** For example, an existing selection would likely
 *   no longer be detected.
 */
export const integrationOptionProvider = (
  existingComponent: string,
  integrations: {
    firestoreRef: KnownFirestoreDoc<ConfigOf<any>>;
    component: string;
    labelTitle: string;
    keyPrefix?: string;
  }[]
) => {
  const toLabel = (title: string, id: string, item?: { label?: string }) =>
    `${title}: ${item?.label ? `${item.label} (${id})` : id}`;

  return async (context: FrontendInstallContext<any>) => {
    const { config } = context;
    const existingWrite = config
      ? installedItems(existingComponent, config)
      : {};
    const options: {
      id: string;
      label?: string;
    }[] = [];

    for (const integration of integrations) {
      const { firestoreRef, component, labelTitle, keyPrefix } = integration;
      if (!firestoreRef.doc) {
        continue;
      }

      const { data } = firestoreRef.doc;
      if (data) {
        const allItems = installedItems(component, data);
        for (const [id, item] of Object.entries(allItems)) {
          const key = keyPrefix ? `${keyPrefix}:${id}` : id;
          if (key in existingWrite) {
            continue;
          }

          options.push({ id: key, label: toLabel(labelTitle, id, item) });
        }
      }
    }

    return sortBy(options, (options) => options.label ?? options.id);
  };
};

import {
  BarChartOutlined,
  SearchOutlined,
  ToolFilled,
} from "@ant-design/icons";
import { Menu } from "antd";
import { pick } from "lodash";
import { useContext, useMemo } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";

import { getNavLinkClassName } from "../../../utils/activeNavLinkClassName";
import { AppRoutes } from "../../App/routeConstants";
import { LogoBreadcrumb, LogoBreadcrumbItem } from "../../Breadcrumb";
import {
  FindingsContext,
  MonitorWithFindings,
} from "../contexts/FindingsContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";

const menuItems = ({
  assessmentId,
  orgSlug,
}: {
  assessmentId: string | undefined;
  orgSlug: string | undefined;
}) => {
  const baseUrl = `/o/${orgSlug}/${AppRoutes.IamAssessment}/${assessmentId}`;
  return [
    {
      key: "assessment-findings",
      link: `${baseUrl}/findings`,
      label: (
        <NavLink
          to={`${baseUrl}/findings?scope=all`}
          className={getNavLinkClassName}
        >
          Findings
        </NavLink>
      ),
      icon: <BarChartOutlined />,
    },
    {
      key: "assessment-explore",
      link: `${baseUrl}/explore`,
      label: (
        <NavLink to={`${baseUrl}/explore`} className={getNavLinkClassName}>
          Explore
        </NavLink>
      ),
      icon: <SearchOutlined />,
    },
    {
      key: "assessment-settings",
      link: `${baseUrl}/settings`,
      label: (
        <NavLink to={`${baseUrl}/settings`} className={getNavLinkClassName}>
          Settings
        </NavLink>
      ),
      icon: <ToolFilled />,
    },
  ];
};

type BreadcrumpMapItems =
  | "assessment-finding"
  | "assessment-findings"
  | "assessment-monitor"
  | "assessment-overview";

const breadcrumbItems = (
  crumbs: BreadcrumpMapItems[],
  {
    assessmentId,
    assessmentName,
    orgSlug,
    findingHumanId,
    monitor,
  }: {
    assessmentName: string;
    assessmentId: string;
    orgSlug: string;
    findingHumanId?: number;
    monitor?: MonitorWithFindings;
  }
) => {
  const breadcrumbMap: Record<BreadcrumpMapItems, LogoBreadcrumbItem> = {
    "assessment-overview": {
      to: `/o/${orgSlug}/${AppRoutes.IamAssessment}/${assessmentId}/findings?scope=all`,
      label: assessmentName,
    },
    "assessment-monitor": {
      to: `/o/${orgSlug}/${AppRoutes.IamAssessment}/${assessmentId}/monitors/${monitor?.monitorId}`,
      label: monitor?.label ?? monitor?.monitorId ?? "monitor",
    },
    "assessment-findings": {
      to: `/o/${orgSlug}/${AppRoutes.IamAssessment}/${assessmentId}/findings?scope=all`,
      label: "Findings",
    },
    "assessment-finding": {
      label: findingHumanId ? `Finding-${findingHumanId}` : "",
    },
  };

  const items: LogoBreadcrumbItem[] = [
    {
      to: `/o/${orgSlug}/${AppRoutes.IamAssessment}`,
      label: "IAM Assessments",
    },
  ];
  crumbs.forEach((crumb, index) => {
    if (index === crumbs.length - 1) {
      items.push(pick(breadcrumbMap[crumb], ["label"]));
    } else {
      items.push(breadcrumbMap[crumb]);
    }
  });
  return items;
};

export const SelectedAssessmentNavigation: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const { assessment, finding } = useContext(SelectedAssessmentContext);
  const { allMonitors } = useContext(FindingsContext);
  const { assessmentId, findingId, orgSlug, monitorId } = useParams();
  const location = useLocation();

  const monitor = useMemo(
    () => (monitorId ? allMonitors[monitorId] : undefined),
    [allMonitors, monitorId]
  );

  const navItems = useMemo(
    () =>
      menuItems({
        assessmentId,
        orgSlug,
      }),
    [assessmentId, orgSlug]
  );

  const selectedKeys = useMemo(() => {
    const output: string[] = [];
    navItems.forEach((item) => {
      if (location.pathname === item.link) {
        output.push(item.key);
      }
    });
    return output;
  }, [location.pathname, navItems]);

  const breadcrumbs = useMemo(() => {
    const output: BreadcrumpMapItems[] = [];
    output.push("assessment-overview");
    if (monitorId) {
      output.push("assessment-monitor");
    }
    if (findingId) {
      output.push("assessment-finding");
    }
    return output;
  }, [monitorId, findingId]);

  return assessment?.doc && assessmentId && orgSlug ? (
    <>
      <div style={{ marginBottom: 16 }}>
        <LogoBreadcrumb
          items={breadcrumbItems(breadcrumbs, {
            assessmentName: assessment?.doc?.data.name,
            assessmentId,
            findingHumanId: finding.doc?.data.humanId,
            orgSlug,
            monitor,
          })}
        />
      </div>
      {!findingId && !monitorId && (
        <Menu
          mode="horizontal"
          items={navItems}
          style={{ flexGrow: 1, marginBottom: 24 }}
          selectedKeys={selectedKeys}
        />
      )}
      {children}
    </>
  ) : null;
};

import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

import { SubscriptionLevel } from "../types/entitlement";

const useLaunchDarkly = (
  orgSlug: string,
  subscriptionLevel: SubscriptionLevel | undefined
) => {
  const ldClient = useLDClient();
  useEffect(() => {
    if (orgSlug !== undefined && ldClient?.getContext().anonymous) {
      ldClient.identify({
        kind: "organization",
        key: orgSlug,
        orgSlug,
        subscriptionLevel:
          subscriptionLevel ?? SubscriptionLevel.EntitlementEnterprise,
      });
    }
  }, [ldClient, orgSlug, subscriptionLevel]);
};

export default useLaunchDarkly;

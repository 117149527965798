import { Typography } from "antd";

const toColor = (severity: string) =>
  severity === "CRITICAL"
    ? "danger"
    : severity === "HIGH"
    ? "warning"
    : severity === "LOW"
    ? "success"
    : "secondary";

export const Severity: React.FC<{ severity: string }> = ({ severity }) => (
  <Typography.Text type={toColor(severity.toUpperCase())}>
    {severity}
  </Typography.Text>
);

import { resourcePredicate } from "shared/assessment/issues/presets";
import { resourceReducers } from "shared/assessment/reducers/resource";

import { resourceColumns } from "./columns";

export const resourceData = {
  predicate: resourcePredicate,
  reducers: resourceReducers,
  columns: resourceColumns,
};

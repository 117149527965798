import { Typography } from "antd";
import React, { ReactNode } from "react";
import styled from "styled-components";

import "./Heading.less";

type HeadingProps = {
  cta?: ReactNode;
  title: ReactNode;
};

const StyledHeading = styled.div`
  margin-bottom: 25px;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    h1 {
      margin-bottom: 0;
    }
  }
`;

export const Heading: React.FC<HeadingProps> = ({ cta, title }) => (
  <StyledHeading>
    <Typography.Title
      level={1}
      style={{
        fontSize: "24px",
        textTransform: "capitalize",
      }}
    >
      {title}
    </Typography.Title>
    {cta}
  </StyledHeading>
);

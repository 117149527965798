import { currentInstall, otherInstalled } from "shared/install/options";
import { DynamicProvider } from "shared/install/types";
import { AwsIntegration } from "shared/integrations/resources/aws/types";

import { FrontendAws } from "../../types";

/**
 * Lists the Account Id / Account Label pair for  all of the AWS accounts
 * currently installed for the user as well as a pair for the account
 * currently being installed.
 */
export const accountReference: DynamicProvider<FrontendAws> = async (
  context,
  id
) => {
  return [
    // list the account currently being installed so the user can
    //  reference it during an initial installation
    ...currentInstall<AwsIntegration>("iam-write")(context, id),
    // filter the the current account out of the "installed" list
    // to avoid double counting
    ...otherInstalled<AwsIntegration>("iam-write")(context).filter(
      (item) => item.id !== id
    ),
  ];
};

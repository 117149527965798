import { SINGLETON_ITEM } from "../../../install/constants";
import { installedIds } from "../../../install/installed";
import { BadInstallationError, StateError } from "../../../types/error";
import { GcloudIntegration } from "./types";

export const roleName = (roleId: string) =>
  roleId.startsWith("roles/") ? roleId.slice(6) : roleId;

/** Returns the root singleton item for a config
 *
 * Use this instead of indexing via {@link SINGLETON_ITEM}
 */
export const rootConfig = (config: GcloudIntegration) => {
  const root = config.root[SINGLETON_ITEM];
  if (!root) throw new BadInstallationError("gcloud", "Google Cloud");
  return root;
};

/**
 * Helper function to identify the projectId and config for unique iam-write-security-perimeter
 * @param config Gcloud integration configuration
 * @returns project Id and configuration object.
 */
export const installedSecurityPerimeter = (config: GcloudIntegration) => {
  const securityPerimeterKeys = installedIds(
    "iam-write-security-perimeter",
    config
  );
  if (securityPerimeterKeys.length !== 1) {
    throw new StateError(
      { securityPerimeterKeys },
      "Expected a unique installed IAM Management security perimeter integration"
    );
  }

  const [key] = securityPerimeterKeys;
  return {
    projectId: key,
    config: config["iam-write-security-perimeter"][key],
  };
};

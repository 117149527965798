import { useEffect } from "react";

import { Cancellation } from "../utils/cancel";

/** useEffect, but handles cancellations within asynchronous code
 *
 * Usage:
 * ```
 * const [error, setError] = useState<any>();
 *
 * useGuardedEffect(
 *   (cancellation) => async () => {
 *     const data = await ...;
 *     cancellation.guard(stateMutation)(data);
 *   }),
 *   [...dependencies],
 *   setError
 * }
 * ```
 */
export const useGuardedEffect = (
  // `effect` returns an async callback instead of making the effect itself async,
  // to avoid a warning from the react-hooks/exhaustive-deps ESLint rule:
  // "Effect callbacks are synchronous to prevent race conditions."
  // The point of useGuardedEffect is to allow for asynchronous code.
  effect: (cancellation: Cancellation) => () => Promise<void>,
  dependencies: any[],
  onError: (error: any) => void
) => {
  useEffect(() => {
    const cancellation = new Cancellation();
    effect(cancellation)().catch(cancellation.guard(onError));
    return cancellation.cancel;
    // can't validate dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, onError]);
};

import {
  Edge as FlowEdge,
  Node as FlowNode,
  ReactFlow,
  ReactFlowInstance,
} from "@xyflow/react";
import { Position as FlowPosition } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Grid } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv, VerticalSpacedDiv } from "components/divs";
import { uniqBy } from "lodash";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { keyOf } from "shared/graph/graph";
import { ConnectedNode, Node } from "shared/graph/types";

/** Convert a node into a React link to its details page */
type NodeToLink<A extends object> = (
  node: ConnectedNode<A, keyof A>
) => string | undefined;

type Position = {
  /** Additional integer micro-offset for this node */
  d: number;
  /** Integer x-offset of this node */
  x: number;
  /** Integer y-offset of this node */
  y: number;
};

export type NodeFlowRenderer<A extends object> = Partial<{
  [K in keyof A]: (node: ConnectedNode<A, K>) => ReactNode | undefined;
}>;

export type NodeFlowSpec<A extends object> = {
  inner: ConnectedNode<A, keyof A>;
  display: "inner" | "leaf" | "root";
  position: Position;
};

const GRAPH_BACKGROUND =
  "radial-gradient(ellipse, transparent, #fafcff 70%), linear-gradient(130deg, transparent 75%, rgba(0, 135, 68, .26) 54%, rgba(0, 135, 68, .26) 60%, transparent 70%), linear-gradient(112deg, transparent 35%, rgba(145, 235, 0, .15) 43%, rgba(145, 235, 0, .15) 0, transparent 65%)";

export const BaseDimensions = {
  node: {
    defaultHeight: 60,
    width: 250, // pixels
  },
  spacing: {
    // Spacing between nodes
    x: 350,
    y: 80,
    // Additional spacing for node type clusters
    offsetX: 30,
    offsetY: 20,
  },
};

const flowDimensions = ({ md, lg }: Partial<Record<Breakpoint, boolean>>) => ({
  ...BaseDimensions,
  view: {
    // Use the full limits of the viewport unless it is particularly small
    height: "max(500px, calc(100vh - 240px))",
    // Aligns width as sidebar + padding change
    width: `calc(100vw - ${lg ? "250px" : md ? "130px" : "110px"})`,
  },
  zoom: lg ? 1 : 0.75,
});

const FlowNodeLabel = <A extends object, K extends keyof A>({
  link,
  node,
  renderer,
  titler,
}: {
  link?: NodeToLink<A>;
  node: ConnectedNode<A, K>;
  renderer: NodeFlowRenderer<A>;
  titler: (node: Node<A, K>) => ReactNode;
}) => {
  const title = useMemo(() => titler(node), [titler, node]);
  const to = useMemo(() => link?.(node), [link, node]);
  return (
    <VerticalSpacedDiv
      style={{
        justifyContent: "space-between",
        gap: "0",
        minHeight: "42px",
      }}
    >
      <div>
        {to ? (
          <Link to={to} relative="path">
            {title}
          </Link>
        ) : (
          title
        )}
      </div>
      <ClipDiv maxWidth={`${BaseDimensions.node.width}px`}>
        {renderer[node.type]?.(node) ?? (
          <GraphTooltip title={node.key}>{node.key}</GraphTooltip>
        )}
      </ClipDiv>
    </VerticalSpacedDiv>
  );
};

/** Renders a graph of nodes as a network visualization */
export const GraphVisualization = <A extends object>(props: {
  link?: NodeToLink<A>;
  renderer: NodeFlowRenderer<A>;
  titler: (node: Node<A, keyof A>) => ReactNode;
  nodes: NodeFlowSpec<A>[];
  edges: { parent: Node<A, keyof A>; child: Node<A, keyof A> }[];
}) => {
  const { link, renderer, titler, nodes, edges } = props;
  const breakpoints = Grid.useBreakpoint();
  const [flowInstance, setFlowInstance] = useState<ReactFlowInstance>();

  const dimensions = useMemo(() => flowDimensions(breakpoints), [breakpoints]);
  const toFlowNode = useMemo(() => {
    return ({ inner, display, position }: NodeFlowSpec<A>): FlowNode => ({
      id: keyOf(inner),
      data: {
        label: (
          <FlowNodeLabel
            node={inner}
            link={link}
            renderer={renderer}
            titler={titler}
          />
        ),
      },
      position: {
        x:
          position.x * dimensions.spacing.x +
          position.d * dimensions.spacing.offsetX,
        y:
          position.y * dimensions.spacing.y +
          position.d * dimensions.spacing.offsetY,
      },
      sourcePosition:
        display === "leaf" ? FlowPosition.Left : FlowPosition.Right,
      targetPosition:
        display === "root" ? FlowPosition.Right : FlowPosition.Left,
      width: dimensions.node.width,
    });
  }, [dimensions, link, renderer, titler]);

  const flowNodes: FlowNode[] = useMemo(
    () => uniqBy(nodes.map(toFlowNode), "id"),
    [nodes, toFlowNode]
  );

  const flowEdges: FlowEdge[] = useMemo(
    () =>
      uniqBy(
        edges.map(({ child, parent }) => ({
          id: `${keyOf(parent)}-${keyOf(child)}`,
          source: keyOf(parent),
          target: keyOf(child),
        })),
        "id"
      ),
    [edges]
  );

  // React flow abandons edges on the graph on update
  // Force clear to ensure that zombie edges do not remain on search update
  useEffect(() => {
    flowInstance?.setEdges([]);
  }, [flowEdges, flowInstance]);

  // Ensure visualization is centered on focus node after graph changes (e.g. by navigation)
  useEffect(() => {
    flowInstance?.setCenter(
      dimensions.node.width / 2,
      dimensions.node.defaultHeight / 2,
      { zoom: dimensions.zoom }
    );
  }, [dimensions, flowInstance, nodes]);

  return (
    <div
      style={{
        height: dimensions.view.height,
        width: dimensions.view.width,
        backgroundColor: "rgb(250, 252, 255)",
        backgroundImage: GRAPH_BACKGROUND,
      }}
    >
      <ReactFlow
        nodes={flowNodes}
        edges={flowEdges}
        nodesConnectable={false}
        onInit={setFlowInstance}
      />
    </div>
  );
};

import { Alert } from "antd";
import { join } from "utils/join";

export const ErrorDisplay: React.FC<{
  title?: string;
  error: any;
  onClose?: () => void;
}> = ({ title, error, onClose }) => (
  <Alert
    closable={!!onClose}
    description={join(String(error).split("\n"), (ix) => (
      <br key={ix} />
    ))}
    message={title}
    onClose={onClose}
    showIcon
    style={{ marginBottom: "1em" }}
    type="error"
  />
);

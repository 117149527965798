import { Col, Row, Spin, Typography } from "antd";
import { useContext, useEffect, useMemo } from "react";

import { useFirestoreDoc } from "../../../providers/FirestoreProvider";
import { ClientConfigError } from "../../../shared/integrations/notifiers/slack/types";
import { Tenant } from "../../Login/util";
import {
  IntegrationCardOverrides,
  OAuth2IntegrationCard,
} from "../IntegrationCard";

const SLACK_APP_BASE = "https://app.slack.com/client";
const SLACK_OAUTH_SCOPE =
  "&scope=channels:join,channels:read,chat:write,commands,im:read,im:write,incoming-webhook,usergroups:read,usergroups:write,users:read,users:read.email";

// From https://api.slack.com/authentication/oauth-v2
export const SlackLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: "20px", width: "20px" }}
    viewBox="0 0 122.8 122.8"
  >
    <path
      d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
      fill="#e01e5a"
    ></path>
    <path
      d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
      fill="#36c5f0"
    ></path>
    <path
      d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
      fill="#2eb67d"
    ></path>
    <path
      d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
      fill="#ecb22e"
    ></path>
  </svg>
);

type SlackOauthV2AccessResponse = {
  approversGroup?: { id: string; name: string; handle: string };
  enterprise: string | null;
  incoming_webhook: {
    channel: string;
    channel_id: string;
    configuration_url: string;
    url: string;
  };
  is_enterprise_install: boolean;
  scope: string;
  state: string;
  team: {
    id: string;
    name: string;
  };
  token_type: "bot" | "user";
};

export const Slack: React.FC<{
  cardOverride?: IntegrationCardOverrides;
  onStateTransition?: (newState: string | undefined) => void;
}> = ({ cardOverride, onStateTransition }) => {
  const tenantId = useContext(Tenant);
  const path = `o/${tenantId}/integrations/slack`;
  const { doc, loading } = useFirestoreDoc<SlackOauthV2AccessResponse>(path, {
    live: true,
  });
  const clientError = useFirestoreDoc<ClientConfigError>(
    `${path}/client_errors/latest`,
    {
      live: true,
    }
  );

  useEffect(() => {
    onStateTransition && onStateTransition(doc?.data?.state);
  }, [doc?.data, onStateTransition]);

  const { approvers, channelName, channelUrl, teamName, teamUrl } =
    useMemo(() => {
      if (doc === undefined) return {};
      const body = doc.data;
      const channelName = body.incoming_webhook.channel;
      const channelUrl = `${SLACK_APP_BASE}/${body.team.id}/${body.incoming_webhook.channel_id}`;
      const teamName = body.team.name;
      const teamUrl = `${SLACK_APP_BASE}/${body.team.id}`;
      const approvers = body.approversGroup
        ? {
            name: `@${body.approversGroup.handle ?? body.approversGroup.name}`,
            url: `${channelUrl}/user_groups/${body.approversGroup.id}`,
          }
        : undefined;
      return { approvers, channelName, channelUrl, teamName, teamUrl };
    }, [doc]);

  return (
    <OAuth2IntegrationCard
      mode="secret"
      integration="slack"
      integrationDoc={doc}
      authExtra={SLACK_OAUTH_SCOPE}
      logo={<SlackLogo />}
      {...cardOverride}
    >
      {loading ? (
        <Spin />
      ) : (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            Installed on{" "}
            <a href={teamUrl} target="_blank" rel="noreferrer">
              {teamName}
            </a>
          </Col>
          <Col span={24}>
            Posts to{" "}
            <a href={channelUrl} target="_blank" rel="noreferrer">
              {channelName}
            </a>
          </Col>
          <Col span={24}>
            {approvers && clientError.doc === undefined ? (
              <>
                Notifies{" "}
                <a href={approvers.url} target="_blank" rel="noreferrer">
                  {approvers.name}
                </a>
              </>
            ) : (
              <>
                <Typography.Text type="warning">
                  {clientError.doc?.data?.message ??
                    "No configured Slack approval group."}
                  <br />
                  You can request access with P0, but approval messages will not
                  notify any Slack members.
                </Typography.Text>
                {clientError.doc?.data?.code === "permission_denied" ? (
                  <>
                    <br />
                    To enable approval groups, allow &ldquo;Everyone&rdquo; to
                    create and update user groups in the{" "}
                    <a
                      href="https://slack.com/admin/settings#user_group_restrictions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Slack admin UI
                    </a>
                  </>
                ) : null}
              </>
            )}
          </Col>
        </Row>
      )}
    </OAuth2IntegrationCard>
  );
};

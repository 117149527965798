import stableKey from "json-stable-stringify";
import { mapValues as lodashMapValues, pickBy as lodashPickBy } from "lodash";

import { RecordFromArray } from "../types/util";

/** Combines values in a collection that have the same result for a grouping function */
export function combineBy<T extends object>(
  input: Iterable<T>,
  selector: keyof T | ((item: T) => any),
  combine: (left: T, right: T | undefined) => T
) {
  const collector: Record<string, T> = {};
  for (const item of input) {
    const key = stableKey(
      typeof selector === "function" ? selector(item) : item[selector]
    );
    const newItem = combine(item, collector[key]);
    collector[key] = newItem;
  }
  return Object.values(collector);
}

/** Extends map to work with generator iteratees
 *
 * Can be used with an iterable object; or, if applied to an
 * object, will map that object's property entries.
 *
 * E.g.
 * ```
 * const values = mapWith(
 *   someObject,
 *   function *([key, value]) {
 *     if (key.startsWith("a")) yield value * 2)
 *   }
 * );
 * ```
 */
export function mapWith<T, U>(
  input: Iterable<T>,
  iteratee: (item: T) => Generator<U>
): U[];
export function mapWith<T extends object, U>(
  input: T,
  iteratee: (entry: [keyof T, T[keyof T]]) => Generator<U>
): U[];
export function mapWith<T, U>(
  input: Iterable<T> | object,
  iteratee:
    | ((entry: [keyof T, T[keyof T]]) => Generator<U>)
    | ((item: T) => Generator<U>)
) {
  const output: U[] = [];
  const iterable = Symbol.iterator in input ? input : Object.entries(input);
  for (const item of iterable) {
    for (const value of (iteratee as (item: T | [string, any]) => Generator<U>)(
      item
    )) {
      output.push(value);
    }
  }

  return output;
}

/** Fixes built-in iteration funtions to preserve type width.
 *
 * Most built-in iteration functions are typed to operate on type V[], rather than
 * type T extends any[]. This means that the result of these functions will be
 * T[number][] when operating on type T. These functions preserve the original type
 * width.
 *
 * Caution: A downside of these utilities is that they will remove any additional
 * properties not numerically indexed. E.g., operating on a type created using
 * `Object.assign(someArray, someObject)` will discard all object properties.
 */
export namespace widetype {
  /** filter, but it does not narrow the collection type */
  export function filter<T extends any[]>(
    collection: T,
    predicate: (value: T[number]) => boolean
  ): T {
    return collection.filter(predicate) as T;
  }

  /** Like array structuring, but does not narrow the collection type */
  export function from<T extends any[]>(...items: T[number][]): T {
    return [...items] as T;
  }

  /** Object.keys, but it assumes readonly types have runtime keys equal to their typing */
  export function keys<
    O extends Readonly<Record<number | string | symbol, any>>
  >(object: O): (keyof O)[] {
    return Object.keys(object);
  }

  export function entries<O extends Readonly<Record<string, any>>>(
    object: O
  ): [keyof O, O[keyof O]][] {
    return Object.entries(object);
  }

  export function fromEntries<A extends [any, any][]>(array: A) {
    return Object.fromEntries(array) as RecordFromArray<A>;
  }

  export function mapEntries<
    O extends Readonly<Record<string, any>>,
    K extends string,
    T
  >(
    object: O,
    iteratee: (key: keyof O, value: O[string]) => [K, T]
  ): { [KK in K]: T } {
    return Object.fromEntries(
      Object.entries(object).map(iteratee as any) as any
    ) as any;
  }

  export function mapValues<O extends Readonly<Record<string, any>>, T>(
    object: O,
    iteratee: (value: O[string], key: keyof O) => T
  ): { [K in keyof O]: T } {
    return lodashMapValues(object, iteratee) as { [K in keyof O]: T };
  }

  /** lodash pickBy, but maintains key-value type relationship */
  export function pickBy<O extends Record<string, any>>(
    object: O,
    predicate: (value: O[string], key: string) => boolean
  ): Partial<O> {
    return lodashPickBy(object, predicate) as Partial<O>;
  }
}

import { BackwardFilled } from "@ant-design/icons";
import { Modal, Typography } from "antd";
import pluralize from "pluralize";
import { useCallback } from "react";

const { confirm } = Modal;

type Props = {
  onBack: () => void;
  isDirty: boolean;
  isAdding: boolean;
  name: string;
};

export const EditorBackButton: React.FC<Props> = ({
  onBack,
  isDirty,
  isAdding,
  name,
}) => {
  const goBack = useCallback(() => {
    if (isDirty) {
      confirm({
        title: isAdding ? `Discard this ${name}?` : "Discard changes?",
        content: "You have unsaved changes that will be lost if you go back.",
        onOk() {
          onBack();
        },
        maskClosable: true,
      });
    } else {
      onBack();
    }
  }, [isAdding, isDirty, name, onBack]);
  return (
    <Typography.Paragraph>
      <Typography.Link type="secondary" onClick={goBack}>
        <BackwardFilled /> Back to all {pluralize(name)}
        {isDirty && " (unsaved changes)"}
      </Typography.Link>
    </Typography.Paragraph>
  );
};

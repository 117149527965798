/**
 *
 * @param keyLabelObject An object where the keys are the Select option values and the values are the Select option labels
 * @returns Select options
 */
export const keyLabelObjectToOptions = (
  keyLabelObject: Record<string, string>
) => {
  return Object.entries(keyLabelObject).map(([value, label]) => ({
    value,
    label,
  }));
};

import { Modal, Typography } from "antd";

const { Title, Paragraph } = Typography;

export type DeleteModalProps = {
  id: string;
  isOpen: boolean;
  toggleOpen: () => void;
  onRemove: () => Promise<void>;
};

export const DeleteModal = (props: DeleteModalProps) => (
  <Modal
    closable={false}
    open={props.isOpen}
    onCancel={props.toggleOpen}
    onOk={props.onRemove}
    okText="Remove"
  >
    <Title level={4}>Remove {props.id}?</Title>
    <Paragraph>
      You will have to re-install {props.id} in order to use it in the future.
    </Paragraph>
    <Paragraph>
      Note that removing this item does not remove any infrastructure
      you&apos;ve created in your {props.id} environment, such as OAuth grants,
      IAM policies, stored procedures, or the like.
    </Paragraph>
  </Modal>
);

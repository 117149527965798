import { useMemo } from "react";

import { TitledEvidenceList } from "../components/EvidenceList";
import { useActiveEvidence } from "../hooks/useEvidence";

export const ActiveEvidence: React.FC = () => {
  // TODO: filter out evidence that is not relevant to the user
  const activeEvidence = useActiveEvidence();

  const standingEvidence = useMemo(
    () => activeEvidence.list.filter((e) => e.data.startsAt <= Date.now()),
    [activeEvidence.list]
  );

  const pendingEvidence = useMemo(
    () => activeEvidence.list.filter((e) => e.data.startsAt > Date.now()),
    [activeEvidence.list]
  );

  return (
    <>
      <div style={{ maxWidth: 1000 }}>
        <TitledEvidenceList
          title="Active"
          evidence={standingEvidence}
          loading={activeEvidence.loading}
        />
        <TitledEvidenceList
          title="Pending"
          evidence={pendingEvidence}
          loading={activeEvidence.loading}
        />
      </div>
    </>
  );
};

import { DeleteOutlined, EditTwoTone, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import yaml from "js-yaml";
import stringify from "json-stable-stringify";
import { useCallback, useMemo, useState } from "react";
import {
  RequestorRule,
  ResourceRule,
  RoutingRule,
} from "shared/types/workflow/types";
import styled from "styled-components";

import { useRoutingRulesStore } from "../../../store/routingRulesStore";
import { StyledConfigFooter } from "../../Integrations/Postgres/styles";
import { EditRuleModal } from "../components/EditRuleModal";
import { RequestorPreview } from "../components/previews/RequestorPreview";
import { ResourcePreview } from "../components/previews/ResourcePreview";
import { useSubmitWorkflow } from "../useSubmitWorkflow";
import { getResourceFilters, getUniqueResources, useOnFilter } from "../utils";
import { renderApprovalSummary } from "./common";

const StyledSearchContainer = styled.div`
  padding: 8px;
`;

const StyledSearchButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  canSubmit: boolean;
  handleDelete: (record: RoutingRule) => void;
};

const FilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  placeholder,
}: any) => {
  const switchFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setSelectedKeys(e.target.value ? [e.target.value] : []),
    [setSelectedKeys]
  );
  const resetSearch = useCallback(() => {
    setSelectedKeys([]);
    confirm();
  }, [confirm, setSelectedKeys]);

  return (
    <StyledSearchContainer>
      <Input
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={switchFilter}
        onPressEnter={confirm}
      />
      <StyledSearchButtonContainer>
        <Button
          onClick={resetSearch}
          type="link"
          size="small"
          disabled={!selectedKeys[0]}
        >
          Reset
        </Button>
        <Button type="primary" onClick={confirm} size="small">
          Search
        </Button>
      </StyledSearchButtonContainer>
    </StyledSearchContainer>
  );
};

const TableView: React.FC<Props> = ({ canSubmit, handleDelete }) => {
  const {
    currentVersion,
    isLoading,
    jsonRules,
    selectedYamlRule,
    setErrors,
    setSelectedRule,
    setShowConfigRulesModal,
  } = useRoutingRulesStore();
  const submitWorkflow = useSubmitWorkflow();

  const [isNewRule, setIsNewRule] = useState(false);

  // Table filters
  const uniqueResources = useMemo(
    () => getUniqueResources(jsonRules),
    [jsonRules]
  );
  const resourceFilters = useMemo(
    () => getResourceFilters(uniqueResources),
    [uniqueResources]
  );

  const handleAddNewRule = useCallback(() => {
    setIsNewRule(true);
    setErrors([]);
    setSelectedRule({});
    setShowConfigRulesModal(true);
  }, [setErrors, setSelectedRule, setShowConfigRulesModal]);

  const handleEditRule = useCallback(
    (record: RoutingRule) => {
      setIsNewRule(false);
      setErrors([]);
      setSelectedRule(record);
      setShowConfigRulesModal(true);
    },
    [setErrors, setSelectedRule, setShowConfigRulesModal]
  );

  const handleSingleRuleSubmit = useCallback(
    async (value: RoutingRule | string) => {
      const updatedRules = [...jsonRules];

      let editorRule: RoutingRule;
      if (typeof value === "string") {
        try {
          editorRule = yaml.load(value) as RoutingRule;
        } catch (err) {
          setErrors([String(err)]);
          return;
        }
      } else {
        editorRule = value;
      }

      let selectedRule: RoutingRule;
      try {
        selectedRule = yaml.load(selectedYamlRule) as RoutingRule;
      } catch (err) {
        setErrors([String(err)]);
        return;
      }
      const editorRuleHash = stringify(editorRule);
      if (isNewRule) {
        if (updatedRules.some((rule) => stringify(rule) === editorRuleHash)) {
          setErrors(["This rule already exists"]);
          return;
        }
        updatedRules.push(editorRule);
      } else {
        const selectedRuleHash = stringify(selectedRule);
        // Did the rule change?
        if (selectedRuleHash === editorRuleHash) {
          console.warn("Rule did not change");
          setShowConfigRulesModal(false);
          return;
        }
        // Only check for duplicates if the rule has changed.
        if (updatedRules.some((rule) => stringify(rule) === editorRuleHash)) {
          setErrors(["This rule already exists"]);
          return;
        }
        const index = updatedRules.findIndex(
          (rule) => stringify(rule) === selectedRuleHash
        );
        if (index !== -1) {
          updatedRules[index] = editorRule;
        } else {
          // This should never happen
          console.error("Rule not found");
          return;
        }
      }
      await submitWorkflow({
        jsonData: { currentVersion, workflow: { rules: updatedRules } },
        shouldNavigate: true,
      });
    },
    [
      currentVersion,
      isNewRule,
      jsonRules,
      selectedYamlRule,
      setErrors,
      setShowConfigRulesModal,
      submitWorkflow,
    ]
  );

  const columns = [
    {
      title: "Resource",
      dataIndex: "resource",
      key: "resource",
      filters: resourceFilters,
      onFilter: (value: any, record: any) => record.resource.type === value,
      render: (resource: ResourceRule) => (
        <ResourcePreview resource={resource} />
      ),
    },
    {
      title: "Requestor",
      dataIndex: "requestor",
      key: "requestor",
      filterDropdown: (props: any) => (
        <FilterDropdown {...props} placeholder="Search..." />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: useOnFilter("requestor"),
      render: (requestor: RequestorRule) => (
        <RequestorPreview requestor={requestor} />
      ),
    },
    {
      title: "Access",
      dataIndex: "approval",
      key: "approvalCombined",
      filterDropdown: (props: any) => (
        <FilterDropdown {...props} placeholder="Search..." />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: useOnFilter("approval"),
      render: renderApprovalSummary,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => {
        return canSubmit ? (
          <Space size="middle">
            <Button
              icon={<EditTwoTone />}
              // Memoized by antd Table
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handleEditRule(record)}
            />
            <Button
              icon={<DeleteOutlined />}
              // Memoized by antd Table
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handleDelete(record)}
            />
          </Space>
        ) : null;
      },
    },
  ];

  const stringifyRecord = useCallback(
    (record: RoutingRule) => stringify(record),
    []
  );

  const renderFooter = useMemo(
    () =>
      canSubmit
        ? () => (
            <StyledConfigFooter>
              <Button type="primary" onClick={handleAddNewRule}>
                Add New Rule
              </Button>
            </StyledConfigFooter>
          )
        : undefined,
    [canSubmit, handleAddNewRule]
  );

  return (
    <>
      <Table
        columns={columns}
        dataSource={jsonRules}
        loading={isLoading}
        rowKey={stringifyRecord}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        footer={renderFooter}
      />
      <EditRuleModal
        isNewRule={isNewRule}
        handleSubmitRule={handleSingleRuleSubmit}
      />
    </>
  );
};

export default TableView;

import { NotFound } from "components/App/NotFound";
import { AppRoutes } from "components/App/routeConstants";

export const RequestNotFound: React.FC<{ orgSlug?: string }> = ({
  orgSlug,
}) => (
  <NotFound
    title="Request Not Found"
    backButton={{
      label: "Back to all requests",
      navigateTo: `/o/${orgSlug}/${AppRoutes.Requests}`,
    }}
  />
);

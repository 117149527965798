import { Grid } from "antd";
import { useMemo } from "react";

const { useBreakpoint } = Grid;
/**
 *
 * @returng {object} { xs, sm, md, lg, xl, isTablet, isDesktop }
 * @description isSmallScreen: is xs
 * @description isDesktop: is lg and xl
 */
export const useScreen = () => {
  const { xs, sm, md, lg, xl } = useBreakpoint();
  const isDesktop = useMemo(() => lg || xl, [lg, xl]);
  return { xs, sm, md, lg, xl, isDesktop };
};

export const RequestStatuses = [
  "APPROVED_NOTIFIED",
  "APPROVED",
  "CLEANUP_ERRORED",
  "CLEANUP_SUBMITTED",
  "CLEANED_UP",
  "DENIED_NOTIFIED",
  "DENIED",
  "DONE_NOTIFIED",
  "DONE",
  "ERRORED_ERRORED", // Encountered an error during error notification
  "ERRORED_NOTIFIED",
  "ERRORED",
  "EXPIRED_NOTIFIED",
  "EXPIRED",
  "EXPIRY_SUBMITTED",
  "NEW",
  "PENDING_APPROVAL",
  "PENDING_APPROVAL_ESCALATED", // track escalated requests in request history, similar to pending_approval
  "REVOKE_SUBMITTED",
  "REVOKED_NOTIFIED",
  "REVOKED",
  "STAGED",
  "TRANSLATED", // Unused but leaving here to prevent crashes
] as const;
export type RequestStatus = (typeof RequestStatuses)[number];
export const TerminalRequestStatuses: RequestStatus[] = [
  "DENIED",
  "DENIED_NOTIFIED",
  "ERRORED_ERRORED",
  "ERRORED_NOTIFIED",
  "EXPIRED",
  "EXPIRED_NOTIFIED",
  "REVOKED",
  "REVOKED_NOTIFIED",
  "CLEANED_UP",
  "CLEANUP_ERRORED",
];

export const PendingRequestStatuses: RequestStatus[] = [
  "NEW",
  "PENDING_APPROVAL",
  "PENDING_APPROVAL_ESCALATED",
];

export const ActiveRequestStatuses: RequestStatus[] = [
  "APPROVED",
  "APPROVED_NOTIFIED",
  "DONE",
  "DONE_NOTIFIED",
  "ERRORED",
  "EXPIRY_SUBMITTED",
  "REVOKE_SUBMITTED",
  "STAGED",
];

export const HandledRequestStatuses = [
  "APPROVED",
  "APPROVED_NOTIFIED",
  "CLEANUP_SUBMITTED",
  "DONE",
  "DENIED",
  "ERRORED",
  "EXPIRY_SUBMITTED",
  "EXPIRED",
  "NEW",
  "REVOKE_SUBMITTED",
  "REVOKED",
  "STAGED",
] as const;

export type HandledRequestStatus = (typeof HandledRequestStatuses)[number];

export const NotifiedRequestStatuses = [
  "APPROVED_NOTIFIED",
  "DONE_NOTIFIED",
  "DENIED_NOTIFIED",
  "ERRORED_NOTIFIED",
  "EXPIRED_NOTIFIED",
  "REVOKED_NOTIFIED",
] as const;

export const OpenRequestStatuses = [
  "APPROVED",
  "APPROVED_NOTIFIED",
  "DONE",
  "DONE_NOTIFIED",
  "EXPIRY_SUBMITTED",
  "REVOKE_SUBMITTED",
  "STAGED",
] as const;
// For type-checking only
const _validateOpenRequestStatuses: readonly RequestStatus[] =
  OpenRequestStatuses;
// Statuses where the access is provisioned in the target resource

export const GrantedRequestStatuses = [
  "DONE",
  "DONE_NOTIFIED",
  "EXPIRY_SUBMITTED",
  "REVOKE_SUBMITTED",
] as const;
// For type-checking only
const _validateGrantedRequestStatuses: readonly RequestStatus[] =
  GrantedRequestStatuses;
// Statuses where the access is staged

export const StagedRequestStatuses = [
  "CLEANUP_ERRORED",
  "CLEANUP_SUBMITTED",
  "CLEANED_UP",
  "DONE_NOTIFIED",
  "DONE",
  "EXPIRED_NOTIFIED",
  "EXPIRED",
  "EXPIRY_SUBMITTED",
  "REVOKED_NOTIFIED",
  "REVOKED",
  "REVOKE_SUBMITTED",
  "STAGED",
] as const;

export type StagedRequestStatus = (typeof StagedRequestStatuses)[number];
// For type-checking only
const _validateStagedRequestStatuses: readonly RequestStatus[] =
  StagedRequestStatuses;

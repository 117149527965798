export const orgRoleAccess = [
  {
    requestor: {
      type: "group",
      group: "devs@you.co",
      directory: "workspace",
    },
    resource: {
      type: "any",
    },
    approval: [
      {
        type: "group",
        id: "sre@you.co",
        label: "SREs",
        directory: "workspace",
        options: {
          allowOneParty: true,
        },
      },
    ],
  },
  {
    requestor: {
      type: "group",
      group: "customer-success@you.co",
      directory: "workspace",
    },
    resource: {
      type: "integration",
      service: "snowflake",
    },
    approval: [
      {
        type: "group",
        id: "data-ops@you.co",
        label: "Data Ops",
        directory: "workspace",
        options: {
          allowOneParty: false,
        },
      },
    ],
  },
];

export const awsPolcyTagAccess = [
  {
    requestor: {
      type: "any",
    },
    resource: {
      type: "integration",
      service: "aws",
      tagRestriction: {
        key: "P0Grantable",
        pattern: "true",
      },
    },
    approval: [
      {
        type: "p0",
      },
    ],
  },
];

export const orgAndResourceRouting = [
  {
    requestor: {
      type: "group",
      group: "devs@you.co",
      directory: "workspace",
    },
    resource: {
      type: "some",
    },
    approval: [
      {
        type: "group",
        id: "eng-managers@you.co",
        label: "Eng managers",
        directory: "workspace",
      },
    ],
  },
  {
    requestor: {
      type: "some",
    },
    resource: {
      type: "integration",
      service: "gcloud",
    },
    approval: [
      {
        type: "group",
        id: "gcloud-owners@you.co",
        label: "Google Cloud owners",
        directory: "workspace",
      },
    ],
  },
];

import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import { useUser } from "../components/Login/hook";
import { getEnvironment } from "../utils/environment";

const useUserIdentification = () => {
  const { user, orgSlug } = useUser();
  const posthog = usePostHog();
  const { environment } = getEnvironment();

  useEffect(() => {
    if (user) {
      const userProperties = {
        name: user.displayName as string,
        email: user.email as string,
      };

      if (environment === "prod") {
        posthog?.identify(user.uid, userProperties);
        posthog?.group("orgSlug", orgSlug as string);
      }
    }
  }, [environment, orgSlug, posthog, user]);
};

export default useUserIdentification;

import { AwsLoginInstaller } from "../../types";
import { federatedLoginInstaller } from "./federated";
import { accountReference } from "./listers";

export const loginInstaller: AwsLoginInstaller = {
  options: {
    idc: { items: { parent: { optionProvider: accountReference } } },
    federated: federatedLoginInstaller,
  },
};

import { ApiTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { deleteDoc } from "@firebase/firestore";
import { Button, Space, Tooltip } from "antd";
import Card from "antd/lib/card";
import { useHasRole } from "components/Login/hook";
import capitalize from "lodash/capitalize";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { FirestoreDoc } from "../../providers/FirestoreProvider";
import { LogoBreadcrumb } from "../Breadcrumb";
import { Logo } from "./Logo";
import { OAuth2, OAuth2Props } from "./OAuth2";
import { DeleteModal } from "./components/DeleteModal";

export type IntegrationCardOverrides = {
  title?: string;
  canRemove?: boolean;
  showBreadcrumbs?: boolean;
  isAuditOnboarding?: boolean;
  isWorkflowOnboarding?: boolean;
  refPath?: string;
};

type IntegrationCardBaseProps = {
  integration: string;
  component?: string;
  componentTitle?: string;
  logo: ReactElement | string;
  integrationDoc?: FirestoreDoc<any>;
  onRemove?: () => Promise<void>;
};

type IntegrationCardProps = IntegrationCardBaseProps & IntegrationCardOverrides;

export const OAuth2IntegrationCard: React.FC<
  React.PropsWithChildren<
    IntegrationCardProps &
      OAuth2Props & {
        preamble?: ReactElement;
      }
  >
> = ({
  canRemove,
  children,
  integration,
  integrationDoc,
  logo,
  onRemove,
  preamble,
  title,
  showBreadcrumbs = true,
  ...oauth2Props
}) => {
  return (
    <IntegrationCard
      canRemove={canRemove}
      onRemove={onRemove}
      integrationDoc={integrationDoc}
      {...{ integration, logo, title, showBreadcrumbs }}
    >
      <Space direction="vertical">
        {preamble}
        <OAuth2 integration={integration} {...oauth2Props}>
          {children}
        </OAuth2>
      </Space>
    </IntegrationCard>
  );
};

export const IntegrationCard: React.FC<
  React.PropsWithChildren<IntegrationCardProps>
> = ({
  children,
  logo,
  integration,
  integrationDoc,
  component,
  componentTitle,
  title,
  canRemove,
  onRemove,
  showBreadcrumbs = true,
  isAuditOnboarding = false,
  isWorkflowOnboarding = false,
  refPath = "/integrations",
}) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const isOwner = useHasRole("owner");

  const basePath = useMemo(() => `/o/${orgSlug}${refPath}`, [orgSlug, refPath]);

  const toggleDeleteModal = useCallback(
    () => setIsDeleteModalOpen(!isDeleteModalOpen),
    [isDeleteModalOpen]
  );

  const removeIntegration = useCallback(async () => {
    setIsDeleting(true);
    setIsDeleteModalOpen(false);
    if (onRemove) await onRemove();
    else if (integrationDoc?.ref) {
      await deleteDoc(integrationDoc.ref).catch(console.error);
    }
    setIsDeleting(false);
  }, [integrationDoc, onRemove]);

  const coalescedCanRemove = useMemo(
    () =>
      isOwner &&
      (canRemove !== undefined ? canRemove : !!(onRemove ?? integrationDoc)),
    [isOwner, canRemove, onRemove, integrationDoc]
  );

  const cardTitle = useMemo(
    () => title ?? capitalize(integration),
    [integration, title]
  );
  const breadcrumbItems = useMemo(
    () =>
      isAuditOnboarding || isWorkflowOnboarding
        ? [
            {
              icon: <Logo logo={logo} title={cardTitle} />,
              to: component && `${basePath}`,
              label: cardTitle,
            },
            ...(component
              ? [{ label: componentTitle ?? capitalize(component) }]
              : []),
          ]
        : [
            {
              icon: <ApiTwoTone style={{ fontSize: 20 }} />,
              to: basePath,
              label: "Integrations",
            },
            {
              icon: <Logo logo={logo} title={cardTitle} />,
              to: component && `${basePath}/${integration}`,
              label: cardTitle,
            },
            ...(component
              ? [{ label: componentTitle ?? capitalize(component) }]
              : []),
          ],
    [
      basePath,
      cardTitle,
      component,
      componentTitle,
      integration,
      isAuditOnboarding,
      isWorkflowOnboarding,
      logo,
    ]
  );

  return (
    <>
      <Card
        style={{ maxWidth: 800 }}
        size="small"
        bordered={false}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {showBreadcrumbs ? (
              <LogoBreadcrumb items={breadcrumbItems} />
            ) : (
              <>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <Logo logo={logo} title={cardTitle} />
                  <span style={{ marginLeft: "0.5em" }}>{cardTitle}</span>
                </div>
              </>
            )}
            {coalescedCanRemove ? (
              <Tooltip
                title={`Uninstall ${cardTitle} integration`}
                placement="topLeft"
              >
                <Button
                  loading={isDeleting}
                  icon={<DeleteTwoTone />}
                  onClick={toggleDeleteModal}
                />
              </Tooltip>
            ) : null}
          </div>
        }
      >
        {children}
      </Card>
      <DeleteModal
        id={cardTitle}
        isOpen={isDeleteModalOpen}
        toggleOpen={toggleDeleteModal}
        onRemove={removeIntegration}
      />
    </>
  );
};

import { Button, Col, Space } from "antd";
import styled from "styled-components";

export const StyledContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
`;

export const StyledSectionCol = styled(Col)`
  padding: 1rem 2rem !important;
`;

export const StyledCompanyContent = styled.div`
  padding: 8rem 3rem;
  display: flex;
  flex-direction: column;
  font-size: 18px;

  @media (max-width: 992px) {
    padding: 5rem 1rem;
  }

  .bg-gradient {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-left: -10px;
  }

  .ant-list {
    padding-top: 1rem;

    .ant-list-item {
      margin-bottom: 10px;
      border: none;
    }
  }
`;

export const StyledListContent = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-right: 10px;
    color: rgb(0 171 142);
  }
  .ant-typography {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const StyledIntegrationLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 0.5rem 0.5rem 0.5rem 3rem;
`;

export const LoginSectionCol = styled(Col)`
  background-color: rgb(0 171 142);
  padding: 1rem !important;
  text-align: center;
  display: grid;
`;

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const StyledLoginLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  text-align: center;
  font-size: 16px;
  padding-top: 1rem;

  a {
    padding-left: 5px;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-image {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  @media (max-width: 768px) {
    .login-text {
      padding-top: 0.5rem;
    }
  }
`;

export const StyledButtonSpacing = styled(Space)`
  text-align: left;
  padding: 0;

  button {
    width: 340px;
    max-width: 340px;
    margin: 0.5rem 0;

    &:hover {
      color: #1677ff;
    }
  }

  input {
    width: 340px;
    max-width: 340px;
    margin-bottom: 1rem;
  }

  .ant-input-status-error {
    border-color: #8d0204 !important;
  }
  .ant-form-item-explain-error {
    color: #8d0204;
    margin-top: -0.7rem;
  }

  .ant-divider-inner-text {
    color: #2d2d2d;
  }
`;

export const StyledPrivacy = styled.p`
  color: #2d2d2d;

  a {
    padding-left: 5px;
    text-decoration: underline;
    color: #3b3b3b;

    &:hover {
      color: #111111;
    }
  }
`;

export const StyledLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img:first-child {
    padding-right: 1rem;
  }
`;

export const EmailButton = styled(Button)`
  background-color: rgb(44, 53, 66);
  color: white;
  border-color: rgb(44, 53, 66);
  margin-top: -1rem !important;
  width: 350px;
  max-width: 350px;

  &:hover,
  &:focus {
    background-color: rgb(61, 69, 84);
    border-color: rgba(0, 0, 0, 0);
    color: white !important;
  }
`;

export const CreateAccountButton = styled(Button)`
  background-color: rgb(44, 53, 66);
  width: 350px;
  max-width: 350px;
  color: white;
  border-color: rgb(44, 53, 66);
  margin-top: -1rem !important;

  &:hover,
  &:focus {
    background-color: rgb(61, 69, 84);
    border-color: rgba(0, 0, 0, 0);
    color: white !important;
  }
`;

export const StyledOrgPointerText = styled.p`
  color: #2d2d2d;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  margin-bottom: 0;
`;

import { Tag } from "antd";
import { UserApprovalOptions } from "shared/types/workflow/types";
import styled from "styled-components";

type Props = {
  approvalOptions: UserApprovalOptions;
};

const StyledApprovalOption = styled(Tag)`
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ApprovalOptionsPreview: React.FC<Props> = ({
  approvalOptions,
}) => (
  <>
    {approvalOptions.allowOneParty && (
      <StyledApprovalOption color="red">Allow one party</StyledApprovalOption>
    )}
    {approvalOptions.requireReason && (
      <StyledApprovalOption color="gray">Require reason</StyledApprovalOption>
    )}
  </>
);

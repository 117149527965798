import { Environment } from "../shared/types/environment";

export const getEnvironment = () => {
  const developer = process.env.P0_DEV_NAME;
  const isLocal = process.env.P0_LOCAL;

  /*
   * P0_ENVIRONMENT allows us to make a "production" build for staging and other prod environments.
   */
  const environment: Environment =
    process.env.NODE_ENV === "production"
      ? process.env.P0_ENVIRONMENT === "stage"
        ? "stage"
        : process.env.P0_ENVIRONMENT === "prod-cna-central"
        ? "prod-cna-central"
        : process.env.P0_ENVIRONMENT === "prod-splunk-nonprod"
        ? "prod-splunk-nonprod"
        : process.env.P0_ENVIRONMENT === "prod-splunk-live"
        ? "prod-splunk-live"
        : "prod"
      : process.env.NODE_ENV === "development"
      ? process.env.P0_ENVIRONMENT === "test"
        ? "test"
        : "dev"
      : "test"; // unit tests

  const apiUrl = (options?: { external?: true }) =>
    environment === "prod"
      ? "https://api.p0.app"
      : environment === "prod-cna-central"
      ? "https://api.cna.p0.app"
      : environment === "prod-splunk-nonprod"
      ? "https://api.splunk-nonprod.p0.app"
      : environment === "prod-splunk-live"
      ? "https://api.splunk.p0.app"
      : environment === "dev"
      ? isLocal && !options?.external
        ? "http://localhost:8088"
        : `https://${developer}.api.${environment}.p0.app`
      : `https://api.${environment}.p0.app`;

  if (environment === "dev") {
    if (!developer) {
      throw "Run app with `P0_DEV_NAME=hyphen-name yarn start`";
    }
    return { environment, developer, apiUrl };
  }
  return { environment, apiUrl };
};

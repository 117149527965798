import { Button, Col, Form, Select, Typography, message } from "antd";
import { usePostHog } from "posthog-js/react";
import React, { ReactElement, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Route, Routes } from "react-router-dom";

import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import { useOnboardingStore } from "../../store/onboardingStore";
import { Page } from "../App/Page";
import { Aws } from "../Integrations/Aws/Aws";
import { Gcloud } from "../Integrations/Gcloud/Gcloud";
import { Slack } from "../Integrations/Slack/Slack";
import { Snowflake } from "../Integrations/Snowflake/Snowflake";
import { useAuthFetch, useUser } from "../Login/hook";
import { Splash } from "../Splash";
import SkipOnboardingButton from "./SkipOnboardingButton";
import { cloudStackOptions } from "./Welcome";
import { StyledCloudContainer } from "./styles";

type OnboardStep = "install-cloud-provider" | "select-cloud-provider" | "slack";
type CloudProvider = "aws" | "gcp" | "snowflake";
type CloudProviderOption = {
  value: CloudProvider;
  label: string;
};

const availableCloudProviders: CloudProvider[] = ["aws", "gcp", "snowflake"];

const handleError = (err: any) => {
  message.error(err);
};

export const OnboardWorkflows: React.FC = () => {
  const authFetch = useAuthFetch();
  const navigate = useNavigate();
  const { orgSlug } = useParams();
  const { user } = useUser();

  const {
    setCloudProvider,
    cloudProvider,
    cloudProviderInstallState,
    setCloudProviderInstallState,
    setSlackInstallState,
  } = useOnboardingStore();
  const posthog = usePostHog();

  const [onboardStep, setOnboardStep] = useState<OnboardStep>(
    "select-cloud-provider"
  );
  const [cloudProviderOptions, setCloudProviderOptions] = useState<
    CloudProviderOption[]
  >([]);

  const handleInstallStateChange = useCallback(
    (newState: string | undefined) => {
      setCloudProviderInstallState(newState || "");
      if (newState === "installed" && cloudProvider === "gcp") {
        posthog?.capture("onboarding_cloud_provider_installed", {
          cloudProvider,
          email: user?.email,
          org: orgSlug,
        });
        navigate("../onboarding/success");
      }
    },
    [
      cloudProvider,
      navigate,
      orgSlug,
      posthog,
      setCloudProviderInstallState,
      user?.email,
    ]
  );

  const updateCloudProvider = useCallback(
    (cloudProvider: CloudProvider) => {
      setCloudProvider(cloudProvider);
      setOnboardStep("install-cloud-provider");
    },
    [setCloudProvider]
  );

  useGuardedEffect(
    (cancellation) => async () => {
      const getOptions = (source: CloudProvider[]): CloudProviderOption[] =>
        source.map(
          (stack: string) =>
            ({
              ...cloudStackOptions.find((c) => c.value === stack),
            }) as CloudProviderOption
        );

      const response = await authFetch("account", {
        method: "GET",
      });
      if (!response) return;
      const data = await response.json();
      if (data.cloudStack.cloudStack.length > 0) {
        if (data.cloudStack.cloudStack.length === 1) {
          cancellation.guard(updateCloudProvider)(
            data.cloudStack.cloudStack[0]
          );
        } else {
          cancellation.guard(setCloudProviderOptions)(
            getOptions(
              data.cloudStack.cloudStack.filter((cp: CloudProvider) =>
                availableCloudProviders.find((avail) => avail === cp)
              )
            )
          );
        }
      } else {
        cancellation.guard(setCloudProviderOptions)(
          getOptions(availableCloudProviders)
        );
      }
    },
    [authFetch, updateCloudProvider],
    handleError
  );

  const handleFinishSlackInstall = useCallback(
    (slackInstallState: string | undefined) => {
      setSlackInstallState(slackInstallState || "");
      if (slackInstallState === "installed") {
        posthog?.capture("onboarding_slack_installed", {
          email: user?.email,
          org: orgSlug,
        });
        navigate("../onboarding/success");
      }
    },
    [navigate, orgSlug, posthog, setSlackInstallState, user?.email]
  );

  const handleGenericInstall = useCallback(
    (config: Record<string, Record<string, { state: string }>>) => {
      for (const component of Object.values(config)) {
        for (const i of Object.values(component)) {
          if (i.state === "installed") {
            handleInstallStateChange(i.state);
            return;
          }
        }
      }
    },
    [handleInstallStateChange]
  );

  const handleSelectProvider = useCallback(
    (data: any) => {
      updateCloudProvider(data.cloudProvider);
    },
    [updateCloudProvider]
  );

  const providerCards: Record<string, ReactElement> = {
    aws: (
      <Aws
        cardOverride={{
          showBreadcrumbs: false,
          canRemove: false,
          isWorkflowOnboarding: true,
        }}
        onInstall={handleGenericInstall}
      />
    ),
    gcp: (
      <Routes>
        <Route
          path="/:component?"
          element={
            <Page title="Google Cloud">
              <Gcloud
                cardOverride={{
                  showBreadcrumbs: false,
                  isAuditOnboarding: true,
                  isWorkflowOnboarding: false,
                  refPath: "/welcome/workflows",
                }}
                onInstall={handleGenericInstall}
              />
            </Page>
          }
        />
      </Routes>
    ),
    snowflake: (
      <Routes>
        <Route
          path="/:component?"
          element={
            <Page title="Snowflake">
              <Snowflake
                cardOverride={{
                  showBreadcrumbs: false,
                  isAuditOnboarding: true,
                  isWorkflowOnboarding: false,
                  refPath: "/welcome/workflows",
                }}
                onInstall={handleGenericInstall}
              />
            </Page>
          }
        />
      </Routes>
    ),
  };

  return (
    <Splash overrideColumn rowStyleOverride={{ alignItems: "center" }}>
      <Col xs={22} md={11} lg={8} xl={6}>
        <Typography.Title level={4}>
          Install P0 in your environment
        </Typography.Title>
        <Typography.Paragraph>
          In order to enable P0 Workflows for secure cloud IAM access
          management, we need to get P0 installed in your cloud environment.
        </Typography.Paragraph>
        {onboardStep === "select-cloud-provider" && (
          <Typography.Paragraph>
            <strong>NOTE:</strong> You&apos;ll start with only one selection,
            and have the option to add additional integrations later.
          </Typography.Paragraph>
        )}
        <SkipOnboardingButton />
      </Col>
      <Col xs={22} md={12} lg={12} xl={10}>
        {onboardStep === "select-cloud-provider" ? (
          <>
            <Form
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              onFinish={handleSelectProvider}
            >
              <Form.Item
                label="Select your cloud provider"
                name="cloudProvider"
                rules={[
                  { required: true, message: "Please select a cloud provider" },
                ]}
              >
                <Select options={cloudProviderOptions} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
                <Button htmlType="submit" type="primary">
                  Use this provider
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : cloudProviderInstallState === "installed" ? (
          <>
            <Typography.Paragraph>
              <strong>Step 2:</strong> Install the P0 Slack bot. Your team will
              use this to request and approve access.
            </Typography.Paragraph>
            <Slack
              cardOverride={{ showBreadcrumbs: false, canRemove: false }}
              onStateTransition={handleFinishSlackInstall}
            />
          </>
        ) : (
          <>
            <StyledCloudContainer>
              {cloudProvider && providerCards[cloudProvider]}
            </StyledCloudContainer>
          </>
        )}
      </Col>
    </Splash>
  );
};

import { AwsComponents } from "shared/integrations/resources/aws/components";

import { Install, InstallProps } from "../Install/Install";
import { awsInstaller } from "./install";

export const AwsIconUrl =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/200px-Amazon_Web_Services_Logo.svg.png";

export const Aws = (
  props: Pick<InstallProps<typeof AwsComponents>, "cardOverride" | "onInstall">
) => (
  <Install<typeof AwsComponents>
    components={AwsComponents}
    installer={awsInstaller}
    integration="aws"
    itemKind="account"
    logo={AwsIconUrl}
    title={"Amazon Web Services"}
    {...props}
  />
);

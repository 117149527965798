export function genericExport<T>(transform: (node: T) => any) {
  return {
    toTsvCell: (node: T) => {
      const result = transform(node);
      return !result
        ? ""
        : typeof result === "string"
        ? result
        : JSON.stringify(result);
    },
    toJsonObject: transform,
  };
}

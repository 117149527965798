import { pick } from "lodash";

import {
  getEmailDomain,
  isValidEmailDomain,
} from "../../shared/configuration/utils";

type tenantInfo = {
  email: string;
  name: string;
  role: string;
  orgName: string;
  orgSlug: string;
  orgTeam: string;
  ssoProvider: string | undefined;
  microsoftPrimaryDomain: string | undefined;
};

export const getTenantData = (formData: any) => {
  const tenantData: tenantInfo = {
    ...pick(formData, [
      "email",
      "name",
      "role",
      "orgName",
      "orgSlug",
      "orgTeam",
      "ssoProvider",
    ]),
    microsoftPrimaryDomain: undefined,
  };

  // Reset ssoProvider to undefined if email-signin is selected
  if (formData.ssoProvider === "email-signin") {
    tenantData.ssoProvider = undefined;
  }

  if (formData.ssoProvider === "azure-oidc") {
    tenantData.microsoftPrimaryDomain = getEmailDomain(formData.email);
  }

  return tenantData;
};

export const validateWorkEmailDomain = async (_: any, value: string) => {
  if (!value) {
    throw new Error("Please enter an email!");
  }

  if (!isValidEmailDomain(value)) {
    throw new Error("Please use your business/work email.");
  }
};

import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Typography } from "antd";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

const PopoverLinkStyles = css`
  color: #1890ff;
  cursor: pointer;
`;

export const StyledRoutingRulePreview = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 8px;
  row-gap: 4px;
  font-weight: 600;

  &.link-styled {
    grid-template-columns: min-content 1fr min-content;

    .info {
      ${PopoverLinkStyles}
    }
  }

  .icon-container {
    grid-column: 1;
    grid-row: 1;
  }

  .label {
    grid-column: 2;
    grid-row: 1;
    margin-bottom: 0;
  }

  .filters {
    grid-column: 2;
    grid-row: 2;
    margin-bottom: 0;
  }

  img,
  .anticon {
    font-size: 20px;
    width: 20px;
  }
`;

export const RoutingRulePopover: React.FC<{
  icon: ReactNode;
  text: string;
  popoverContent?: ReactNode;
}> = ({ icon, text, popoverContent }) => (
  <StyledRoutingRulePreview className="link-styled">
    <div className="icon-container">{icon}</div>
    <Typography.Paragraph className="label">{text}</Typography.Paragraph>
    {popoverContent && (
      <Popover
        content={<div style={{ maxWidth: 350 }}>{popoverContent}</div>}
        trigger={"click"}
      >
        <InfoCircleOutlined className="info" />
      </Popover>
    )}
  </StyledRoutingRulePreview>
);

/**
 * Styles for Rule Editor
 */
export const StyledResourceFilterCount = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 8px;

  .text {
    grid-column: 1;
    grid-row: 1;
    margin-bottom: 0;
  }

  .popover {
    color: rgba(
      0,
      0,
      0,
      0.45
    ); // Matches the color used for "secondary" text in Ant Design
    grid-column: 2;
    grid-row: 1;
  }
`;

export const StyledResourceFilterEdit = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 150px 1fr;
  column-gap: 8px;
  row-gap: 8px;
  margin-bottom: 12px;

  .filter-key-and-pattern {
    grid-column: 2;
  }

  .filter-part {
    display: grid;
    align-items: center;
    grid-template-columns: 150px 1fr;
    column-gap: 8px;
  }
`;

import { split } from "lodash";

import { PrincipalType } from "../../../types/assessment/data";

export const AssetType = {
  folder: "cloudresourcemanager.googleapis.com/Folder",
  organization: "cloudresourcemanager.googleapis.com/Organization",
  project: "cloudresourcemanager.googleapis.com/Project",
  serviceAccount: "iam.googleapis.com/ServiceAccount",
  serviceAccountKey: "iam.googleapis.com/ServiceAccountKey",
} as const;

export const MemberKey: Partial<Record<PrincipalType, string>> = {
  "federated-identity": "user",
  "logged-in": "allAuthenticatedUsers",
  "service-account": "serviceAccount",
  domain: "domain",
  group: "group",
  public: "allUsers",
  user: "user",
};

export const ResourcePrefix = {
  folder: "//cloudresourcemanager.googleapis.com/folders/",
  organization: "//cloudresourcemanager.googleapis.com/organizations/",
  project: "//cloudresourcemanager.googleapis.com/projects/",
} as const;

export const Service = {
  crm: "cloudresourcemanager",
  iam: "iam",
};

export const ServicePrefix = {
  bigquery: "//bigquery.googleapis.com",
  compute: "//compute.googleapis.com",
  crm: "//cloudresourcemanager.googleapis.com",
  iam: "//iam.googleapis.com",
  iap: "//iap.googleapis.com",
  storage: "//storage.googleapis.com",
};

/** Converts a full resource name to an object id
 *
 * E.g.
 *
 *   //cloudresourcemanager.googleapis.com/projects/foo => foo
 */
export const frnToId = (frn: string) => {
  const id = frn.split("/").at(-1);
  if (id === undefined)
    throw Object.assign(new Error("zero-length name"), { frn });
  return id;
};

/** For top-level resource, converts an ID to a full resource name */
export const idToFrn = (type: keyof typeof ResourcePrefix, id: string) =>
  `${ResourcePrefix[type]}${id}`;

/**
 * Removes the api name prefix from a full resource name, if it is present
 *
 * //cloudresourcemanager.googleapis.com/projects/foo/serviceAccounts/test => projects/foo/serviceAccounts/test
 */
export const removeApiPrefix = (frn: string) =>
  frn.startsWith("//") ? split(frn, /googleapis.com\/(.*)/s)[1] : frn;

/** Returns the project ID from a custom role ID, or undefined */
export const extractCustomRoleProject = (name: string): string | undefined => {
  // Project IDs in GCP may contain lower-case letters, numbers, and hyphens. See https://cloud.google.com/resource-manager/docs/creating-managing-projects#before_you_begin
  const match = name.match("projects/([a-z0-9-]+)/roles");
  if (match === null) {
    return undefined;
  }
  return match[1];
};

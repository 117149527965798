import { Radio, RadioChangeEvent } from "antd";
import { ReactNode, useCallback } from "react";
import styled from "styled-components";
import { colors } from "styles/variables";

type RadioItem<T> = {
  value: T;
  display: ReactNode;
};

type Props<T extends string> = {
  value: T | undefined;
  onChange: (value: T) => void;
  items: RadioItem<T>[];
};

const StateRadioItem = <T extends string>({
  item,
  onChange,
  value,
}: { item: RadioItem<T> } & Omit<Props<T>, "items">) => {
  const changeItem = useCallback(
    () => onChange(item.value),
    [item.value, onChange]
  );
  return (
    <StyledRuleEditorTypeSelectBoxButton
      key={item.value}
      type="button"
      onClick={changeItem}
      className={value === item.value ? "selected" : ""}
    >
      <Radio value={item.value} />
      {item.display}
    </StyledRuleEditorTypeSelectBoxButton>
  );
};

export const StateRadioGroup = <T extends string>({
  value,
  onChange,
  items,
}: Props<T>) => {
  const changeFromRadio = useCallback(
    (e: RadioChangeEvent) => onChange(e.target.value),
    [onChange]
  );
  return (
    <Radio.Group value={value} onChange={changeFromRadio}>
      <StyledRuleEditorTypeContainer>
        {items.map((item, ix) => (
          <StateRadioItem
            item={item}
            value={value}
            onChange={onChange}
            key={ix}
          />
        ))}
      </StyledRuleEditorTypeContainer>
    </Radio.Group>
  );
};

const StyledRuleEditorTypeContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(1, max-content);
  grid-gap: 4px;
`;

const StyledRuleEditorTypeSelectBoxButton = styled.button`
  background: transparent;
  border: 2px solid ${colors.neutral["15"]};
  border-radius: 4px;
  color: ${colors.neutral["60"]};
  cursor: pointer;
  display: grid;
  gap: 16px;
  grid-template-columns: max-content 1fr;
  padding: 16px 24px;
  text-align: unset;
  transition: border-color 0.25s ease-in-out;

  &:hover {
    border-color: ${colors.primary["50"]};
  }

  &.selected {
    border-color: ${colors.primary["50"]};
  }

  .ant-typography,
  span {
    color: ${colors.neutral["60"]};
    font-size: 14px;
  }
`;

export const getEmailDomain = (email: string) => {
  const parts = email.split("@");

  // TODO: use real email parsing and validation library
  if (parts.length > 1 && /.+[.].+/.test(parts[1])) {
    return parts[1];
  }
};

export const blockedDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "icloud.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "facebook.com",
  "mitico.org",
];

export const isValidEmailDomain = (email: string) => {
  const domain = getEmailDomain(email);
  return !!domain && !blockedDomains.includes(domain);
};

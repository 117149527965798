import { IntegrationConfig } from "../../shared";

export namespace ms {
  export namespace models {
    // NB: There are a bazillion useless fields on these objects, like
    // "employeeHireDate" and "faxNumber"; those are ommitted from these types
    export type Group = {
      "@odata.type": string;
      displayName: string | null;
      mail: string | null;
      id: string;
      members: Group[] | User[] | null;
    };

    export type User = {
      "@odata.type": string;
      displayName: string | null;
      id: string;
      jobTitle: string | null;
      mail: string | null;
      userPrincipalName: string;
      signInActivity?: {
        lastSignInDateTime: string;
      };
      proxyAddresses: string[] | null;
    };
    export type UserMfaDetails = {
      "@odata.type": string;
      id: string;
      userPrincipalName: string;
      isMfaCapable: boolean;
    };
  }
}

export type EmailField = "mail" | "userPrincipalName";

export type AzureAdIntegration = IntegrationConfig & {
  emailField: EmailField;
};

export const DEFAULT_EMAIL_FIELD: EmailField = "userPrincipalName";

export const UUID_PATTERN =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

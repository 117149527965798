import { Col, Divider, Row } from "antd";
import { ReactNode } from "react";
import { ApprovalRule } from "shared/types/workflow/types";
import { hasApprovalOptions } from "shared/types/workflow/util";
import { join } from "utils/join";

import { ApprovalOptionsPreview } from "../components/previews/ApprovalOptionsPreview";
import { ApprovalPreview } from "../components/previews/ApprovalPreview";

export function renderApprovalSummary(record: any): ReactNode {
  if (!record || !Array.isArray(record) || record.length === 0) {
    return "No approvals";
  }

  const rendered = record.map((item: ApprovalRule, index) => (
    <Row key={index} gutter={16} align="middle">
      <Col span={12}>
        <ApprovalPreview approval={item} />
      </Col>
      {hasApprovalOptions(item) && (
        <Col span={12}>
          {item.options && (
            <ApprovalOptionsPreview approvalOptions={item.options} />
          )}
        </Col>
      )}
    </Row>
  ));

  return join(rendered, (index) => <Divider key={index} />);
}

import { Element, InstallSpec, ItemComponent } from "../../../install/types";

export const SnowflakeComponents = InstallSpec({
  base: ItemComponent({
    label: "Snowflake",
    type: "generated",
    hidden: true,
    schema: {
      rsaPublicKey: Element({
        label: "RSA Public Key",
        type: "string",
      }),
    },
  }),
  "iam-write": ItemComponent({
    label: "IAM management",
    description:
      "Allows P0 to grant and revoke Snowflake access for your users.",
    type: "string",
    schema: {
      defaultWarehouse: Element({
        label: "Default Warehouse",
        description: "Select a default warehouse for generated roles",
        type: "dynamic",
      }),
      uidColumn: Element({
        label: "User provisioning",
        description: "Select how users are provisioned in this account",
        type: "dynamic",
      }),
    },
    validator: async (_id, field) => {
      const ACCOUNT_IDENTIFIER_PATTERN = /^[0-9A-Z]+[-][0-9A-Z]+$/;
      if (!field.match(ACCOUNT_IDENTIFIER_PATTERN))
        return "Identifiers should be in the format `ORGNAME-ACCOUNTNAME` and use only numbers and uppercase letters";
    },
  }),
});

export const PermissionTitle: React.FC<{ title: string }> = ({ title }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <div>{title}</div>
    <div
      style={{
        fontSize: "small",
        fontWeight: "normal",
        display: "flex",
        whiteSpace: "nowrap",
      }}
    >
      used / unused / unknown
    </div>
  </div>
);

import React, { useContext } from "react";
import { Navigate, Outlet, useParams } from "react-router";

import { UserAuthz, UserRole } from "./util";

/** Prevents users from accidentally viewing forbidden pages if they manually navigated to them */
export const AuthzGuard: React.FC<
  React.PropsWithChildren<{
    requirement: UserRole[];
  }>
> = ({ requirement }) => {
  const userAuthz = useContext(UserAuthz);
  const { orgSlug } = useParams();
  // Don't immediately redirect if we are still loading authz
  return userAuthz.size === 0 ||
    !!requirement.find((req) => userAuthz.has(req)) ? (
    <Outlet />
  ) : !!orgSlug ? (
    <Navigate to={`/o/${orgSlug}`} />
  ) : (
    <Navigate to="/" />
  );
};

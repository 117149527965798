import { rootConfig } from "shared/integrations/resources/gcloud/util";
import { assertNever } from "shared/types";

import { pgInstallTypeInstaller } from "./installType";
import { gcpCloudSqlInstructor } from "./instructions/gcp-cloudsql";
import { GcloudConfigs, PgInstaller } from "./types";

export const pgInstaller = (gcloud: GcloudConfigs): PgInstaller => ({
  "access-management": {
    labeler: () => (
      <>
        Please enter a unique label which will only be used to identify this
        database in the P0 interface. You will be prompted for the database name
        and other details in the following steps. You cannot change this later.
      </>
    ),
    instructions: (_context, _id, item) => {
      switch (item.installType.type) {
        case "cloud-sql": {
          const gcpServiceAcctEmail = rootConfig(
            gcloud.doc.data
          ).serviceAccountEmail;

          return gcpCloudSqlInstructor(item, gcpServiceAcctEmail);
        }
        default:
          throw assertNever(item.installType.type);
      }
    },
    items: {
      installType: pgInstallTypeInstaller(gcloud),
    },
  },
});

import { Button, Space } from "antd";
import { UserAuthz } from "components/Login";
import { useContext } from "react";

export const EnforcePanel: React.FC<{
  runId: string;
  handler: (runId: string) => Promise<void>;
}> = ({ runId, handler }) => {
  const userAuthz = useContext(UserAuthz);
  return (
    <>
      <Space direction="vertical">
        <Button
          type="primary"
          data-testid="enforce-btn"
          // Legacy do not copy
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => handler(runId)}
          disabled={!userAuthz.has("owner")}
        >
          Enforce Remediation
        </Button>
      </Space>
    </>
  );
};

import { useEffect, useState } from "react";

export const useIsPrint = () => {
  const [isPrint, setIsPrint] = useState(false);

  useEffect(() => {
    const listener: EventListener = (event: Event) => {
      setIsPrint(event.type === "beforeprint");
    };
    window.addEventListener("afterprint", listener);
    window.addEventListener("beforeprint", listener);
    return () => {
      window.removeEventListener("afterprint", listener);
      window.removeEventListener("beforeprint", listener);
    };
  }, []);

  return isPrint;
};

import { LinkOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Typography } from "antd";

import { Page } from "../App/Page";
import { Heading } from "../Heading";
import {
  awsPolcyTagAccess,
  orgAndResourceRouting,
  orgRoleAccess,
} from "./routingExamples";

export const RoutingSandbox: React.FC = () => (
  <Page title="Control Requests Rules with P0">
    <Heading title="Control Requests Rules with P0" />
    <div style={{ maxWidth: 600, marginBottom: 25 }}>
      <Typography.Paragraph>
        With P0, you have full control over how your organization&apos;s
        requests are handled. On this page, you&apos;ll see several examples of
        how you can use our powerful workflow routing API to control your
        requests.
      </Typography.Paragraph>
      <Typography.Paragraph>
        This feature is exposed as a fully-customizable JSON API. There&apos;s
        an inline JSON editor in the P0 web app, but you can also use your
        favorite API tools (like Postman or cURL) to make changes directly with
        the API.
      </Typography.Paragraph>
      <Typography.Title level={4}>How it works</Typography.Title>
      <Typography.Paragraph>
        A workflow is an array of &quot;Rules&quot;. Each Rule has a
        &quot;Requestor&quot;, &quot;Resource&quot;, and one or more
        &quot;Approvals&quot;.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Requestor refers to the people who are making an access request.
        Resource refers to the resource(s) that the Requestor is requesting
        access to. And Approvers refer to the people/services who can
        approve/deny requests.
      </Typography.Paragraph>
      <Typography.Paragraph>
        You can read more about the routing rules API in our documentation.
      </Typography.Paragraph>
      <Button
        href="https://docs.p0.dev/just-in-time-access/request-routing"
        target="_blank"
        rel="noreferrer"
        icon={<LinkOutlined />}
      >
        Documentation
      </Button>
    </div>
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} lg={8}>
        <Card title="Allow different access based on organizational role">
          <Typography.Paragraph>
            Allow developers to access different resources than customer-success
            engineers.
          </Typography.Paragraph>
          <pre>
            <code style={{ fontSize: "smaller" }}>
              {JSON.stringify(orgRoleAccess, null, 2)}
            </code>
          </pre>
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={8}>
        <Card title="Filter access by AWS policy tag">
          <Typography.Paragraph>
            Restrict which AWS policies can be requested to those with a certain
            tag.
          </Typography.Paragraph>
          <pre>
            <code style={{ fontSize: "smaller" }}>
              {JSON.stringify(awsPolcyTagAccess, null, 2)}
            </code>
          </pre>
        </Card>
      </Col>
      <Col xs={24} sm={12} lg={8}>
        <Card title="Org- and resource-specific routing">
          <Typography.Paragraph>
            Allow either the requestor&apos;s manager or the service owner to
            approve request.
          </Typography.Paragraph>
          <pre>
            <code style={{ fontSize: "smaller" }}>
              {JSON.stringify(orgAndResourceRouting, null, 2)}
            </code>
          </pre>
        </Card>
      </Col>
    </Row>
  </Page>
);

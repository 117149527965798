import { DevEnv } from "../../../../types/environment";
import {
  MANAGED_BY_P0,
  P0_TAG_NAME,
  PROVISION_USER_ACCESS_DOCUMENT,
} from "../constants";
import { p0GrantsPathSegment } from "./shared";

export type AwsPolicyOptions = {
  sshOnly?: boolean;
};

export const iamAssessorPolicy = () => ({
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "P0CanReadPoliciesAndListResources",
      Effect: "Allow",
      Action: [
        "access-analyzer:GetFinding",
        "access-analyzer:ListFindings",
        "account:ListRegions",
        "ce:GetDimensionValues",
        "iam:GetGroup",
        "iam:GetGroupPolicy",
        "iam:GetPolicy",
        "iam:GetPolicyVersion",
        "iam:GetRole",
        "iam:GetRolePolicy",
        "iam:GetSAMLProvider",
        "iam:GetUser",
        "iam:GetUserPolicy",
        "iam:ListAccountAliases",
        "iam:ListAttachedGroupPolicies",
        "iam:ListAttachedRolePolicies",
        "iam:ListAttachedUserPolicies",
        "iam:ListGroupPolicies",
        "iam:ListGroups",
        "iam:ListGroupsForUser",
        "iam:ListPolicies",
        "iam:ListPolicyTags",
        "iam:ListPolicyVersions",
        "iam:ListRolePolicies",
        "iam:ListRoles",
        "iam:ListUserPolicies",
        "iam:ListUsers",
        "iam:ListUserTags",
        "resource-explorer-2:ListIndexes",
        "resource-explorer-2:Search",
        "sso:ListInstances",
      ],
      Resource: "*",
    },
  ],
});

/** Identity policy for the P0ResourceInventory role */
export const resourceInventoryPolicy = ({
  awsAccountId,
}: {
  awsAccountId: string;
  roleName: string;
}) => ({
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "P0CanListResources",
      Effect: "Allow",
      // GetRole and GetRolePolicy are for install verification
      Action: [
        "resource-explorer-2:ListIndexes",
        "resource-explorer-2:Search",
        "iam:GetRole",
        "iam:GetRolePolicy",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
  ],
});

/** Identity policy for the least-privilege P0IamManager role used only for SSH access.
 *  This was requested by Splunk, due to security concerns (they only use SSH currently).
 */
const sshIamManagerPolicy = ({
  context,
  awsAccountId,
  roleName,
}: {
  context: DevEnv;
  awsAccountId: string;
  roleName: string;
}) => {
  const pathPrefix = p0GrantsPathSegment(context);

  return {
    Version: "2012-10-17",
    Statement: [
      {
        Sid: "P0CanReadItsOwnRoleForValidation",
        Effect: "Allow",
        Action: ["iam:GetRole", "iam:GetRolePolicy"],
        Resource: `arn:aws:iam::${awsAccountId}:role/${roleName}`,
      },
      {
        Sid: "P0CanReadP0ProvisionUserAccessDocumentForValidation",
        Effect: "Allow",
        Action: ["ssm:GetDocument"],
        Resource: `arn:aws:ssm:*:${awsAccountId}:document/${PROVISION_USER_ACCESS_DOCUMENT.documentName}`,
      },
      {
        Sid: "P0CanReadAccountInformation",
        Effect: "Allow",
        Action: [
          "account:ListRegions",
          "iam:ListAccountAliases",
          "iam:GetSAMLProvider",
        ],
        Resource: "*",
        Condition: {
          StringEquals: {
            "aws:ResourceAccount": awsAccountId,
          },
        },
      },
      {
        Sid: "P0CanCreateP0ManagedPolicies",
        Effect: "Allow",
        Action: ["iam:CreatePolicy", "iam:TagPolicy"],
        Resource: `arn:aws:iam::${awsAccountId}:policy/P0Policy*`,
        Condition: {
          StringEquals: {
            [`aws:RequestTag/${P0_TAG_NAME}`]: MANAGED_BY_P0,
          },
        },
      },
      {
        Sid: "P0CanChangeP0ManagedPolicies",
        Effect: "Allow",
        Action: [
          "iam:CreatePolicyVersion",
          "iam:DeletePolicy",
          "iam:DeletePolicyVersion",
          "iam:GetPolicy",
          "iam:GetPolicyVersion",
          "iam:ListPolicyVersions",
        ],
        Resource: `arn:aws:iam::${awsAccountId}:policy/P0Policy*`,
      },
      {
        Sid: "P0CanListP0ManagedRoles",
        Effect: "Allow",
        Action: ["iam:ListRoles"],
        Resource: `arn:aws:iam::${awsAccountId}:role/${pathPrefix}/*`,
      },
      {
        Sid: "P0CanChangeP0ManagedRoles",
        Effect: "Allow",
        Action: [
          "iam:DeleteRolePolicy",
          "iam:GetRole",
          "iam:GetRolePolicy",
          "iam:ListAttachedRolePolicies",
          "iam:ListRolePolicies",
          "iam:ListRoles",
          "iam:PutRolePolicy",
        ],
        Resource: `arn:aws:iam::${awsAccountId}:role/${pathPrefix}/P0GrantsRole*`,
      },
      {
        Sid: "P0CanAttachP0ManagedPoliciesToP0ManagedRoles",
        Effect: "Allow",
        Action: ["iam:AttachRolePolicy", "iam:DetachRolePolicy"],
        Resource: `arn:aws:iam::${awsAccountId}:role/${pathPrefix}/P0GrantsRole*`,
        Condition: {
          StringLike: {
            "iam:PolicyARN": `arn:aws:iam::${awsAccountId}:policy/P0Policy*`,
          },
        },
      },
      {
        Sid: "P0CanManageSshAccess",
        Effect: "Allow",
        Action: [
          "ec2:DescribeTags",
          "ssm:DescribeInstanceInformation",
          "ssm:DescribeSessions",
          "ssm:GetCommandInvocation",
          "ssm:TerminateSession",
        ],
        Resource: "*",
        Condition: {
          StringEquals: {
            "aws:ResourceAccount": awsAccountId,
          },
        },
      },
      {
        Sid: "P0CanProvisionUserForSshAccess",
        Effect: "Allow",
        Action: "ssm:SendCommand",
        Resource: [
          `arn:aws:ec2:*:${awsAccountId}:instance/*`,
          `arn:aws:ssm:*:${awsAccountId}:document/${PROVISION_USER_ACCESS_DOCUMENT.documentName}`,
        ],
      },
      {
        Sid: "P0CanNotAlterItsOwnRole",
        Effect: "Deny",
        Action: [
          "iam:AttachRole*",
          "iam:DeleteRole*",
          "iam:DetachRole*",
          "iam:PutRole*",
          "iam:TagRole",
          "iam:UntagRole",
          "iam:UpdateRole*",
        ],
        Resource: `arn:aws:iam::${awsAccountId}:role/${roleName}`,
      },
      {
        Sid: "P0CannotModifySSMDocuments",
        Effect: "Deny",
        Action: [
          "ssm:CreateDocument",
          "ssm:UpdateDocument",
          "ssm:DeleteDocument",
        ],
        Resource: "*",
      },
      {
        Sid: "P0CanNotAssumeRoles",
        Effect: "Deny",
        Action: "sts:AssumeRole",
        Resource: "*",
      },
    ],
  };
};

/** Identity policy for the P0IamManager role */
const fullIamManagerPolicy = ({
  awsAccountId,
  roleName,
}: {
  context: DevEnv;
  awsAccountId: string;
  roleName: string;
}) => ({
  Version: "2012-10-17",
  Statement: [
    {
      Sid: "P0CanGetAndListPolicies",
      Effect: "Allow",
      Action: [
        "iam:GetPolicy",
        "iam:GetPolicyVersion",
        "iam:ListPolicyTags",
        "iam:ListPolicyVersions",
      ],
      Resource: "*",
    },
    {
      Sid: "P0CanManagePoliciesAndListResources",
      Effect: "Allow",
      Action: [
        "account:ListRegions",
        "iam:AddUserToGroup",
        "iam:AttachRolePolicy",
        "iam:AttachUserPolicy",
        "iam:CreatePolicy",
        "iam:CreatePolicyVersion",
        "iam:DeletePolicy",
        "iam:DeletePolicyVersion",
        "iam:DeleteRole",
        "iam:DeleteRolePolicy",
        "iam:DetachRolePolicy",
        "iam:DetachUserPolicy",
        "iam:GetRole",
        "iam:GetRolePolicy",
        "iam:GetSAMLProvider",
        "iam:GetUser",
        "iam:ListAccountAliases",
        "iam:ListAttachedGroupPolicies",
        "iam:ListAttachedRolePolicies",
        "iam:ListAttachedUserPolicies",
        "iam:ListGroupPolicies",
        "iam:ListGroups",
        "iam:ListGroupsForUser",
        "iam:ListPolicies",
        "iam:ListRolePolicies",
        "iam:ListRoles",
        "iam:ListUsers",
        "iam:ListUserTags",
        "iam:PutRolePolicy",
        "iam:RemoveUserFromGroup",
        "resource-explorer-2:ListIndexes",
        "resource-explorer-2:Search",
        "sagemaker:ListNotebookInstances",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
    {
      Sid: "P0CanManageSshAccess",
      Effect: "Allow",
      Action: [
        "ec2:DescribeTags",
        "ssm:AddTagsToResource",
        "ssm:GetDocument",
        "ssm:DescribeInstanceInformation",
        "ssm:DescribeSessions",
        "ssm:GetCommandInvocation",
        "ssm:ListCommandInvocations",
        "ssm:TerminateSession",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
    {
      Sid: "P0CanProvisionUserForSshAccess",
      Effect: "Allow",
      Action: "ssm:SendCommand",
      Resource: [
        `arn:aws:ec2:*:${awsAccountId}:instance/*`,
        `arn:aws:ssm:*:${awsAccountId}:document/${PROVISION_USER_ACCESS_DOCUMENT.documentName}`,
      ],
    },
    {
      Sid: "P0CanManageKubernetesAccess",
      Effect: "Allow",
      Action: [
        "eks:CreateAccessEntry",
        "eks:DeleteAccessEntry",
        "eks:DescribeAccessEntry",
        "eks:UpdateAccessEntry",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
        ArnNotEquals: {
          "eks:principalArn": `arn:aws:iam::${awsAccountId}:role/${roleName}`,
        },
      },
    },
    {
      Sid: "P0CanManageSsoAssignments",
      Effect: "Allow",
      Action: [
        "iam:GetSAMLProvider",
        "identitystore:ListUsers",
        "sso:AttachCustomerManagedPolicyReferenceToPermissionSet",
        "sso:AttachManagedPolicyToPermissionSet",
        "sso:CreateAccountAssignment",
        "sso:CreatePermissionSet",
        "sso:DeleteAccountAssignment",
        "sso:DeletePermissionSet",
        "sso:DescribeAccountAssignmentCreationStatus",
        "sso:DescribeAccountAssignmentDeletionStatus",
        "sso:DescribePermissionSet",
        "sso:DescribePermissionSetProvisioningStatus",
        "sso:GetInlinePolicyForPermissionSet",
        "sso:ListAccountAssignments",
        "sso:ListInstances",
        "sso:ListManagedPoliciesInPermissionSet",
        "sso:ListCustomerManagedPolicyReferencesInPermissionSet",
        "sso:ListPermissionSets",
        "sso:ListTagsForResource",
        "sso:ProvisionPermissionSet",
        "sso:PutInlinePolicyToPermissionSet",
      ],
      Resource: "*",
      Condition: {
        StringEquals: {
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
    {
      Sid: "P0CanCreateSsoRolesOnly",
      Effect: "Allow",
      Action: "iam:CreateRole",
      // Granting on all resources would allow an attacker that compromised P0 to
      // privilege escalate: they could create a role with an arbitrary trust boundary.
      // Prevent this by restricting created roles to only those generated via AWS SSO;
      // such roles are always restricted to a valid SSO identity. Note that forward
      // slashes are not allowed in role names, so this ARN can not be impersonated by
      // manually including this previx in the role name.
      Resource: `arn:aws:iam::${awsAccountId}:role/aws-reserved/sso.amazonaws.com/*`,
    },
    {
      Sid: "P0CanTagPoliciesAndRoles",
      Effect: "Allow",
      Action: ["iam:CreatePolicy", "iam:TagPolicy"],
      Resource: "*",
      Condition: {
        StringEquals: {
          [`aws:RequestTag/${P0_TAG_NAME}`]: MANAGED_BY_P0,
          "aws:ResourceAccount": awsAccountId,
        },
      },
    },
    {
      Sid: "P0CanNotAlterItsOwnRole",
      Effect: "Deny",
      Action: [
        "iam:AttachRole*",
        "iam:DeleteRole*",
        "iam:DetachRole*",
        "iam:PutRole*",
        "iam:TagRole",
        "iam:UntagRole",
        "iam:UpdateRole*",
      ],
      Resource: `arn:aws:iam::${awsAccountId}:role/${roleName}`,
    },
    {
      Sid: "P0CanNotCreateSshUserProvisioningDocument",
      Effect: "Deny",
      Action: ["ssm:CreateDocument"],
      Resource: `arn:aws:ssm:*:${awsAccountId}:document/${PROVISION_USER_ACCESS_DOCUMENT.documentName}`,
    },
    {
      Sid: "P0CanNotAssumeRoles",
      Effect: "Deny",
      Action: "sts:AssumeRole",
      Resource: "*",
    },
  ],
});

export const iamManagerPolicy = (params: {
  context: DevEnv;
  awsAccountId: string;
  roleName: string;
  options: AwsPolicyOptions;
}) =>
  params.options.sshOnly
    ? sshIamManagerPolicy(params)
    : fullIamManagerPolicy(params);

import { Button, Input, Typography } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { JiraIcon } from "components/Integrations/Jira/Jira";
import { AuthFetch } from "components/Login/hook";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { useCallback } from "react";
import { Finding } from "shared/types/assessment/finding";

import { FindingAssignment } from "./FindingAssignment";
import { useFindingsStateUpdates } from "./useFindingsStateUpdates";

export const FindingStateEditor: React.FC<{
  finding: FirestoreDoc<Finding>;
  authFetch: AuthFetch;
}> = ({ finding, authFetch }) => {
  const { state, history, ignore, trigger } = finding.data;
  const {
    assessmentFindingAssignment: hasAssignment,
    assessmentUnassignForDemo: hasUnassign,
  } = useFlags();

  const {
    assignFindings,
    ignoreFindings,
    unassignFindings,
    unignoreFindings,
    updateFindingsNotes,
  } = useFindingsStateUpdates(authFetch);

  const onClickIgnore = useCallback(
    () => ignoreFindings([finding]),
    [ignoreFindings, finding]
  );
  const onClickUnignore = useCallback(
    () => unignoreFindings([finding]),
    [unignoreFindings, finding]
  );
  const onClickChangeNotes = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) =>
      updateFindingsNotes([finding], event),
    [updateFindingsNotes, finding]
  );
  const onClickUnassign = useCallback(
    () => unassignFindings([finding]),
    [unassignFindings, finding]
  );

  const onClickAssign = useCallback(
    () => assignFindings([finding]),
    [assignFindings, finding]
  );

  const action = (
    <ButtonGroup>
      {hasAssignment ? (
        finding.data.assignment && hasUnassign ? (
          // TODO: choose icon based on installed tracking integration
          <Button
            onClick={onClickUnassign}
            disabled={finding.data.assignment.status !== "complete"}
          >
            {JiraIcon}&nbsp;Unassign{" "}
          </Button>
        ) : (
          <Button onClick={onClickAssign} disabled={!!finding.data.assignment}>
            {JiraIcon}&nbsp;Assign{" "}
          </Button>
        )
      ) : undefined}
      {state === "open" ? (
        <Button onClick={onClickIgnore}>🔇&nbsp;Ignore</Button>
      ) : state === "ignored" ? (
        <Button onClick={onClickUnignore}>🔊&nbsp;Un-ignore</Button>
      ) : null}
    </ButtonGroup>
  );

  return (
    <div
      style={{
        marginBottom: "1em",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          gap: "1em",
        }}
      >
        <Typography.Paragraph>
          {state === "resolved"
            ? `Fixed ${moment(history.slice(-1)[0]?.at).fromNow()}`
            : state === "ignored"
            ? `Ignored ${moment(ignore?.at).fromNow()} by ${ignore?.by}`
            : `${trigger.type === "new" ? "Discovered" : "Regressed"} ${moment(
                history.find((h) => h.jobId === trigger.jobId)?.at
              ).fromNow()}`}
        </Typography.Paragraph>
        {action}
      </div>
      {hasAssignment && <FindingAssignment finding={finding} />}
      <label>Enter any notes here:</label>
      <Input.TextArea
        defaultValue={ignore?.justification}
        onChange={onClickChangeNotes}
      />
      {ignore && (
        <Typography.Text type="secondary" style={{ fontSize: "small" }}>
          Last updated {moment(ignore.at).fromNow()} by {ignore.by}
        </Typography.Text>
      )}
    </div>
  );
};

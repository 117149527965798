import { Breadcrumb } from "antd";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Force center alignment of breadcrumb content for proper icon display
const AlignedBreadcrumb = styled(Breadcrumb)`
  ol,
  ol li,
  ol li .ant-breadcrumb-link,
  ol li div {
    display: inline-flex;
    align-items: center;
  }
`;

export type LogoBreadcrumbItem = {
  icon?: ReactElement;
  to?: string;
  label: string;
};

/** Breadcrumbs with displayed logos */
export const LogoBreadcrumb: React.FC<{
  items: LogoBreadcrumbItem[];
}> = ({ items }) => (
  <AlignedBreadcrumb>
    {items.map(({ icon, to, label }, ix) => {
      const el = (
        <>
          {icon ? (
            <>
              {icon}&nbsp;{label}
            </>
          ) : (
            label
          )}
        </>
      );
      return (
        <Breadcrumb.Item key={ix}>
          {to ? <Link to={to}>{el}</Link> : el}
        </Breadcrumb.Item>
      );
    })}
  </AlignedBreadcrumb>
);

import { ArrowRightOutlined } from "@ant-design/icons";
import { Image, Row, Typography, message } from "antd";
import { Auth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useFlags } from "launchdarkly-react-client-sdk";
import { usePostHog } from "posthog-js/react";
import { getAuth } from "providers/FirestoreProvider";
import * as React from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { SSO_OIDC_PROVIDERS } from "../../../../../shared/configuration/constant";
import { isValidEmailDomain } from "../../../../../shared/configuration/utils";
import { useCreateAccountPageStore } from "../../../store/createAccountStore";
import { getEnvironment } from "../../../utils/environment";
import CompanySection from "./CompanySection";
import OrgInfoForm from "./OrgInfoForm";
import SignUpForm from "./SignUpForm";
import {
  LoginSectionCol,
  StyledButtonSpacing,
  StyledContainer,
  StyledLoginLink,
  StyledLogos,
  StyledMainContent,
  StyledPrivacy,
} from "./styles";

const { Title } = Typography;

const CreateAccountPage: React.FC = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const flags = useFlags();

  const {
    setIsSubmittingInfo,
    setIsGoogleSSOLoading,
    setNewAccountInfo,
    newAccountInfo,
    showOrgInfo,
    setShowOrgInfo,
  } = useCreateAccountPageStore();
  const [messageApi, contextHolder] = message.useMessage();

  const createAccount = useCallback(
    async (orgSlug: string) => {
      const { email, userDisplayName, ssoProvider } = newAccountInfo;
      setIsSubmittingInfo(true);

      try {
        if (flags.allowWorkEmail && !isValidEmailDomain(email)) {
          throw new Error("Please use your business/work email.");
        }

        const fetchUrl = `${getEnvironment().apiUrl()}/provision/create-tenant`;
        const response = await fetch(fetchUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            orgSlug,
            name: userDisplayName,
            ssoProvider,
          }),
        });
        if (!response.ok) {
          throw new Error("Failed to create account");
        }

        const responseData = await response.json();
        posthog?.capture("new_account_created", {
          email: email,
          org: orgSlug,
        });

        navigate(`/o/${responseData.orgSlug}?tenant=new`);
      } catch (error) {
        messageApi.error(`${error instanceof Error ? error.message : error}`);
      } finally {
        setIsSubmittingInfo(false);
        setIsGoogleSSOLoading(false);
      }
    },
    [
      newAccountInfo,
      setIsSubmittingInfo,
      flags.allowWorkEmail,
      messageApi,
      posthog,
      navigate,
      setIsGoogleSSOLoading,
    ]
  );

  const handleGoogleSignUp = useCallback(async () => {
    const auth: Auth = getAuth();
    setIsGoogleSSOLoading(true);
    try {
      const response = await signInWithPopup(auth, new GoogleAuthProvider());
      const email = response.user.email;
      const displayName = response.user.displayName;
      if (!email || !displayName) {
        throw new Error("Failed to retrieve email from Google");
      }

      if (flags.allowWorkEmail && !isValidEmailDomain(email)) {
        throw new Error("Please use your business/work email.");
      }

      posthog?.capture("new_account_google_sso_signup", {
        email: email,
      });

      setNewAccountInfo({
        email,
        userDisplayName: displayName,
        ssoProvider: SSO_OIDC_PROVIDERS.google,
      });
      setShowOrgInfo(true);
    } catch (error: unknown) {
      messageApi.error(
        error instanceof Error
          ? error.message
          : "Failed to sign up with Google. Please try again later."
      );
    } finally {
      setIsGoogleSSOLoading(false);
    }
  }, [
    messageApi,
    posthog,
    setNewAccountInfo,
    setShowOrgInfo,
    setIsGoogleSSOLoading,
    flags.allowWorkEmail,
  ]);

  const onEmailSignUp = useCallback(
    ({ email }: { email: string }) => {
      posthog?.capture("new_account_email_signup", {
        email: email,
      });
      setNewAccountInfo({
        email,
        userDisplayName: email.split("@")[0].replace(".", " "),
      });
      setShowOrgInfo(true);
    },
    [posthog, setNewAccountInfo, setShowOrgInfo]
  );

  return (
    <StyledContainer>
      {contextHolder}
      <Row gutter={16} style={{ height: "100vh" }}>
        <CompanySection />
        <LoginSectionCol xs={24} md={12}>
          <StyledLoginLink>
            <Image
              preview={false}
              src={"/p0 dark logomark.png"}
              alt="P0"
              height={30}
              width={30}
            />
            <div className="login-text">
              Have an account?
              <a href={"./login"}>
                Log in <ArrowRightOutlined />
              </a>
            </div>
          </StyledLoginLink>
          <StyledMainContent>
            <Title level={4}>
              {showOrgInfo
                ? `What's the name of your company or team?`
                : "Control cloud entitlements for your engineers"}
            </Title>
            <StyledButtonSpacing direction="vertical">
              {showOrgInfo ? (
                <OrgInfoForm handleCreateAccount={createAccount} />
              ) : (
                <SignUpForm
                  handleGoogleSignUp={handleGoogleSignUp}
                  onEmailSignUp={onEmailSignUp}
                />
              )}
            </StyledButtonSpacing>
            <StyledPrivacy>
              By signing up, you agree to our
              <a href="./tos">Terms of Service</a> and
              <a href="./privacy">Privacy Policy.</a>
            </StyledPrivacy>
          </StyledMainContent>
          <StyledLogos>
            <Image preview={false} src={"/SOC.png"} alt="P0" width={80} />
            <Image
              preview={false}
              src={"/gcp_partner.png"}
              alt="P0"
              width={80}
            />
          </StyledLogos>
        </LoginSectionCol>
      </Row>
    </StyledContainer>
  );
};

export default CreateAccountPage;

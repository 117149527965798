import { GoogleOutlined } from "@ant-design/icons";
import { Logo } from "components/Integrations/Logo";
import { OktaIconUrl } from "components/Integrations/Okta/Okta";
import {
  Auth,
  AuthProvider,
  GoogleAuthProvider,
  NextOrObserver,
  OAuthCredential,
  OAuthProvider,
  Unsubscribe,
  User,
  UserCredential,
  browserSessionPersistence,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithEmailLink,
  signInWithPopup,
} from "firebase/auth";
import { getAuth } from "providers/FirestoreProvider";
import React from "react";
import { OrgData } from "shared/types/tenant";
import {
  AzureOIDCProvider,
  GoogleOIDCProvider,
  LegacyOktaSsoProvider,
  MicrosoftSsoProvider,
  OidcPkceProvider,
} from "shared/types/tenant/sso";
import { assertNever } from "utils/assert";

import { EntraIdIconUrl } from "../Integrations/AzureAD/AzureAd";
import { LimitedUse } from "../Integrations/Google/LimitedUse";
import { signInWithPkce } from "./pkce";

const actionCodeSettingsBase = {
  url: `${window.location.origin}/login/email-callback?orgSlug=[orgSlug]`,
  handleCodeInApp: true,
};

export namespace idPlatform {
  export async function finishEmailSignIn(
    email: string,
    tenantId: string
  ): Promise<UserCredential | undefined> {
    const auth: Auth = getAuth(); // Requires initialized Firestore
    auth.tenantId = tenantId;
    if (isSignInWithEmailLink(auth, window.location.href)) {
      return await signInWithEmailLink(auth, email, window.location.href);
    }
  }

  export async function signIn(
    tenantDetails: OrgData,
    options: {
      anonymous?: boolean;
      email?: string;
      orgSlug: string;
    }
  ): Promise<OAuthCredential | UserCredential | null | void> {
    const idp = idpSelector(tenantDetails);
    const auth: Auth = getAuth(); // Requires initialized Firestore
    auth.tenantId = tenantDetails.tenantId;
    await auth.setPersistence(browserSessionPersistence);
    // `signInWithPopup` below is the actual login window managed by Google Identity Platform and the configured Provider
    // See an example for OIDC: https://cloud.google.com/identity-platform/docs/web/oidc#signing_in_users_with_oauth
    if (options.anonymous) {
      return await signInAnonymously(auth);
    } else if (idp?.flow === "pkce") {
      const provider = idp.authProvider();
      const result = await signInWithPkce(
        auth,
        provider as OAuthProvider,
        tenantDetails as OidcPkceProvider,
        options.orgSlug
      );
      return idp.credentialFromResult(result);
    } else if (idp !== undefined) {
      const result = await signInWithPopup(auth, idp.authProvider());
      return idp.credentialFromResult(result);
    } else if (options.email !== undefined) {
      const actionCodeSettings = { ...actionCodeSettingsBase };
      actionCodeSettings.url = actionCodeSettings.url.replace(
        "[orgSlug]",
        options.orgSlug
      );
      return sendSignInLinkToEmail(
        auth,
        options.email,
        actionCodeSettings
      ).catch(console.error);
    }
  }

  export async function signOut() {
    return await getAuth().signOut(); // Requires initialized Firestore
  }

  export function onAuthStateChanged(
    nextOrObserver: NextOrObserver<User>
  ): Unsubscribe {
    return getAuth().onAuthStateChanged(nextOrObserver); // Requires initialized Firestore
  }

  export function element(tenantDetails: OrgData) {
    const idp = idpSelector(tenantDetails);
    return !!idp && idp.component();
  }

  export function footer(tenantDetails: OrgData) {
    const idp = idpSelector(tenantDetails);
    return idp?.footer;
  }
}

type IdentityProvider = {
  component: () => React.ReactElement;
  authProvider: () => AuthProvider;
  credentialFromResult: (result: UserCredential) => OAuthCredential | null;
  flow?: "pkce" | "popup";
  footer?: React.ReactElement;
};

const idpSelector = (provider: OrgData): IdentityProvider | undefined => {
  const { ssoProvider } = provider;
  return ssoProvider === undefined
    ? undefined
    : ssoProvider === "google"
    ? googleIdentityProvider()
    : ssoProvider === "microsoft"
    ? microsoftIdentityProvider(provider)
    : ssoProvider === "okta"
    ? oktaIdentityProvider(provider)
    : ssoProvider === "oidc-pkce"
    ? pkceIdentityProvider(provider)
    : ssoProvider === "google-oidc"
    ? googleOidcIdentityProvider(provider)
    : ssoProvider === "azure-oidc"
    ? azureOidcIdentityProvider(provider)
    : assertNever(provider);
};

const googleIdentityProvider = (): IdentityProvider => ({
  component: () => {
    return (
      <>
        <GoogleOutlined /> Sign in with Google
      </>
    );
  },
  authProvider: () => new GoogleAuthProvider(),
  credentialFromResult: (result: UserCredential) =>
    GoogleAuthProvider.credentialFromResult(result),
  footer: <LimitedUse />,
});

const googleOidcIdentityProvider = (
  provider: GoogleOIDCProvider
): IdentityProvider => ({
  component: () => {
    return (
      <>
        <GoogleOutlined /> Sign in with Google
      </>
    );
  },
  authProvider: () => {
    return new OAuthProvider(`oidc.${provider.ssoProvider}`);
  },
  credentialFromResult: (result: UserCredential) => {
    return OAuthProvider.credentialFromResult(result);
  },
  footer: <LimitedUse />,
});

const azureOidcIdentityProvider = (
  provider: AzureOIDCProvider
): IdentityProvider => ({
  component: () => {
    return (
      <>
        <img src={EntraIdIconUrl} width={15} alt="Entra ID" /> Sign in with
        Microsoft | Entra ID
      </>
    );
  },
  authProvider: () => {
    return new OAuthProvider(
      `oidc.${provider.ssoProvider}`
    ).setCustomParameters({
      tenant: provider.microsoftPrimaryDomain,
    });
  },
  credentialFromResult: (result: UserCredential) => {
    return OAuthProvider.credentialFromResult(result);
  },
});

const microsoftIdentityProvider = (
  provider: MicrosoftSsoProvider
): IdentityProvider => ({
  component: () => {
    return (
      <>
        <img src={EntraIdIconUrl} width={15} alt="Entra ID" /> Sign in with
        Microsoft | Entra ID
      </>
    );
  },
  authProvider: () => {
    // Prevent another Microsoft tenant or a personal account from signing in to this tenant
    // If this is not set, a random live.com account can log in to any Azure AD tenant,
    // albeit, they won't see any data because of the Firestore rules that enforce a regex match
    // on the domain using the Firestore document field `o/{tenantId}.openIdDomain`.
    // Make sure to update that field and the `orgs/{tenantSlug}.microsoftPrimaryDomain` field simultaneously.
    // If this field is set, the login attempt is blocked with the error message "User account 'user@hotmail.com'
    // from identity provider 'live.com' does not exist in tenant 'XYZ'...".
    return new OAuthProvider("microsoft.com").setCustomParameters({
      tenant: provider.microsoftPrimaryDomain,
    });
  },
  credentialFromResult: (result: UserCredential) => {
    return OAuthProvider.credentialFromResult(result);
  },
});

const pkceIdentityProvider = (
  provider: OidcPkceProvider
): IdentityProvider => ({
  component: () => (
    <>
      {provider.providerType === "okta" ? (
        <Logo logo={OktaIconUrl} title="Okta" />
      ) : null}{" "}
      Sign in with {provider.providerType === "okta" ? "Okta" : "your provider"}
    </>
  ),
  flow: "pkce",
  authProvider: () => new OAuthProvider(provider.providerId),
  credentialFromResult: OAuthProvider.credentialFromResult,
});

/** @deprecated New users should use pkceIdentityProvider instead
 *
 * This is for backwards compatibility for customers that do not yet have a PKCE-requiring native OIDC app.
 */
const oktaIdentityProvider = (
  provider: LegacyOktaSsoProvider
): IdentityProvider => ({
  component: () => {
    return (
      <>
        <Logo logo={OktaIconUrl} title="Okta" /> Sign in with Okta
      </>
    );
  },
  authProvider: () => {
    // See https://cloud.google.com/identity-platform/docs/web/oidc?_ga=2.24136511.-468889233.1666576248#signing_in_users_with_oauth
    // The provider ID is tenant-specific
    return new OAuthProvider(provider.providerId);
  },
  credentialFromResult: (result: UserCredential) => {
    return OAuthProvider.credentialFromResult(result);
  },
});

import { SINGLETON_ITEM } from "shared/install/constants";
import { installedItem } from "shared/install/installed";
import { otherInstalled } from "shared/install/options";
import { GCLOUD_SECURITY_PERIMETER_FEATURE_FLAG } from "shared/integrations/resources/gcloud/constants";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";

import { gcloudAccessLogsInstructor } from "./access-logs";
import { iamInstructor, iamPrerequisiteMessage } from "./iam";
import { orgWidePolicyInstructor } from "./org-wide-policy";
import { sharingRestrictionWarningLabeler } from "./root";
import {
  cloudRunUrlInstaller,
  securityPerimeterInstructions,
} from "./security-perimeter";
import { sharingRestrictionInstructions } from "./sharing-restriction";
import { GcloudInstaller } from "./types";

export const gcloudInstaller: GcloudInstaller = {
  root: {
    items: {
      organizationId: {
        labeler: sharingRestrictionWarningLabeler,
      },
    },
  },
  "access-logs": {
    instructions: gcloudAccessLogsInstructor("project"),
    optionProvider: async (context) =>
      otherInstalled<GcloudIntegration>("iam-assessment")(context),
    prerequisiteMessages: async (context) => {
      if (installedItem("org-access-logs", context.config, SINGLETON_ITEM))
        return (
          <div>
            Cannot install project access logs with active organization access
            log installation
          </div>
        );
    },
    items: {},
  },
  "iam-assessment": {
    instructions: iamInstructor("project", "iam-assessment"),
  },
  "iam-write": {
    prerequisiteMessages: iamPrerequisiteMessage,
    instructions: (context, id) => {
      return context.featureFlags?.[GCLOUD_SECURITY_PERIMETER_FEATURE_FLAG]
        ? iamInstructor("project", "iam-write-2")(context, id)
        : iamInstructor("project", "iam-write")(context, id);
    },
  },
  "org-access-logs": {
    instructions: gcloudAccessLogsInstructor("organization"),
    items: {},
  },
  "org-iam-assessment": {
    instructions: iamInstructor("organization", "iam-assessment"),
  },
  "org-wide-policy": {
    instructions: orgWidePolicyInstructor,
  },
  "sharing-restriction": {
    instructions: sharingRestrictionInstructions,
    optionProvider: async (context) =>
      otherInstalled<GcloudIntegration>("iam-write")(context),
  },
  "iam-write-security-perimeter": {
    labeler: () => (
      <>
        Project identifier, in your environment, where you will install
        P0&apos;s security perimeter
      </>
    ),
    instructions: securityPerimeterInstructions("iam-write-security-perimeter"),
    items: {
      cloudRunUrl: cloudRunUrlInstaller,
    },
  },
};

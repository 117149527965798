import { DevEnv } from "../../../../types/environment";
import { AwsPolicyOptions } from "./policies";
import { roleInstallCommands, roleTerraform } from "./shared";

export const iamWriteInstallCommands = (
  context: DevEnv,
  options: AwsPolicyOptions,
  args: {
    gcloudServiceAccountId: string;
    awsAccountId: string;
  }
) => roleInstallCommands("iam-write", context, options, args);

export const iamWriteTerraform = (
  gcloudServiceAccountId: string,
  context: DevEnv,
  options: AwsPolicyOptions
) => roleTerraform("iam-write", context, options, gcloudServiceAccountId);

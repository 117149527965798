import { KnownProviderSshSessionSpec, SshSessionSpec } from "./accesses";
import { UnknownSshSessionSpec } from "./accesses/unknown";

export const isAwsArn = (destination: string) =>
  destination.startsWith("arn:aws:");
export const isGcloudResourceUrl = (destination: string) =>
  destination.startsWith("//compute.googleapis.com");
export const isAzureInstanceId = (destination: string) =>
  destination.startsWith("/subscriptions/");

export const VALID_GCLOUD_PROVIDERS = ["gcloud", "gcp"];

export const normalizeProvider = (provider: string | undefined) => {
  return provider?.toLowerCase();
};

export const isAwsId = (id: string) => id.match(/^aws:/);
export const isGcloudId = (id: string) => id.match(/^gcloud:/);
export const isAzureId = (id: string) => id.match(/^azure:/);

export const isKnownProviderSshSessionSpec = (
  permission: SshSessionSpec["permission"] | UnknownSshSessionSpec["permission"]
): permission is KnownProviderSshSessionSpec["permission"] => {
  return permission.provider != null;
};

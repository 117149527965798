import { useCallback, useEffect, useState } from "react";

const SANDBOX_LOCALSTORAGE_KEY = "p0-sandbox";

export const useSandbox = (enable = false) => {
  const [loaded, setLoaded] = useState(false);
  const [requestsVisited, setRequestsVisited] = useState<boolean | null>(null);
  const [assessmentVisited, setAssessmentVisited] = useState<boolean | null>(
    null
  );

  const visitRequests = useCallback(() => {
    if (loaded) {
      setRequestsVisited(true);
      updateLocalStorage("requestsVisited", true);
    }
  }, [loaded]);

  const visitAssessment = useCallback(() => {
    if (loaded) {
      setAssessmentVisited(true);
      updateLocalStorage("assessmentVisited", true);
    }
  }, [loaded]);

  useEffect(() => {
    if (enable) {
      const stored = localStorage.getItem(SANDBOX_LOCALSTORAGE_KEY);
      if (stored !== null) {
        const { requestsVisited, assessmentVisited } = JSON.parse(stored);
        setRequestsVisited(requestsVisited ?? false);
        setAssessmentVisited(assessmentVisited ?? false);
      } else {
        localStorage.setItem(
          SANDBOX_LOCALSTORAGE_KEY,
          JSON.stringify({
            requestsVisited: false,
            assessmentVisited: false,
          })
        );
        setRequestsVisited(false);
        setAssessmentVisited(false);
      }
      setLoaded(true);
    }
  }, [enable]);

  return {
    requestsVisited,
    assessmentVisited,
    visitRequests,
    visitAssessment,
  };
};

const updateLocalStorage = (key: string, value: any) => {
  const stored = localStorage.getItem(SANDBOX_LOCALSTORAGE_KEY);
  if (stored !== null) {
    const parsed = JSON.parse(stored);
    localStorage.setItem(
      SANDBOX_LOCALSTORAGE_KEY,
      JSON.stringify({ ...parsed, [key]: value })
    );
  }
};

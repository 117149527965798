import {
  IntegrationConfig,
  IntegrationStatus,
} from "../../../shared/integrations/shared";
import {
  ExtendedIntegrationData,
  InstallCount,
  IntegrationData,
} from "../types";

/** Calculates how many components are installed for a single integration */
export const getInstallCounts = (
  { availableComponents }: IntegrationData,
  installation:
    | IntegrationConfig
    | Record<string, Record<string, any>>
    | undefined
): ExtendedIntegrationData["counts"] => {
  if (!installation) return { total: 1, installed: 0, inProgress: 0 };
  // N.B. Remove after all resources migrated to "state"
  if (
    ("state" in installation && installation.state) ||
    (installation as any).status
  ) {
    return (installation as any).state ??
      (installation as any).status === "installed"
      ? { total: 1, installed: 1, inProgress: 0 }
      : { total: 1, installed: 0, inProgress: 1 };
  }
  const components = Object.entries(installation)
    .filter(([, v]) => typeof v === "object")
    .map(([k, v]) =>
      "items" in v
        ? ([k, (v as any).items as { state: IntegrationStatus }[]] as const)
        : "state" in v
        ? ([k, [{ state: (v as any).state as IntegrationStatus }]] as const)
        : ([k, Object.values(v) as { state: IntegrationStatus }[]] as const)
    );
  const counts: InstallCount = {
    total: availableComponents?.length ?? 1,
    installed: 0,
    inProgress: 0,
  };
  for (const [key, items] of components) {
    if (!availableComponents?.includes(key)) continue;
    if (Object.values(items).find((i) => i.state !== "installed")) {
      counts.inProgress += 1;
      continue;
      // If a integration has some projects installed and
      // some in progress, consider that in progress and do not double count
    }
    counts.installed += items.find((i) => i.state === "installed") ? 1 : 0;
  }
  return counts;
};

import { Link } from "react-router-dom";

// TODO dedup with GraphToolTop pattern
export const ConditionalAnchor: React.FC<{
  wrappedComponent: React.ReactNode;
  url?: string;
}> = ({ wrappedComponent, url }) =>
  url ? (
    <Link to={url} target="_blank" rel="noreferrer">
      {wrappedComponent}
    </Link>
  ) : (
    <>{wrappedComponent}</>
  );

import { Input, InputProps, Tag } from "antd";
import { pullAt, uniq } from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

/** An Input where the prefix automatically wraps as input overflows
 *
 * Begins as a single-line input with prefix items prepended to the input;
 * automatically forms two lines once the available input length falls below 15em.
 */
const WrappedInput = styled(Input)`
  flex-flow: row wrap;
  .ant-input {
    margin-bottom: 0;
    flex: 1 1 15em;
  }
  .ant-input-prefix {
    flex-flow: row wrap;
    gap: 0.5em 0;
    margin-right: 0;
    max-width: 100%;
  }
`;

type TagInputProps = InputProps & {
  tags: string[];
  onUpdate: (tags: string[]) => void;
  tagToElement?: (tag: string, onClose: () => void) => JSX.Element;
  /** Character class or fixed strings to match for tags */
  tagPattern?: RegExp;
};

const defaultTagToElement = (tag: string, onClose: () => void) => {
  return (
    <Tag key={tag} closable={true} onClose={onClose}>
      {tag}
    </Tag>
  );
};

const defaultTagPattern = /[^\s,;]/;

export const TagInput: React.FC<TagInputProps> = (props) => {
  const {
    tags,
    tagPattern,
    tagToElement,
    onUpdate,
    disabled,
    placeholder,
    ...inputProps
  } = props;
  const [text, setText] = useState("");
  const updateTags = useMemo(
    () => (newTags: string[]) => onUpdate(uniq(newTags.sort())),
    [onUpdate]
  );
  const tagClass = tagPattern ?? defaultTagPattern;
  const [splitRegex, matchRegex] = useMemo(
    () => [
      new RegExp(`.+(?<!${tagClass.source})`),
      new RegExp(`(?:${tagClass.source})+`),
    ],
    [tagClass.source]
  );
  const tagElements = useMemo(
    () =>
      tags.map((v, ix) => {
        const onTagClose = () => {
          // have to clone tags array otherwise diffing does not work
          const newTags = [...tags];
          pullAt(newTags, ix);
          return updateTags(newTags);
        };
        return (tagToElement ?? defaultTagToElement)(v, onTagClose);
      }),
    [tagToElement, tags, updateTags]
  );
  const inputOnChange = useMemo(
    () =>
      (event: React.SyntheticEvent<HTMLInputElement> & { key?: string }) => {
        const soFar = event.currentTarget.value;
        if (event.key === "Enter" || soFar.match(splitRegex)) {
          const newItem = soFar.match(matchRegex);
          if (!newItem) setText(soFar);
          else {
            updateTags([...tags, newItem[0]]);
            setText("");
          }
        } else {
          setText(soFar);
        }
      },
    [matchRegex, splitRegex, tags, updateTags]
  );
  return (
    <WrappedInput
      {...inputProps}
      prefix={<>{tagElements}</>}
      onChange={inputOnChange}
      onPressEnter={inputOnChange}
      value={text}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

import { Col } from "antd";
import styled from "styled-components";

export const StyledCloudContainer = styled.div`
  border: 2px solid #69c0ff;
  border-radius: 6px;
  overflow: auto;
  margin-bottom: 20px;
  padding: 10px;
  max-height: 600px;
`;

export const StyledListContent = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-right: 10px;
    color: rgb(0 171 142);
  }
  .ant-typography {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledIamAudit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCardTitle = styled.div`
  font-size: 14px;
`;

export const StyledCol = styled(Col)`
  .ant-card-head-title {
    white-space: normal;
    text-overflow: initial;
  }
  .ant-card-body {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const StyledLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  .anticon-arrow-right {
    margin-left: 5px;
    color: #1890ff;
  }
`;

import { Typography } from "antd";
import pluralize from "pluralize";
import { useMemo } from "react";
import { Filter } from "shared/types/workflow/types";

type Props = {
  filter: Filter;
  filterType: string;
};

export const ResourceFilterPreview: React.FC<Props> = ({
  filter,
  filterType,
}) => {
  // @TODO: Fetch/resolve # of hits for filter, eg. "3 roles matching tag [xyz]"
  const filterTypeDisplay = useMemo(
    () =>
      filterType ? (
        <Typography.Text underline>
          {pluralize(filterType as string)}
        </Typography.Text>
      ) : null,
    [filterType]
  );

  const filterKeyDisplay = useMemo(
    () =>
      filter && filter.effect !== "removeAll" && "key" in filter ? (
        <Typography.Text underline>{filter.key}</Typography.Text>
      ) : null,
    [filter]
  );

  const filterContentDisplay = useMemo(
    () =>
      filter.effect !== "removeAll" ? (
        <Typography.Text code>
          {"value" in filter ? String(filter.value) : filter.pattern}
        </Typography.Text>
      ) : null,
    [filter]
  );

  return filter?.effect === "removeAll" ? (
    <Typography.Text>Exclude all {filterTypeDisplay}</Typography.Text>
  ) : filter?.effect === "remove" ? (
    <Typography.Text>
      Exclude {filterTypeDisplay} with {filterKeyDisplay} {filterContentDisplay}
      .
    </Typography.Text>
  ) : (
    <Typography.Text>
      Include only {filterTypeDisplay} matching {filterKeyDisplay}{" "}
      {filterContentDisplay}.
    </Typography.Text>
  );
};

import Layout, { Content } from "antd/lib/layout/layout";
import { Outlet, Route } from "react-router-dom";

import { Page } from "../components/App/Page";
import { ReactComponent as Privacy } from "./privacy.md";
import { ReactComponent as Tos } from "./tos.md";

export const PolicyRoutes = (
  <Route
    element={
      <Layout>
        <Content
          className="site-layout-background"
          style={{
            background: "#fff",
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            overflow: "auto",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    }
  >
    <Route
      path="privacy"
      element={
        <Page title="Privacy Policy">
          <Privacy />
        </Page>
      }
    />
    <Route
      path="tos"
      element={
        <Page title="Terms of Service">
          <Tos />
        </Page>
      }
    />
  </Route>
);

import { ConfigOf } from "../../../install/types";
import { SshComponents } from "./components";

export const AWS_TAG_KEY_REGEX = /^[a-zA-Z0-9+-=.,_:@]{0,128}$/;

// https://cloud.google.com/resource-manager/docs/tags/tags-creating-and-managing#creating_tag
export const GCLOUD_TAG_KEY_REGEX = /^(([A-Za-z0-9-])+\/[^'"\\/]{1,256})?$/;

export type ProvisionUserAccessParameters = {
  UserName: string[];
  Action: string[];
  RequestId: string[];
  Sudo?: string[];
  PublicKey?: string[];
};

export type SshComponents = typeof SshComponents;

export type SshUse = keyof SshComponents;

export type SshIntegration = ConfigOf<typeof SshComponents>;

export const AWS_PROVIDER = "aws";
export const GCLOUD_PROVIDER = "gcloud";
export const AZURE_PROVIDER = "azure";

export const SshProviders = [
  AWS_PROVIDER,
  AZURE_PROVIDER,
  GCLOUD_PROVIDER,
] as const;
export type SshProviderKey = (typeof SshProviders)[number];

// TODO: ENG-2629 remove this and use SshProviders once azure is fully supported
export const OldSshProviders = [AWS_PROVIDER, GCLOUD_PROVIDER] as const;

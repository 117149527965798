import { PgGcpCloudSqlConfig } from "./types";

export enum PgServiceAccountExtension {
  CLOUD_SQL = ".gserviceaccount.com",
}

export const gcpCloudSqlToInstanceName = (config: PgGcpCloudSqlConfig) => {
  const { projectId, region, instanceId } = config;
  return `${projectId}:${region}:${instanceId}`;
};

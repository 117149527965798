import { kebabCase } from "lodash";

export const gcpNameToResource = (name: string) => {
  // E.g. `//compute.googleapis.com/projects/my-project/zones/us-west-1c/instances/my-instance`
  const nameParts = name.split("/");
  const [, , api, ...restOfUrl] = nameParts;
  const id = restOfUrl.at(-1);
  const type = restOfUrl.at(-2);
  const [service] = api.split(".");
  return { api, id, service, type };
};

export const gcpFriendlyAssetType = (assetType: string) =>
  kebabCase(assetType.split("/").at(-1) ?? "");

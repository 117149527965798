/** Utility to help cancel useEffect hooks
 *
 *  Usage:
 *
 *  const [state, setState] = useState(...);
 *  useEffect(
 *    () => {
 *      const cancellation = new Cancellation();
 *      // without cancellation.guard, React would error if
 *      // component was unmounted before promise completion
 *      somethingAsynchronous.then(cancellation.guard(setState));
 *      return cancellation.cancel;
 *    }
 *  )
 */
export class Cancellation {
  isCancelled = false;

  cancel = () => {
    this.isCancelled = true;
  };

  guard =
    <T>(fn: (t: T) => void) =>
    (t: T) => {
      if (!this.isCancelled) fn(t);
    };
}

import { CheckCircleFilled } from "@ant-design/icons";
import { Image, List, Typography } from "antd";
import * as React from "react";

import { AwsIconUrl } from "../../Integrations/Aws/Aws";
import { GcloudIconUrl } from "../../Integrations/Gcloud/Gcloud";
import { K8sIconUrl } from "../../Integrations/Kubernetes/K8s";
import { Logo } from "../../Integrations/Logo";
import { OktaIconUrl } from "../../Integrations/Okta/Okta";
import { PostgresIconUrl } from "../../Integrations/Postgres/Pg";
import { SnowflakeIconUrl } from "../../Integrations/Snowflake/Snowflake";
import LinearGradientComponent from "./LinearGradientComponent";
import {
  StyledCompanyContent,
  StyledIntegrationLogo,
  StyledListContent,
  StyledSectionCol,
} from "./styles";

const { Title, Paragraph } = Typography;

const data = [
  "Grant granular least-privileged access to: Google Cloud, AWS, Azure, K8s, Snowflake, PostgreSQL, Okta and more.",
  "Conduct an IAM assessment for your Google Cloud.",
  "Automate access escalations and gain visibility into privileged access.",
];

const CompanySection: React.FC = () => {
  const renderUseCase = React.useCallback(
    (item: string, index: number) => (
      <>
        <List.Item>
          <StyledListContent>
            <CheckCircleFilled />
            <Paragraph>{item}</Paragraph>
          </StyledListContent>
        </List.Item>
        {index === 0 && (
          <StyledIntegrationLogo>
            <Logo title={"Google Cloud"} logo={GcloudIconUrl} size={30} />
            <Logo title={"AWS"} logo={AwsIconUrl} size={30} />
            <Logo title={"Snowflake"} logo={SnowflakeIconUrl} size={30} />
            <Logo title={"Kubernetes"} logo={K8sIconUrl} size={30} />
            <Logo title={"PostgreSQL"} logo={PostgresIconUrl} size={30} />
            <Logo title={"Okta"} logo={OktaIconUrl} size={30} />
          </StyledIntegrationLogo>
        )}
      </>
    ),
    []
  );
  return (
    <StyledSectionCol xs={0} md={12}>
      <Image preview={false} src={"/main_site_logo.svg"} alt="P0" width={200} />
      <StyledCompanyContent>
        <div className="bg-gradient">
          <LinearGradientComponent />
        </div>
        <Title level={4}>
          Deploy in 5 minutes, no credit card required and immediately:
        </Title>
        <List dataSource={data} size="large" renderItem={renderUseCase} />
      </StyledCompanyContent>
    </StyledSectionCol>
  );
};

export default CompanySection;

import { Input } from "antd";
import styled from "styled-components";

/** Fixes disgusting prefix styling default for antd inputs */
export const SearchInput = styled(Input)`
  .ant-input-prefix {
    .anticon {
      line-height: 22px;
    }
    align-items: baseline;
    max-width: 100%;
  }
  .ant-input {
    margin-bottom: unset;
  }
`;

import { CSSProperties, ReactNode } from "react";

import { StyledPivotPrefix, StyledPrefixWrapper } from "./styles";

export const Prefix: React.FC<
  React.PropsWithChildren<{ prefix: ReactNode; style?: CSSProperties }>
> = ({ children, prefix, style }) => (
  <StyledPrefixWrapper style={style}>
    <StyledPivotPrefix>{prefix}</StyledPivotPrefix>
    {children}
  </StyledPrefixWrapper>
);

import { SECONDS } from "../time";
import { Unsubscribe } from "../types";

const DEFAULT_EVENT_BLOCK_MS = 1 * SECONDS;

export const sleep = (
  millis: number
): Promise<void> & { cancel: Unsubscribe } => {
  let timeout: NodeJS.Timeout;
  const promise = new Promise<void>((resolve) => {
    timeout = setTimeout(resolve, millis);
  });
  const cancel = () => clearTimeout(timeout);
  return Object.assign(promise, { cancel });
};

/** Runs a loop, but yields the event loop if too much time has elapsed */
export const eventYieldingFor = async <T>(
  iterable: Iterable<T>,
  inner: (value: T) => void,
  options?: {
    maxOccupancyMs?: number;
    onSleep?: (value: T, index: number) => void;
  }
) => {
  let last = Date.now();
  let ix = 0;
  for (const value of iterable) {
    if (
      Date.now() - last >
      (options?.maxOccupancyMs ?? DEFAULT_EVENT_BLOCK_MS)
    ) {
      options?.onSleep?.(value, ix);
      await sleep(0);
      last = Date.now();
    }
    inner(value);
    ix++;
  }
};

import { OktaComponents } from "shared/integrations/directories/okta/components";

import { Install, InstallProps } from "../Install/Install";
import { oktaInstaller } from "./install";

export const OktaIconUrl = "https://www.okta.com/favicon.ico";

export const Okta = (
  props: Pick<InstallProps<typeof OktaComponents>, "cardOverride" | "onInstall">
) => (
  <Install<typeof OktaComponents>
    components={OktaComponents}
    installer={oktaInstaller}
    integration="okta"
    itemKind="directory"
    logo={OktaIconUrl}
    title={"Okta"}
    {...props}
  />
);

export const toClientId = (args: {
  org: { slug: string };
  clusterId: string;
}) => {
  const { org, clusterId } = args;
  return `${org.slug}:${clusterId}`;
};

export const toParts = (clientId: string) => {
  const items = clientId.split(":");
  if (items.length !== 2) {
    throw new Error("Invalid client ID");
  }
  const [orgSlug, clusterId] = items;
  return { orgSlug, clusterId };
};

import {
  Auth,
  OAuthProvider,
  UserCredential,
  signInWithCredential,
} from "firebase/auth";
import pkceChallenge from "pkce-challenge";
import { useLocation } from "react-router";
import { OidcPkceProvider } from "shared/types/tenant/sso";
import { deferral } from "shared/util/deferral";
import { assertNever } from "utils/assert";
import { getEnvironment } from "utils/environment";

const authUrl = (tenant: OidcPkceProvider) =>
  tenant.providerType === "okta"
    ? `https://${tenant.providerDomain}/oauth2/v1`
    : tenant.providerType === "ping"
    ? `https://${tenant.providerDomain}/${tenant.environmentId}/as`
    : assertNever(tenant);

// Mounted at oidc/auth/_redirect
export const OidcRedirect: React.FC<object> = () => {
  const { search } = useLocation();
  const { error } = (window.opener as any).p0.relayPkce(
    new URLSearchParams(search)
  );
  if (error) return <div>{error}</div>;
  window.close();
  return <></>;
};

export const signInWithPkce = async (
  auth: Auth,
  provider: OAuthProvider,
  tenant: OidcPkceProvider,
  orgSlug: string
): Promise<UserCredential> => {
  const codeDeferral = deferral<string>();

  const state = crypto.randomUUID();
  const base = authUrl(tenant);
  const { code_challenge, code_verifier } = pkceChallenge();
  const redirect_uri = new URL(`oidc/auth/_redirect`, window.location.origin)
    .href;

  const data = {
    client_id: tenant.clientId,
    code_challenge,
    code_challenge_method: "S256",
    redirect_uri,
    response_type: "code",
    scope: "openid groups profile email",
    state,
  };
  const search = new URLSearchParams(data);

  (window as any).p0 = {
    relayPkce: (search: URLSearchParams) => {
      if (search.get("state") !== state) {
        codeDeferral.reject("Code exchange state mismatch");
        return { error: "Code exchange state mismatch" };
      }
      const code = search.get("code");
      if (code) {
        codeDeferral.resolve(code);
      } else {
        codeDeferral.reject("No code returned");
      }
      return { ok: true };
    },
  };
  window.open(
    `${base}/authorize?${search.toString()}`,
    "p0_oidc_signin",
    "popup=true"
  );

  const code = await codeDeferral.promise;
  const { apiUrl } = getEnvironment();

  // TODO: Should be useApiFetch
  const response = await fetch(`${apiUrl()}/o/${orgSlug}/oidc/token`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      client_id: data.client_id,
      code,
      code_verifier,
      grant_type: "authorization_code",
      redirect_uri: data.redirect_uri,
      scope: data.scope,
    }).toString(),
  });

  if (!response?.ok) {
    throw new Error(await response.text());
  }

  const token = await response.json();

  const credential = await signInWithCredential(
    auth,
    provider.credential({
      accessToken: token.access_token,
      idToken: token.id_token,
    })
  );
  return credential;
};

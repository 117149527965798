import { Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { FrontendInstallContext } from "install/types";
import { size } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { installedItem, installedItems } from "shared/install/installed";
import {
  GCLOUD_COMPONENT_SETUP,
  GCLOUD_SECURITY_PERIMETER_FEATURE_FLAG,
  setupPermissions,
} from "shared/integrations/resources/gcloud/constants";
import {
  GcloudIamComponentKey,
  GcloudInstallableResource,
  GcloudIntegration,
} from "shared/integrations/resources/gcloud/types";
import { rootConfig } from "shared/integrations/resources/gcloud/util";
import { assertNever } from "utils/assert";

import { securityPerimeterSaInstructions } from "./security-perimeter";
import { SharingTemporaryLiftInstructions } from "./sharing-restriction";

const { Paragraph } = Typography;

type GcloudInstall = {
  serviceAccountEmail: string;
} & GcloudInstallableResource;

/** Converts a GCP role name to a Terraform-compatible string
 *
 * E.g. roles/iam.securityAdmin becomes iam_securityAdmin
 */
const tfSanitize = (role: string) =>
  role.split("/").at(-1)?.replace(/[^\w]/g, "_");

export const installIamCommands = (
  context: FrontendInstallContext<GcloudIntegration>,
  data: (typeof GCLOUD_COMPONENT_SETUP)[
    | "access-logs"
    | "iam-assessment"
    | "iam-write-2"
    | "iam-write"
    | "org-wide-policy"],
  install: GcloudInstall
) => {
  const { customRole } = data;
  const predefinedRole =
    "predefinedRole" in data ? data.predefinedRole : undefined;
  const { location, bindCommand, rolePath } =
    install.type === "organization"
      ? {
          location: `--organization=${install.organizationId}`,
          bindCommand: `gcloud organizations add-iam-policy-binding ${install.organizationId}`,
          rolePath: `organizations/${install.organizationId}/roles/${customRole.id}`,
        }
      : install.type === "project"
      ? {
          location: `--project=${install.projectId}`,
          bindCommand: `gcloud projects add-iam-policy-binding ${install.projectId}`,
          rolePath: `projects/${install.projectId}/roles/${customRole.id}`,
        }
      : assertNever(install);
  return `# Creates the role that P0 uses to communicate with Google Cloud
if ! ( \\
  gcloud iam roles list ${location} --format='(name)' | \\
  grep ${rolePath}
); then
  gcloud iam roles create ${customRole.id} \\
    --stage=GA \\
    ${location} \\
    --title="${customRole.name}" \\
    --description="Role used by P0 to access your GCP project";
fi

# Assigns the permissions that P0 needs to access your GCP project
gcloud iam roles update ${customRole.id} ${location} \\
    --add-permissions=${setupPermissions(install.type, data).join(",")}

# This gives the P0 service account access to the role just created
${bindCommand} \\
  --member="serviceAccount:${install.serviceAccountEmail}" \\
  --condition=None \\
  --role="${rolePath}"
  
${
  predefinedRole
    ? `# The P0 service account also needs this predefined role
${`${bindCommand} \\
  --member="serviceAccount:${install.serviceAccountEmail}" \\
  --condition=None \\
  --role="${predefinedRole}"`}`
    : ""
}
    
${
  "securityPerimeter" in data && install.type === "project"
    ? securityPerimeterSaInstructions(
        context,
        data["securityPerimeter"],
        install.projectId
      )
    : ""
}    
`;
};

export const installIamTerraform = (
  data: (typeof GCLOUD_COMPONENT_SETUP)[
    | "access-logs"
    | "iam-assessment"
    | "iam-write-2"
    | "iam-write"
    | "org-wide-policy"],
  install: GcloudInstall
) => {
  const { customRole } = data;

  const { resourceId, resourceProperty, rolePath } =
    install.type === "organization"
      ? {
          resourceProperty: "org_id",
          resourceId: install.organizationId,
          rolePath: `organizations/${install.organizationId}/roles/${customRole.id}`,
        }
      : install.type === "project"
      ? {
          resourceProperty: "project",
          resourceId: install.projectId,
          rolePath: `projects/${install.projectId}/roles/${customRole.id}`,
        }
      : assertNever(install);

  const predefinedRole =
    "predefinedRole" in data ? data.predefinedRole : undefined;

  return `# Creates the role that P0 uses to communicate with Google Cloud
resource "google_${install.type}_iam_custom_role" "${
    customRole.id
  }-${resourceId}" {
  role_id      = "${customRole.id}"
  ${resourceProperty.padEnd(12)} = "${resourceId}"

  description  = "Role used by P0 to access your GCP project"
  stage        = "GA"
  title        = "${customRole.name}"

  permissions = [
    ${setupPermissions(install.type, data)
      .map((p) => `"${p}"`)
      .join(",\n    ")}
  ]
}

# This gives the P0 service account access to the role just created
resource "google_${install.type}_iam_member" "${
    customRole.id
  }-${resourceId}-membership" {
  role         = "${rolePath}"
  ${resourceProperty.padEnd(12)} = "${resourceId}"
  member       = "serviceAccount:${install.serviceAccountEmail}"
}

${
  predefinedRole
    ? `# The P0 service account also needs this predefined role
${`resource "google_${install.type}_iam_member" "${resourceId}-${tfSanitize(
  predefinedRole
)}-membership" {
  role         = "${predefinedRole}"
  ${install.type.padEnd(12)} = "${resourceId}"
  member       = "serviceAccount:${install.serviceAccountEmail}"
}`}
`
    : ""
}
`;
};

export const iamResource = (
  level: "organization" | "project",
  context: FrontendInstallContext<GcloudIntegration>,
  id: string
): GcloudInstallableResource =>
  level === "organization"
    ? {
        type: "organization",
        organizationId: rootConfig(context.config).organizationId,
      }
    : level === "project"
    ? { type: "project", projectId: id }
    : assertNever(level);

export const iamInstructor =
  (level: "organization" | "project", component: GcloudIamComponentKey) =>
  (context: FrontendInstallContext<GcloudIntegration>, id: string) => {
    const resource = iamResource(level, context, id);
    return {
      help: (
        <>
          <Paragraph>
            To install P0 on this account, provision P0&apos;s access using{" "}
            <Link
              href={`https://console.cloud.google.com/cloudshelleditor?project=${id}&cloudshell=true`}
              target="_blank"
              rel="noopener"
            >
              Cloud Shell
            </Link>{" "}
            or Terraform:
          </Paragraph>
          {installedItem("sharing-restriction", context.config, id) ? (
            <SharingTemporaryLiftInstructions context={context} id={id} />
          ) : null}
        </>
      ),
      commands: {
        shell: [
          {
            command: installIamCommands(
              context,
              GCLOUD_COMPONENT_SETUP[component],
              {
                ...resource,
                ...rootConfig(context.config),
              }
            ),
          },
        ],
        iac: [
          {
            command: installIamTerraform(GCLOUD_COMPONENT_SETUP[component], {
              ...resource,
              ...rootConfig(context.config),
            }),
          },
        ],
      },
    };
  };
export const iamPrerequisiteMessage = async (
  context: FrontendInstallContext<GcloudIntegration>
) => {
  if (
    context.featureFlags?.[GCLOUD_SECURITY_PERIMETER_FEATURE_FLAG] &&
    !size(installedItems("iam-write-security-perimeter", context.config))
  )
    return (
      <div>
        Requires installed &quot;
        <RouterLink to={"./iam-write-security-perimeter"}>
          IAM management security perimeter
        </RouterLink>
        &quot; component
      </div>
    );
  return undefined;
};

import { orderBy } from "@firebase/firestore";
import { Button, Spin } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { Timestamp } from "firebase/firestore";
import React, { useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router";

import { useFirestoreCollection } from "../../providers/FirestoreProvider";
import { Heading } from "../Heading";
import { Tenant } from "../Login";
import { RunProgressStatus } from "./components/RunProgressStatus";
import { FirestoreRunDoc } from "./types";

const columns: ColumnsType<FirestoreRunDoc> = [
  {
    title: "Run Id",
    dataIndex: ["data", "runId"],
    render: (runId: string) => <div>{runId}</div>,
  },
  {
    title: "Stage",
    render: ({ data }) => <RunProgressStatus doc={data} />,
  },

  {
    title: "Requested At",
    render: ({ data }) => {
      return (
        (data.createdTimestamp as Timestamp)?.toDate().toLocaleString() ?? "NA"
      );
    },
  },
  {
    title: "Actions",
    render: (_) => (
      // Memoization performed by antd Table
      // eslint-disable-next-line react/jsx-no-bind
      <Button type="link" size="small" onClick={() => _.showRequestInfo(_.id)}>
        Details
      </Button>
    ),
  },
];

export const RunHistory: React.FC<object> = () => {
  const tenantId = useContext(Tenant);

  const navigate = useNavigate();
  const { orgSlug } = useParams();
  const firestorePath = `o/${tenantId}/rest-state/integrations/snowflake/config`;

  const runs = useFirestoreCollection(`${firestorePath}/diffs`, {
    live: true,
    queryConstraints: [orderBy("createdTimestamp", "desc")],
  }) as FirestoreRunDoc[];

  const showRequestInfo = useCallback(
    (id: string) => {
      navigate(`/o/${orgSlug}/rest-state/run/${id}`);
    },
    [navigate, orgSlug]
  );
  return (
    <>
      <Heading title="History" />
      {runs ? (
        <Table
          size="small"
          columns={columns}
          dataSource={(runs ?? [])
            .sort(
              (t1, t2) =>
                t2.data.createdTimestamp.toMillis() -
                t1.data.createdTimestamp.toMillis()
            )
            .map((doc) => ({ ...doc, showRequestInfo }))}
          rowKey="id"
          scroll={{ y: `calc(100vh - 285px)`, x: true }}
        />
      ) : (
        <Spin />
      )}
    </>
  );
};

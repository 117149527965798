import { message } from "antd";
import { useCallback } from "react";

import { Cancellation } from "../utils/cancel";
import { getEnvironment } from "../utils/environment";

export const updateHeadersInit = (
  headers: HeadersInit,
  updates: Record<string, string>
) => {
  if (Array.isArray(headers)) {
    for (const entry of Object.entries(updates)) {
      headers.push(entry);
    }
  } else if ("set" in headers) {
    for (const [key, value] of Object.entries(updates)) {
      (headers as Headers).set(key, value);
    }
  } else {
    for (const [key, value] of Object.entries(updates)) {
      headers[key] = value;
    }
  }
};

/** Returns an unauthorized fetch context
 *
 * Use this fetch context just like a normal browser fetch context, except the
 * path is relative to the api root.
 *
 * Example:
 * ```
 * const [error, setError] = useState<string>();
 * const apiFetch = useApiFetch(setError);
 * ...
 * const response = await apiFetch("relative/path", {method: "POST", data});
 * if (response) {
 *    ...
 * }
 * ```
 *
 * @returns Fetch callback. Works like `fetch` except:
 *   - Can directly pass `json` values in RequestInit; this will automatically send the proper
 *     JSON request body and headers
 *   - Can pass `onNotOk` in RequestInit; this will register a handler to be applied when a non-ok
 *     status is returned (otherwise `onError` will be used)
 *   - Can pass a Cancellation object (to Automatically clean up fetches inside useEffect)
 *
 * @param onError Called when an error occurs. Note that 4XX / 5xx statuses are not errors. Will also
 *                be called on these statuses if onNotOk is not defined.
 */
export const useApiFetch = (
  onError?: (error: string | undefined) => void,
  onFetch?: (isFetching: boolean) => void
) => {
  const authFetch = useCallback(
    async (
      path: string,
      init: RequestInit & {
        json?: any;
        onNotOk?: (response: Response) => void;
        cancellation?: Cancellation;
      }
    ) => {
      if (init?.cancellation?.isCancelled) return;
      const newHeaders: Record<string, string> = {};

      if ("json" in init) {
        newHeaders["Content-Type"] = "application/json; charset=utf-8";
        init.body = JSON.stringify(init.json);
      }

      // Headers can be either a Headers object, an object, or an array of string pairs
      const headers = init.headers ?? {};
      updateHeadersInit(headers, newHeaders);

      const apiInit = {
        ...init,
        headers,
      };
      const { apiUrl } = getEnvironment();
      const fetchUrl = `${apiUrl()}/${path}`;
      try {
        onFetch?.(true);
        const response = await fetch(fetchUrl, apiInit);
        if (init?.cancellation?.isCancelled) return;
        if (!response.ok) {
          if (init.onNotOk) {
            init.onNotOk(response);
            return;
          }
          const errorText = response.headers
            .get("Content-Type")
            ?.startsWith("application/json")
            ? (await response.json()).error
            : await response.text();
          if (init.cancellation?.isCancelled) return;
          onError ? onError(errorText) : message.error(errorText);
        } else {
          // Reset error in error handler
          onError?.(undefined);
          return response;
        }
      } catch (error: any) {
        const errMessage = error.message ?? String(error);
        onError ? onError(errMessage) : message.error(errMessage);
      } finally {
        onFetch?.(false);
      }
    },
    [onError, onFetch]
  );

  return authFetch;
};

import { List, ListProps } from "antd";
import { PropsWithChildren } from "react";

export const OverflowList = <T,>(props: PropsWithChildren<ListProps<T>>) => (
  <List<T>
    style={{
      maxHeight: "280px",
      paddingRight: "15px",
      overflowY: "auto",
    }}
    {...props}
  />
);

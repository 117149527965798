import { Button } from "antd";
import * as React from "react";

type Props = {
  subject?: string;
};
const ContactSupport: React.FC<Props> = ({ subject = "Upgrade Plan" }) => {
  const email = "support@p0.dev";
  const body = "Dear support team, ...";

  const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  return (
    <Button type="link" size="small" className="button-link" href={mailtoUrl}>
      contact our support team
    </Button>
  );
};

export default ContactSupport;

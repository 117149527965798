import { Input } from "antd";
import { useCallback } from "react";

import { StyledFormItem, standardFormItemCols } from "./styles";

type Props = {
  value: string;
  onChange: (value: string) => void;
  label: string;
  required?: string;
  extra?: React.ReactNode;
};

// @TODO: Support additional validation rules
export const FormInput: React.FC<Props> = ({
  value,
  onChange,
  label,
  required,
  extra,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <StyledFormItem
      label={label}
      rules={[
        {
          required: Boolean(required),
          message: required,
        },
      ]}
      {...standardFormItemCols}
    >
      <Input value={value} onChange={handleChange} />
      {extra}
    </StyledFormItem>
  );
};

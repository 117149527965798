import { Alert, Typography } from "antd";
import { CommandDisplay } from "components/Integrations/CommandDisplay";
import { FrontendInstallContext } from "install/types";
import { ReactNode } from "react";
import { SINGLETON_ITEM } from "shared/install/constants";
import { Instructions } from "shared/install/types";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import { rootConfig } from "shared/integrations/resources/gcloud/util";

const { Paragraph, Link } = Typography;

const SharingRequiredPrivilegesAlert = (props: {
  context: FrontendInstallContext<GcloudIntegration>;
}) => (
  <Alert
    showIcon
    message="Organization-level permissions required"
    description={
      <>
        <Paragraph>
          The &quot;Organization Policy Admin&quot; role, at the organization
          level, is required in order to continue. Explictly grant yourself this
          role prior to enabling, even if you are already &quot;Owner&quot; on
          the organization.
        </Paragraph>
        <Paragraph>
          To grant yourself the &quot;Organization Policy Admin&quot; role, run:
        </Paragraph>
        <CommandDisplay
          commands={`gcloud organizations add-iam-policy-binding ${
            rootConfig(props.context.config).organizationId
          } \\
--member='user:${props.context.user?.email ?? ""}' \\
--condition=None \\
--role='roles/orgpolicy.policyAdmin'`}
        />
      </>
    }
  />
);

export const SharingTemporaryLiftInstructions = (props: {
  context: FrontendInstallContext<GcloudIntegration>;
  id: string;
}) => (
  <>
    <Paragraph>
      You have a domain restriction policy in place, so in order to proceed with
      the installation process you&apos;ll need to temporarily add P0 to this
      policy. We&apos;ll have you remove P0 from the policy after installation
      is complete.
    </Paragraph>
    <SharingRequiredPrivilegesAlert context={props.context} />
    <Paragraph style={{ marginTop: "1em" }}>
      To allow P0, run:
      <CommandDisplay
        commands={`gcloud resource-manager org-policies allow \\
  iam.allowedPolicyMemberDomains C0277bxit \\
  --project ${props.id}`}
      />
    </Paragraph>
    <Paragraph>
      After installing this component, restore the domain-restricted sharing
      policy using:
      <CommandDisplay
        commands={`# These commands remove P0 from your domain-restricted sharing allow-list.
# Alternatively, you can manually edit your policy settings at
# https://console.cloud.google.com/iam-admin/orgpolicies/list.
# "C0277bxit" is the Workspace ID of p0.dev.

curl -s -X GET "https://orgpolicy.googleapis.com/v2/projects/${props.id}/policies/iam.allowedPolicyMemberDomains" \\
  -H "Authorization: Bearer $(gcloud auth print-access-token)" | \\
python3 -c '
import json, sys

policy_spec = json.load(sys.stdin)
values = policy_spec["spec"]["rules"][0]["values"]
values["allowedValues"] = [x for x in values["allowedValues"] if x != "C0277bxit"]
print(policy_spec)
' | \\
curl -s -X PATCH \\
  "https://orgpolicy.googleapis.com/v2/projects/${props.id}/policies/iam.allowedPolicyMemberDomains" \\
  -H "Authorization: Bearer $(gcloud auth print-access-token)" \\
  -H "Content-Type: application/json" \\
  --data-binary @-`}
      />
    </Paragraph>
  </>
);

export const sharingRestrictionInstructions = (
  context: FrontendInstallContext<GcloudIntegration>,
  id: string
): Instructions<ReactNode> => ({
  help: (
    <div>
      <Paragraph>
        In order to fully secure your P0-Google Cloud integration, add a
        domain-restricted-sharing policy to each project for which P0 has
        IAM-management access.
      </Paragraph>
      <Paragraph>
        This ensures that any future IAM changes only grant roles to users in
        your domain, ensuring that your Google projects are safe, even if P0 is
        compromised. Existing IAM grants will not be impacted by this change.
      </Paragraph>
      <Paragraph>
        <Typography.Text type="secondary">
          See{" "}
          <Link
            href="https://cloud.google.com/resource-manager/docs/organization-policy/restricting-domains"
            target="_blank"
            rel="noreferrer"
          >
            Google&apos;s documentation
          </Link>{" "}
          to learn more.
        </Typography.Text>
      </Paragraph>
      <Paragraph>
        To verify your domain-restriction policy, P0 will attempt to grant
        itself a custom role with redundant read-only permissions, and validate
        that this attempt fails.
      </Paragraph>
      <SharingRequiredPrivilegesAlert context={context} />
    </div>
  ),
  commands: {
    shell: [
      {
        command: `gcloud organizations list \\
  --filter='name=organizations/${context.config.root[SINGLETON_ITEM].organizationId}' \\
  --format='value(owner.directoryCustomerId)' | \\
xargs gcloud resource-manager org-policies allow \\
  --project ${id} iam.allowedPolicyMemberDomains;`,
      },
    ],
  },
});

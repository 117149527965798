import { useControls } from "components/Assessment/hooks/useControls";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Node } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

export const RollupGrantDrilldownLink: React.FC<{
  node: Node<AssessmentNodes, "grant">;
}> = ({ node }) => {
  const { controls } = useControls();
  const link = useMemo(
    () =>
      `../../../explore?display=table&where=${encodeURIComponent(
        `grant=principal:${node.data.principal}`
      )}&show=${encodeURIComponent(controls.show)}&scope=${encodeURIComponent(
        `${node.data.integration}:${node.data.resources[0]}`
      )}`,
    [controls, node]
  );
  return (
    <Link to={link} relative="path">
      view
    </Link>
  );
};

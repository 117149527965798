import { Spin, Typography } from "antd";
import { ErrorDisplay } from "components/Error";
import { Heading } from "components/Heading";
import { useAuthFetch } from "components/Login/hook";
import { useContext, useEffect, useMemo, useState } from "react";

import { GraphProcessingStep } from "../components/GraphStep";
import { ResultDetail } from "../components/ResultDetail";
import { FindingStateEditor } from "../components/finding/FindingStateEditor";
import { FindingsContext } from "../contexts/FindingsContext";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";

export const FindingDetail: React.FC<object> = ({}) => {
  const [error, setError] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);

  const authFetch = useAuthFetch(setError, setIsFetching);
  const { allMonitors } = useContext(FindingsContext);
  const { finding, last } = useContext(SelectedAssessmentContext);
  const { graph, step, scopeKey, setScopeKey } = useContext(ScopeContext);

  useEffect(() => {
    if (finding.doc && scopeKey && scopeKey !== finding.doc?.data.scopeKey) {
      setScopeKey(finding.doc.data.scopeKey, "replaceIn");
    }
  }, [finding.doc, scopeKey, setScopeKey]);

  const monitor = useMemo(
    () =>
      finding.doc?.data.monitorId
        ? allMonitors[finding.doc.data.monitorId]
        : undefined,
    [finding.doc, allMonitors]
  );

  const terms = useMemo(
    () => monitor?.search.map((s) => s.term) ?? [],
    [monitor]
  );

  if (finding.loading || last.loading) return <Spin />;
  if (step !== "done") return <GraphProcessingStep step={step} />;
  if (!finding.doc || !graph || !monitor)
    return (
      <div>
        <Heading title="Not Found" />
      </div>
    );
  return (
    <div style={{ maxWidth: "800px" }} data-testid="finding-detail">
      <Heading
        title={
          <>
            Finding {finding.doc.data.humanId} &ndash; {monitor?.label}
          </>
        }
      />
      {error && <ErrorDisplay title="Could not update finding" error={error} />}
      {isFetching ? (
        <Spin />
      ) : (
        <FindingStateEditor finding={finding.doc} authFetch={authFetch} />
      )}
      <Typography.Title level={4}>Description</Typography.Title>

      <p>{monitor?.cta}</p>
      <ResultDetail
        terms={terms}
        node={finding.doc.data.node}
        show={monitor.show}
      />
    </div>
  );
};

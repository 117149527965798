import pkceChallenge from "pkce-challenge";
import { useCallback, useContext, useMemo } from "react";

import { useFirestoreDoc } from "../../providers/FirestoreProvider";
import { Tenant } from "../Login/util";
import { OAuth2Props, RootIntegrationDoc } from "./OAuth2";

export const STATE_NO_AUTH = "no_auth";
export const STATE_AUTH_CODE = "auth_code";
export const STATE_INSTALLED = "installed";
export const STATE_TOKEN_FETCH = "token_fetch";

export type OAuth2State =
  | typeof STATE_AUTH_CODE
  | typeof STATE_INSTALLED
  | typeof STATE_NO_AUTH
  | typeof STATE_TOKEN_FETCH;

export type AuthContext = {
  clientId: string;
  verifier: string;
  redirectLocation: string;
  integration: string;
  oAuthRedirect: string;
};

interface TenantIntegrationDoc {
  /** The epoch time at which the most recent integration data update was requested */
  updateRequested?: number;
  /** The integration installation state */
  state?: typeof STATE_INSTALLED;
}

export const sessionKeyForNonce = (nonce: string) => `oauth-nonce-${nonce}`;

export const useOAuth2Context = () => {
  const params = new URLSearchParams(window.location.search);
  const nonce = params.get("state");

  const authContext = useMemo(() => {
    if (nonce === null) return undefined;
    const item = sessionStorage.getItem(sessionKeyForNonce(nonce));
    if (item === null) return undefined;
    return JSON.parse(item) as AuthContext;
  }, [nonce]);

  return { nonce, authContext, params };
};

export const useIntegrationContext = (integration: string) => {
  const oauth2Context = useOAuth2Context();

  const tenantId = useContext(Tenant);
  const integrationPath = `o/${tenantId}/integrations/${integration}`;
  const { doc, loading } = useFirestoreDoc<TenantIntegrationDoc>(
    integrationPath,
    { live: true }
  );

  const authState = (doc?.data.state ||
    oauth2Context.params.get("auth_state") ||
    STATE_NO_AUTH) as OAuth2State;

  return {
    ...oauth2Context,
    authState,
    integrationData: doc?.data,
    tenantId,
    loading,
  };
};

export const useOAuth2Callback = ({
  authExtra,
  integration,
  mode,
}: Pick<OAuth2Props, "authExtra" | "integration" | "mode">) => {
  const tenantId = useContext(Tenant);
  const { doc, loading } = useFirestoreDoc<RootIntegrationDoc>(
    `/integrations/${integration}`
  );
  const tenantDoc = useFirestoreDoc<RootIntegrationDoc>(
    `o/${tenantId}/integrations/${integration}-install`
  );
  const authUrl = useMemo(
    () => tenantDoc?.doc?.data?.authUrl ?? doc?.data?.authUrl,
    [tenantDoc, doc]
  );
  const clientId = useMemo(
    () => tenantDoc?.doc?.data?.clientId ?? doc?.data?.clientId,
    [tenantDoc, doc]
  );

  const onAuthSubmit = useCallback(() => {
    if (clientId === undefined) {
      return console.warn("No client ID");
    }
    const { code_verifier, code_challenge } = pkceChallenge();
    const oAuthRedirect = new URL(
      `integration/auth/_redirect`,
      window.location.origin
    ).href;
    const extraTerm = authExtra ?? "";
    sessionStorage.setItem(
      sessionKeyForNonce(code_challenge),
      JSON.stringify({
        clientId,
        verifier: code_verifier,
        redirectLocation: window.location.pathname,
        oAuthRedirect,
        integration,
      } as AuthContext)
    );
    let href = `${authUrl}?client_id=${encodeURIComponent(
      clientId
    )}${extraTerm}&state=${encodeURIComponent(
      code_challenge
    )}&redirect_uri=${encodeURIComponent(oAuthRedirect)}`;
    if (mode === "pkce") {
      href =
        href +
        `&response_type=code&code_challenge=${encodeURIComponent(
          code_challenge
        )}&code_challenge_method=S256`;
    }
    // debugger;
    window.location.href = href;
  }, [authExtra, clientId, integration, authUrl, mode]);

  const authEnabled = !!clientId;
  const authLoading = loading || tenantDoc.loading;

  return { authEnabled, authLoading, onAuthSubmit };
};

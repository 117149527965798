import { create } from "zustand";

type CloudProvider = "aws" | "gcp" | "snowflake";

type OnboardingState = {
  cloudProvider?: CloudProvider;
  cloudProviderInstallState: string;
  slackInstallState: string;

  setCloudProvider: (value: CloudProvider) => void;
  setCloudProviderInstallState: (value: string) => void;
  setSlackInstallState: (value: string) => void;
  isSlackInstalled: boolean;
};

export const useOnboardingStore = create<OnboardingState>((set, get) => ({
  cloudProvider: undefined,
  cloudProviderInstallState: "",
  slackInstallState: "",

  setCloudProvider: (value) => set({ cloudProvider: value }),
  setCloudProviderInstallState: (value) =>
    set({ cloudProviderInstallState: value }),
  setSlackInstallState: (value) => set({ slackInstallState: value }),

  get isSlackInstalled() {
    return get().slackInstallState === "installed";
  },
}));

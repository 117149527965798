import { ReactElement, ReactNode } from "react";

/** Like String.join, except for lists of React elements
 *
 * E.g.
 * ```
 * join(elements, ", ")
 * ```
 * will insert spans with ", " in between each element.
 *
 * N.B.: when the inserted objects are React elements (rather than strings),
 * they must have unique keys. Use the passed index to create keys:
 * ```
 * join(
 *   elements,
 *   (ix) => <InBetweenElement key={ix}/>
 * )
 * ```
 *
 * @param elements The elements to join
 * @param conjunction Either a string, or a generator from array index to another element
 */
export const join = (
  elements: ReactNode[],
  conjunction: string | ((ix: number) => ReactElement)
) =>
  elements.reduce(
    (memo: ReactNode[], element, ix) =>
      memo.length > 0
        ? [
            ...memo,
            typeof conjunction === "string" ? (
              <span key={`delim-${ix}`}>{conjunction}</span>
            ) : (
              conjunction(ix)
            ),
            element,
          ]
        : [element],
    []
  );

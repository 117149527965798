/** Map API names to fragments used by navigation/icons.svg */
const serviceNavFragments: Record<string, string> = {
  apikeys: "keymanagementservice",
  artifactregistry: "containerregistry",
  bigquerymigration: "bigquery",
  billing: "cloudbilling",
  cloudasset: "cloudassetinventory",
  cloudidentity: "cloudiam", // Google re-uses the IAM logo for Cloud Identity
  cloudkms: "keymanagementservice",
  cloudnotifications: "monitoring",
  cloudresourcemanager: "generic",
  cloudtrace: "trace",
  cloudtranslate: "cloudtranslationapi",
  compute: "computeengine",
  composer: "cloudcomposer",
  container: "kubernetesengine",
  dataflow: "clouddataflow",
  datafusion: "clouddatafusion",
  dataproc: "clouddataproc",
  datastore: "clouddatastore",
  dlp: "clouddatalosspreventionapi",
  dns: "clouddns",
  file: "cloudfilestore",
  gkebackup: "kubernetesengine",
  gkemulticloud: "anthos",
  language: "cloudnaturallanguageapi",
  lifesciences: "genomics",
  iam: "cloudiam",
  iap: "generic", // No available icon
  integrations: "apigeeapiplatform",
  memcache: "memorystore",
  metastore: "clouddataproc",
  networkservices: "kubernetesengine",
  orgpolicy: "cloudiam",
  pubsub: "cloudpubsub",
  recommender: "alloydb",
  redis: "memorystore",
  run: "cloudrun",
  securitycenter: "cloudsecuritycommandcenter",
  sourcerepo: "generic", // N.B. Google uses the "Logging" icon for the Cloud Source Repositories product in their overview
  spanner: "cloudspanner",
  speech: "cloudtexttospeech",
  sqladmin: "cloudsql",
  storage: "cloudstorage",
  vision: "cloudvisionapi",
};

/** Icons at navigation/{path}, not navigation/icons.svg#{fragment} */
const overridePaths: Record<string, string> = {
  aiplatform: "vertex-ai.svg",
  analyticshub: "analytics-hub.svg",
  apigateway: "api-gateway.svg",
  clouddeploy: "cloud-deploy.svg",
  cloudtestservice: "firebase.svg",
  discoveryengine: "vertex-ai.svg",
  firebase: "firebase.svg",
  firebaseabt: "firebase.svg",
  firebaseappdistro: "firebase.svg",
  firebasecrashlytics: "firebase.svg",
  firebasedatabase: "firebase.svg",
  firebasedynamiclinks: "firebase.svg",
  firebaseextensions: "firebase.svg",
  firebasehosting: "firebase.svg",
  firebasenotifications: "firebase.svg",
  firebaserules: "firebase.svg",
  notebooks: "vertex-ai.svg",
};

/** Icons not at navigation/{path} */
const overrideUrls: Record<string, string> = {
  alloydb:
    "https://lh3.googleusercontent.com/A7NDzHSjhN7otiP1pd3-d37aQgC0c07khXBeOO5ILx_PO_I8690EcpLH5Uun0QwNVy7khMrqil8=s600-w600",
  assuredoss:
    "https://developers.google.com/static/assured-oss/images/logo_96.png",
  documentai:
    "https://www.gstatic.com/bricks/image/fff136126db59a25544b2f7bc4d86325ef94b7113f71066139c968ebfe155877.svg",
  sourcerepo:
    "https://cdn.worldvectorlogo.com/logos/google-cloud-source-repositories.svg",
};

/** Human-friendly Google product names */
export const names: Record<string, string> = {
  actions: "Conversational Actions",
  aiplatform: "AI Platform",
  alloydb: "AlloyDB",
  analyticshub: "BigQuery Analytics Hub",
  apigateway: "API Gateway",
  apikeys: "API Keys",
  appengine: "App Engine",
  artifactregistry: "Artifact Registry",
  assuredoss: "Assured OSS",
  assuredworkloads: "Assured Workloads",
  automl: "Cloud AutoML",
  bigquery: "BigQuery",
  bigquerymigration: "BigQuery Migrations",
  bigtable: "Bigtable",
  billing: "Cloud Billing",
  chronicle: "Google Security Operations",
  clientauthconfig: "OAuth",
  cloudasset: "Cloud Asset Inventory",
  cloudbilling: "Cloud Billing",
  cloudbuild: "Cloud Build",
  clouddeploy: "Cloud Deploy",
  cloudfunctions: "Cloud Functions",
  cloudidentity: "Cloud Identity",
  cloudkms: "Cloud KMS",
  cloudnotifications: "Cloud Monitoring",
  cloudresourcemanager: "Google Cloud",
  cloudscheduler: "Cloud Scheduler",
  cloudsecurityscanner: "Cloud Security Scanner",
  cloudshell: "Cloud Shell",
  cloudsql: "Cloud SQL",
  cloudsupport: "Support",
  cloudtasks: "Cloud Tasks",
  cloudtestservice: "Firebase Test Lab",
  cloudtrace: "Cloud Trace",
  cloudtranslate: "Cloud Translation API",
  composer: "Cloud Composer",
  compute: "Compute Engine",
  container: "Kubernetes Engine",
  containeranalysis: "Container Analysis",
  containerregistry: "Container Registry",
  dataflow: "Dataflow",
  datafusion: "Data Fusion",
  dataplex: "Dataplex",
  dataproc: "Dataproc",
  datastore: "Cloud Datastore",
  datastream: "Datastream",
  datastudio: "Data Studio",
  deploymentmanager: "Deployment Manager",
  dialogflow: "Dialogflow",
  discoveryengine: "Discovery Engine",
  dlp: "Cloud Data Loss Prevention",
  dns: "Cloud DNS",
  domains: "Cloud Domains",
  errorreporting: "Error Reporting",
  essentialcontacts: "Essential Contacts",
  eventarc: "Event Arc",
  file: "Cloud Filestore",
  firebase: "Firebase",
  firebaseabt: "Firebase A/B Testing",
  firebaseappdistro: "Firebase App Distribution",
  firebasecrashlytics: "Firebase Crashlytics",
  firebasedatabase: "Firebase Realtime Database",
  firebasedynamiclinks: "Firebase Dynamic Links",
  firebaseextensions: "Firebase Extensions",
  firebasehosting: "Firebase Hosting",
  firebasenotifications: "Firebase Notifications",
  firebaserules: "Firebase Security Rules",
  firestore: "Firestore",
  gkebackup: "Backup for GKE",
  gkemulticloud: "Anthos",
  iam: "Identity & Access Management",
  ids: "Cloud IDS",
  iap: "Identity-Aware Proxy",
  identityplatform: "Identity Platform",
  identitytoolkit: "Identity Toolkit",
  integrations: "Apigee Integrations API",
  lifesciences: "Cloud Life Sciences API",
  language: "Cloud Natural Language API",
  logging: "Cloud Logging",
  memcache: "Memorystore (Memcache)",
  metastore: "Dataproc Metastore",
  monitoring: "Cloud Monitoring",
  networkmanagement: "Network Management",
  networkservices: "Traffic Director",
  notebooks: "Cloud Notebooks",
  orgpolicy: "Organization Policies",
  pubsub: "Pub/Sub",
  recommender: "AlloyDb / BigQuery Recommender",
  redis: "Memorystore (Redis)",
  resourcemanager: "Resource Manager",
  run: "Cloud Run",
  secretmanager: "Secret Manager",
  securitycenter: "Security Command Center",
  servicedirectory: "Service Directory",
  serviceusage: "Service Usage",
  sourcerepo: "Cloud Source Repositories",
  spanner: "Cloud Spanner",
  speech: "Cloud Speech-to-Text API",
  sqladmin: "Cloud SQL",
  storage: "Cloud Storage",
  vision: "Cloud Vision API",
  workstations: "Cloud Workstations",
};

/** Returns the fragment for a service when the icon is hosted
 *  at navigation/icons.svg#{fragment}
 */
const serviceIconFragment = (service: string) =>
  serviceNavFragments[service] ?? service;

/** Returns the icon source url for a resource
 *
 * `locator` is of the form `//{service}.googleapis.com/...`.
 */
export const navIconUrl = (service: string) => {
  if (service in overrideUrls) return overrideUrls[service];
  const navPath =
    overridePaths[service] ?? `icons.svg#${serviceIconFragment(service)}`;
  return `https://www.gstatic.com/cloud/images/navigation/${navPath}`;
};

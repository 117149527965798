import { mapWith } from "../util/collections";
import { InstallContext, InstallSpec, ItemConfigOf } from "./types";

/** Returns all visible components */
export const visibleComponentEntries = (
  spec: InstallSpec,
  flags?: Record<string, boolean>
) =>
  Object.entries(spec).filter(
    ([key, { hidden, type, featureFlag }]) =>
      !hidden &&
      type !== "generated" &&
      key !== "root" &&
      (!featureFlag || flags?.[featureFlag])
  );

/** Returns all visible component keys */
export const visibleComponentKeys = (
  spec: InstallSpec,
  flags?: Record<string, boolean>
) => visibleComponentEntries(spec, flags).map(([key]) => key);

/** Returns all installed items of a component, for an install context */
export const otherInstalled =
  <R extends Record<string, Record<string, ItemConfigOf<any>>>>(
    component: keyof R
  ) =>
  (context: InstallContext<R>) =>
    context.config
      ? mapWith(
          Object.entries(context.config[component]),
          function* ([id, { state, label }]) {
            if (state === "installed") yield { id, label };
          }
        )
      : [];

/** Returns the item that corresponds to the current installation regardless of state */
export const currentInstall =
  <R extends Record<string, Record<string, ItemConfigOf<any>>>>(
    component: keyof R
  ) =>
  (context: InstallContext<R>, installId: string) =>
    context.config
      ? mapWith(
          Object.entries(context.config[component]),
          function* ([id, { label }]) {
            if (id === installId) yield { id, label };
          }
        )
      : [];

import { GraphTooltip } from "components/GraphTable/GraphTooltip";

export const CredentialTitle: React.FC<object> = () => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <div>Authentication Methods</div>
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        gap: "1em",
      }}
    >
      <div
        style={{
          fontSize: "small",
          fontWeight: "normal",
          display: "flex",
          whiteSpace: "nowrap",
        }}
      >
        <GraphTooltip title="An unused credential has not been used for 40 days">
          used / unused
        </GraphTooltip>{" "}
      </div>
      <div
        style={{
          fontSize: "small",
          fontWeight: "normal",
          display: "flex",
          whiteSpace: "nowrap",
        }}
      >
        <GraphTooltip title="A stale credential has not been rotated for 90 days">
          stale / fresh
        </GraphTooltip>
      </div>
    </div>
  </div>
);

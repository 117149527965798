import { Bitset } from "./bitset";

/** Graph node
 *
 * Tuple of type & key is unique
 */
export type Node<G extends object, L extends keyof G> = {
  type: L;
  key: string;
  data: G[L];
};

export type NodeOf<G extends object> = Node<G, keyof G>;

export type Path<G extends object> = ConnectedNode<G, keyof G>[];

/** Type-narrows a node */
export const isNode =
  <L extends number | string | symbol>(type: L) =>
  <G extends { [LL in L]: any }>(node: Node<G, keyof G>): node is Node<G, L> =>
    node.type === type;

export const Directions = ["children", "parents"] as const;

export type Direction = (typeof Directions)[number];

export type Boost = {
  [K in Direction]: {
    types?: Set<symbol>;
    digrams?: Bitset;
  };
};

export const EmptyBoost = () => ({
  children: {},
  parents: {},
});

/** A graph node that references its edges
 *
 * Intended for internal use only.
 */
export type ConnectedNode<G extends object, L extends keyof G> = Node<G, L> & {
  children: ConnectedNode<G, keyof G>[];
  parents: ConnectedNode<G, keyof G>[];
  boost?: Boost;
};

/** A typed, directed graph
 *
 * The graph may have cycles.
 *
 * Nodes may only have types drawn from the keys of G, and their
 * data must match G's corresponding entry for that key.
 */
export type DirectedGraph<G extends object> = {
  nodes: ConnectedNode<G, keyof G>[];
};

export type GraphOf<D> = D extends DirectedGraph<infer G> ? G : never;

export type ConnectedNodeOf<D> = D extends DirectedGraph<infer G>
  ? ConnectedNode<G, keyof G>
  : never;

/** Type-narrows a connected node */
export const isConnectedNode =
  <L extends number | string | symbol>(type: L) =>
  <G extends { [LL in L]: any }>(
    node: ConnectedNode<G, keyof G>
  ): node is ConnectedNode<G, L> =>
    node.type === type;

/** Reduces nodes in a graph
 *
 * Reducers are implemented via three components:
 * - An extraction method that converts a node to a value
 * - A storage structure that holds values for many nodes
 * - A finalization method that converts the storage structure to a final output aggregate
 */
export type Reducer<G extends object, Memo, Value, Return> = {
  /** Converts this reducers intermediate storage value into the aggregte final output value */
  finalize: (value: Memo) => Return;
  /** Creates the storage data structure */
  initialize: () => Memo;
  /** Adds a single node's value to the storage data structure */
  reduce: (memo: Memo, value: Value) => void;
  /** Converts the data structure to individual node values */
  span: (memo: Memo) => Iterable<Value>;
  /** Converts a node to an individual value */
  toValue: (node: Node<G, keyof G>) => Value;
};

/** Represents a mapping of aggregate keys to Reducer objects */
export type Reducers<G extends object, A extends object> = {
  [K in keyof A]: Reducer<G, any, any, A[K]>;
};

import { PaginatedListDisplay } from "components/common/PaginatedList";
import React, { ReactNode, useCallback } from "react";

const ACTIONS_PAGE_SIZE = 5;

export const ActionListDisplay: React.FC<{
  actions: ReactNode[][] | string[];
}> = ({ actions }) => {
  const renderer = useCallback(
    (action: string) => (action === "*" ? "* (All Actions)" : action),
    []
  );
  return (
    <PaginatedListDisplay
      items={actions}
      pageSize={ACTIONS_PAGE_SIZE}
      renderer={renderer}
    />
  );
};

// Shared between backend and frontend

// Based on https://web.dev/articles/base64-encoding

export const encodeBase64 = (str: string): string => {
  return bytesToBase64(new TextEncoder().encode(str));
};

export const decodeBase64 = (str: string): string => {
  return new TextDecoder().decode(base64ToBytes(str));
};

// From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
function base64ToBytes(base64: string) {
  // Using deprecated function `atob` for browser compatibility. The newer nodejs way would be: Buffer.from(str, "base64").toString("binary");
  const binString = atob(base64);
  const codePoints: number[] = [];
  for (let i = 0; i < binString.length; i++) {
    const codePoint = binString.codePointAt(i);
    if (codePoint === undefined) {
      throw Object.assign(
        new Error("Error decoding base64 string: invalid UTF-16 code point"),
        { name: "InvalidUtf16StringError" }
      );
    }
    codePoints.push(codePoint);
  }
  return Uint8Array.from(codePoints);
}

// From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
function bytesToBase64(bytes: Uint8Array) {
  const binString = String.fromCodePoint(...bytes);
  // Using deprecated function `btoa` for browser compatibility. The newer nodejs way would be: Buffer.from(str, "binary").toString("base64");
  return btoa(binString);
}

import styled from "styled-components";

export const StyledConfigFooter = styled.div`
  display: flex;
  justify-content: end;
`;

export const StyledWorkflowContent = styled.div`
  overflow: auto;
`;

export const StyledButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

import { Alert, Space, Tabs, TabsProps, Typography } from "antd";

import {
  EntitlementDiff,
  PrivilegeDiff,
  SnowflakeDriftCheckDocument,
} from "../types";
import { EnforcePanel } from "./EnforcePanel";
import { EntitlementDiffTable, PrivilegeDiffTable } from "./Tables";

const driftCheckItems: (
  privilegeDiffs: PrivilegeDiff[],
  entitlementDiffs: EntitlementDiff[]
) => TabsProps["items"] = (
  privilegeDiffs: PrivilegeDiff[],
  entitlementDiffs: EntitlementDiff[]
) => [
  {
    key: "privilegeDiffs",
    label: `Privilege Differences`,
    children: <PrivilegeDiffTable privilegeDiffs={privilegeDiffs} />,
  },
  {
    key: "entitlementDiffs",
    label: `Entitlement Differences`,
    children: <EntitlementDiffTable entitlementDiffs={entitlementDiffs} />,
  },
];
export const DriftCheckVisualizer: React.FC<{
  runId: string;
  handler: (runId: string) => Promise<void>;
  doc: SnowflakeDriftCheckDocument;
}> = ({ runId, handler, doc }) => {
  const { privilegeDiffs, entitlementDiffs, warnings } = doc.diff ?? {
    privilegeDiffs: [],
    entitlementDiffs: [],
    warnings: [],
  };
  return (
    <Space direction="vertical" data-testid="drift-check-visualizer">
      <Typography.Title level={2}>Findings</Typography.Title>

      {privilegeDiffs &&
      entitlementDiffs &&
      privilegeDiffs.length === 0 &&
      entitlementDiffs.length === 0 ? (
        <div>P0 found no drifts in your Snowflake configuration.</div>
      ) : (
        <>
          <div>P0 found drifts in your Snowflake configuration.</div>
          {warnings?.map((warning: string, id: number) => (
            <Alert
              type="warning"
              message={warning}
              key={`drift-check-warning-${id}`}
              style={{ marginBottom: "10px" }}
            />
          ))}
          <div
            data-testid="drift-display"
            style={{
              display: "flex",
              alignItems: "stretch",
              maxWidth: "85vw",
            }}
          >
            <EnforcePanel runId={runId} handler={handler} />
          </div>
          <Tabs items={driftCheckItems(privilegeDiffs, entitlementDiffs)} />
        </>
      )}
    </Space>
  );
};

import { DevEnv } from "../../../../types/environment";
import { AwsPolicyOptions } from "./policies";
import { roleInstallCommands, roleTerraform } from "./shared";

export const accessAnalyzerName = "P0AccessAnalyzer";

const accessAnalyzerCommand = `aws accessanalyzer create-analyzer --type ACCOUNT_UNUSED_ACCESS \\
--analyzer-name ${accessAnalyzerName} \\
--configuration '{"unusedAccess" : {"unusedAccessAge" : 90}}'`;

const accessAnalyzerTerraform = `resource "aws_accessanalyzer_analyzer" "p0_aws_access_analyzer" {
  analyzer_name = "${accessAnalyzerName}"
  type          = "ACCOUNT_UNUSED_ACCESS"
  configuration {
    unused_access {
      unused_access_age = 90
    }
  }
}`;

export const iamAssessmentInstallCommands = (
  context: DevEnv,
  options: AwsPolicyOptions,
  args: {
    gcloudServiceAccountId: string;
    awsAccountId: string;
  }
) => {
  const roleSetupCommands = roleInstallCommands(
    "iam-assessment",
    context,
    options,
    args
  );

  return `${roleSetupCommands} \\
  && \\ 
${accessAnalyzerCommand}`;
};

// TODO: Test terraform prior to making this install public
export const iamAssessmentTerraform = (
  context: DevEnv,
  options: AwsPolicyOptions,
  gcloudServiceAccountId: string
) => {
  const roleSetupTerraform = roleTerraform(
    "iam-assessment",
    context,
    options,
    gcloudServiceAccountId
  );
  return `${roleSetupTerraform}

${accessAnalyzerTerraform}`;
};

import { Tag } from "antd";
import { priorityLabels } from "shared/assessment/constants";
import { MonitorPriority } from "shared/types/assessment/monitor";
import { MonitorBase } from "shared/types/assessment/monitor";

const priorityColor: Record<
  MonitorPriority,
  {
    color: string;
    text: string;
  }
> = {
  CRITICAL: {
    color: "#ffa39e",
    text: "#5c0011",
  },
  HIGH: {
    color: "#fffb8f",
    text: "#614700",
  },
  MEDIUM: {
    color: "#8c8c8c",
    text: "#ffffff",
  },
  LOW: {
    color: "#d9d9d9",
    text: "#595959",
  },
};

export const MonitorSeverity: React.FC<{ monitor: MonitorBase }> = ({
  monitor,
}) =>
  priorityColor[monitor.priority] && (
    <Tag
      color={priorityColor[monitor.priority].color}
      style={{ color: priorityColor[monitor.priority].text }}
    >
      {priorityLabels[monitor.priority]}
    </Tag>
  );

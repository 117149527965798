import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  CloseCircleTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { List, Progress } from "antd";
import { ErrorDisplay } from "components/Error";
import { AuthzButton } from "components/common/AuthzButton";
import { SpaceBetweenDiv } from "components/divs";
import { formatDistanceToNow } from "date-fns";
import { Timestamp } from "firebase/firestore";
import { round, sortBy } from "lodash";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import { isa } from "shared/types/is";
import styled from "styled-components";

import {
  FirestoreDoc,
  useFirestoreCollection,
} from "../../../providers/FirestoreProvider";
import {
  AssessmentJob,
  AssessmentProgress,
  AssessmentProgressTypeDescription,
  AssessmentProgressTypes,
  TerminalAssessmentStatuses,
} from "../../../shared/types/assessment/job";
import { Tenant } from "../../Login";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { StyledListInfo, StyledStatus } from "../styles";
import { TargetsList } from "./Targets";

type Props = {
  id: string;
  job: AssessmentJob;
};

const StyledSummaryList = styled(List<FirestoreDoc<AssessmentProgress>>)`
  margin-bottom: 10px;

  .ant-list-item {
    padding: 0;
  }
  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
`;

export const JobSummary: React.FC<Props> = ({ id, job }) => {
  const tenantId = useContext(Tenant);
  const auditProgressDocs = useFirestoreCollection<AssessmentProgress>(
    `o/${tenantId}/job-state/${id}/progress`,
    {
      live: true,
    }
  );
  const orderedProgress = useMemo(
    () =>
      sortBy(auditProgressDocs ?? [], (a) =>
        AssessmentProgressTypes.indexOf(a.data.name)
      ),
    [auditProgressDocs]
  );

  const renderListItem = useCallback(
    (item: FirestoreDoc<AssessmentProgress>) => {
      const element = (
        <StyledStatus>
          {item.data.state === "COMPLETED" ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : job.status === "ERRORED" ? (
            <CloseCircleTwoTone twoToneColor="red" />
          ) : item.data.state === "NOT_STARTED" ? (
            <ClockCircleOutlined />
          ) : (
            <SyncOutlined spin />
          )}
          <StyledListInfo>
            {item.data.state !== "COMPLETED" &&
            item.data.state !== "NOT_STARTED" ? (
              <>
                <div style={{ flex: "1" }}>
                  {
                    AssessmentProgressTypeDescription[item.data.name]
                      ?.inProgress
                  }
                </div>
                <div style={{ flex: "1", marginLeft: "5px" }}>
                  <Progress
                    percent={round(item.data.progress * 100)}
                    status="active"
                    trailColor="#ffffff"
                  />
                  <div>{item.data.message}</div>
                </div>
              </>
            ) : (
              <div>
                {
                  AssessmentProgressTypeDescription[item.data.name]?.[
                    item.data.state === "COMPLETED" ? "completed" : "inProgress"
                  ]
                }
              </div>
            )}
          </StyledListInfo>
        </StyledStatus>
      );
      return (
        <List.Item>
          <List.Item.Meta title={element} />
        </List.Item>
      );
    },
    [job]
  );

  return (
    <>
      <StyledSummaryList
        itemLayout="horizontal"
        size="small"
        split={false}
        loading={!auditProgressDocs}
        dataSource={orderedProgress}
        renderItem={renderListItem}
      />
      {job.status === "ERRORED" && <ErrorDisplay error={job.error?.message} />}
    </>
  );
};

export const AssessmentSummary = () => {
  const { current, runAssessmentNow, assessment } = useContext(
    SelectedAssessmentContext
  );
  return (
    <SpaceBetweenDiv>
      <div style={{ flexGrow: 2 }}>
        {current.doc && (
          <JobSummary job={current.doc.data} id={current.doc.id} />
        )}
        <AuthzButton
          roles={["owner", "iamOwner"]}
          type="primary"
          disabledProps={!current.isCompleted}
          onClick={runAssessmentNow}
        >
          Run unscheduled assessment now
        </AuthzButton>
      </div>
      <div style={{ flexGrow: 1 }}>
        {assessment.doc?.data.lastAssessmentDate
          ? !isa(TerminalAssessmentStatuses, current.doc?.data.status ?? "")
            ? "Running"
            : `Last run ${formatDistanceToNow(
                (assessment.doc.data.lastAssessmentDate as Timestamp).toDate()
              )} ago`
          : "Hasn't been run yet"}
        <TargetsList targets={assessment?.doc?.data.targets ?? []} />
      </div>
    </SpaceBetweenDiv>
  );
};

import { create } from "zustand";

import { SubscriptionLevel } from "../../types/entitlement";
import { OrgInfo } from "./types";

type OrgInfoStore = {
  orgInfo: OrgInfo;
  resetOrgInfo: () => void;
  setOrgInfo: (newOrgInfo: OrgInfo) => void;
  useSubscriptionPlan: () => string | undefined;
};
// Create a Zustand store
export const useOrgInfoStore = create<OrgInfoStore>((set, get) => ({
  orgInfo: {
    status: "loading",
    info: {},
  },
  setOrgInfo: (newOrgInfo: OrgInfo) => {
    const updatedOrgInfo: OrgInfo = {
      ...newOrgInfo,
      info: {
        ...newOrgInfo.info,
        subscriptionPlan:
          newOrgInfo.info.subscriptionPlan ??
          SubscriptionLevel.EntitlementEnterprise,
      },
    };
    set({ orgInfo: updatedOrgInfo });
  },
  resetOrgInfo: () => {
    set({
      orgInfo: {
        status: "loading",
        info: {},
      },
    });
  },
  useSubscriptionPlan: () => {
    const orgInfo = get().orgInfo;
    return orgInfo.info.subscriptionPlan;
  },
}));

import { camelCase, capitalize, kebabCase, startCase } from "lodash";
import pluralize from "pluralize";
import { AWS_RESOURCE_ARN } from "shared/assessment/constants";
import { gcpNameToResource } from "shared/assessment/issues/gcp";
import {
  fallbackIconUrl as awsFallbackIconUrl,
  serviceIconUrl as awsServiceIconUrl,
} from "shared/assessment/media/Aws";
import {
  names as gcpNames,
  navIconUrl as gcpNavIconUrl,
} from "shared/assessment/media/GoogleCloud";
import { resourceIconUrl as k8sResourceIconUrl } from "shared/assessment/media/Kubernetes";
import {
  googleGenericIcon,
  serviceIconUrl as workspaceIconUrl,
} from "shared/assessment/media/Workspace";
import { AwsServices } from "shared/integrations/resources/aws/constants";
import {
  Service as GcpService,
  idToFrn as gcpIdToFrn,
} from "shared/integrations/resources/gcloud/asset";
import { K8S_SEPARATOR } from "shared/integrations/resources/kubernetes/assessment";
import { AssessmentNodes } from "shared/types/assessment/data";

export type ResourceInfo = {
  /** The resource service name */
  service: string;
  /** Any potential subservice */
  type?: string;
  /** The full resource locator */
  locator: string;
  /** The individual resource item identifier */
  id: string;
  /** Is not a specific managed service */
  isGeneric: boolean;
  /** Is an entire service */
  isService: boolean;
  /** The service's icon URL */
  url: string;
};

export const resourceLabels = (
  resource: AssessmentNodes["resource"],
  shortPath?: boolean
): ResourceInfo => {
  let { service, type } = resource;
  const url = gcpNavIconUrl(service);
  // TODO: disentangle GCP hard-coding
  const isGeneric = service === GcpService.crm;
  service = gcpNames[service] ?? service;
  type = type ? capitalize(type.replace(/-/g, " ")) : "(Entire service)";
  const { locator, id } = resource;

  return {
    service,
    type,
    id,
    locator: shortPath ? id : locator,
    isGeneric,
    isService: type === undefined,
    url,
  };
};

export const awsResourceInfo = (
  resource: string,
  _shortPath?: boolean
): ResourceInfo => {
  const match = resource.match(AWS_RESOURCE_ARN);
  if (match) {
    const [, service, , first, name] = match;
    // AwsServices is downloaded from https://github.com/fluggo/aws-service-auth-reference/blob/master/service-auth.json
    // then passed through jq:
    //   jq 'map( { (.servicePrefix): del(.|.actions) }) | add
    // TODO: Automate periodic collection
    const humanService = (AwsServices as any)[service]?.name ?? service;
    const id =
      name === "*"
        ? first
          ? `All ${pluralize(first?.replace(/-/g, " "))}`
          : service === "*"
          ? resource
          : `All ${service} resources`
        : name;
    return {
      service: humanService,
      type: first ? first.replace(/-/g, " ") : undefined,
      id,
      locator: resource,
      isGeneric: true,
      isService: !first,
      url: awsServiceIconUrl[service] ?? awsFallbackIconUrl,
    };
  }
  return {
    service: resource === "*" ? "AWS accounts" : "unknown",
    type: undefined,
    id: resource === "*" ? "Entire account" : resource,
    locator: resource,
    isGeneric: true,
    isService: false,
    url: awsFallbackIconUrl,
  };
};

export const gcpResourceInfo = (
  resource: string,
  shortPath?: boolean,
  isRollup?: boolean
) => {
  let data;
  if (isRollup) {
    data = gcpNameToResource(gcpIdToFrn("project", resource));
  } else {
    data = gcpNameToResource(resource);
  }

  // GCS doesn't use type indicators, so cluge these back (objects have parents in the locator)
  if (data.service === "storage") {
    data.type = data.type ? "objects" : "buckets";
  }
  const id =
    data.service === GcpService.crm
      ? data.type === "organizations"
        ? "(Entire organization)"
        : data.id ?? ""
      : data.id ?? "(Entire service)";
  return resourceLabels(
    {
      ...data,
      locator: resource,
      id,
      type: kebabCase(data.type).replace(/-/g, " "),
    },
    shortPath
  );
};

export const k8sResourceInfo = (resource: string, _shortPath?: boolean) => {
  const [apiGroup, kind, namespace, name] = resource.split(K8S_SEPARATOR);
  return {
    service: apiGroup,
    type: undefined,
    id:
      kind === "*" && namespace === "*" && name === "*"
        ? "Entire cluster"
        : kind !== "*" && namespace === "*" && name === "*"
        ? `All ${kind} in entire cluster` // `kind` is already plural
        : kind === "*" && namespace !== "*" && name === "*"
        ? `All resources in ${namespace} namespace`
        : kind === "*" && namespace === "*" && name !== "*"
        ? name // must be a non-namespaced cluster-level object - is this even possible with `kind = *`?
        : kind !== "*" && namespace !== "*" && name === "*"
        ? `All ${kind} in ${namespace} namespace` // `kind` is already plural
        : kind !== "*" && namespace === "*" && name !== "*"
        ? `${name} ${pluralize.singular(kind)}` // must be a non-namespaced cluster-level object
        : kind === "*" && namespace !== "*" && name !== "*"
        ? `${name} in ${namespace} namespace` // is this even possible with `kind = *`?
        : `${name} ${pluralize.singular(kind)} in ${namespace} namespace`,
    locator: resource,
    isGeneric: true,
    isService: false,
    url: k8sResourceIconUrl[kind] ?? k8sResourceIconUrl.controlplane,
  };
};

export const workspaceResourceInfo = (
  resource: string,
  _shortPath?: boolean
): ResourceInfo => {
  const title = startCase(camelCase(resource));
  return {
    id: title,
    isGeneric: false,
    isService: true,
    locator: title,
    service: title,
    url: workspaceIconUrl[resource] ?? googleGenericIcon,
  };
};

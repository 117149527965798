import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Typography } from "antd";
import stringify from "json-stable-stringify";
import pluralize from "pluralize";
import { useMemo } from "react";
import { Filter } from "shared/types/workflow/types";

import { StyledResourceFilterCount } from "../../styles";
import { ResourceFilterPreview } from "./ResourceFilterPreview";

type Props = {
  resourceFilters: {
    filter: Filter;
    filterType: string;
  }[];
};

export const ResourceFilterCount: React.FC<Props> = ({ resourceFilters }) => {
  const filterList = useMemo(
    () => (
      <ul style={{ maxWidth: 350, paddingLeft: "1em", marginBottom: 0 }}>
        {resourceFilters.map((filter) => {
          return (
            <li key={stringify(filter)}>
              <ResourceFilterPreview
                filter={filter.filter}
                filterType={filter.filterType}
              />
            </li>
          );
        })}
      </ul>
    ),
    [resourceFilters]
  );

  return (
    <StyledResourceFilterCount className="filters">
      <Typography.Paragraph type="secondary" className="text">
        {`${resourceFilters.length} ${pluralize(
          "filter",
          resourceFilters.length
        )}`}
      </Typography.Paragraph>
      <Popover content={filterList} trigger="click" className="popover">
        <InfoCircleOutlined />
      </Popover>
    </StyledResourceFilterCount>
  );
};

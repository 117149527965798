import { Alert, Button, Col, Empty, Modal, Row, Spin, Typography } from "antd";
import { AuthzButton } from "components/common/AuthzButton";
import { formatDistanceToNow, isBefore, milliseconds } from "date-fns";
import { Timestamp, limit, orderBy, where } from "firebase/firestore";
import { identity, size } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { installedItems } from "shared/install/installed";
import { AwsIntegration } from "shared/integrations/resources/aws/types";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import {
  AssessmentJob,
  TerminalAssessmentStatuses,
} from "shared/types/assessment/job";
import { isa } from "shared/types/is";
import { widetype } from "shared/util/collections";

import {
  FirestoreDoc,
  useFirestoreCollection,
  useFirestoreDoc,
} from "../../../providers/FirestoreProvider";
import {
  AssessmentScopeIntegration,
  IamAssessment,
} from "../../../shared/types/assessment";
import { AppRoutes } from "../../App/routeConstants";
import { Heading } from "../../Heading";
import { Tenant } from "../../Login";
import { JobSummary } from "../components/JobSummary";
import { NewAssessmentForm } from "../components/NewAssessmentForm";
import { TargetsList } from "../components/Targets";
import { AssessmentCreationProvider } from "../contexts/AssessmentCreationContext";
import {
  StyledListItem,
  StyledListItemProgress,
  StyledListItemRow,
} from "../styles";

export const Overview: React.FC = () => {
  const tenantId = useContext(Tenant);
  const assessments = useFirestoreCollection<IamAssessment>(
    `o/${tenantId}/iam-assessments`,
    { live: true }
  );
  const [modalOpen, setModalOpen] = useState(false);

  const { doc: gcloudDoc, loading: gcloudLoading } =
    useFirestoreDoc<GcloudIntegration>(`integrations/gcloud`, {
      live: true,
      tenantAware: true,
    });

  const { doc: awsDoc, loading: awsLoading } = useFirestoreDoc<AwsIntegration>(
    `integrations/aws`,
    {
      live: true,
      tenantAware: true,
    }
  );

  const installed: Record<AssessmentScopeIntegration, boolean> = useMemo(
    () => ({
      aws: !!size(installedItems("iam-assessment", awsDoc?.data ?? {})),
      gcloud: !!(
        size(installedItems("iam-assessment", gcloudDoc?.data ?? {})) ||
        size(installedItems("org-iam-assessment", gcloudDoc?.data ?? {}))
      ),
      k8s: false, //TODO: Update to make this reflect accurately
      workspace: false,
    }),
    [awsDoc, gcloudDoc]
  );

  const assessmentInstalled = useMemo(
    () => !!Object.values(installed).find(identity),
    [installed]
  );

  const loading = useMemo(
    () => gcloudLoading || awsLoading,
    [gcloudLoading, awsLoading]
  );

  const closeModal = useCallback(() => setModalOpen(false), []);
  const openModal = useCallback(() => setModalOpen(true), []);

  return (
    <>
      <Heading
        title="IAM Assessments"
        cta={
          <AuthzButton
            roles={["owner", "iamOwner"]}
            type="primary"
            size="large"
            onClick={openModal}
            disabledProps={!assessmentInstalled}
          >
            New Assessment
          </AuthzButton>
        }
      />
      {loading ? (
        <Spin />
      ) : (
        !assessmentInstalled &&
        (assessments && assessments.length === 0 ? (
          <Alert
            type="warning"
            message="No projects installed currently."
            action={
              <Button type="default" href="integrations/gcloud">
                Install IAM Assessments (Google Cloud)
              </Button>
            }
            style={{ marginBottom: "20px" }}
          />
        ) : (
          <Alert
            type="error"
            message="Your Google Cloud installation is not complete."
            description="Any existing assessments will not run correctly until you fix it."
            action={
              <Button type="primary" href="integrations/gcloud">
                Install IAM Assessments (Google Cloud)
              </Button>
            }
            style={{ marginBottom: "20px" }}
          />
        ))
      )}
      {assessments && assessments.length > 0 ? (
        assessments.map((assessment: FirestoreDoc<IamAssessment>) => (
          <AssessmentItem
            assessment={assessment.data}
            id={assessment.id}
            key={assessment.id}
          />
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No assessments yet. Create one to get started."
        />
      )}
      <Modal
        open={modalOpen}
        destroyOnClose // Clear new assessment form on close
        maskClosable={false}
        footer={false}
        onCancel={closeModal}
      >
        <Typography.Title level={4}>Create New Assessment</Typography.Title>
        <AssessmentCreationProvider>
          <NewAssessmentForm
            setFormOpen={setModalOpen}
            installedIntegrations={widetype
              .keys(installed)
              .filter((integration) => installed[integration])}
          />
        </AssessmentCreationProvider>
      </Modal>
    </>
  );
};

const AssessmentItem: React.FC<{
  assessment: IamAssessment;
  id: string;
}> = ({ assessment, id }) => {
  const { orgSlug } = useParams();
  const tenantId = useContext(Tenant);
  const navigate = useNavigate();
  const jobDoc = useFirestoreCollection<AssessmentJob>(
    `o/${tenantId}/job-state`,
    {
      live: true,
      queryConstraints: [
        where("assessmentId", "==", id),
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1),
      ],
    }
  );

  const formattedLastRun = useMemo(
    () =>
      !isa(TerminalAssessmentStatuses, jobDoc?.at(-1)?.data.status ?? "")
        ? "Running"
        : assessment.lastAssessmentDate
        ? `Last run ${formatDistanceToNow(
            (assessment.lastAssessmentDate as Timestamp).toDate()
          )} ago`
        : "Never run",
    [assessment.lastAssessmentDate, jobDoc]
  );

  const formattedNextRun = useMemo(() => {
    if (!assessment.frequency?.anchorDate) {
      return "Not scheduled for additional runs";
    }
    const unitMillis = milliseconds({ [assessment.frequency.unit]: 1 });
    const anchor = (assessment.frequency.anchorDate as Timestamp).toMillis();
    const nextRunDate = new Date(
      Math.ceil((Date.now() - anchor) / unitMillis) * unitMillis + anchor
    );
    if (isBefore(nextRunDate, new Date())) {
      return "Next run is imminent";
    }
    return `Next run in ${formatDistanceToNow(nextRunDate)}`;
  }, [assessment.frequency]);

  const navigateToFindings = useCallback(
    () =>
      navigate(
        `/o/${orgSlug}/${AppRoutes.IamAssessment}/${id}/findings?scope=all`
      ),
    [id, navigate, orgSlug]
  );

  return (
    <StyledListItem>
      <StyledListItemRow
        gutter={[
          { xs: 8, md: 16, lg: 20 },
          { xs: 8, md: 16, lg: 20 },
        ]}
      >
        <StyledListItemProgress xs={24} md={12} lg={10}>
          <Typography.Title level={4} style={{ marginTop: "0" }}>
            {assessment.name}
          </Typography.Title>
          {assessment.lastAssessmentDate &&
            (jobDoc?.[0] ? (
              <JobSummary id={jobDoc[0].id} job={jobDoc[0].data} />
            ) : (
              <Spin />
            ))}
        </StyledListItemProgress>
        <Col xs={24} md={12} lg={14}>
          <Row gutter={[0, 16]}>
            <Col xs={24} lg={12}>
              <Typography.Paragraph>{formattedLastRun}</Typography.Paragraph>
              <Typography.Paragraph>{formattedNextRun}</Typography.Paragraph>
              <Button
                type="default"
                onClick={navigateToFindings}
                style={{ width: "100%", maxWidth: "300px" }}
              >
                View Details
              </Button>
            </Col>
            <Col xs={24} lg={12}>
              <TargetsList targets={assessment.targets} />
            </Col>
          </Row>
        </Col>
      </StyledListItemRow>
    </StyledListItem>
  );
};

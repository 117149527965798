import { Col, Row } from "antd";
import styled from "styled-components";

export const StyledListItem = styled.div`
  margin: 0 -24px;
  padding: 12px 24px;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

export const StyledListItemRow = styled(Row)`
  max-width: 1200px;
`;

export const StyledListItemProgress = styled(Col)`
  @media (min-width: 768px) {
    border-right: 1px solid #bfbfbf;
  }
`;

export const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledListInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  width: 95%;
`;

export const AssessmentSettingsSection = styled.div`
  margin-bottom: 24px;
  max-width: 800px;
`;

export const StyledMonitorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  .ant-alert {
    flex-grow: 1;
  }
`;

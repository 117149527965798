import Link from "antd/lib/typography/Link";
import { CommandDisplay } from "components/Integrations/CommandDisplay";
import {
  accessAnalyzerName,
  iamAssessmentInstallCommands,
  iamAssessmentTerraform,
} from "shared/integrations/resources/aws/policy/iam-assessment";

import { AwsInstaller } from "../types";

const iamAssessmentInstructions: AwsInstaller["iam-assessment"]["instructions"] =
  (context, id) => ({
    help: (
      <>
        To install P0 on this account, provision P0&apos;s access using{" "}
        <Link
          href={`https://${id}.signin.aws.amazon.com/console`}
          target="_blank"
          rel="noopener"
        >
          AWS Cloud Shell
        </Link>{" "}
        or Terraform:
      </>
    ),
    commands: {
      shell: [
        {
          command: iamAssessmentInstallCommands(
            context,
            {},
            {
              gcloudServiceAccountId: context.config.base[id]?.serviceAccountId,
              awsAccountId: id,
            }
          ),
        },
      ],
      iac: [
        {
          command: iamAssessmentTerraform(
            context,
            {},
            context.config.base[id]?.serviceAccountId
          ),
        },
      ],
    },
  });

export const awsIamAssessmentInstaller: AwsInstaller["iam-assessment"] = {
  instructions: iamAssessmentInstructions,
  items: {
    accessAnalyzerArn: {
      labeler: () => (
        <>
          This is the ARN of the access analyzer you created during set-up. To
          get the ARN, run the following command:
          <CommandDisplay
            commands={`aws accessanalyzer get-analyzer --analyzer-name ${accessAnalyzerName}`}
          ></CommandDisplay>
        </>
      ),
    },
  },
};

import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, Empty, List, Spin, Tooltip, Typography, message } from "antd";
import { useAuthFetch } from "components/Login/hook";
import { formatDistance } from "date-fns";
import { toLower } from "lodash";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { useCallback, useState } from "react";
import { Evidence } from "shared/types/evidence";

import { EvidencePreview } from "./EvidencePreview";

const REVOKED_EVIDENCE_SUCCESS = "Evidence revoked successfully.";
const REVOKED_EVIDENCE_FAILURE = "Failed to revoke evidence.";

export const TitledEvidenceList: React.FC<
  {
    title: string;
    loading: boolean;
  } & EvidenceListProps
> = ({ title, loading, ...listProps }) => (
  <>
    <Typography.Title level={4}>{title}</Typography.Title>
    {loading ? (
      <Spin />
    ) : listProps.evidence.length > 0 ? (
      <EvidenceList {...listProps} />
    ) : (
      <Empty description={`No ${toLower(title)} evidence`} />
    )}
  </>
);

type EvidenceListProps = {
  evidence: FirestoreDoc<Evidence>[];
};

const EvidenceList: React.FC<EvidenceListProps> = ({ evidence }) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const authFetch = useAuthFetch();

  const handleRevoke = useCallback(
    async (evidenceId: string) => {
      try {
        setDirty(true);
        const response = await authFetch(`evidence/${evidenceId}/revoke`, {
          method: "POST",
        });

        if (response?.ok) {
          message.success(REVOKED_EVIDENCE_SUCCESS);
        } else {
          message.error(REVOKED_EVIDENCE_FAILURE);
        }
      } catch (error) {
        message.error(REVOKED_EVIDENCE_FAILURE);
      } finally {
        setDirty(false);
      }
    },
    [authFetch]
  );

  const renderEvidence = useCallback(
    (item: FirestoreDoc<Evidence>) => {
      return (
        <List.Item
          actions={[
            item.data.startsAt > Date.now() ? (
              <Typography.Text key="expiry" type="secondary">
                Starts in{" "}
                {item.data.startsAt &&
                  formatDistance(Date.now(), item.data.startsAt)}
              </Typography.Text>
            ) : null,
            <Typography.Text key="expiry" type="secondary">
              Expires in{" "}
              {item.data.endsAt && formatDistance(item.data.endsAt, Date.now())}
            </Typography.Text>,
            <Tooltip
              key="revoke"
              title={
                dirty
                  ? "Evidence is being revoked..."
                  : !!item.data.revokedAt &&
                    "Evidence has already been revoked."
              }
            >
              <Button
                key="revoke"
                type="default"
                // In a useCallback already
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handleRevoke(item.id)}
                disabled={!!item.data.revokedAt || dirty}
              >
                <MinusCircleOutlined /> Revoke
              </Button>
            </Tooltip>,
          ]}
        >
          <EvidencePreview evidence={item.data} />
        </List.Item>
      );
    },
    [dirty, handleRevoke]
  );

  return (
    <List
      dataSource={evidence}
      renderItem={renderEvidence}
      pagination={{
        pageSize: 10,
        size: "small",
        hideOnSinglePage: true,
      }}
    />
  );
};

import {
  Element,
  InstallSpec,
  ItemComponent,
  Option,
} from "../../../install/types";

// Based on allowable field names in Firestore
const VALID_COMPONENT_NAME_PATTERN = /^[A-Za-z][A-Za-z0-9-]*$/;

const postgresComponentNameValidator = async (_id: string, field: string) => {
  if (!field.match(VALID_COMPONENT_NAME_PATTERN)) {
    return "Identifier must start with a letter and contain only alphanumeric characters and hyphens";
  }
};

const cloudSqlInstanceIdValidator = async (_id: string, field: string) => {
  if (field.match(/:/)) {
    return "CloudSQL instance ID must not contain colons. Enter only the instance ID, not the full connection name.";
  }
};

const postgresInstallTypeOptions = {
  "cloud-sql": Option({
    label: "Google CloudSQL",
    schema: {
      projectId: Element({
        label: "GCP project ID",
        type: "dynamic",
        step: "new",
      }),
      region: Element({
        label: "GCP region",
        description: "The region where the CloudSQL instance is located.",
        type: "string",
        step: "new",
      }),
      instanceId: Element({
        label: "CloudSQL instance ID",
        description:
          "The ID of the CloudSQL instance hosting the database. It must have public IP access enabled.",
        type: "string",
        step: "new",
        validator: cloudSqlInstanceIdValidator,
      }),
    },
  }),
};

export const PostgresComponents = InstallSpec({
  "access-management": ItemComponent({
    label: "Access management",
    description: "Manage access to a PostgreSQL database.",
    type: "string",
    schema: {
      installType: Element({
        label: "Installation type",
        description: "How is the PostgreSQL database hosted or managed?",
        type: "select" as const,
        default: "cloud-sql" as const,
        options: postgresInstallTypeOptions,
        step: "new",
      }),
      databaseName: Element({
        label: "Database name",
        description:
          "The name of the database to manage. Have more than one on the same instance? Create an additional integration for each.",
        type: "string",
        step: "new",
      }),
    },
    validator: postgresComponentNameValidator,
  }),
});

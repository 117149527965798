// TODO use these filters to exclud system roles in namespaces
export const SYSTEM_CLUSTER_ROLE_PREFIXES = [
  // Common
  "system:",
  // GKE
  "ca-cr-actor",
  "cloud-provider",
  "cluster-autoscaler",
  "external-metrics-reader",
  "fluentbit-",
  "gce:",
  "gke-",
  "kubelet-api-admin",
  "pdcsi-",
  "read-updateinfo",
  "snapshot-controller-runner",
  "storage-version-migration-crd-creator",
  "storage-version-migration-initializer",
  "storage-version-migration-trigger",
  // EKS
  "aws-node",
  "vpc-resource-controller-role",
  "eks:",
  // P0
  "p0-",
];

export const KUBE_SYSTEM_NAMESPACES = [
  "kube-node-lease",
  "kube-public",
  "kube-system",
];

export const SYSTEM_NAMESPACES = [...KUBE_SYSTEM_NAMESPACES, "p0-security"];

export const READER_VERBS = ["get", "list", "watch"];

export const P0_ROLE_NAME = "p0-service-role";
export const P0_ROLE_BINDING_NAME = "p0-service-role-binding";

export const P0_CLUSTER_ROLE = {
  apiVersion: "rbac.authorization.k8s.io/v1",
  kind: "ClusterRole",
  metadata: {
    name: P0_ROLE_NAME,
  },
  rules: [
    {
      apiGroups: [""],
      resources: [
        "bindings",
        "configmaps",
        "endpoints",
        "events",
        "limitranges",
        "namespaces",
        "namespaces/status",
        "nodes",
        "persistentvolumeclaims",
        "persistentvolumeclaims/status",
        "pods",
        "pods/log",
        "pods/status",
        "replicationcontrollers",
        "replicationcontrollers/scale",
        "replicationcontrollers/status",
        "resourcequotas",
        "resourcequotas/status",
        "serviceaccounts",
        "services",
        "services/status",
      ],
      verbs: ["list"],
    },
    {
      apiGroups: ["discovery.k8s.io"],
      resources: ["endpointslices"],
      verbs: ["list"],
    },
    {
      apiGroups: ["apps"],
      resources: [
        "controllerrevisions",
        "daemonsets",
        "daemonsets/status",
        "deployments",
        "deployments/scale",
        "deployments/status",
        "replicasets",
        "replicasets/scale",
        "replicasets/status",
        "statefulsets",
        "statefulsets/scale",
        "statefulsets/status",
      ],
      verbs: ["list"],
    },
    {
      apiGroups: ["autoscaling"],
      resources: [
        "horizontalpodautoscalers",
        "horizontalpodautoscalers/status",
      ],
      verbs: ["list"],
    },
    {
      apiGroups: ["batch"],
      resources: ["cronjobs", "cronjobs/status", "jobs", "jobs/status"],
      verbs: ["list"],
    },
    {
      apiGroups: ["extensions"],
      resources: [
        "daemonsets",
        "daemonsets/status",
        "deployments",
        "deployments/scale",
        "deployments/status",
        "ingresses",
        "ingresses/status",
        "networkpolicies",
        "replicasets",
        "replicasets/scale",
        "replicasets/status",
        "replicationcontrollers/scale",
      ],
      verbs: ["list"],
    },
    {
      apiGroups: ["policy"],
      resources: ["poddisruptionbudgets", "poddisruptionbudgets/status"],
      verbs: ["list"],
    },
    {
      apiGroups: ["networking.k8s.io"],
      resources: ["ingresses", "ingresses/status", "networkpolicies"],
      verbs: ["list"],
    },
    {
      apiGroups: ["coordination.k8s.io"],
      resources: ["leases"],
      verbs: ["list"],
    },
    {
      apiGroups: [""],
      resourceNames: ["aws-auth"],
      resources: ["configmaps"],
      verbs: ["get", "create", "patch", "update"],
    },
    {
      apiGroups: ["rbac.authorization.k8s.io"],
      resources: ["roles", "clusterroles"],
      verbs: [
        "get",
        "list",
        "create",
        "patch",
        "update",
        "delete",
        "bind",
        "escalate",
      ],
    },
    {
      apiGroups: ["rbac.authorization.k8s.io"],
      resources: ["rolebindings", "clusterrolebindings"],
      verbs: ["get", "list", "create", "patch", "update", "delete"],
    },
  ],
};

export const P0_CLUSTER_ROLE_BINDING = {
  apiVersion: "rbac.authorization.k8s.io/v1",
  kind: "ClusterRoleBinding",
  metadata: {
    name: P0_ROLE_BINDING_NAME,
  },
  subjects: [
    {
      kind: "ServiceAccount",
      name: "p0-service-account",
      namespace: "p0-security",
    },
  ],
  roleRef: {
    kind: "ClusterRole",
    name: P0_ROLE_NAME,
    apiGroup: "rbac.authorization.k8s.io",
  },
};

export const PROXY_ADDRESS_LOCAL = "http://localhost:8081/client";
export const PROXY_ADDRESS_K8S =
  "http://api.p0-namespace.svc.cluster.local:8081/client";

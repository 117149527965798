import { DeleteTwoTone } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useCallback } from "react";
import { SINGLETON_ITEM } from "shared/install/constants";
import { InstallSpec, ItemComponent } from "shared/install/types";

import { ComponentProps } from "./Component";
import { Config } from "./Config";

const { Paragraph } = Typography;

export const Singleton = <T extends InstallSpec>(
  props: ComponentProps<T> & { component: ItemComponent<any> }
) => {
  const { authFetch, config, component, componentKey, integration } = props;
  const item = config[componentKey]?.[SINGLETON_ITEM];
  const path = `integrations/${integration}/config/${componentKey}/${SINGLETON_ITEM}`;

  const uninstall = useCallback(async () => {
    await authFetch(path, { method: "DELETE" });
  }, [authFetch, path]);

  const stage = useCallback(async () => {
    await authFetch(path, { method: "PUT", json: {} });
  }, [authFetch, path]);

  return item ? (
    <>
      <Config {...props} item={item} id={SINGLETON_ITEM} />
      {component.type === "singleton" && (
        <Button icon={<DeleteTwoTone />} onClick={uninstall}>
          Uninstall
        </Button>
      )}
    </>
  ) : (
    <>
      <Paragraph>To continue, click &quot;Install&quot;</Paragraph>
      <Button type="primary" onClick={stage}>
        Install
      </Button>
    </>
  );
};

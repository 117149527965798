import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Table } from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";

import { EntitlementDiff, PrivilegeDiff } from "../types";

const privilegeDiffColumns: ColumnsType<PrivilegeDiff> = [
  {
    title: "Type",
    dataIndex: ["type"],
    render: (type: string) => (
      <div>{type === "GRANT" ? `Missing` : `Excess`}</div>
    ),
  },
  {
    title: "Role",
    dataIndex: ["role"],
    render: (role: string) => <div>{role}</div>,
  },
  {
    title: "privilege",
    dataIndex: ["privilege"],
    render: (privilege: string) => <div>{privilege}</div>,
  },
  {
    title: "on",
    dataIndex: ["on"],
    render: (on: string) => <div>{on}</div>,
  },

  {
    title: "object Name",
    dataIndex: ["objectName"],
    render: (objectName: string) => <div>{objectName}</div>,
  },
  {
    title: "description",
    render: (data) => (
      <div>
        {data.type === "GRANT"
          ? `${data.role} should have ${data.privilege} on ${data.on} ${data.objectName} but it doesn't.`
          : `${data.role} has ${data.privilege} on ${data.on} ${data.objectName} when it shouldn't.`}
      </div>
    ),
  },
];

const privilegeEnforcementColumns: ColumnsType<
  PrivilegeDiff & { success: boolean }
> = [
  {
    title: "Status",
    dataIndex: ["success"],
    render: (success: boolean) => (
      <div>
        {success ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="red" />
        )}
      </div>
    ),
  },
  {
    title: "Type",
    dataIndex: ["type"],
    render: (type: string) => (
      <div>{type === "GRANT" ? `Missing` : `Excess`}</div>
    ),
  },
  {
    title: "Role",
    dataIndex: ["role"],
    render: (role: string) => <div>{role}</div>,
  },
  {
    title: "privilege",
    dataIndex: ["privilege"],
    render: (privilege: string) => <div>{privilege}</div>,
  },
  {
    title: "on",
    dataIndex: ["on"],
    render: (on: string) => <div>{on}</div>,
  },

  {
    title: "object Name",
    dataIndex: ["objectName"],
    render: (objectName: string) => <div>{objectName}</div>,
  },
  {
    title: "description",
    render: (_) => (
      <div>
        {`${_.success ? `Performed ` : `Failed to perform `} ${_.type} ${
          _.privilege
        } on ${_.on} ${_.objectName} to ${_.role}`}
      </div>
    ),
  },
];

const entitlementDiffColumns: ColumnsType<EntitlementDiff> = [
  {
    title: "Type",
    dataIndex: ["type"],
    render: (type: string) => (
      <div>{type === "GRANT" ? `Missing` : `Excess`}</div>
    ),
  },
  {
    title: "Role",
    dataIndex: ["role"],
    render: (role: string) => <div>{role}</div>,
  },
  {
    title: "Grantee Type",
    dataIndex: ["granteeType"],
    render: (granteeType: string) => <div>{granteeType}</div>,
  },
  {
    title: "Grantee Name",
    dataIndex: ["granteeName"],
    render: (granteeName: string) => <div>{granteeName}</div>,
  },
  {
    title: "description",
    render: (data) => (
      <div>
        {data.type === "GRANT"
          ? `${data.granteeName} should have ${data.role} but it doesn't.`
          : `${data.granteeName} has ${data.role} when it shouldn't.`}
      </div>
    ),
  },
];

const entitlementEnforcementColumns: ColumnsType<
  EntitlementDiff & { success: boolean }
> = [
  {
    title: "Status",
    dataIndex: ["success"],
    render: (success: boolean) => (
      <div>
        {success ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="red" />
        )}
      </div>
    ),
  },
  {
    title: "Type",
    dataIndex: ["type"],
    render: (type: string) => (
      <div>{type === "GRANT" ? `Missing` : `Excess`}</div>
    ),
  },
  {
    title: "Role",
    dataIndex: ["role"],
    render: (role: string) => <div>{role}</div>,
  },
  {
    title: "Grantee Type",
    dataIndex: ["granteeType"],
    render: (granteeType: string) => <div>{granteeType}</div>,
  },
  {
    title: "Grantee Name",
    dataIndex: ["granteeName"],
    render: (granteeName: string) => <div>{granteeName}</div>,
  },
  {
    title: "description",
    render: (_) => (
      <div>
        {`${_.success ? `Performed` : `Failed to perform `} ${_.type} ${
          _.role
        } to ${_.granteeName}`}
      </div>
    ),
  },
];

type DriftTableProps<D extends object> = {
  model: ColumnsType<D>;
  data: D[] | undefined;
  loading?: boolean;
  message: string;
};

const DriftTable = <D extends object>(
  props: TableProps<D> & DriftTableProps<D>
) => {
  return (
    <>
      {props.data?.length === 0 ? (
        <> {props.message}</>
      ) : (
        <Table columns={props.model} dataSource={props.data} {...props} />
      )}
    </>
  );
};

export const entitlementKey = (record: EntitlementDiff) =>
  `${record.role}-${record.granteeName}-${record.type}-${record.granteeType}`;

export const privilegeKey = (record: PrivilegeDiff) =>
  `${record.role}-${record.privilege}-${record.on}-${record.objectName}`;

export const PrivilegeDiffTable: React.FC<{
  privilegeDiffs: PrivilegeDiff[];
}> = ({ privilegeDiffs }) => {
  return (
    <DriftTable<PrivilegeDiff>
      model={privilegeDiffColumns}
      data={privilegeDiffs}
      rowKey={privilegeKey}
      message="No drift was detected for privileges"
    />
  );
};
export const EntitlementDiffTable: React.FC<{
  entitlementDiffs: EntitlementDiff[];
}> = ({ entitlementDiffs }) => {
  return (
    <DriftTable<EntitlementDiff>
      model={entitlementDiffColumns}
      data={entitlementDiffs}
      rowKey={entitlementKey}
      message="No drift was detected for entitlements"
    />
  );
};

export const PrivilegeEnforcementTable: React.FC<{
  privilegeEnforcements: (PrivilegeDiff & { success: boolean })[];
}> = ({ privilegeEnforcements }) => {
  return (
    <DriftTable<PrivilegeDiff & { success: boolean }>
      model={privilegeEnforcementColumns}
      data={privilegeEnforcements}
      rowKey={privilegeKey}
      message="No drift enforced for privileges"
    />
  );
};
export const EntitlementEnforcementTable: React.FC<{
  entitlementEnforcements:
    | (EntitlementDiff & { success: boolean })[]
    | undefined;
}> = ({ entitlementEnforcements }) => {
  return (
    <DriftTable<EntitlementDiff & { success: boolean }>
      model={entitlementEnforcementColumns}
      data={entitlementEnforcements}
      rowKey={entitlementKey}
      message="No drift enforced for entitlements"
    />
  );
};

export enum AppRoutes {
  Requests = "requests",
  RequestHistory = "request-history",
  Routing = "routing",
  RestState = "rest-state",
  Integrations = "integrations",
  Settings = "settings",
  IamAssessment = "iam-assessments",
  IntegrationsSandbox = "sandbox/integrations",
  RoutingSandbox = "sandbox/routing",
  SettingsSandbox = "sandbox/settings",
}

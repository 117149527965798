import { Typography } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv } from "components/divs";
import { ALL_SCOPE_SENTINEL } from "shared/assessment/constants";
import { policyName } from "shared/integrations/resources/aws/util";
import { roleName } from "shared/integrations/resources/gcloud/util";
import { AssessmentScopeIntegration } from "shared/types/assessment";
import { MonitorScope } from "shared/types/assessment/monitor";
import { assertNever } from "utils/assert";

import { HasAddTerm, ShowHideTerm } from "./ShowHide";

const awsLabel = (policyStatement: string | undefined) => {
  if (!policyStatement) return null;
  const [policy, statement] = policyStatement.split(":");
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {policy}
      {statement && (
        <Typography.Text type="secondary">
          {isNaN(+statement) ? statement : `Statement ${statement}`}
        </Typography.Text>
      )}
    </div>
  );
};

const k8sLabel = (permissionSet: string) => {
  const splitIndex = permissionSet.lastIndexOf(":");
  const roleId = permissionSet.substring(0, splitIndex);
  const ruleIndex = permissionSet.substring(splitIndex + 1);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {roleId}
      <Typography.Text type="secondary">{`Rule ${ruleIndex}`}</Typography.Text>
    </div>
  );
};

export const permissionSetName = (
  integration: MonitorScope,
  permissionSet: string | undefined
) =>
  permissionSet
    ? integration === "all"
      ? "Privilege set"
      : integration === "aws"
      ? awsLabel(policyName(permissionSet))
      : integration === "gcloud"
      ? roleName(permissionSet)
      : integration === "k8s"
      ? k8sLabel(permissionSet)
      : integration === "workspace" || integration === "all"
      ? permissionSet
      : assertNever(integration)
    : undefined;

export const PermissionSet: React.FC<
  {
    permissionSet?: string;
    integration?: AssessmentScopeIntegration;
  } & HasAddTerm
> = ({ permissionSet, integration, ...props }) => {
  const name = permissionSetName(
    integration ?? ALL_SCOPE_SENTINEL,
    permissionSet
  );
  return name ? (
    <ClipDiv>
      <GraphTooltip
        width="fit-content"
        title={
          <>
            {name}
            <ShowHideTerm
              term={`role:"${permissionSet}"`}
              name="roles"
              {...props}
            />
          </>
        }
      >
        {name}
      </GraphTooltip>
    </ClipDiv>
  ) : null;
};

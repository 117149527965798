import { Spin } from "antd";
import { useContext } from "react";
import { useParams } from "react-router";

import { NotFound } from "../App/NotFound";
import { AppRoutes } from "../App/routeConstants";
import { SelectedAssessmentNavigation } from "./components/SelectedAssessmentNavigation";
import { SelectedAssessmentContext } from "./contexts/SelectedAssessmentContext";

export const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { orgSlug } = useParams();
  const {
    assessment: { doc, loading },
  } = useContext(SelectedAssessmentContext);

  return loading ? (
    <Spin />
  ) : doc !== undefined ? (
    <SelectedAssessmentNavigation>{children}</SelectedAssessmentNavigation>
  ) : (
    <NotFound
      title="IAM Assessment Not Found"
      backButton={{
        label: "Back to all assessments",
        navigateTo: `/o/${orgSlug}/${AppRoutes.IamAssessment}`,
      }}
    />
  );
};

import { InfoCircleTwoTone } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { CommandDisplay } from "components/Integrations/CommandDisplay";
import { FrontendInstallContext } from "install/types";
import yaml from "js-yaml";
import { compact } from "lodash";
import { ReactNode } from "react";
import { ConfigOf, Instructions } from "shared/install/types";
import { arnInfo } from "shared/integrations/resources/aws/util";
import {
  P0_CLUSTER_ROLE,
  P0_CLUSTER_ROLE_BINDING,
} from "shared/integrations/resources/kubernetes/constants";
import { toClientId } from "shared/integrations/resources/kubernetes/id-util";
import {
  KubernetesBaseConfig,
  KubernetesComponents,
  KubernetesHosting,
  KubernetesIamWriteConfig,
  KubernetesIamWriteProxyConfig,
} from "shared/integrations/resources/kubernetes/types";
import { isDefined } from "shared/types/is";
import { getEnvironment } from "utils/environment";

import {
  P0PerimeterConfiguration,
  P0PerimeterDeployment,
  P0ProxyDeployment,
  P0ProxyVolumeClaim,
  P0_BOUNDARY_SERVICE,
} from "../constants";
import { K8sInstaller } from "./types";

const { Paragraph, Text } = Typography;

const envToTunnelHost = (environment: ReturnType<typeof getEnvironment>) => {
  return new URL(environment.apiUrl({ external: true })).host;
};

const awsCommands = {
  eksDriverCommand: (region: string, clusterName: string) =>
    region && clusterName
      ? `ADDON=$(aws eks list-addons --region=${region} --cluster-name ${clusterName} | jq '.addons | select(index("aws-ebs-csi-driver"))')
if [ -z "$ADDON" ]
then
eksctl utils associate-iam-oidc-provider --region=${region} --cluster=${clusterName} --approve && \
eksctl create iamserviceaccount \
--name ebs-csi-controller-sa \
--namespace kube-system \
--region ${region} \
--cluster ${clusterName} \
--attach-policy-arn arn:aws:iam::aws:policy/service-role/AmazonEBSCSIDriverPolicy \
--approve \
--role-only \
--role-name AmazonEKS_EBS_CSI_DriverRole && \
eksctl create addon --name aws-ebs-csi-driver --region ${region} --cluster ${clusterName} --service-account-role-arn arn:aws:iam::$(aws sts get-caller-identity --query Account --output text):role/AmazonEKS_EBS_CSI_DriverRole --force
fi`
      : `Please provide aws region of the cluster.`,
};

export const proxyCommand = (
  clusterId: string,
  item: Partial<KubernetesIamWriteProxyConfig>,
  slug: string
) => {
  const clientId = toClientId({
    org: { slug },
    clusterId,
  });

  const tunnelHost = envToTunnelHost(getEnvironment());
  return `kubectl apply -f - <<EOF
apiVersion: v1
kind: Namespace
metadata:
  name: p0-security
---

${
  // TODO: Fix hosting identification
  item.hosting
    ? yaml.dump(P0ProxyVolumeClaim(item.hosting.type), { flowLevel: 3 })
    : ""
}
---

${yaml.dump(P0ProxyDeployment(clientId, item, tunnelHost), {
  flowLevel: 3,
})}
EOF`;
};

export const k8sCommand = (
  base: KubernetesBaseConfig
) => `kubectl apply -f - <<EOF
apiVersion: v1
kind: Namespace
metadata:
  name: p0-security

---

apiVersion: v1
kind: ServiceAccount
metadata:
  name: p0-service-account
  namespace: p0-security

---

apiVersion: v1
kind: Secret
metadata:
  name: p0-service-account-secret
  namespace: p0-security
  annotations:
    kubernetes.io/service-account.name: p0-service-account
type: kubernetes.io/service-account-token

---

${yaml.dump(P0_CLUSTER_ROLE, { flowLevel: 3 })}
---

${yaml.dump(P0_CLUSTER_ROLE_BINDING, { flowLevel: 3 })}
---

${yaml.dump(P0PerimeterDeployment(base.serverKey, base.serverCert), {
  flowLevel: 8,
})}
---

${yaml.dump(P0_BOUNDARY_SERVICE, { flowLevel: 4 })}
---

${yaml.dump(P0PerimeterConfiguration(base.caBundle), { flowLevel: 5 })}

EOF`;

const sharedCommands = (
  clusterName: string,
  item: Partial<KubernetesIamWriteConfig>,
  base: KubernetesBaseConfig,
  orgSlug: string
) => {
  const { connectivity } = item;
  return compact([
    connectivity?.type === "proxy"
      ? {
          header: <Paragraph>Setup braekhus proxy:</Paragraph>,
          command: proxyCommand(
            clusterName,
            { ...item, connectivity },
            orgSlug
          ),
        }
      : undefined,
    {
      header: (
        <Paragraph>
          Setup P0 admission controller, then click &quot;Next&quot;:
        </Paragraph>
      ),
      command: k8sCommand(base),
    },
  ]);
};

const providerInstructions: {
  [keyof in KubernetesHosting]?: { helpInstruction?: JSX.Element };
} = {
  aws: {
    helpInstruction: (
      <Paragraph>
        Provide the region and cluster name below for commands that enable the
        EBS CSI driver. See&nbsp;
        <Link
          href={"https://docs.aws.amazon.com/eks/latest/userguide/ebs-csi.html"}
        >
          Amazon EBS CSI driver
        </Link>
        &nbsp;documentation for more information.
      </Paragraph>
    ),
  },
};

const sharedInstructions = (
  component: FrontendInstallContext<ConfigOf<KubernetesComponents>>,
  id: string,
  item: KubernetesIamWriteConfig
) => {
  const { orgData } = component;
  const base = component.config.base[id];
  return {
    help: (
      <>
        <Paragraph>
          {item.connectivity?.type === "proxy" &&
            providerInstructions[item.hosting.type]?.helpInstruction}
        </Paragraph>
        <Paragraph>
          Run these kubectl commands to deploy the{" "}
          {item.connectivity?.type === "proxy" && <>braekhus proxy and</>} P0
          security perimeter{" "}
        </Paragraph>
      </>
    ),
    commands: {
      shell: [
        item.hosting?.type === "aws" && item.connectivity?.type === "proxy"
          ? {
              header: <Paragraph>Set up the AWS EKS CSI driver:</Paragraph>,
              // TODO: NO
              command: awsCommands.eksDriverCommand(
                arnInfo(item.hosting.arn).region ?? "",
                id
              ),
            }
          : undefined,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...sharedCommands(id, item, base, orgData!.slug),
      ].filter(isDefined),
    },
  };
};

export const k8sInstaller: K8sInstaller = {
  base: {
    items: {},
  },
  "iam-write": {
    instructions: (component, id, item): Instructions<ReactNode> => {
      const helper = sharedInstructions(component, id, item);

      return {
        help: helper?.help,
        commands: helper?.commands,
      };
    },
    items: {
      endpoint: {
        labeler: () => (
          <span>
            Server API endpoint&nbsp;
            <Tooltip
              title={
                <ul>
                  <li>
                    AWS EKS:
                    <br />
                    Server API endpoint
                  </li>
                  <li>
                    Azure AKS:
                    <br />
                    API server address
                  </li>
                  <li>
                    GCloud GKE:
                    <br />
                    External or Internal endpoint
                  </li>
                </ul>
              }
            >
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        ),
      },
      ca: {
        labeler: () =>
          "Find in the 'clusters' section of an existing client kube config file",
      },
      connectivity: {
        options: {
          proxy: {
            items: {
              publicJwk: {
                labeler: () => (
                  <>
                    Access the pod with <Text code>kubectl</Text> and retrieve
                    the contents of the <Text code>jwk.public.json</Text> file:
                    <br />
                    <CommandDisplay
                      commands={`kubectl exec -it deploy/p0-braekhus-proxy -n p0-security -c braekhus -- cat /p0-files/jwk.public.json | jq -Mr`}
                    />
                  </>
                ),
              },
            },
          },
          public: {},
        },
      },
      hosting: {
        options: {
          aws: { items: { arn: {} } },
          azure: {},
          gcp: {},
        },
      },
      token: {
        labeler: () => (
          <>
            Retrieve the token of the{" "}
            <Text code>p0-service-account-secret</Text> secret: <br />
            <CommandDisplay
              commands={`echo $(kubectl get secret p0-service-account-secret -o json -n p0-security | jq -r '.data.token' | base64 -d)`}
            />
          </>
        ),
      },
    },
  },
};

import { max } from "../../graph/aggregate";
import { Reducers } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import { PrincipalAggregates } from "../../types/assessment/data/principal";
import { grantAggregate, keyAggregate, permissionAggregate } from "./shared";

export const principalReducers: Reducers<AssessmentNodes, PrincipalAggregates> =
  {
    credentials: keyAggregate("credential"),
    lastAuthEvent: max("credential", (n) => n.lastAuthnTime),
    grants: grantAggregate,
    permissions: permissionAggregate,
    risks: keyAggregate("risk"),
    consumers: keyAggregate("consumer"),
  };

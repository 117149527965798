import { DatePicker, Segmented } from "antd";
import { Prefix } from "components/GraphTable/Prefix";
import { SpaceBetweenDiv } from "components/divs";
import { Moment } from "moment";
import moment from "moment";
import { useCallback, useContext } from "react";

import { FindingsContext } from "../contexts/FindingsContext";
import { FindingsExport } from "./FindingsExport";
import { ScopeSelect, ScopeSelectProps } from "./ScopeSelect";

export const FindingsSelect: React.FC<
  ScopeSelectProps & {
    includeExport?: true;
    includeRange?: true;
  }
> = ({ includeExport, includeRange, ...scopeSelectProps }) => {
  const { trigger, setTrigger, state, setState, range, setRange } =
    useContext(FindingsContext);

  const updateTriggerQuery = useCallback(
    (trigger: number | string) => setTrigger(String(trigger)),
    [setTrigger]
  );
  const updateStateQuery = useCallback(
    (state: number | string) => setState(String(state)),
    [setState]
  );
  const disabledRange = useCallback(
    (date?: Moment) =>
      !!date &&
      (date.isBefore(moment().startOf("day").subtract(30, "day")) ||
        date.isAfter(moment().endOf("day"))),
    []
  );
  const onUpdateRange = useCallback(
    (range: [Moment | null, Moment | null] | null) => {
      if (!range) setRange([undefined, undefined]);
      else {
        const [start, end] = range;
        setRange([start?.toDate(), end?.toDate()]);
      }
    },
    [setRange]
  );

  return (
    <SpaceBetweenDiv>
      <Prefix
        prefix="Findings"
        style={{ width: "initial", alignItems: "baseline" }}
      >
        <Segmented
          options={[
            { label: "All", value: "all" },
            { label: "New", value: "new" },
          ]}
          onChange={updateTriggerQuery}
          value={trigger}
        />
      </Prefix>
      <Segmented
        options={[
          { label: "Open", value: "open" },
          { label: "Ignored", value: "ignored" },
          { label: "Resolved", value: "resolved" },
        ]}
        onChange={updateStateQuery}
        value={state}
      />
      <ScopeSelect {...scopeSelectProps} />
      {includeExport && <FindingsExport />}
      {includeRange && (
        <DatePicker.RangePicker
          allowEmpty={[true, true]}
          disabledDate={disabledRange}
          value={[
            range[0] ? moment(range[0]) : null,
            range[1] ? moment(range[1]) : null,
          ]}
          onChange={onUpdateRange}
        />
      )}
    </SpaceBetweenDiv>
  );
};

import { Spin } from "antd";
import * as React from "react";
import styled from "styled-components";

import { Splash } from "../Splash";

const StyledSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader: React.FC = () => {
  return (
    <Splash>
      <StyledSpinnerContainer>
        <Spin />
      </StyledSpinnerContainer>
    </Splash>
  );
};

export default Loader;

import { GoogleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";

import { useCreateAccountPageStore } from "../../../store/createAccountStore";
import { validateWorkEmailDomain } from "../utils";
import { EmailButton } from "./styles";

type Props = {
  handleGoogleSignUp: () => void;
  onEmailSignUp: ({ email }: { email: string }) => void;
};

const SignUpForm: React.FC<Props> = ({ handleGoogleSignUp, onEmailSignUp }) => {
  const { isGoogleSSOLoading } = useCreateAccountPageStore();
  const flags = useFlags();

  return (
    <>
      <Form onFinish={onEmailSignUp} layout="vertical">
        <Form.Item
          name="email"
          rules={
            flags.allowWorkEmail
              ? [
                  {
                    validator: validateWorkEmailDomain,
                  },
                ]
              : [
                  {
                    type: "email",
                    message: "The input is not a valid email!",
                  },
                ]
          }
        >
          <Input
            type="email"
            placeholder="Enter your business email address"
            size="large"
          />
        </Form.Item>
        <EmailButton htmlType="submit" size="large">
          Sign In with Email
        </EmailButton>
      </Form>
      <Divider plain>OR CONTINUE WITH</Divider>
      <Button
        block
        size="large"
        icon={<GoogleOutlined />}
        loading={isGoogleSSOLoading}
        onClick={handleGoogleSignUp}
      >
        Google
      </Button>
    </>
  );
};

export default SignUpForm;

export const colors = {
  primary: {
    "00": "#e6f7ff",
    "40": "#40a9ff",
    "50": "#1890ff",
    "60": "#096dd9",
    "100": "#001a33",
  },
  neutral: {
    "00": "#ffffff",
    "10": "#f5f5f5",
    "15": "#f0f0f0",
    "20": "#d9d9d9",
    "30": "#bfbfbf",
    "40": "#8c8c8c",
    "50": "#595959",
    "60": "#434343",
    "70": "#262626",
    "80": "#1f1f1f",
    "90": "#141414",
    "100": "#000000",
  },
  success: {
    "00": "#f6ffed",
    "30": "#95de64",
    "40": "#73d13d",
    "50": "#52c41a",
    "60": "#389e0d",
    "70": "#237804",
    "100": "#092b00",
  },
};

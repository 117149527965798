import { ColumnType } from "antd/lib/table";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { Link } from "react-router-dom";
import { Node } from "shared/graph/types";

export const DetailsColumn = (
  fromData: (data: Node<any, any>) => { key: string; to: string } | undefined
): ColumnType<Node<any, any>> => ({
  align: "center" as const,
  title: "",
  render: (_: any, data: Node<any, any>) => {
    const props = fromData(data);
    return (
      props && (
        <GraphTooltip title="Click for details">
          <Link {...props}>view</Link>
        </GraphTooltip>
      )
    );
  },
});

import { Typography } from "antd";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { MfaStatus, PrincipalNode } from "shared/types/assessment/data";

import { HasAddTerm, ShowHideTerm } from "./ShowHide";

const Mfa: React.FC<{ mfa?: MfaStatus }> = ({ mfa }) =>
  mfa === "enabled" ? <>✅</> : mfa === "disabled" ? <>❌</> : <>❓</>;

export const MfaCell: React.FC<{ node: PrincipalNode } & HasAddTerm> = ({
  node,
  ...props
}) =>
  node.data.mfa ? (
    <GraphTooltip
      width="fit-content"
      title={
        <ShowHideTerm
          term={`principal:mfa:"${node.data.mfa}"`}
          name="MFA statuses"
          {...props}
        />
      }
    >
      <div>
        <Mfa mfa={node.data.mfa} />
      </div>
    </GraphTooltip>
  ) : null;

export const MfaTitle: React.FC = () => (
  <GraphTooltip
    title={
      <>
        <Typography.Paragraph>
          Displays whether MFA is enabled for each user.
        </Typography.Paragraph>
        <Typography.Paragraph type="secondary" style={{ fontSize: "small" }}>
          Requires P0 to be connected to your user directory.
        </Typography.Paragraph>
      </>
    }
  >
    MFA?
  </GraphTooltip>
);

import { Button, Card, Input, Space, Typography } from "antd";
import * as firestore from "firebase/firestore";
import React, { useContext, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { DB, useFirestoreDoc } from "../../providers/FirestoreProvider";
import { Splash } from "../Splash";
import "./DeviceAuth.less";
import { useUser } from "./hook";
import { Tenant } from "./util";

type DeviceData = {
  deviceCode: string;
  userCode: string;
  validUntil: number;
};

export const DeviceAuth: React.FC<object> = () => {
  const tenantId = useContext(Tenant);
  const { authCredential } = useUser();
  const [params] = useSearchParams();
  const deviceCode = params.get("device_code");
  const [isDone, setIsDone] = useState(false);

  const device = useFirestoreDoc<DeviceData>(
    `/o/${tenantId}/cli/${deviceCode}`
  );

  const expectedUserCode = useMemo(
    () => device.doc?.data.userCode,
    [device.doc]
  );

  const onAuth = useMemo(
    () => async () => {
      try {
        if (deviceCode === null)
          throw new Error("missing `device_code` query parameter");

        if (authCredential === undefined) throw new Error("user not logged in");
        const grantedBy = Object.fromEntries(
          Object.entries(
            "toJSON" in authCredential ? authCredential.toJSON() : {}
          ).filter(([, v]) => v !== undefined)
        );
        await firestore.setDoc(
          firestore.doc(
            DB,
            `/o/${tenantId}/cli/${deviceCode}/grant-flows`,
            "current"
          ),
          { grantedAt: Date.now(), grantedBy }
        );
        setIsDone(true);
      } catch (err) {
        alert(`Can not authorize:\n${err}`);
        console.error(err);
        return;
      }
    },
    [authCredential, deviceCode, tenantId]
  );

  if (deviceCode === null) {
    return (
      <Splash>
        Oh no, something went wrong! Please contact support@p0.dev and share
        this URL:
        <br />
        {window.location.href}
      </Splash>
    );
  }
  return (
    <Splash>
      {isDone ? (
        <Card
          title={
            <>
              <Typography.Text code>p0cli</Typography.Text> authorized
            </>
          }
        >
          You can close this browser tab.
        </Card>
      ) : (
        <Card
          title={
            <>
              Authorize your <Typography.Text code>p0cli</Typography.Text>{" "}
              installation
            </>
          }
        >
          <Space
            style={{
              display: "flex",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <div>Confirm that this is the code shown by the CLI:</div>
            <Input
              size="large"
              contentEditable={false}
              maxLength={expectedUserCode?.length}
              className="DeviceAuth__input"
              value={expectedUserCode}
            />
            <Button type="primary" onClick={onAuth}>
              Authorize
            </Button>
          </Space>
        </Card>
      )}
    </Splash>
  );
};

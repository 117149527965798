import { useCallback, useContext, useEffect, useState } from "react";
import { anyIsInstalled } from "shared/install/installed";
import { DirectoryGroup } from "shared/types/directory";

import { Tenant } from "../components/Login";
import { AuthFetch } from "../components/Login/hook";
import * as firestoreProvider from "../providers/FirestoreProvider";
import { OktaIntegration } from "../shared/integrations/directories/okta/types";

/***
 * useOktaGroups Hook
 * @param authFetch - A function that will make an authenticated request to the api backend
 * @returns {Object} - {
 * oktaData: OktaIntegration,
 * loading: boolean,
 * refresh: () => Promise<string[]>,
 * }
 * @description - This hook will return the okta integration data, a boolean indicating if the data is loading, and a function that will refresh the okta groups
 * It will return the following:
 * groups - DirectoryGroup[] - the groups object from the backend
 * loading - boolean - true if the hook is currently fetching data from the firebase
 * refresh - () => Promise<string[]> - a function to refresh the Okta groups
 */
export const useOktaGroups = (authFetch: AuthFetch) => {
  const [groups, setGroups] = useState<DirectoryGroup[]>();
  const tenantId = useContext(Tenant);
  const integrationData = firestoreProvider.useFirestoreDoc<OktaIntegration>(
    `o/${tenantId}/integrations/okta`,
    { live: true }
  );
  const refresh = useCallback(async () => {
    if (!integrationData.doc || !anyIsInstalled(integrationData.doc?.data)) {
      return;
    }
    const response = await authFetch(`integrations/okta/refresh`, {
      method: "POST",
    });
    if (!response) return;
    const { groups } = await response.json();
    setGroups(groups);
  }, [authFetch, integrationData.doc]);

  useEffect(() => {
    refresh().catch(console.error);
  }, [integrationData.doc?.data, refresh]);

  return { groups, loading: integrationData.loading, refresh };
};

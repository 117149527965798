import { useContext } from "react";
import { useParams } from "react-router";

import { Tenant } from "../../Login";
import { RequestLogs } from "../components/RequestLogs";
import { RequestNotFound } from "../components/RequestNotFound";

export const RequestDetail: React.FC = () => {
  const { orgSlug, requestId } = useParams();
  const tenantId = useContext(Tenant);
  if (!requestId) return <RequestNotFound orgSlug={orgSlug} />;
  return <RequestLogs requestId={requestId} tenantId={tenantId} />;
};

import { PaginatedListDisplay } from "components/common/PaginatedList";
import { ReactNode } from "react";

const WHY_PAGE_SIZE = 10;

export const ReasonsDisplay: React.FC<{
  reasons: ReactNode[][] | string[];
}> = ({ reasons }) => (
  <PaginatedListDisplay items={reasons} pageSize={WHY_PAGE_SIZE} />
);

import { Space, Tabs, TabsProps, Typography } from "antd";
import { useMemo } from "react";

import {
  EntitlementDiff,
  PrivilegeDiff,
  SnowflakeDriftEnforcementDocument,
} from "../types";
import {
  EntitlementEnforcementTable,
  PrivilegeEnforcementTable,
} from "./Tables";

const driftEnforcementItems: (
  privilegeEnforcements: (PrivilegeDiff & { success: boolean })[],
  entitlementEnforcements: (EntitlementDiff & {
    success: boolean;
  })[]
) => TabsProps["items"] = (
  privilegeEnforcements: (PrivilegeDiff & { success: boolean })[],
  entitlementEnforcements: (EntitlementDiff & {
    success: boolean;
  })[]
) => [
  {
    key: "privilegeDiffs",
    label: `Privilege Differences`,
    children: (
      <PrivilegeEnforcementTable
        privilegeEnforcements={privilegeEnforcements}
      />
    ),
  },
  {
    key: "entitlementDiffs",
    label: `Entitlement Differences`,
    children: (
      <EntitlementEnforcementTable
        entitlementEnforcements={entitlementEnforcements}
      />
    ),
  },
];

export const DriftEnforcementVisualizer: React.FC<{
  doc: SnowflakeDriftEnforcementDocument;
}> = ({ doc }) => {
  const {
    success: successPrivilegeEnforcements,
    failure: failurePrivilegeEnforcements,
  } = doc.privileges;
  const {
    success: successEntitlementEnforcements,
    failure: failureEntitlementEnforcements,
  } = doc.entitlements;

  const computedPrivileges = useMemo(() => {
    const combinedPrivileges = [
      ...(successPrivilegeEnforcements ?? []).map((priv) => ({
        ...priv,
        success: true,
      })),
      ...(failurePrivilegeEnforcements ?? []).map((priv) => ({
        ...priv.req,
        success: false,
      })),
    ];

    return combinedPrivileges;
  }, [successPrivilegeEnforcements, failurePrivilegeEnforcements]);
  const computedEntitlements = useMemo(() => {
    const combinedEntitlements = [
      ...(successEntitlementEnforcements ?? []).map((entitlement) => ({
        ...entitlement,
        success: true,
      })),
      ...(failureEntitlementEnforcements ?? []).map((entitlement) => ({
        ...entitlement.req,
        success: false,
      })),
    ];

    return combinedEntitlements;
  }, [successEntitlementEnforcements, failureEntitlementEnforcements]);
  return (
    <Space direction="vertical">
      <Typography.Title level={5}>Enforcement Results</Typography.Title>
      {computedEntitlements.length === 0 && computedPrivileges.length === 0 ? (
        <div>No Drifts remediated</div>
      ) : (
        <div data-testid="drift-enforcement-visualizer">
          <div>The following enforcements are performed</div>
          <Tabs
            items={driftEnforcementItems(
              computedPrivileges,
              computedEntitlements
            )}
          />
        </div>
      )}
    </Space>
  );
};

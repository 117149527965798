import { reduce } from "lodash";
import { createContext, useEffect, useMemo } from "react";
import {
  DirectoryToIntegrationConfig,
  IntegrationConfig,
} from "shared/integrations/shared";
import { directoryLabels } from "shared/types/workflow/constants";
import { Directory } from "shared/types/workflow/types";
import { widetype } from "shared/util/collections";
import { useDirectoriesStore } from "store/resources/directoriesStore";

import { useFirestoreCollection } from "./FirestoreProvider";

type ResourcesStore = {
  directoryIntegrations: DirectoryToIntegrationConfig | null;
};

export const Resources = createContext<ResourcesStore>({
  directoryIntegrations: null,
});

export const ResourcesProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { setAvailableDirectories } = useDirectoriesStore();

  const integrations = useFirestoreCollection<IntegrationConfig>(
    "integrations",
    {
      live: true,
      tenantAware: true,
    }
  );

  const directoryIntegrations = useMemo(() => {
    if (!integrations) return null;
    return reduce(
      integrations ?? [],
      (acc, i) => {
        acc[i.id as Directory] = i.data;
        return acc;
      },
      {} as DirectoryToIntegrationConfig
    );
  }, [integrations]);

  useEffect(() => {
    if (integrations) {
      setAvailableDirectories(
        widetype
          .keys(directoryLabels)
          .filter((key) => integrations.some((i) => i.id === key))
      );
    }
  }, [integrations, setAvailableDirectories]);

  return (
    <Resources.Provider
      value={{
        directoryIntegrations,
      }}
    >
      {children}
    </Resources.Provider>
  );
};

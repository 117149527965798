import { Dictionary } from "lodash";

export const resourceIconUrl: Dictionary<string> = {
  controlplane:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/infrastructure_components/unlabeled/control-plane.svg",
  configmaps:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/cm.svg",
  cronjobs:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/cronjob.svg",
  deployments:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/deploy.svg",
  endpoints:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/ep.svg",
  endpointslices:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/ep.svg",
  horizontalpodautoscalers:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/hpa.svg",
  ingresses:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/ing.svg",
  jobs: "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/job.svg",
  networkpolicies:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/netpol.svg",
  namespaces:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/ns.svg",
  nodes:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/infrastructure_components/unlabeled/node.svg",
  pods: "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/pod.svg",
  secrets:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/secret.svg",
  services:
    "https://raw.githubusercontent.com/kubernetes/community/6ec7d3f29241b81c5a78b0644375499be601e225/icons/svg/resources/unlabeled/svc.svg",
};

import { Spin } from "antd";
import {
  FrontendComponentInstaller,
  FrontendInstallContext,
  FrontendInstallDomain,
} from "install/types";
import { isEqual, omit } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  Component,
  Element,
  ElementInstaller,
  Instructions as InstructionsType,
} from "shared/install/types";

import { Instructions } from "./Instructions";

type DiffInstuctionsProps = {
  after: any;
  before: any;
  component: Component<any>;
  componentKey: string;
  context: FrontendInstallContext<any>;
  id: string;
  installer: FrontendComponentInstaller<any>;
  isFetching: boolean;
  item: any;
  onFinish: () => void;
};

type InstructionStates = Record<string, Record<string, any>>;

const itemInstructions = (
  props: Omit<DiffInstuctionsProps, "component" | "installer"> & {
    component: Element;
    installer: ElementInstaller<FrontendInstallDomain<any>, any, any, any>;
    state: Record<string, any>;
    setState: (value: Record<string, any>) => void;
  }
) => {
  const { after, component, context, id, item, installer, state, setState } =
    props;
  const instructions: InstructionsType<ReactNode>[] = [];
  if (installer.instructions) {
    instructions.push(
      installer.instructions(
        context,
        id,
        item,
        after,
        state,
        setState
      ) as InstructionsType<ReactNode>
    );
  }
  if ("options" in installer && "options" in component) {
    const option = after.type;
    if (!option || !(option in installer.options)) return instructions;
    const optionInstaller = installer.options[option];
    if (!optionInstaller) return instructions;
    const setOptionsState = (value: InstructionStates) =>
      setState({ ...state, _options: value });
    instructions.push(
      ...componentInstructions({
        ...props,
        componentKey: option,
        component: component.options[option],
        installer: optionInstaller as any,
        states: state._options ?? {},
        setStates: setOptionsState,
      })
    );
  }
  return instructions;
};

const componentInstructions = (
  props: DiffInstuctionsProps & {
    states: InstructionStates;
    setStates: (value: InstructionStates) => void;
  }
): InstructionsType<ReactNode>[] => {
  const { after, before, component, context, id, installer, item } = props;
  const instructions: InstructionsType<ReactNode>[] = [];
  if (installer.instructions)
    instructions.push(
      (installer as any).instructions(context, id, item, after)
    );
  if (!("items" in installer)) return instructions;

  const setSingleState = (key: string) => (value: Record<string, any>) =>
    props.setStates({ ...props.states, [key]: value });

  for (const key of Object.keys(installer.items)) {
    const subAfter = after[key];
    const subBefore = before?.[key];
    if (!isEqual(subAfter, subBefore)) {
      const subInstaller = installer.items[key];
      if (!subInstaller) continue;
      instructions.push(
        ...itemInstructions({
          ...props,
          after: subAfter,
          before: subBefore,
          componentKey: key,
          component: component.schema[key],
          installer: subInstaller,
          state: props.states[key] ?? {},
          setState: setSingleState(key),
        })
      );
    }
  }
  return instructions;
};

/** Displays instructions for any individual config diffs
 *
 * Instructions are displayed one at a time; after the final instruction control
 * is returned to the outer element (via the `onFinish` prop).
 *
 * If there are no diffs, returns control immediately.
 */
export const DiffInstructions: React.FC<DiffInstuctionsProps> = (props) => {
  const { installer, isFetching, onFinish } = props;
  const [step, setStep] = useState(0);
  const [states, setStates] = useState<Record<string, Record<string, any>>>({});

  const instructions = useMemo(
    () =>
      componentInstructions({
        ...props,
        // Top-level instructions are shown prior to config
        installer: omit(installer, "instructions"),
        states,
        setStates,
      }),
    [installer, props, states, setStates]
  );

  const advance = useCallback(() => {
    setStep(step + 1);
    if (step >= instructions.length - 1) onFinish();
  }, [instructions.length, onFinish, step]);
  useEffect(() => {
    if (instructions.length === 0) onFinish();
  }, [instructions.length, onFinish]);

  return step < instructions.length ? (
    <Instructions
      instructions={instructions[step]}
      isFetching={isFetching}
      onNext={advance}
    />
  ) : (
    <Spin />
  );
};

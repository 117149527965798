import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const Page: React.FC<React.PropsWithChildren<{ title: string }>> = ({
  children,
  title,
}) => {
  useEffect(() => {
    document.title = `Security - ${title}`;
  }, [title]);
  return children ? <>{children}</> : <Outlet />;
};

import pluralize from "pluralize";

import { EXPIRY_OPTIONS_SECONDS } from "../configuration/constant";
import { ApprovalExpiryOption } from "../configuration/types";
import { ValidationError } from "../types/error";
import { PermissionRequest } from "../types/permission";

const acceptedDurationUnitsBase = ["hour", "minute", "day", "week", "second"]; // "second" is only used in e2e tests
export const acceptedDurationUnits = acceptedDurationUnitsBase.reduce(
  (acc, unit) => [...acc, unit, pluralize(unit)],
  [] as string[]
);

// Units must match moment.unitOfTime.Base values from moment.js
// This conversion takes the first letter to get one of: "h", "m", "d", "w", "s" (only used in e2e tests)
export const convertBaseToUnit = (base: string) => base.slice(0, 1);
export const EXPIRY_PATTERN = new RegExp(
  `(\\d+)(${acceptedDurationUnitsBase.map(convertBaseToUnit).join("|")})`
);
export const CUSTOM_EXPIRY_PATTERN = new RegExp(
  `^(\\d+)\\s+(${acceptedDurationUnits.join("|")})$`
);

const expiryUnitConversionRatios = {
  d: 24,
  w: 24 * 7,
};
export const expiryUnitLabels = {
  m: "minute",
  h: "hour",
  d: "day",
  w: "week",
  // Exclude "second" because it's only used in e2e tests
};

export const isValidExpiryUnit = (expiry: string) => {
  return EXPIRY_PATTERN.test(expiry);
};

export function expiryUnit(option: ApprovalExpiryOption): string;
export function expiryUnit(time: number | string, unit: string): string;
export function expiryUnit(
  arg1: ApprovalExpiryOption | number | string,
  arg2?: string
): string {
  if (typeof arg1 === "object") {
    return expiryUnit(arg1.time, arg1.unit);
  }
  return `${arg1}${arg2}`;
}

/**
 * You should use this function with a try-catch block to handle invalid expiry units.
 * @param expiry a string representing an expiry unit in the format of "number" + "unit" (e.g. "1h", "30m", "2d", "1w"). Only accepts "m", "h", "d", "w" as units.
 * @returns a object representing the expiry unit, with week or day values converted to hours.
 */
export const convertExpiryUnitToApprovalExpiryOption = (
  expiry: string
): ApprovalExpiryOption => {
  const match = expiry.match(EXPIRY_PATTERN);
  if (!isValidExpiryUnit(expiry) || match === null) {
    throw new ValidationError(
      "convert expiry",
      { expiry },
      "Invalid expiry unit."
    );
  }

  // "Unit" here is implied to be 'm' | 'h' | 'd' | 'w' due to the Regex match above.
  const [_, time, unit] = match;
  let outputTime = parseInt(time);
  if (["d", "w"].includes(unit)) {
    const baseTime = outputTime;
    const baseUnit = expiryUnitLabels[unit as "d" | "w"];
    outputTime *= expiryUnitConversionRatios[unit as "d" | "w"];
    return {
      time: outputTime,
      unit: "h",
      // Since we're multiplying week/day values to get hours, we can guarantee this will be plural.
      value: `${baseTime} ${baseTime === 1 ? baseUnit : pluralize(baseUnit)}`,
    };
  }
  // Make TypeScript happy. This is guaranteed because of the above logic.
  const typedUnit = unit as "h" | "m";
  return {
    time: outputTime,
    unit: typedUnit,
    value: `${outputTime} ${
      outputTime === 1
        ? expiryUnitLabels[typedUnit]
        : pluralize(expiryUnitLabels[typedUnit])
    }`,
  };
};

/**
 *
 * @param expiry a custom expiry string in the format of "number" + "unit" (e.g. "30 minutes", "1 hour", "2 days", "1 week"). Only accepts "minutes", "hours", "days", "weeks" as units.
 * @returns a string representing the expiry unit in the format of "number" + "short unit" (e.g. "30m", "1h", "2d", "1w").
 */
export const parseCustomExpiry = (expiry: string) => {
  const match = expiry.match(CUSTOM_EXPIRY_PATTERN);
  if (!match || match.length < 3) {
    throw new ValidationError(
      "expiry",
      { expiry },
      "Invalid custom expiration format. Please enter a number followed by a unit (e.g., '30 minutes', '1 hour', '2 days', '1 week')."
    );
  }

  const [_, duration, unitMatch] = match;
  const unit = unitMatch.toLowerCase();

  if (!acceptedDurationUnits.includes(unit)) {
    throw new ValidationError(
      "expiry",
      { expiry },
      `Invalid expiration unit. Please use one of the following units: ${acceptedDurationUnits.join(
        ", "
      )}.`
    );
  }

  return expiryUnit(duration, convertBaseToUnit(unit));
};

export const sortApprovalOptions = (
  option1: ApprovalExpiryOption,
  option2: ApprovalExpiryOption
) =>
  option1.time * EXPIRY_OPTIONS_SECONDS[option1.unit] -
  option2.time * EXPIRY_OPTIONS_SECONDS[option2.unit];

export const notificationsAreEnabled = (request: PermissionRequest) =>
  request.approvalDetails?.approvalSource !== "persistent";

export const isCustomExpiry = (expiry: string) => {
  return CUSTOM_EXPIRY_PATTERN.test(expiry);
};

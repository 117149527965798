import { ConfigOf } from "../../../install/types";
import { OktaComponents } from "./components";

export type JwaPair = {
  privateKey: string;
  publicKey: string;
};

/** Matches Okta organization URLs
 *
 * Allows
 * - https://domain.okta.com
 * - https://domainpreview.okta.com
 * - https://domain-emea.okta.com
 * - any of the above without the https
 *
 * Does not allow
 * - https://domain-admin.okta.com
 * - domain-admin.okta.com
 */
export const OKTA_DOMAIN = /^([^:/\n]+)\.okta(preview|-emea)?\.com$/;
// Safari does not support lookahead, so instead error if admin matches
export const OKTA_ADMIN_DOMAIN =
  /^([^:/\n]+)-admin\.okta(preview|-emea)?\.com$/;

export type OktaJwk = Record<string, string>;

export type OktaIntegration = ConfigOf<typeof OktaComponents>;

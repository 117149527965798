import { buildIdpGroupId } from "components/Routing/utils";
import { reduce } from "lodash";
import { Directory, IdpGroup } from "shared/types/workflow/types";
import { LoadingState } from "types/store";
import { create } from "zustand";

import { Directories, DirectoryGroups } from "./types";
import { getBaseDirectoryStore } from "./utils";

type DirectoriesStore = {
  availableGroups: Record<Directory, DirectoryGroups>;
  updateAvailableGroups: (directory: Directory, groups: IdpGroup[]) => void;
  getSquashedGroups: () => IdpGroup[];
  setDirectoryGroupsState: (directory: Directory, state: LoadingState) => void;
  availableDirectories: Directories;
  setAvailableDirectories: (directories: Directory[]) => void;
  isLoading: () => boolean;
};

export const useDirectoriesStore = create<DirectoriesStore>((set, get) => ({
  availableGroups: {
    "azure-ad": getBaseDirectoryStore(),
    okta: getBaseDirectoryStore(),
    workspace: getBaseDirectoryStore(),
  },
  updateAvailableGroups: (directory, groups) => {
    const updated = get().availableGroups[directory];
    const newGroups = reduce(
      groups,
      (memo, group) => {
        memo[buildIdpGroupId(group)] = group;
        return memo;
      },
      {} as DirectoryGroups["groups"]
    );
    updated.groups = newGroups;
    updated.state = "loaded";
    updated.lastUpdated = Date.now();
    set({
      availableGroups: {
        ...get().availableGroups,
        [directory]: updated,
      },
    });
  },
  getSquashedGroups: () => {
    const groups = get().availableGroups;
    return Object.keys(groups).flatMap((key) =>
      Object.values(groups[key as Directory].groups)
    );
  },
  setDirectoryGroupsState: (directory, state) => {
    const updated = get().availableGroups[directory];
    updated.state = state;
    set({
      availableGroups: {
        ...get().availableGroups,
        [directory]: updated,
      },
    });
  },
  availableDirectories: { state: "loading", lastUpdated: 0, directories: [] },
  setAvailableDirectories: (directories) => {
    set({
      availableDirectories: {
        state: "loaded",
        lastUpdated: Date.now(),
        directories,
      },
    });
  },
  isLoading: () => {
    return (
      get().availableDirectories.state === "loading" ||
      Object.values(get().availableGroups).some((g) => g.state === "loading")
    );
  },
}));

import _ from "lodash";

import { Element, InstallSpec, ItemComponent } from "../../../install/types";
import { AWS_TAG_KEY_REGEX, GCLOUD_TAG_KEY_REGEX } from "./types";
import { isAwsId, isGcloudId } from "./util";

export const SshComponents = InstallSpec({
  "iam-write": ItemComponent({
    type: "dynamic",
    label: "SSH Management",
    description: "Allows P0 to provide ssh access to compute instances",
    schema: {
      groupKey: Element({
        label: "Grouping tag (optional)",
        description:
          "If present, compute instances will be grouped by the value of this tag. Access can be requested, in one request, to all instances with a shared tag value.",
        type: "string",
        validator: async (id, field) => {
          if (isAwsId(id)) {
            if (!field.match(AWS_TAG_KEY_REGEX)) {
              return "Invalid AWS TAG Key. Expected a string of 1 to 128 characters";
            }
          }

          if (isGcloudId(id)) {
            if (!field.match(GCLOUD_TAG_KEY_REGEX)) {
              return "Invalid Google Cloud TAG Key. Tag keys must include a project or organization identifier followed by a key name of 1 to 256 characters without \" ' \\ /.  The project or og identifier and key name must be joined by a /";
            }
          }
        },
      }),
      isSudoEnabled: Element({
        label: "Enable requesting sudo access",
        description:
          "If enabled the integration will provide sudo access to the instances",
        type: "switch",
      }),
    },
  }),
});

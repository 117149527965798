import { Alert, Typography } from "antd";
import { FrontendInstallContext } from "install/types";
import { size } from "lodash";
import { Link } from "react-router-dom";
import { installedIds, installedItems } from "shared/install/installed";
import { GCLOUD_SECURITY_PERIMETER_FEATURE_FLAG } from "shared/integrations/resources/gcloud/constants";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";

const { Paragraph } = Typography;

const SharingRestrictionWarning = (props: { projects: string[] }) => (
  <Alert
    showIcon
    type="warning"
    message="Insecure installation"
    style={{
      minWidth: "420px",
      width: "calc(100vw - 260px)",
      maxWidth: "770px",
    }}
    description={
      <>
        <Paragraph>
          You have not yet enabled a domain restriction on some projects:
        </Paragraph>
        <Paragraph>{props.projects.join(", ")}</Paragraph>
        <Paragraph>
          To secure your installation, add a{" "}
          <Link to="./sharing-restriction">
            domain-restricted-sharing component
          </Link>
          .
        </Paragraph>
      </>
    }
  />
);

export const sharingRestrictionWarningLabeler = (
  context: FrontendInstallContext<GcloudIntegration>
) => {
  const sensitiveProjects = installedIds("iam-write", context.config ?? {});
  const protectedProjects = new Set(
    installedIds("sharing-restriction", context.config ?? {})
  );
  const needsRestriction = sensitiveProjects.filter(
    (id) => !protectedProjects.has(id)
  );

  if (!needsRestriction.length) return undefined;

  if (!!size(installedItems("iam-write-security-perimeter", context.config))) {
    return undefined;
  }

  return <SharingRestrictionWarning projects={needsRestriction} />;
};

import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  CloseCircleTwoTone,
  SyncOutlined,
} from "@ant-design/icons";

import { StyledListInfo, StyledStatus } from "../../Assessment/styles";
import { SnowflakeAPIRunDocument } from "../types";

export const RunProgressStatus: React.FC<{ doc: SnowflakeAPIRunDocument }> = ({
  doc,
}) => {
  return (
    <>
      <StyledStatus>
        {doc.type === "ENFORCE" ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <>
            {doc.status === "SUCCESS" ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : doc.status === "ERRORED" || doc.status === "ERRORED_ERRORED" ? (
              <CloseCircleTwoTone twoToneColor="red" />
            ) : (
              <SyncOutlined spin />
            )}
          </>
        )}
        <StyledListInfo>Drift Check</StyledListInfo>
      </StyledStatus>
      <StyledStatus>
        {doc.type === "CHECK" ? (
          <ClockCircleOutlined />
        ) : (
          <>
            {doc.status === "SUCCESS" ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : doc.status === "ERRORED" || doc.status === "ERRORED_ERRORED" ? (
              <CloseCircleTwoTone twoToneColor="red" />
            ) : (
              <SyncOutlined spin />
            )}
          </>
        )}
        <StyledListInfo>Drift Enforcement</StyledListInfo>
      </StyledStatus>
    </>
  );
};

import { ReactElement } from "react";

export const Logo: React.FC<{
  logo: ReactElement | string;
  size?: number;
  title: string;
}> = ({ logo, size, title }) => {
  return typeof logo === "string" ? (
    <img src={logo} alt={`${title} logo`} width={size ?? 20} />
  ) : (
    logo
  );
};

import {
  CheckCircleTwoTone,
  EllipsisOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact } from "lodash";
import React, { ReactNode, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import { IntegrationConfig } from "../../../shared/integrations/shared";
import { useOrgInfoStore } from "../../../store/org-info/useOrgInfoStore";
import { SubscriptionLevel } from "../../../types/entitlement";
import { Logo } from "../Logo";
import { ExtendedIntegrationData, IntegrationData } from "../types";
import { canNavigateToAddIntegration } from "../utils/canNavigateToAddIntegration";
import { getInstallCounts } from "../utils/getInstallCounts";
import { numberOfResourceInstalled } from "../utils/resourcesInstalledUtil";

const columns = (title: string): ColumnsType<ExtendedIntegrationData> => [
  {
    title,
    dataIndex: "label",
    key: "label",
    render: (label, { icon, key }) => (
      <Link to={key}>
        <div style={{ display: "flex", alignItems: "center", gap: "0.7em" }}>
          <Logo title={label} logo={icon} />
          {label}
        </div>
      </Link>
    ),
  },
  {
    title: "Installed?",
    dataIndex: "counts",
    key: "state",
    width: 150,
    render: ({ installed, inProgress, total }) => {
      const isComponent = total > 1;
      return installed === 0 && inProgress === 0 ? (
        <span>
          <EllipsisOutlined style={{ color: "#1890ff" }} />
          &nbsp;Not&nbsp;installed
        </span>
      ) : (
        <>
          {compact([
            installed > 0 ? (
              <div key="installed">
                <CheckCircleTwoTone />
                &nbsp;
                {isComponent && (
                  <span>
                    {installed}/{total}&nbsp;
                  </span>
                )}
                Installed
              </div>
            ) : undefined,
            inProgress > 0 && (
              <div key="in_progress">
                <LoadingOutlined />
                &nbsp;
                {isComponent && (
                  <span>
                    {inProgress}/{total}&nbsp;
                  </span>
                )}
                In&nbsp;progress
              </div>
            ),
          ])}
        </>
      );
    },
  },
];

/** Renders the top-level integration component */
const IntegrationTable: React.FC<{
  title: string;
  data: IntegrationData[];
  installations: Record<string, IntegrationConfig>;
  zeroNotes?: ReactNode;
}> = ({ title, data, installations, zeroNotes }) => {
  const { useSubscriptionPlan } = useOrgInfoStore();
  const subscriptionPlan = useSubscriptionPlan();
  const flags = useFlags();

  const numberOfResourcesSupported = flags.cloudResourcesSupported;

  const extendedData = useMemo(
    () =>
      data.map((d) => {
        const install = installations[d.key] as
          | (IntegrationConfig & { status?: string })
          | undefined;
        return {
          ...d,
          ...{ counts: getInstallCounts(d, install) },
        } as ExtendedIntegrationData;
      }),
    [installations, data]
  );

  const numInstalled = useMemo(
    () => numberOfResourceInstalled(extendedData),
    [extendedData]
  );

  const shouldNotAllowAccess =
    title !== "Resources" &&
    title !== "Notifiers" &&
    subscriptionPlan === SubscriptionLevel.EntitlementStarter;

  const rowClassName = useCallback(
    (record: ExtendedIntegrationData): string => {
      if (shouldNotAllowAccess) {
        return "disabled-row";
      }

      return title === "Resources" &&
        !canNavigateToAddIntegration(
          record,
          numInstalled,
          numberOfResourcesSupported
        )
        ? "disabled-row"
        : "enabled-row";
    },
    [numInstalled, numberOfResourcesSupported, shouldNotAllowAccess, title]
  );

  return (
    <>
      <Table
        className="table-styles"
        columns={useMemo(() => columns(title), [title])}
        dataSource={extendedData}
        pagination={{ position: ["none", "none"] as any }}
        rowClassName={rowClassName}
      />
      {numInstalled === 0 ? zeroNotes : null}
    </>
  );
};

export default IntegrationTable;

import { credentialReducers } from "shared/assessment/reducers/credential";
import { isNode } from "shared/graph/types";

import { credentialColumns } from "./columns";

export const credentialData = {
  predicate: isNode("credential"),
  reducers: credentialReducers,
  columns: credentialColumns,
};

import pluralize from "pluralize";

import { KubernetesResourceName } from "./types";

const SEPARATOR = " / ";

const displayName = (name: string) => (name === "*" ? "All resources" : name);
const displayNamespace = (namespace: string) =>
  namespace === "*" ? "All namespaces" : namespace;
export const displayKind = (kind: string) =>
  kind === "*" ? "All kinds" : pluralize(kind);

export const resourceToFriendly = (resource: KubernetesResourceName) => {
  const items = resource.namespace
    ? [displayNamespace(resource.namespace), displayName(resource.name)]
    : [displayName(resource.name)];
  return items.join(SEPARATOR);
};

import { Descriptions, List } from "antd";
import { OverflowList } from "components/lists";
import { sortBy } from "lodash";
import { isNode } from "shared/graph/types";
import { ResourceNode } from "shared/types/assessment/data";

import { NodeLink } from "../../cells/NodeLink";
import { PrincipalCell, PrincipalLink } from "../../cells/Principal";
import { Resource } from "../../cells/Resource";
import { NodeDescriptions } from "../NodeDescriptions";
import { resourceLabels } from "./labels";

const { Item } = Descriptions;

export const ResourceNodeDisplay: React.FC<{
  node: ResourceNode;
}> = ({ node }) => {
  const parent = node.parents.find((n) => n.type === "resource");
  return (
    <>
      <NodeDescriptions>
        <Item label="Resource">
          <Resource resource={node} />
        </Item>
        <Item label="Parent">
          {parent && isNode("resource")(parent) ? (
            <>
              <NodeLink node={parent} />
              &nbsp;
              <Resource resource={parent} />
            </>
          ) : (
            "(no parent)"
          )}
        </Item>
        <Item label="Children">
          {node.children.length ? (
            <OverflowList>
              {sortBy(node.children, (c) => c.key).map((c, ix) =>
                isNode("resource")(c) ? (
                  <List.Item key={ix}>
                    <NodeLink node={c} />
                    &nbsp;
                    <Resource resource={c} key={ix} />
                  </List.Item>
                ) : null
              )}
            </OverflowList>
          ) : (
            "(no child resources)"
          )}
        </Item>
        <Item label="Accessible by">
          {resourceLabels(node.data).isGeneric ? (
            "(Select a service to view accessors)"
          ) : node.aggregates.principals.length ? (
            <OverflowList>
              {sortBy(node.aggregates.principals, ["principalType", "id"]).map(
                (p, ix) => (
                  <List.Item key={ix}>
                    <PrincipalLink data={p} type="short" show="grant" />{" "}
                    <PrincipalCell principalData={p} />
                  </List.Item>
                )
              )}
            </OverflowList>
          ) : (
            "(Not accessible)"
          )}
        </Item>
      </NodeDescriptions>
    </>
  );
};

import { Dictionary } from "lodash";

// These are manually extracted using icons in AWS console. There's no guarantee that these URLs are stable.
export const serviceIconUrl: Dictionary<string> = {
  // Taken from Alexa Dev Portal
  a4b: "https://m.media-amazon.com/images/G/01/AlexaDevPortal/favicon-16x16._CB1544644669_.png",
  account:
    "https://d3rrzw75sdtfe5.cloudfront.net/icon/2342d9ccd7d52448de0ef187d97c575c-de388fcdf7988c4792628bc8089f788f.svg",
  apigateway:
    "https://d3rrzw75sdtfe5.cloudfront.net/icon/fb0cde6228b21d89ec222b45efec54e7-0856e92285f4e7ed254b2588d1fe1829.svg",
  athena:
    "https://d3rrzw75sdtfe5.cloudfront.net/icon/0454aa97682235df3ed1a3456bc86e62-690ff61a3c9cabacf2d3d79e64e3a8a4.svg",
  cloudformation:
    "https://d2q66yyjeovezo.cloudfront.net/icon/5d3629a64564e611bbeae9b3045be424-e9ebf1d2d9d9c14a684cec1c80e127eb.svg",
  codebuild:
    "https://d2q66yyjeovezo.cloudfront.net/icon/13ee531096ccb4384d55f6b7cc66572b-9f8463d77a472721923c47b01f973d59.svg",
  codecommit:
    "https://d2q66yyjeovezo.cloudfront.net/icon/f302aa78eafe713bb278e61ef3f29ef9-1901ceb3d0fa4648fa6ac2e2aa8559ed.svg",
  codepipeline:
    "https://d2q66yyjeovezo.cloudfront.net/icon/59874d8b5a9e702e16641126cc15e561-8137f94920dd8639de205d20e8e72ad6.svg",
  codestar:
    "https://d3rrzw75sdtfe5.cloudfront.net/icon/3da5e8169d2f1426f99fbef54575fe96-6382cb2dfdd2f74c99bc8a64a338358e.svg",
  ec2: "https://d2q66yyjeovezo.cloudfront.net/icon/d88319dfa5d204f019b4284149886c59-7d586ea82f792b61a8c87de60565133d.svg",
  ecr: "https://d2q66yyjeovezo.cloudfront.net/icon/de7db04805a33606a40b897578543648-c0174badf433f1e0148e43426ae8e43a.svg",
  // EventBridge
  events:
    "https://d3rrzw75sdtfe5.cloudfront.net/icon/16908b0605f2645dfcb4c3a8d248cef3-8fdd092f1116685eeb75b950acb85987.svg",
  glue: "https://d2q66yyjeovezo.cloudfront.net/icon/1ed4de8986cf6e4a68d5a18c135d36f2-83f0853380b5ba846a691333dfd4d3ba.svg",
  iam: "https://d2q66yyjeovezo.cloudfront.net/icon/0ebc580ae6450fce8762fad1bff32e7b-0841c1f0e7c5788b88d07a7dbcaceb6e.svg",
  kinesis:
    "https://d2q66yyjeovezo.cloudfront.net/icon/988287f7a1eb966ffc4e19bdbdeec7c3-a2004c84c8d3476ac6ad6485f44e9d1b.svg",
  kms: "https://d2q66yyjeovezo.cloudfront.net/icon/4951a865fa68d758b42a9cef50ae168a-88cc389f0bffefa8034ab2023186acde.svg",
  lambda:
    "https://d2q66yyjeovezo.cloudfront.net/icon/945f3fc449518a73b9f5f32868db466c-926961f91b072604c42b7f39ce2eaf1c.svg",
  // CloudWatch
  logs: "https://d2q66yyjeovezo.cloudfront.net/icon/8f57ebd825a828e205b2dde223ba17e4-6af63a22dc297f8041286760ee8cd2c9.svg",
  redshift:
    "https://d2q66yyjeovezo.cloudfront.net/icon/33b2042a5120c531aab0d921c3d3686c-d3e9e06b23c57a3260ad218d52391ad3.svg",
  sagemaker:
    "https://d2q66yyjeovezo.cloudfront.net/icon/0c8d8b2a870bf0792528dc54c41799f1-eff89633572cc66bc69c8bed5885bc73.svg",
  secretsmanager:
    "https://d2q66yyjeovezo.cloudfront.net/icon/8915d768b4680de51126e0bf60a2a5d7-9ce11337c80efcb3442238d27794ee30.svg",
  s3: "https://d2q66yyjeovezo.cloudfront.net/icon/c0828e0381730befd1f7a025057c74fb-43acc0496e64afba82dbc9ab774dc622.svg",
  sns: "https://d2q66yyjeovezo.cloudfront.net/icon/6002c6713f40e8a35d365605542e72b0-03c2386fb392e0d689e45d9b4f683a8d.svg",
  sqs: "https://d3rrzw75sdtfe5.cloudfront.net/icon/252a8156d87a671bfeb32a02f200406f-c7d7df285c32f703a63b1d3c7f9679fb.svg",
  // System Manager
  ssm: "https://d2q66yyjeovezo.cloudfront.net/icon/b5c87c140628d6200f5ffeae85895b67-eed4319c5eaa4b84741dd26adcad7c1a.svg",
  // Step Functions
  states:
    "https://d2q66yyjeovezo.cloudfront.net/icon/34d955a0df5f7af9c9b4e4dccb3c3564-b81a255cd22d2aa9087aed8ea35861c1.svg",
};

serviceIconUrl["codestar-connections"] = serviceIconUrl.codestar;
serviceIconUrl.firehose = serviceIconUrl.kinesis;
serviceIconUrl.s3express = serviceIconUrl.s3;

export const fallbackIconUrl = serviceIconUrl.account;

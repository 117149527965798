import { useFirestoreDoc } from "providers/FirestoreProvider";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import { PostgresComponents } from "shared/integrations/resources/postgres/components";

import { Install, InstallProps } from "../Install/Install";
import { pgInstaller } from "./install/install";

export const PostgresIconUrl =
  "https://wiki.postgresql.org/images/a/a4/PostgreSQL_logo.3colors.svg";

export const Pg = (
  props: Pick<
    InstallProps<typeof PostgresComponents>,
    "cardOverride" | "onInstall"
  >
) => {
  const gcloud = useFirestoreDoc<GcloudIntegration>("integrations/gcloud", {
    tenantAware: true,
  });

  return (
    <Install<typeof PostgresComponents>
      components={PostgresComponents}
      installer={pgInstaller(gcloud)}
      integration="pg"
      itemKind="database"
      logo={PostgresIconUrl}
      title={"PostgreSQL"}
      {...props}
    />
  );
};

import { LoadingOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import { VerticalSpacedDiv } from "components/divs";

export type GraphProcessingStep =
  | "boosting"
  | "converting"
  | "done"
  | "loading"
  | "requested";

const STEPS: Record<GraphProcessingStep, { index: number; label: string }> = {
  requested: { index: 0, label: "Requesting IAM graph" },
  loading: { index: 1, label: "Downloading IAM graph" },
  converting: { index: 2, label: "Converting data" },
  boosting: { index: 3, label: "Adding search boost" },
  done: { index: 4, label: "Done" },
};

// 'done' state is not displayed
const MAX_LOADING_INDEX = 3;

export const GraphProcessingStep: React.FC<{ step: GraphProcessingStep }> = ({
  step,
}) => {
  const { index, label } = STEPS[step];
  return (
    <VerticalSpacedDiv style={{ width: "400px" }}>
      {/* -2 as 'done' state is not displayed in progress */}
      <Progress percent={100 * (index / MAX_LOADING_INDEX)} showInfo={false} />
      <div>
        <LoadingOutlined style={{ marginRight: "12px" }} />
        {label}
      </div>
    </VerticalSpacedDiv>
  );
};

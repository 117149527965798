import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";

/** Converts a value whose reference may change every render
 *  into a value that only changes under deep equality check
 */
export const useDeepMemo = <T>(value: T) => {
  const [latest, setLatest] = useState<T>(value);
  useEffect(() => {
    if (!isEqual(value, latest)) {
      setLatest(value);
    }
  }, [value, latest, setLatest]);
  return latest;
};

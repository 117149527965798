import { DeleteTwoTone, PlusSquareFilled } from "@ant-design/icons";
import { Button, Spin, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { AuthFetch } from "components/Login/hook";
import { FrontendInstallContext } from "install/types";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { ConfigOf, InstallSpec, ItemConfigOf } from "shared/install/types";

import { DeleteModal } from "../components/DeleteModal";
import { Config, configPath } from "./Config";
import { InstallProps } from "./Install";
import { New } from "./New";
import { Singleton } from "./Singleton";

export type ComponentProps<T extends InstallSpec> = InstallProps<T> & {
  authFetch: AuthFetch;
  canEdit: boolean;
  config: ConfigOf<T>;
  context: FrontendInstallContext<ConfigOf<T>>;
  componentKey: string;
  error: string | undefined;
  isFetching: boolean;
};

export const NEW_SENTINEL = "_new";

const DeleteAction: React.FC<{
  id: string;
  deleteItem: (id: string) => Promise<void>;
}> = ({ id, deleteItem }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const toggleDeleteModal = useCallback(
    () => setIsDeleteModalOpen(!isDeleteModalOpen),
    [isDeleteModalOpen]
  );
  const confirmDelete = useCallback(async () => {
    setIsDeleting(true);
    setIsDeleteModalOpen(false);
    await deleteItem(id);
    setIsDeleting(false);
  }, [deleteItem, id]);

  return (
    <>
      <Button
        type="link"
        icon={<DeleteTwoTone />}
        loading={isDeleting}
        onClick={isDeleting ? undefined : toggleDeleteModal}
      />
      <DeleteModal
        id={id}
        isOpen={isDeleteModalOpen}
        toggleOpen={toggleDeleteModal}
        onRemove={confirmDelete}
      />
    </>
  );
};

const itemColumns = (props: {
  title: string;
  canEdit: boolean;
  deleteItem: (item: string) => Promise<void>;
}): ColumnsType<ItemConfigOf<any> & { key: string }> => [
  {
    key: "id",
    title: props.title,
    render: (_, { key, label }) => (
      <Link to={encodeURIComponent(key)} relative="path">
        {label ? `${label} (${key})` : key}
      </Link>
    ),
  },
  {
    key: "state",
    title: "State",
    // TODO: make pretty
    render: (_, { state }) =>
      state === "installed" ? "Installed" : "In progress",
  },
  {
    key: "actions",
    title: "",
    render: (_, { key }) =>
      props.canEdit && <DeleteAction id={key} deleteItem={props.deleteItem} />,
  },
];

export const Component = <T extends InstallSpec>(props: ComponentProps<T>) => {
  const { id: encodedId } = useParams<{ id: string }>();
  const id = encodedId ? decodeURIComponent(encodedId) : undefined;
  const navigate = useNavigate();

  const {
    authFetch,
    canEdit,
    componentKey,
    components,
    config,
    context,
    itemKind,
  } = props;
  const component = components?.[componentKey];
  const items = config?.[componentKey];

  const isSingleton = component.type === "singleton";

  const deleteItem = useCallback(
    async (id: string) => {
      await authFetch(configPath({ ...props, id }), { method: "DELETE" });
    },
    [authFetch, props]
  );

  const columns = useMemo(
    () => itemColumns({ title: capitalize(itemKind), canEdit, deleteItem }),
    [canEdit, deleteItem, itemKind]
  );

  const data = useMemo(
    () => Object.entries(items ?? {}).map(([key, item]) => ({ ...item, key })),
    [items]
  );

  const item = useMemo(
    () => (id !== undefined && id !== NEW_SENTINEL ? items?.[id] : undefined),
    [items, id]
  );

  const installNew = useCallback(
    () => navigate("_new", { relative: "path" }),
    [navigate]
  );

  return (
    <>
      <Typography.Title level={3}>{component?.label}</Typography.Title>
      <Typography.Paragraph>{component?.description}</Typography.Paragraph>
      {isSingleton ? (
        <Singleton {...props} component={component} />
      ) : id === NEW_SENTINEL ? (
        <New {...props} component={component} context={context} />
      ) : id ? (
        item ? (
          <Config
            {...props}
            component={component}
            context={context}
            id={id}
            item={item}
          />
        ) : (
          <Spin />
        )
      ) : (
        <>
          <Typography.Title level={4}>
            Installed {pluralize(itemKind)}
          </Typography.Title>
          <div
            style={{
              marginBottom: 16,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {!canEdit ? null : !component.maxItems ||
              component.maxItems > data.length ? (
              <Button
                onClick={installNew}
                type="primary"
                icon={<PlusSquareFilled />}
              >
                Add {itemKind}
              </Button>
            ) : (
              `A maximum of ${component.maxItems} ${pluralize(
                itemKind,
                component.maxItems
              )} may be installed`
            )}
          </div>
          <Table<ItemConfigOf<any> & { key: string }>
            columns={columns}
            dataSource={data}
          />
        </>
      )}
    </>
  );
};

import { WorkflowConfiguration } from "./types";

export const DEFAULT_WORKFLOW_CONFIG: WorkflowConfiguration = {
  options: [
    { time: 5, unit: "m", value: "5 minutes" },
    { time: 1, unit: "h", value: "1 hour" },
    { time: 24, unit: "h", value: "1 day" },
    { time: 168, unit: "h", value: "1 week" },
    { time: 720, unit: "h", value: "30 days" },
  ],
};
export const EXPIRY_OPTIONS_SECONDS = {
  m: 60,
  h: 60 * 60,
};
export const WORKFLOW_CONFIGURATION_COLLECTION = "workflow-configuration";

export enum SSO_OIDC_PROVIDERS {
  "azure" = "azure-oidc",
  "google" = "google-oidc",
  "okta" = "okta-oidc",
}

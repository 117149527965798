import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv } from "components/divs";
import { mapValues } from "lodash";
import {
  ALL_SCOPE_SENTINEL,
  integrationTitles,
} from "shared/assessment/constants";
import { GrantNode } from "shared/types/assessment/data";
import { MonitorScope } from "shared/types/assessment/monitor";

import { ExportColumnType } from "../../AssessmentExport";
import { ConditionDisplay } from "../../cells/Condition";
import { PermissionAggregate } from "../../cells/PermissionAggregate";
import { PermissionSet as PermissionSetCell } from "../../cells/PermissionSet";
import { PermissionTitle } from "../../cells/PermissionTitle";
import { PrincipalCell } from "../../cells/Principal";
import { Resource as ResourceCell } from "../../cells/Resource";
import { RiskAggregate, riskSorter } from "../../cells/RiskAggregate";
import { ShowHideTerm } from "../../cells/ShowHide";
import { genericExport } from "../../export";
import { stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";

type ColumnFactory = (
  props: AssessmentColumnProps,
  scopeKey: string
) => ExportColumnType<GrantNode>;

const Principal: ColumnFactory = (props: AssessmentColumnProps) => ({
  key: "principal",
  title: "Principal",
  sorter: (left, right) =>
    stringSorter(left.data.principal, right.data.principal),
  render: (_, node) => (
    <ClipDiv>
      <PrincipalCell
        principalData={{
          ...node.data,
          label: node.data.principal,
        }}
        termPrefix="grant:"
        {...props}
      />
    </ClipDiv>
  ),
  export: genericExport((node) => node.data.principal),
});

const PermissionSet: ColumnFactory = (props: AssessmentColumnProps) => ({
  key: "permissionSet",
  title: integrationTitles[props.integration]["permissionSet"],
  sorter: (left, right) =>
    stringSorter(left.data.permissionSet ?? "", right.data.permissionSet ?? ""),
  render: (_, { data }) => (
    <PermissionSetCell
      permissionSet={data.permissionSet}
      {...props}
      integration={data.integration ?? ALL_SCOPE_SENTINEL}
    />
  ),
  export: genericExport((node) => node.data.permissionSet),
});

const Resource: ColumnFactory = (props: AssessmentColumnProps) => ({
  key: "resource",
  title: "Resource",
  sorter: (left, right) =>
    stringSorter(left.data.resources[0], right.data.resources[0]),
  render: (_, node) => {
    if (!node.data.resources?.length) return null;
    return node.data.resources.length <= 2 ? (
      <>
        {node.data.resources.map((r, ix) => (
          <ClipDiv key={ix}>
            <ResourceCell resource={node} index={ix} {...props} />
          </ClipDiv>
        ))}
      </>
    ) : (
      <GraphTooltip
        title={node.data.resources.map((r, ix) => (
          <div style={{ maxHeight: "400px", overflowY: "auto" }} key={ix}>
            <ResourceCell resource={node} index={ix} {...props} />
          </div>
        ))}
      >
        <ClipDiv>
          <ResourceCell resource={node} index={0} {...props} />
          <div>and {node.data.resources.length - 1} more</div>
        </ClipDiv>
      </GraphTooltip>
    );
  },
  export: genericExport((node) => node.data.resources?.join(", ")),
});

const Condition: ColumnFactory = (props: AssessmentColumnProps) => ({
  key: "condition",
  title: "Condition",
  sorter: ({ data: { condition: l } }, { data: { condition: r } }) => {
    return stringSorter(
      typeof l === "string" ? l : l?.title ?? l?.expression ?? "",
      typeof r === "string" ? r : r?.title ?? r?.expression ?? ""
    );
  },
  render: (_, { data: { condition } }) => {
    if (!condition) return null;
    if (typeof condition === "string") return condition;
    return (
      <ClipDiv>
        <GraphTooltip
          title={
            <div style={{ maxWidth: "400px" }}>
              <ConditionDisplay condition={condition} />
              <ShowHideTerm
                term={`condition:"${condition.title ?? condition.expression}"`}
                name="conditions"
                {...props}
              />
            </div>
          }
          width="400px"
        >
          {condition.title}
        </GraphTooltip>
      </ClipDiv>
    );
  },
  export: genericExport(({ data }) =>
    typeof data.condition === "string"
      ? data.condition
      : data.condition?.expression
  ),
});

const Risk: ColumnFactory = () => ({
  key: "risk",
  title: "Risks",
  sorter: (left, right) =>
    riskSorter(left.aggregates.risks, right.aggregates.risks),
  render: (_, node) => <RiskAggregate risks={node.aggregates.risks} />,
  export: genericExport((node) => node.aggregates.risks),
});

const Permission: ColumnFactory = (
  props: AssessmentColumnProps,
  scopeKey: string
) => ({
  key: "permissions",
  title: (
    <PermissionTitle
      title={integrationTitles[props.integration]["permissions"]}
    />
  ),
  render: (_, { aggregates }) => {
    const counts: Record<string, number> =
      scopeKey === ALL_SCOPE_SENTINEL
        ? aggregates.usages
        : mapValues(aggregates.permissions, (p) => p.length);
    return <PermissionAggregate counts={counts} {...props} />;
  },
  export: genericExport((node) => ({
    used: node.aggregates?.permissions?.used?.length ?? 0,
    unused: node.aggregates?.permissions?.unused?.length ?? 0,
    unknown: node.aggregates?.permissions?.unknown?.length ?? 0,
  })),
});

const defaultFactories: ColumnFactory[] = [
  Principal,
  PermissionSet,
  Resource,
  Condition,
  Risk,
  Permission,
];

const columnFactories: Record<MonitorScope, ColumnFactory[]> = {
  all: defaultFactories,
  aws: defaultFactories,
  gcloud: defaultFactories,
  // Kubernetes does not have conditions
  k8s: defaultFactories.filter((factory) => factory !== Condition),
  workspace: defaultFactories,
};

export const grantColumns = (
  props: AssessmentColumnProps,
  scopeKey: string
): ExportColumnType<GrantNode>[] => {
  return columnFactories[props.integration].map((factory) =>
    factory(props, scopeKey)
  );
};

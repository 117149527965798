export const Footer = () => (
  <div className="site-footer">
    <a href="/tos" target="_black" rel="noreferrer">
      Terms of Service
    </a>
    &nbsp;|&nbsp;
    <a href="/privacy" target="_black" rel="noreferrer">
      Privacy Policy
    </a>
  </div>
);

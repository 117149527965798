import { Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMemo } from "react";
import { GroupAccessPrincipal, GroupPrivilege } from "shared/types/directory";
import { widetype } from "shared/util/collections";

type Data = {
  privilege: GroupPrivilege;
  principal?: GroupAccessPrincipal;
};

const GROUP_PRIVILEGE_LABELS: Record<GroupPrivilege, string> = {
  add: "Add members",
  approve: "Approve join requests",
  join: "Join",
  view: "View group content",
};

const GROUP_ACCESS_PRINCIPAL_LABELS: Record<GroupAccessPrincipal, string> = {
  admin: "Group administrators",
  domain: "Anyone in your organization",
  group: "Group members",
  invited: "Any invited user",
  owner: "Group owners",
  public: "Anyone on the Internet",
  unknown: "(unknown)",
};

const GROUP_ACCESS_CONTROL_COLUMNS: ColumnType<Data>[] = [
  {
    dataIndex: "privilege",
    title: "Privilege",
    render: (_, { privilege }) => GROUP_PRIVILEGE_LABELS[privilege],
  },
  {
    dataIndex: "principal",
    title: "Principal",
    render: (_, { principal }) =>
      GROUP_ACCESS_PRINCIPAL_LABELS[principal ?? "unknown"],
  },
];

export const GroupAccessTable: React.FC<{
  access: Partial<Record<GroupPrivilege, GroupAccessPrincipal>>;
}> = ({ access }) => {
  const data = useMemo(
    () =>
      widetype
        .entries(access)
        .map(([privilege, principal]) => ({ privilege, principal })),
    [access]
  );
  return (
    <Table
      columns={GROUP_ACCESS_CONTROL_COLUMNS}
      dataSource={data}
      pagination={false}
    />
  );
};

import { ApprovalRule } from "./types";

export const isApprovalRuleType =
  <T>(tpe: T) =>
  (rule: ApprovalRule): rule is ApprovalRule & { type: T } =>
    rule.type === tpe;

export const hasApprovalOptions = (
  rule: ApprovalRule
): rule is ApprovalRule & { options: any } => "options" in rule;

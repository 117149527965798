import { Spin } from "antd";
import { User } from "firebase/auth";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getAuth } from "providers/FirestoreProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";

import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import { useOrgInfoStore } from "../../store/org-info/useOrgInfoStore";
import { UserAuthz } from "../Login";
import { useAuthFetch } from "../Login/hook";
import { Splash } from "../Splash";

// Need an invisible error catch.
const handleError = () => {};

export const OnboardingRedirect: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const auth = useMemo(() => getAuth(), []);
  const userAuthz = useContext(UserAuthz);

  const authFetch = useAuthFetch(handleError);
  const { onboardingFlowV1 } = useFlags();
  const [user, setUser] = useState<User | null>();
  const { orgInfo, setOrgInfo } = useOrgInfoStore();

  useEffect(() => {
    if (auth.currentUser) {
      setUser(auth.currentUser);
    } else {
      return auth.onAuthStateChanged((user) => {
        setUser(user);
      });
    }
  }, [auth, auth.currentUser]);

  useGuardedEffect(
    (cancellation) => async () => {
      if (user && orgInfo.status !== "found") {
        const response = await authFetch("account", {
          method: "GET",
        });
        if (!response) return;
        const data = await response.json();
        cancellation.guard(setOrgInfo)({
          status: "found",
          info: data,
        });
      }
    },
    [authFetch, user, setOrgInfo, orgInfo.status],
    handleError
  );

  return (
    <>
      {onboardingFlowV1 ? (
        orgInfo.status === "found" ? (
          !orgInfo.info.onboarding?.onboardedToAudit &&
          !orgInfo.info.onboarding?.onboardedToWorkflows &&
          userAuthz.has("owner") ? (
            <Navigate to="welcome" />
          ) : (
            children
          )
        ) : orgInfo.status === "loading" ? (
          <Splash>
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          </Splash>
        ) : (
          children
        )
      ) : (
        children
      )}
    </>
  );
};

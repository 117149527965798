import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { Node } from "shared/graph/types";
import { FindingState } from "shared/types/assessment/finding";

export const SinceColumn = (
  findingsState: FindingState,
  at: (node: Node<any, any>) => number | undefined
): ColumnType<Node<any, any>> => ({
  title:
    findingsState === "open"
      ? "Since"
      : findingsState === "ignored"
      ? "Ignored at"
      : "Resolved at",
  sorter: (left, right) => (at(left) ?? 0) - (at(right) ?? 0),
  render: (_: any, data: Node<any, any>) => {
    const date = at(data);
    return date && moment(date).format("ll");
  },
});

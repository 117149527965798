import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: true,
  capture_pageview: true,
};

type props = {
  children: React.ReactNode;
};

const PostHogProviderWrapper: React.FC<props> = ({ children }) => {
  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      {children}
    </PostHogProvider>
  );
};

export default PostHogProviderWrapper;

import { Form } from "antd";
import styled from "styled-components";

export const standardFormItemCols = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  colon: false,
};

export const standardSubmitButtonCol = {
  wrapperCol: { offset: 8, span: 16 },
};

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    white-space: wrap;
  }
`;

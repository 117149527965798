import styled from "styled-components";

const Notes = styled.div`
  font-size: small;
  color: #999;
  margin-top: 3em;
  max-width: 800px;
`;

export const LimitedUse: React.FC<object> = () => (
  <Notes>
    P0&apos;s use and transfer to any other app of information received from
    Google APIs will adhere to{" "}
    <a
      href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
      target="_blank"
      rel="noreferrer"
    >
      Google API Services User Data Policy
    </a>
    , including the Limited Use requirements.
  </Notes>
);

import { Grid, Spin, Typography } from "antd";
import { useUser } from "components/Login/hook";
import styled from "styled-components";

const LoggedInUserContainer = styled.div`
  color: rgba(255, 255, 255, 0.65);
  display: block;
  padding: 10px 24px;

  .ant-typography {
    color: rgba(255, 255, 255, 0.65);
  }
`;

export const LoggedInUserNavItem: React.FC = () => {
  const { user, tenant } = useUser();

  const { lg } = Grid.useBreakpoint();

  return lg ? (
    <LoggedInUserContainer>
      <Typography.Paragraph>Welcome,</Typography.Paragraph>
      {tenant.state === "found" && tenant.isSandbox ? (
        <Typography.Paragraph strong>Sandbox User</Typography.Paragraph>
      ) : user ? (
        <Typography.Paragraph strong>
          {user.displayName || user.email}
        </Typography.Paragraph>
      ) : (
        <Spin />
      )}
    </LoggedInUserContainer>
  ) : null;
};

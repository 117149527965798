import { Alert, Button, Card, Typography } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import useLaunchDarkly from "../../hooks/useLaunchDarkly";
import useUserIdentification from "../../hooks/useUserIdentification";
import { useOrgInfoStore } from "../../store/org-info/useOrgInfoStore";
import { Splash } from "../Splash";
import Loader from "../common/Loader";
import { idPlatform } from "./IdentityPlatform";
import { LoginForm } from "./components/LoginForm";
import { useUser, useUserAuthz } from "./hook";
import { Tenant, UserAuthz } from "./util";

export type LoginInputs = {
  email: string;
};

type LoginElements = {
  element: JSX.Element;
  heading: string;
  footer?: ReactElement;
};

/** Renders three possible UIs:
 *  1. Renders a spinner while loading auth state
 *  2. If not signed in, renders a sign-in page
 *  3. If signed in, renders `children`
 */
export const Login: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const skipLogin = queryParams.get("skipLogin") === "true";
  const {
    user,
    isInAuth,
    signInWithEmail,
    signInWithProvider,
    tenant,
    useSso,
  } = useUser(skipLogin);
  const userAuthz = useUserAuthz(user, tenant);
  const { orgSlug } = useParams();

  const [searchParams] = useSearchParams();
  const isNewTenant = searchParams.get("tenant") === "new";
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { orgInfo } = useOrgInfoStore();

  // register organization with LaunchDarkly
  useLaunchDarkly(
    orgSlug ?? "",
    orgInfo.status === "found" ? orgInfo.info.subscriptionPlan : undefined
  );
  // register user with logrocket and heap analytics
  useUserIdentification();

  const handleLoginForm = useCallback(
    async ({ email }: LoginInputs) => {
      // At this point, inline validation has passed.
      const success = await signInWithEmail(email);
      if (success) {
        setEmailSent(true);
      }
    },
    [signInWithEmail]
  );

  useEffect(() => {
    (async () => {
      if (skipLogin) {
        setIsLoading(true);
        await signInWithProvider();
        setIsLoading(false);
      }
    })();
  }, [signInWithProvider, skipLogin]);

  const orgName = useMemo(
    () => (tenant.state === "found" ? tenant.orgName : undefined),
    [tenant]
  );

  const signInElement = useMemo(
    (): LoginElements =>
      emailSent
        ? {
            element: (
              <Typography.Paragraph>
                Check your email for a sign in link.
              </Typography.Paragraph>
            ),
            heading: "Success",
          }
        : tenant.state === "loading"
        ? {
            element: <Typography.Paragraph>Loading</Typography.Paragraph>,
            heading: "Please hold",
          }
        : tenant.state === "found"
        ? {
            element: useSso ? (
              <Button onClick={signInWithProvider}>
                {idPlatform.element(tenant)}
              </Button>
            ) : (
              <>
                <Typography.Paragraph>
                  We&apos;ll email you a magic link to sign in.
                </Typography.Paragraph>
                <LoginForm onLogin={handleLoginForm} />
              </>
            ),
            heading: `Sign in to ${orgName ?? orgSlug ?? "your organization"}`,
            footer: idPlatform.footer(tenant),
          }
        : {
            element: (
              <>
                <Typography.Paragraph>
                  Organization not found
                </Typography.Paragraph>
                <Button type="primary" href="/">
                  Return home
                </Button>
              </>
            ),
            heading: "Oh no...",
          },
    [
      emailSent,
      tenant,
      useSso,
      signInWithProvider,
      handleLoginForm,
      orgSlug,
      orgName,
    ]
  );

  return user?.tenantId ? (
    // We're still loading roles for user
    userAuthz.size === 0 ? (
      <Loader />
    ) : (
      <Tenant.Provider value={user.tenantId}>
        <UserAuthz.Provider value={userAuthz}>{children}</UserAuthz.Provider>
      </Tenant.Provider>
    )
  ) : isInAuth || isLoading ? (
    <Loader />
  ) : signInElement ? (
    <Splash>
      <>
        <Card title={signInElement.heading}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {isNewTenant && tenant.state === "found" && (
              <Alert
                type="success"
                showIcon
                message="Account created successfully"
                description={`Your account for ${
                  orgName ?? orgSlug
                } was successfully created. Please log in with your email to get started here.`}
                style={{ marginBottom: "20px" }}
              />
            )}
            {signInElement.element}
            {signInElement.footer}
          </div>
        </Card>
      </>
    </Splash>
  ) : (
    <Splash>Page not found</Splash>
  );
};

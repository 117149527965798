import { Button, Input, Modal } from "antd";
import { VerticalSpacedDiv } from "components/divs";
import pluralize from "pluralize";
import { useCallback, useState } from "react";
import { Finding } from "shared/types/assessment/finding";

import { FirestoreDoc } from "../../../providers/FirestoreProvider";
import { MonitorActionProps } from "../pages/MonitorResults";

/** Displays commands used to fix findings / revert fixes */
export const FindingsManageModal: React.FC<MonitorActionProps> = ({
  allNodes,
  actOn,
  monitor,
}) => {
  const [staged, setStaged] = useState<FirestoreDoc<Finding>[]>([]);

  const openModal = useCallback(() => setStaged(actOn), [actOn]);
  const closeModal = useCallback(() => setStaged([]), []);
  return (
    <>
      <Button disabled={!allNodes?.length} onClick={openModal}>
        {" "}
        {"⚙️\u00a0Manage"}{" "}
      </Button>
      <Modal
        footer={false}
        maskClosable={false}
        open={staged.length > 0}
        onCancel={closeModal}
        style={{ minWidth: "600px" }}
      >
        <VerticalSpacedDiv>
          <div>
            Manage {staged.length} {pluralize("finding", staged.length)}?{" "}
            <br></br>
            {monitor.management?.description}
          </div>

          {monitor.management?.inputPrompt && (
            <div>
              {monitor.management?.inputPrompt}
              <Input.TextArea />
            </div>
          )}
          <div>
            <Button onClick={closeModal}>OK</Button>
          </div>
        </VerticalSpacedDiv>
      </Modal>
    </>
  );
};

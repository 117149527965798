import concat from "lodash/concat";
import pluralize from "pluralize";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { Resource } from "psql-grants/src/grants/types";
import { ReactNode } from "react";
import { AwsSpec } from "shared/integrations/resources/aws/accesses";
import { AzureSpec } from "shared/integrations/resources/azure/accesses";
import { GroupSpec } from "shared/integrations/resources/directory-group/types";
import { GcloudSpec } from "shared/integrations/resources/gcloud/types";
import { resourceToFriendly } from "shared/integrations/resources/kubernetes/display";
import { KubernetesSpec } from "shared/integrations/resources/kubernetes/types";
import {
  Role,
  SnowflakeSpec,
  SqlText,
} from "shared/integrations/resources/snowflake/types";
import {
  SshGroupSpec,
  SshSessionSpec,
  UnstagedSshSpec,
} from "shared/integrations/resources/ssh/accesses";
import { notificationsAreEnabled } from "shared/permission-requests/util";
import { assertNever } from "shared/types";
import { Permission, PermissionRequest } from "shared/types/permission";
import {
  HandledRequestStatuses,
  NotifiedRequestStatuses,
  RequestStatus,
  RequestStatuses,
  TerminalRequestStatuses,
} from "shared/types/request-status";
import { DistributedOmit } from "shared/types/util";

import { isa } from "../../shared/types/is";

export const SLACK_TLD = "slack.com";

const terminalSuccessColor = "blue";
const inActionColor = "gold";
const happyPathColor = "green";
export const COLOR_MAP = {
  "Pending Approval": inActionColor,
  "Request Created": "#434343",
  Approved: happyPathColor,
  Staged: happyPathColor,
  Denied: "orange",
  Errored: "red",
  Expired: terminalSuccessColor,
  Expiring: inActionColor,
  Granted: happyPathColor,
  "N/A": "grey",
  Relinquished: terminalSuccessColor,
  Relinquishing: inActionColor,
} as const;

export const permissionAccount = (request: Permission) => {
  switch (request.type) {
    case "aws":
      return request.permission.accountId;
    case "azure-ad":
    case "okta":
    case "workspace":
      return undefined;
    case "gcloud":
      return request.permission.projectId;
    case "k8s":
      return request.permission.clusterId;
    case "snowflake":
      return request.permission.account;
    case "pg":
      return request.permission.integrationType;
    case "azure":
      // TODO: Implement Azure resource description
      return "Azure IAM";
    case "ssh": {
      switch (request.access) {
        case "all":
          return "all";
        case "group":
          if (!request.permission.provider) {
            return "all";
          }
          switch (request.permission.provider) {
            case "aws":
              return request.permission.parent;
            case "gcloud":
              return request.permission.parent;
            case "azure":
              return request.permission.parent;
            default:
              throw assertNever(request.permission.provider);
          }
        case "session":
          switch (request.permission.provider) {
            case "aws":
              return request.permission.parent;
            case "gcloud":
              return request.permission.parent;
            case "azure":
              return request.permission.parent;
            default:
              throw assertNever(request.permission);
          }
        default:
          throw assertNever(request);
      }
    }
    default:
      throw assertNever(request);
  }
};

export const requestDescription = (request: Permission): ReactNode => {
  const { permission, type } = request;
  switch (type) {
    case "aws":
      return buildAwsDescription(request);
    case "gcloud":
      return buildGcloudDescription(permission);
    case "snowflake":
      return buildSnowflakeDescription(permission);
    case "azure-ad":
    case "okta":
    case "workspace":
      return `Group | ${buildDirectoryDescription(permission)}`;
    case "k8s":
      return buildK8sDescription(permission);
    case "pg":
      return "PostgreSQL";
    case "ssh":
      return buildSshDescription(request);
    case "azure":
      return buildAzureIamDescription(permission);
    default:
      console.warn("Got unexpected permission request", {
        ...(permission as any),
      });
      // Don't want to interrupt control flow for older permission requests,
      // so just make type assertion; not run-time assertion
      const _tpe: never = type;
  }
};

const buildAzureIamDescription = (_permission: AzureSpec["permission"]) => {
  // TODO: Implement Azure resource description
  return `Resource | Something in Azure`;
};

const buildSnowflakeDescription = (permission: SnowflakeSpec["permission"]) => {
  const permissionType = permission.type;
  if (permissionType === "sqlText") {
    return `SQL text | ${buildSnowflakeSqlTextDescription(permission)}`;
  } else if (permissionType === "role") {
    return `Role | ${buildSnowflakeRoleDescription(permission)}`;
  } else {
    throw assertNever(permissionType);
  }
};

const buildDirectoryDescription = (permission: GroupSpec["permission"]) => {
  return permission.group
    ? permission.group.label
      ? permission.group.label
      : permission.group.id
    : "unknown group";
};

const buildK8sDescription = (permission: KubernetesSpec["permission"]) => {
  const permissionType = permission.type;
  if (permissionType === "resource") {
    return `Resource | ${permission.role} on ${resourceToFriendly(
      permission.resource
    )} in ${permission.clusterId}`;
  } else {
    throw assertNever(permissionType);
  }
};

const buildAwsDescription = (
  request: DistributedOmit<AwsSpec, "generated">
) => {
  switch (request.access) {
    case "policy":
      return `Policy | ${request.permission.arn
        ?.split("/")
        .slice(-1)
        .join("")}`;
    case "resource":
      return `Resource | ${(request.permission.policies ?? [])
        .map?.((p) => p.split("/").slice(-1).join(""))
        .join(", ")} on ${request.permission.arn}`;
    case "group":
      return `Group | ${request.permission.name}`;
    case "role":
      return `Role | ${request.permission.name}`;
    case "permission-set":
      return `Permission set | ${request.permission.name}`;
    default:
      // TODO: restore `throw` after migrating old request format
      try {
        assertNever(request);
      } catch (error: any) {
        return "Unknown AWS access";
      }
  }
};

const buildGcloudDescription = (permission: GcloudSpec["permission"]) => {
  const permissionType = permission.type;
  switch (permissionType) {
    case "role":
      const roleName =
        permission.roleId.substring(permission.roleId.indexOf("roles/") + 6) ??
        "";
      return `Role | ${roleName}`;
    case "permission":
      return `Permissions | ${permission.permissions.join(", ")}`;
    case "resource": {
      const grant = permission.permissionList?.[0];
      if (!grant) return "no access";
      return `Resource | ${grant.access.accessName} on ${
        grant.requestedResource?.name ?? "(unknown)"
      }`;
    }
    default:
      throw assertNever(permissionType);
  }
};

const buildSnowflakeRoleDescription = (role: Role) => {
  return role.roleName;
};

const buildSnowflakeSqlTextDescription = (sqlText: SqlText) => {
  if (sqlText.grants === undefined) {
    return "Missing grants";
  }

  let writes = [];
  let reads = [];
  if ("read" in sqlText.grants || "write" in sqlText.grants) {
    // This if-branch is for backward-compatibility after change https://github.com/p0-security/main-repo/pull/275
    const grants = sqlText.grants as any | undefined;
    writes = grants?.write ?? [];
    reads = grants?.read ?? [];
  } else {
    writes = concat(
      sqlText.grants.insert,
      sqlText.grants.delete,
      sqlText.grants.update
    );
    reads = sqlText.grants.select;
  }
  const count = writes.length + reads.length;
  let mostSensitive:
    | {
        resource: Resource;
        operations: string;
      }
    | undefined;
  if (writes.length > 0) {
    mostSensitive = {
      resource: writes[0],
      operations: "INSERT,UPDATE,DELETE",
    };
  } else if (reads.length > 0) {
    mostSensitive = {
      resource: reads[0],
      operations: "SELECT",
    };
  }
  if (mostSensitive !== undefined) {
    let desc = `${mostSensitive.operations} / ${mostSensitive.resource.catalog}.${mostSensitive.resource.schema}.${mostSensitive.resource.relation}`;
    if (count > 1) {
      desc += ` and ${count - 1} more`;
    }
    return desc;
  }
  return "Missing grants";
};

const buildSshDescription = (spec: UnstagedSshSpec) => {
  switch (spec.access) {
    case "all":
      return "All Nodes";
    case "session":
      return `Node | ${buildSshSessionDescription(
        spec.permission
      )}${buildSudoDescription(spec.permission)}`;
    case "group":
      return `Group | ${spec.permission.name}${buildSudoDescription(
        spec.permission
      )}`;
    default:
      throw assertNever(spec);
  }
};

const buildSshSessionDescription = (spec: SshSessionSpec["permission"]) => {
  switch (spec.provider) {
    case "aws":
      return spec.resource.instanceName ?? spec.resource.instanceId;
    case "gcloud":
      return spec.resource.instanceName ?? spec.resource.fullName;
    case "azure":
      return spec.resource.instanceName ?? spec.resource.instanceId;
    default:
      throw assertNever(spec);
  }
};

const buildSudoDescription = (
  spec: SshGroupSpec["permission"] | SshSessionSpec["permission"]
) => {
  return spec.sudo ? " with sudo" : "";
};

const friendlyPrincipal = (request: PermissionRequest) =>
  request.type === "snowflake"
    ? request.permission.userName
    : request.principal;

export type RequestLog = {
  initialStatus: RequestStatus;
  updates?: PermissionRequest;
};

export const progressMessages: Record<RequestStatus, string> = {
  NEW: "Setting up workflow",
  PENDING_APPROVAL: "Workflow initiated; waiting for approval",
  PENDING_APPROVAL_ESCALATED:
    "Workflow initiated with escalated notification; waiting for approval",
  APPROVED: "Approved; notifying requestor",
  APPROVED_NOTIFIED: "Staging access",
  ERRORED: "Errored; notifying requestor",
  ERRORED_NOTIFIED: "NA",
  REVOKE_SUBMITTED: "Revoking request",
  DENIED: "Denied; notifying requestor",
  DENIED_NOTIFIED: "NA",
  DONE: "Access granted; notifying requestor",
  DONE_NOTIFIED: "Access granted; awaiting expiry",
  EXPIRY_SUBMITTED: "Expiring request",
  EXPIRED: "Access expired; notifying requestor",
  EXPIRED_NOTIFIED: "NA",
  REVOKED: "Revoking request; notifying reequestor",
  REVOKED_NOTIFIED: "NA",
  TRANSLATED: "NA",
  ERRORED_ERRORED: "NA",
  STAGED: "Granting access",
  CLEANUP_SUBMITTED: "Cleanup in progress",
  CLEANED_UP: "Cleanup completed",
  CLEANUP_ERRORED: "Cleanup encountered an error",
};

export const extractApproverName = (request: PermissionRequest) => {
  // check approvalDetails first, then approverName, then slack notification
  // in approvalDetails
  const { approvalDetails: details, notifications } = request;
  return details
    ? details.approvalSource === "persistent"
      ? "Persistent access"
      : details.email ?? details.name ?? details.id ?? "(Unknown approver)"
    : // Old Request, will be migrated to approvalDetails
      (request as any).approverName ??
        (notifications?.slack as any)?.approvalByEmail ??
        (request.status === "NEW" || request.status === "PENDING_APPROVAL"
          ? "NA"
          : "Unknown Approver");
};

export const statusDescriptions: Record<
  RequestStatus,
  (request: PermissionRequest) => string | undefined
> = {
  EXPIRED_NOTIFIED: (request) =>
    `Notified ${request.requestor} that this access was revoked`,
  EXPIRED: (request) => `
        Access expired; ${requestDescription(request)} removed from${" "}
        ${friendlyPrincipal(request)}
     `,
  NEW: () => "Created request",
  PENDING_APPROVAL: (request) => `
        Sent request for approval to ${
          request.notifications?.slack?.approvalConversationUrl
            ? request.notifications.slack.approvalConversationUrl
            : ""
        }
      `,
  PENDING_APPROVAL_ESCALATED: (request) => `
        Escalated request for approval
        ${extractEscalationDetails(request) ?? ""}
      `,
  APPROVED: (request) => `Request approved by ${extractApproverName(request)}`,
  DENIED: (request) => `Request denied by ${extractApproverName(request)}`,
  REVOKED: (request) => `Access revoked; ${requestDescription(
    request
  )} removed from${" "}
        ${friendlyPrincipal(request)}`,
  DONE: (request) => `Granted ${requestDescription(request)} to
        ${friendlyPrincipal(request)}`,
  APPROVED_NOTIFIED: () =>
    `Updated Slack message to indicate that this request was approved`,
  STAGED: () => `Staged request`,
  DENIED_NOTIFIED: (request) =>
    `Notified ${request.requestor} that this request was denied`,
  ERRORED_NOTIFIED: (request) =>
    `Notified ${request.requestor} that this request encountered an error`,
  DONE_NOTIFIED: (request) =>
    `Notified ${request.requestor} that this access was granted`,
  ERRORED_ERRORED: () => `
        Encountered an irrecoverable error while processing another error;
        contact support@p0.dev for assistance
      `,
  ERRORED: (request) => `
        Encountered an error while trying to process this access request:
        ${request.error?.message}
      `,
  EXPIRY_SUBMITTED: () =>
    "Triggered access expiration (principal still has access at this time)",
  REVOKE_SUBMITTED: (request) => `
        ${request.principal} relinquished ${requestDescription(request)}
      `,
  REVOKED_NOTIFIED: (request) =>
    `Notified ${request.requestor} that this access was revoked`,
  TRANSLATED: () => "NA",
  CLEANUP_SUBMITTED: () => "Cleaning up grants that should not be present",
  CLEANED_UP: () => "Cleanup completed",
  CLEANUP_ERRORED: () => "Cleanup encountered an error",
};

// Helper function to determine if notifications were skipped for a request log
const isSkippedNotificationEvent = (
  request: PermissionRequest,
  event: RequestLog
) =>
  isa(NotifiedRequestStatuses, event.initialStatus) &&
  !notificationsAreEnabled(request);

// Helper function for reconstructing request history. Given a status and list of
// request log documents, returns the log for the next logged status, and adds any intermediate
// events that occured between the two logs
const findNextLog = (
  updates: PermissionRequest,
  logDocs: FirestoreDoc<PermissionRequest>[]
): {
  nextLog: FirestoreDoc<PermissionRequest> | undefined;
  additionalEvents: RequestLog[];
} => {
  if (isa(TerminalRequestStatuses, updates.status)) {
    // TODO: simplify more for handling cleanup statuses
    // the current logic is a bit convoluted
    // with cleanup statuses, the current terminal status can be both terminal
    // and non-terminal statuses based on their zombie grants being present
    // so we make sure the history does not contain any cleanup statuses
    // if there are clean up statuses, we continue to the next clean up status
    const isCleanedUp = logDocs.some((doc) =>
      ["CLEANUP_SUBMITTED", "CLEANED_UP", "CLEANUP_ERRORED"].includes(doc.id)
    );
    let nextLog, additionalEvents;

    if (isCleanedUp) {
      nextLog =
        updates.status !== "CLEANED_UP" && updates.status !== "CLEANUP_ERRORED"
          ? logDocs.find((doc) => doc.id === "CLEANUP_SUBMITTED")
          : undefined;
      additionalEvents =
        updates.status === "CLEANED_UP" || updates.status === "CLEANUP_ERRORED"
          ? [{ initialStatus: updates.status, updates }]
          : [];
    } else {
      nextLog = undefined;
      additionalEvents = [{ initialStatus: updates.status, updates }];
    }

    return { nextLog, additionalEvents };
  }

  if (isa(HandledRequestStatuses, updates.status))
    return {
      nextLog: logDocs.find((doc) => doc.id === updates.status),
      additionalEvents: [],
    };

  // TODO: Refactor to make request updates in a driver class in order to log all request updates
  // Currently, logging is done in the state machine handler, so we do not logs for transitions that
  // occur outside of that. This is a hack to deal with that for now.
  switch (updates.status) {
    case "PENDING_APPROVAL": {
      /**
       * In normal flow pending approval is never recorded as a handled request status, as there will be no logs for it
       * we try to find if the request has been approved or denied. if these state exists, we skip over to that status
       * else we consider it as pending_approval and show pending approval state. but with pending approval escalated state
       * pending_approval state can exist. if the request is escalated, there will be a pending_approval status, which we can
       * use to show users that the request has been escalated and pending_approval_escalated state behaves like pending_approval state
       * and moves to approved or denied state
       */
      const escalated = logDocs.find((doc) => doc.id === "PENDING_APPROVAL");
      if (escalated) {
        return {
          nextLog: escalated,
          additionalEvents: [],
        };
      }
      const nextLog = logDocs.find(
        (doc) => doc.id === "APPROVED" || doc.id === "DENIED"
      );
      return {
        nextLog,
        additionalEvents: nextLog
          ? []
          : [{ initialStatus: "PENDING_APPROVAL", updates }],
      };
    }
    case "PENDING_APPROVAL_ESCALATED": {
      const nextLog = logDocs.find(
        (doc) => doc.id === "APPROVED" || doc.id === "DENIED"
      );
      return {
        nextLog,
        additionalEvents: [
          { initialStatus: "PENDING_APPROVAL_ESCALATED", updates },
        ],
      };
    }
    case "DONE_NOTIFIED":
      return {
        nextLog: logDocs.find(
          (doc) =>
            doc.id === "EXPIRY_SUBMITTED" || doc.id === "REVOKE_SUBMITTED"
        ),
        additionalEvents: [{ initialStatus: "DONE_NOTIFIED", updates }],
      };
    default:
      return { nextLog: undefined, additionalEvents: [] };
  }
};

export const getRequestEvents = (
  request: PermissionRequest,
  requestLogs: FirestoreDoc<PermissionRequest>[]
): { events: RequestLog[]; isComplete: boolean } => {
  const requestEvents = [];
  let currentLog = requestLogs.find((doc) => doc.id === "NEW");
  let updates = currentLog?.data;

  while (currentLog) {
    if (isa(RequestStatuses, currentLog.id)) {
      requestEvents.push({
        initialStatus: currentLog.id,
        updates,
      });
    }
    updates = currentLog.data;
    const { nextLog, additionalEvents } = findNextLog(updates, requestLogs);
    requestEvents.push(...additionalEvents);
    currentLog = updates.status != currentLog.id ? nextLog : undefined;
  }
  // Filter out any notification statuses for which notifications were not sent
  const filteredEvents = requestEvents.filter(
    (event) => !isSkippedNotificationEvent(request, event)
  );
  return {
    events: filteredEvents,
    isComplete:
      requestEvents.length > 0 &&
      TerminalRequestStatuses.includes(
        requestEvents[requestEvents.length - 1].initialStatus
      ),
  };
};
function extractEscalationDetails(request: PermissionRequest) {
  const { escalationDetails } = request;
  if (escalationDetails) {
    const incidents = escalationDetails.pagerduty;
    return `and created pagerduty ${pluralize(
      "incident",
      incidents.length
    )} ${incidents.join(", ")}`;
  }
  return undefined;
}

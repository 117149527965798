import { principalPredicate } from "shared/assessment/issues/presets";
import { principalReducers } from "shared/assessment/reducers/principal";

import { principalColumns } from "./columns";

export const principalData = {
  predicate: principalPredicate,
  reducers: principalReducers,
  columns: principalColumns,
};

import { ApiFilled, FilterFilled, SettingFilled } from "@ant-design/icons";
import { Alert, Button, Spin, Typography } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { Link, NavLink, Navigate, Outlet, Route } from "react-router-dom";

import { useScreen } from "../../hooks/useScreen";
import { getNavLinkClassName } from "../../utils/activeNavLinkClassName";
import { AppRoutes } from "../App/routeConstants";
import { useUser } from "../Login/hook";
import { IntegrationsSandbox } from "./IntegrationsSandbox";
import { RoutingSandbox } from "./RoutingSandbox";
import { SettingsSandbox } from "./SettingsSandbox";

export const sandboxRoutes = () => (
  <Route element={<SandboxRedirect />}>
    <Route
      path={AppRoutes.IntegrationsSandbox}
      element={<IntegrationsSandbox />}
    />
    <Route path={AppRoutes.RoutingSandbox} element={<RoutingSandbox />} />
    <Route path={AppRoutes.SettingsSandbox} element={<SettingsSandbox />} />
  </Route>
);

export const addSandboxMenu = (items: ItemType[], isOwner: boolean) => {
  items.push({
    key: AppRoutes.RoutingSandbox,
    icon: <FilterFilled />,
    label: (
      <NavLink to={AppRoutes.RoutingSandbox} className={getNavLinkClassName}>
        Routing
      </NavLink>
    ),
  });
  items.push({
    key: AppRoutes.IntegrationsSandbox,
    icon: <ApiFilled />,
    label: (
      <NavLink
        to={AppRoutes.IntegrationsSandbox}
        className={getNavLinkClassName}
      >
        Integrations
      </NavLink>
    ),
  });
  items.push({
    key: AppRoutes.SettingsSandbox,
    icon: <SettingFilled />,
    label: (
      <NavLink to={AppRoutes.SettingsSandbox} className={getNavLinkClassName}>
        Settings
      </NavLink>
    ),
  });

  if (isOwner) {
    items.push({
      key: "divider",
      type: "divider",
      className: "nav-divider",
    });
  }
};

export const SandboxAlert: React.FC = () => {
  const { md: isTabletOrLarger } = useScreen();

  return (
    <Alert
      message="Want to give p0 a try with your own data?"
      description={
        <>
          {isTabletOrLarger && (
            <Typography.Paragraph>
              Sign up for a free account. No credit card required. Get started
              in fifteen minutes or less.
            </Typography.Paragraph>
          )}
          <Link to="/create-account">
            <Button type="primary">Create your account</Button>
          </Link>
        </>
      }
      style={{ marginBottom: 24 }}
    />
  );
};

const SandboxRedirect: React.FC = () => {
  const { tenant } = useUser();
  return tenant.state === "found" ? (
    tenant.isSandbox ? (
      <Outlet />
    ) : (
      <Navigate to={AppRoutes.Requests} />
    )
  ) : (
    <Spin />
  );
};

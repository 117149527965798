import { ProfileOutlined } from "@ant-design/icons";
import { AllIntegrationLogos } from "components/Integrations/IntegrationLogo";
import {
  RoutingRulePopover,
  StyledRoutingRulePreview,
} from "components/Routing/styles";
import { IdpGroup } from "shared/types/workflow/types";

type Props = {
  idpGroup: IdpGroup;
  isApproval?: boolean;
  showPopover?: boolean;
};

export const IdpGroupPreview: React.FC<Props> = ({
  idpGroup,
  isApproval = false,
  showPopover = true,
}) => {
  return (
    <RoutingRulePopover
      text={`${isApproval ? "Approval by " : ""} ${idpGroup.label}`}
      icon={AllIntegrationLogos[idpGroup.directory]}
      popoverContent={showPopover ? idpGroup.id : undefined}
    />
  );
};

export const GenericIdpGroupPreview: React.FC<{ isApproval?: boolean }> = ({
  isApproval,
}) => (
  <StyledRoutingRulePreview>
    <ProfileOutlined />
    <span>{`${
      isApproval ? "Approval by any" : "Any"
    } user in a group from an Identity Provider`}</span>
  </StyledRoutingRulePreview>
);

import { capitalize } from "lodash";

import { ApiUser, P0User } from ".";
import { AuthResult } from "./rbac";

interface PublicError {
  publicMessage: string;
}

export class ValidationError extends Error implements PublicError {
  readonly type = "validation";
  readonly formId: string;
  readonly state: any;
  constructor(formId: string, state: any, message?: string | undefined) {
    super(message);
    this.formId = formId;
    this.state = state;
  }
  get publicMessage() {
    return this.message;
  }
}

export class AuthError extends Error implements PublicError {
  readonly type = "auth";
  readonly user: ApiUser | P0User | undefined;
  readonly result: AuthResult;
  readonly extra: object | undefined;
  constructor(
    user: ApiUser | P0User | undefined,
    result: AuthResult,
    message?: string | undefined,
    extra?: object
  ) {
    super(message);
    this.user = user;
    this.result = result;
    this.extra = extra;
  }
  get publicMessage() {
    switch (this.result) {
      case "unapprovable":
        return "This resource doesn't exist, or your organization doesn't allow this principal to access this resource";
      case "requires_two_party":
        return "Your organization doesn't allow you to modify your own access requests (organization owners can change this in P0 settings)";
      case "unauthenticated":
        return `P0 could not properly identify you. Please ensure that either:
- You are using a valid API token, or
- You are logged in, and your profile contains your verified email address`;
      case "unauthenticated_api":
        return "P0 could not authenticate your API key";
      default:
        return "You don't have permission to execute this action";
    }
  }
}

export const AuthnError = (message: string, extra?: object) =>
  new AuthError(undefined, "unauthenticated", message, extra);

export const ApiAuthnError = (message: string, extra?: object) =>
  new AuthError(undefined, "unauthenticated_api", message, extra);

export const AuthzError = (message: string, extra?: object) =>
  new AuthError(undefined, "unauthorized", message, extra);

export class NotFoundError extends Error implements PublicError {
  readonly type = "not_found";
  readonly locator: string;
  constructor(locator: string) {
    super("Not found");
    this.locator = locator;
  }
  get publicMessage() {
    return this.message;
  }
}

export class ConcurrentModificationError extends Error implements PublicError {
  readonly type = "conflict";
  constructor(message: string) {
    super(message);
  }
  get publicMessage() {
    return this.message;
  }
}

export class StateError extends Error implements PublicError {
  readonly type = "state";
  readonly state: object;
  constructor(state: object, message: string) {
    super(message);
    this.state = state;
  }
  get publicMessage() {
    return this.message;
  }
}

export class BadInstallationError extends StateError {
  constructor(integration: string, name?: string, extra?: string) {
    super(
      { integration },
      `There is an unrecoverable error in your organization's ${
        name ?? capitalize(integration)
      } installation${
        extra ? `: ${extra}` : ""
      }. Please ask your P0 admin to re-install this integration for the requested environment.`
    );
  }
}

export class NotInstalledError extends StateError {
  constructor(integration: string, component: string, id: string) {
    super(
      { integration, component, id },
      `P0 is not installed for ${component} on ${id}. Please ask your P0 admin to install this integration component before continuing.`
    );
  }
}

export class ServiceUnavailableError extends Error implements PublicError {
  readonly type = "service_unavailable";
  readonly service: string;
  readonly cause: Error;
  constructor(service: string, cause: Error) {
    super(cause.message);
    this.service = service;
    this.cause = cause;
  }
  get publicMessage() {
    return `${this.service} is temporarily unavailable; please try again later`;
  }
}

export class ProxyError extends Error implements PublicError {
  readonly type = "proxy";
  readonly service: string;
  readonly cause: Error;
  readonly state: any;
  constructor(service: string, message: string, cause: Error, state?: any) {
    super(message);
    this.service = service;
    this.cause = cause;
    this.state = state;
  }
  get publicMessage() {
    return `Error in service ${this.service}: ${this.message}`;
  }
}

export type ClientError =
  | AuthError
  | ConcurrentModificationError
  | NotFoundError
  | StateError
  | ValidationError;

export type UpstreamError = ProxyError | ServiceUnavailableError;

export const isClientError = (err: any): err is ClientError =>
  err instanceof AuthError ||
  err instanceof ConcurrentModificationError ||
  err instanceof NotFoundError ||
  err instanceof StateError ||
  err instanceof ValidationError;

export const isPublicError = (err: any): err is ClientError | UpstreamError =>
  isClientError(err) ||
  err instanceof ServiceUnavailableError ||
  err instanceof ProxyError;

import { GcloudComponents } from "shared/integrations/resources/gcloud/components";

import { Install, InstallProps } from "../Install/Install";
import { gcloudInstaller } from "./install";

export const GcloudIconUrl =
  "https://lh3.googleusercontent.com/VEnnK2SyklusfxZ3dIYjlQH3xSwK2BFSJ69TFQ9g8HjM6m3CouRlTia5FW3z3GS0x83WC9TylZCaA9Jf_2kmr7mXxI9_HYLZTFy_bg";

export const Gcloud = (
  props: Pick<
    InstallProps<typeof GcloudComponents>,
    "cardOverride" | "onInstall"
  >
) => (
  <Install<typeof GcloudComponents>
    components={GcloudComponents}
    installer={gcloudInstaller}
    integration="gcloud"
    itemKind="project"
    logo={GcloudIconUrl}
    title={"Google Cloud"}
    {...props}
  />
);

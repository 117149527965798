import { Tenant } from "components/Login";
import { orderBy, where } from "firebase/firestore";
import { PERMISSION_REQUESTS_COLLECTION } from "firestore/constants";
import { useFirestoreCollection } from "providers/FirestoreProvider";
import { useContext, useEffect, useState } from "react";
import { PermissionRequest } from "shared/types/permission";
import { RequestStatus } from "shared/types/request-status";

export const useRequests = (statuses: readonly RequestStatus[]) => {
  const [loading, setLoading] = useState(true);
  const tenantId = useContext(Tenant);
  const requests = useFirestoreCollection<PermissionRequest>(
    `o/${tenantId}/${PERMISSION_REQUESTS_COLLECTION}`,
    {
      live: true,
      queryConstraints: [
        where("status", "in", statuses),
        orderBy("requestedTimestamp", "desc"),
      ],
    }
  );

  useEffect(() => {
    if (requests !== undefined) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [requests]);
  return {
    loading,
    requests: requests ?? [],
  };
};

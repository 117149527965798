import { integrationOptionProvider } from "components/Integrations/utils/resourcesInstalledUtil";

import {
  GcloudConfigs,
  PgCloudSqlInstaller,
  PgCloudSqlProjectIdInstaller,
  PgInstallTypeInstaller,
} from "./types";

const pgCloudSqlProjectIdInstaller = (
  gcloud: GcloudConfigs
): PgCloudSqlProjectIdInstaller => ({
  optionProvider: integrationOptionProvider("access-management", [
    {
      firestoreRef: gcloud,
      component: "iam-write",
      labelTitle: "Google Cloud",
    },
  ]),
});

const pgCloudSqlInstaller = (gcloud: GcloudConfigs): PgCloudSqlInstaller => ({
  items: {
    projectId: pgCloudSqlProjectIdInstaller(gcloud),
  },
});

export const pgInstallTypeInstaller = (
  gcloud: GcloudConfigs
): PgInstallTypeInstaller => ({
  options: {
    "cloud-sql": pgCloudSqlInstaller(gcloud),
  },
});

import { doc, getDoc } from "firebase/firestore";
import { createContext } from "react";
import { OrgData } from "shared/types/tenant";

import { DB } from "../../providers/FirestoreProvider";

export const slugToTenant = async (
  slug: string
): Promise<OrgData | undefined> => {
  const data = (await getDoc(doc(DB, `orgs/${slug}`))).data();
  if (data === undefined || data.tenantId === undefined) {
    return undefined;
  }
  return data as OrgData;
};

export const Tenant = createContext("");

export type UserRole =
  | "approver"
  | "iamOwner"
  | "iamViewer"
  | "owner"
  | "restStateManager"
  | "viewer";

/** The current user's roles within P0
 *
 * An empty set indicates that authorization is loading, as
 * all authenticated users will have at least the "viewer"
 * role.
 */
export const UserAuthz = createContext(new Set<UserRole>());

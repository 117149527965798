const BITS_PER_NUMBER = 31; // Max number of bits we can store using bitshift for targets before ES2020 :(

type BitsetOptions = { maxSize: number; initial?: number[] };

export class Bitset {
  // TODO: If targeting ES2020 or greater, use BigInt instead
  #store: number[];
  #options: BitsetOptions;
  constructor(options: BitsetOptions) {
    this.#store = options.initial
      ? [...options.initial]
      : Array(Math.ceil(options.maxSize / BITS_PER_NUMBER)).fill(0);
    this.#options = options;
  }

  #modulus(index: number) {
    if (index > this.#options.maxSize)
      throw new RangeError(
        `${index} is greater than max size of ${this.#options.maxSize}`
      );
    const base = Math.floor(index / BITS_PER_NUMBER);
    const residue = index - base * BITS_PER_NUMBER;
    return [base, residue];
  }

  /** Returns an independent copy of this bitset */
  clone() {
    return new Bitset({ ...this.#options, initial: this.#store });
  }

  /** Sets an index of this bitset to present */
  set(index: number) {
    const [base, residue] = this.#modulus(index);
    this.#store[base] = this.#store[base] | (1 << residue);
  }

  /** Sets multiple indices */
  setAll(indices: number[]) {
    for (const ix of indices) {
      this.set(ix);
    }
  }

  /** Returns truee iff this bitset has this index */
  has(index: number) {
    const [base, residue] = this.#modulus(index);
    const comp = 1 << residue;
    return (this.#store[base] & comp) === comp;
  }

  /** Returns true iff all the passed values are in this bitset */
  hasAll(indices: number[]) {
    for (const ix of indices) {
      if (!this.has(ix)) return false;
    }
    return true;
  }

  /** Creates a somewhat compact serialized representation of this set */
  serialize() {
    return this.#store;
  }

  /** Converts a serialized value into a Bitset class */
  static deserialize(value: number[]) {
    // Note that maxSize could be larger than serialized input
    return new Bitset({
      maxSize: value.length * BITS_PER_NUMBER,
      initial: value,
    });
  }

  /** Creates an independent bitset with the values of both left and right sets
   *
   * Set sizes must be equal.
   */
  static merge(left: Bitset, right: Bitset) {
    if (left.#options.maxSize !== right.#options.maxSize) {
      throw new Error("Merged bitsets must have same size");
    }
    const cloned = left.clone();
    cloned.#store.forEach((n, ix) => {
      cloned.#store[ix] = n | right.#store[ix];
    });
    return cloned;
  }
}

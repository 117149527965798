import { ShareAltOutlined, TableOutlined } from "@ant-design/icons";
import { Alert, Segmented, Space, Spin, Table } from "antd";
import { ColumnType, TableProps } from "antd/lib/table";
import { SpaceBetweenDiv } from "components/divs";
import { isEqual, omit } from "lodash";
import { ReactElement, ReactNode, useCallback, useMemo, useState } from "react";
import {
  AggregatedGraph,
  AggregatedNode,
  aggregate,
} from "shared/graph/aggregate";
import { DiscoverMatch, discoverPaths } from "shared/graph/discover";
import { NodePredicate, NodeSearch } from "shared/graph/search";
import { DirectedGraph, Reducers } from "shared/graph/types";
import { sleep } from "shared/util/sleep";
import styled from "styled-components";
import { staticError } from "utils/console";

import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import {
  DiscoverVisualization,
  DiscoverVisualizationProps,
} from "./DiscoverVisualization";
import { GraphSearch, GraphSearchProps } from "./GraphSearch";

export interface GraphTableProps<
  G extends object,
  K extends keyof G,
  A extends object,
> extends Omit<GraphSearchProps, "isSearching">,
    TableProps<AggregatedNode<G, K, A>> {
  columns: ColumnType<AggregatedNode<G, K, A>>[];
  visualize: Omit<DiscoverVisualizationProps<G>, "matches">;
  exporter: (selected: AggregatedNode<G, K, A>[]) => ReactNode;
  from: NodePredicate<G>;
  graph: DirectedGraph<G>;
  reducers: Reducers<G, A>;
  search: NodeSearch<G>[];
  searchExtra?: React.ReactNode;
  onSearch?: (nodes: AggregatedNode<G, K, A>[]) => void;
  onSelection?: (items: AggregatedNode<G, K, A>[]) => void;
}

// Amount of time to wait before showing a search spinner
const INDICATED_SEARCH_MS = 50;

const TableWrapper = styled.div`
  .ant-table.ant-table-small {
    font-size: 13px;
    tr > td {
      padding: 6px 6px;
    }
  }
  .ant-pagination {
    font-size: 13px;
  }
  .ant-pagination-options .ant-select {
    font-size: 13px;
  }
`;

export const GraphTable = <
  G extends object,
  K extends keyof G,
  A extends object,
>({
  columns,
  controls,
  exporter,
  from,
  frozen,
  graph,
  onSelection,
  onSearch,
  reducers,
  search,
  searchExtra,
  showOptions,
  visualize,
  ...tableProps
}: GraphTableProps<G, K, A>): ReactElement => {
  const [matches, setMatches] = useState<DiscoverMatch<DirectedGraph<G>>[]>();
  const [searched, setSearched] = useState<AggregatedGraph<G, A>>();

  // Keep track of selected rows, as only selected rows will be exported
  const [selected, setSelected] = useState<AggregatedNode<G, K, A>[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  // Used to render a search in-progress indicator in the search bar;
  // note that we can't render the spinner in the table, as it would
  // clear table state (filtering, pagination, etc.)
  const [isSearching, setIsSearching] = useState(false);
  // Ensure that table display matches graph data structure by hiding
  // table when search has not yet updated
  const [renderShow, setRenderShow] = useState<string>();

  const aggregated = useMemo(() => {
    const before = performance.now();
    const result = aggregate(graph, reducers);
    // TODO: send to analytics
    // eslint-disable-next-line no-console
    console.log(
      "Time to aggregate graph",
      (performance.now() - before).toFixed(1),
      "ms"
    );
    return result;
  }, [reducers, graph]);

  const warnings = useMemo(
    () => search.flatMap((s) => s.warnings ?? []),
    [search]
  );

  useGuardedEffect(
    (cancellation) => async () => {
      if (!aggregated) return;
      // Clear selection on search / show update to prevent filtered rows from staying in
      // selection; this is less complicated than re-filtering the selection
      setSelected([]);
      setSelectedKeys([]);
      // onSelection?.([], []);
      let isFinished = false;
      // Debounces "isSearching" indicator, so it only spins if search takes longer than
      // INDICATED_SEARCH_MS
      void sleep(INDICATED_SEARCH_MS)
        .then(() => {
          if (!isFinished) cancellation.guard(setIsSearching)(true);
        })
        .catch(console.error);
      // Allow time for controls to re-render before we halt render loop
      await sleep(10);
      const beforeSearch = performance.now();
      const result = await discoverPaths(aggregated, from, search);
      // antd recursively flattens objects; it can take ant many seconds to flatten
      // just one page of top-level nodes; prevent this by removing the children altogether
      const noChildren = result.map(({ node }) =>
        omit(node, "children")
      ) as AggregatedNode<G, K, A>[];
      // TODO: send to analytics
      // eslint-disable-next-line no-console
      console.log(
        "Time to search graph",
        (performance.now() - beforeSearch).toFixed(1),
        "ms"
      );
      isFinished = true;
      if (cancellation.isCancelled) return;
      setIsSearching(false);
      setMatches(result);
      if (isEqual(noChildren, searched?.nodes)) return;
      setSearched({ nodes: noChildren });
      onSearch?.(noChildren);
      setRenderShow(controls.show);
    },
    [aggregated, from, search, controls, searched?.nodes, onSearch],
    staticError
  );

  const registerSelection = useCallback(
    (keys: React.Key[], values: AggregatedNode<G, K, A>[]) => {
      setSelectedKeys(keys);
      setSelected(values);
      onSelection?.(values);
    },
    [onSelection]
  );

  const exporterElement = useMemo(
    () =>
      exporter((selected.length ? selected : (searched?.nodes as any)) ?? []),
    [exporter, searched, selected]
  );

  return (
    <div data-testid="graph-table">
      {!searched ? (
        <div style={{ height: "430px" }}>
          <Spin />
        </div>
      ) : (
        <Space direction="vertical">
          <SpaceBetweenDiv
            style={{ alignItems: "center", width: "1000px", overflowX: "auto" }}
          >
            <GraphSearch
              controls={controls}
              frozen={frozen}
              isSearching={isSearching}
              showOptions={showOptions}
            />
            <Segmented
              value={controls.display ?? "table"}
              options={[
                { value: "table", label: <TableOutlined /> },
                { value: "graph", label: <ShareAltOutlined /> },
              ]}
              onChange={controls.onChangeDisplay as any}
            />
            {exporterElement}
          </SpaceBetweenDiv>
          {warnings.length ? (
            <Alert
              type="warning"
              description={warnings.map((w, ix) => (
                <li key={ix}>{w}</li>
              ))}
            />
          ) : null}
          {searchExtra}
          {controls.show !== renderShow ? (
            <Spin />
          ) : controls.display === "graph" ? (
            <DiscoverVisualization matches={matches ?? []} {...visualize} />
          ) : (
            // Always fall back to table display
            <TableWrapper>
              <Table
                dataSource={searched.nodes as AggregatedNode<G, K, A>[]}
                columns={columns}
                size="small"
                expandable={{ showExpandColumn: false }}
                data-testid="graph-table-inner-table"
                rowSelection={{
                  onChange: registerSelection,
                  selectedRowKeys: selectedKeys,
                  type: "checkbox",
                }}
                // Sorter tooltips block other more important parts of the UI (like query reference)
                showSorterTooltip={false}
                {...{ pagination: { hideOnSinglePage: true } }}
                {...tableProps}
              />
            </TableWrapper>
          )}
        </Space>
      )}
    </div>
  );
};

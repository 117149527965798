import Link from "antd/lib/typography/Link";
import { ReactNode, useCallback, useEffect, useState } from "react";

export const ExpandableList = ({
  initialLength,
  items,
  width,
  startShowAll,
}: {
  initialLength?: number;
  items: ReactNode[];
  width?: number;
  startShowAll?: boolean;
}) => {
  const [isShowAll, setIsShowAll] = useState(!!startShowAll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsShowAll(!!startShowAll), [items]);
  const onClickShowAll = useCallback(() => setIsShowAll(true), []);
  const length = initialLength ?? 10;
  return (
    <div
      style={{
        fontSize: "small",
        display: "flex",
        flexDirection: "column",
        maxHeight: 20.5 * (length + 1), // Height equal to full unexpanded list
        overflowX: "clip",
        overflowY: "auto",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        ...(width ? { maxWidth: width } : {}),
      }}
    >
      {(isShowAll ? items : items.slice(0, length)).map((i, ix) =>
        typeof i === "string" ? <span key={ix}>{i}</span> : i
      )}
      {!isShowAll && items.length > length && (
        <span>
          (
          <Link onClick={onClickShowAll}>
            Show {items.length - length} more
          </Link>
          )
        </span>
      )}
    </div>
  );
};

import { Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

const { Text } = Typography;

const StyledLearnMoreText = styled(Text)`
  margin-top: 10px;
  display: block;
`;

type Props = { to: string };

const P0SecurityFeaturesLink: React.FC<Props> = ({
  to = "https://p0.dev/blog",
}) => {
  return (
    <StyledLearnMoreText>
      Learn more about P0’s built-in security features{" "}
      <Link to={to} target="_blank" rel="noreferrer">
        here
      </Link>
      .
    </StyledLearnMoreText>
  );
};

export default P0SecurityFeaturesLink;

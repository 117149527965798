import { List, Pagination } from "antd";
import Item from "antd/lib/list/Item";
import { ContentSizeDiv, RightAlignedDiv } from "components/divs";
import { ReactNode, useState } from "react";
import styled from "styled-components";

const ItemList = styled(List)`
  .ant-list-item {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const PaginatedListDisplay: React.FC<{
  items: ReactNode[][] | string[];
  pageSize: number;
  renderer?: (item: string) => ReactNode;
}> = ({ items, pageSize, renderer }) => {
  // Note pagination is 1-indexed
  const [pageIndex, setPageIndex] = useState(1);

  return (
    <ContentSizeDiv>
      <ItemList>
        {items
          .slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
          .map((p, ix) => (
            <Item key={ix}>
              {typeof p === "string" && renderer ? renderer(p) : p}
            </Item>
          ))}
      </ItemList>
      <RightAlignedDiv>
        <Pagination
          hideOnSinglePage={true}
          current={pageIndex}
          total={items.length}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={setPageIndex}
        />
      </RightAlignedDiv>
    </ContentSizeDiv>
  );
};

import {
  DeleteOutlined,
  EditTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, List } from "antd";
import { useCallback, useMemo } from "react";

type Props<T> = {
  items: T[];
  onEditItem: (index: number) => void;
  onDeleteItem: (index: number) => void;
  onAddItem: () => void;
  renderItem: (item: T, index: number) => React.ReactNode;
  addButtonText: string;
  addButtonDisabled?: boolean;
};

export const EditableList = <T,>({
  items,
  onEditItem,
  onDeleteItem,
  onAddItem,
  renderItem,
  addButtonText,
  addButtonDisabled = false,
}: Props<T>) => {
  const deleteCallbacks = useMemo(
    () => items.map((_item, ix) => () => onDeleteItem(ix)),
    [items, onDeleteItem]
  );
  const editCallbacks = useMemo(
    () => items.map((_item, ix) => () => onEditItem(ix)),
    [items, onEditItem]
  );

  const renderEditableItem = useCallback(
    (item: T, index: number) => (
      <List.Item
        actions={[
          <Button
            key={`${index}-0`}
            type="default"
            onClick={editCallbacks[index]}
            icon={<EditTwoTone />}
          />,
          <Button
            key={`${index}-1`}
            type="default"
            onClick={deleteCallbacks[index]}
            icon={<DeleteOutlined />}
          />,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: "1",
          }}
        >
          {renderItem(item, index)}
        </div>
      </List.Item>
    ),
    [deleteCallbacks, editCallbacks, renderItem]
  );

  return (
    <List
      dataSource={items}
      renderItem={renderEditableItem}
      header={
        <Button
          type="default"
          onClick={onAddItem}
          icon={<PlusCircleOutlined />}
          disabled={addButtonDisabled}
        >
          {addButtonText}
        </Button>
      }
    />
  );
};

import { useParams } from "react-router";
import { Link } from "react-router-dom";

type Props = React.PropsWithChildren<{
  to: string;
}>;

export const TenantAwareLink: React.FC<Props> = ({ to, children, ...rest }) => {
  const { orgSlug } = useParams();

  return (
    <Link to={`/o/${orgSlug}/${to}`} {...rest}>
      {children}
    </Link>
  );
};

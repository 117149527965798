import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { ClipDiv } from "components/divs";
import { ResourceNode } from "shared/types/assessment/data";

import { ExportColumnType } from "../../AssessmentExport";
import { Resource } from "../../cells/Resource";
import { ShowHideTerm } from "../../cells/ShowHide";
import { genericExport } from "../../export";
import { stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";
import { resourceLabels } from "./labels";

export const resourceColumns = (
  props: AssessmentColumnProps
): ExportColumnType<ResourceNode>[] => [
  {
    key: "resource",
    title: "Resource",
    sorter: (left, right) => stringSorter(left.key, right.key),
    render: (node) => {
      return (
        node && (
          <ClipDiv maxWidth="500px">
            <Resource resource={node} {...props} />
          </ClipDiv>
        )
      );
    },
    export: genericExport((node) => node.key),
  },
  {
    key: "service",
    title: "Service",
    sorter: (left, right) =>
      stringSorter(left.data.service, right.data.service),
    render: (node) => {
      const value = resourceLabels(node.data).service;
      return (
        <GraphTooltip
          title={
            <>
              {value}
              <ShowHideTerm
                term={`resource=service:"${node.data.service}"`}
                name="services"
                {...props}
              />
            </>
          }
        >
          {value}
        </GraphTooltip>
      );
    },
  },
  {
    key: "type",
    title: "Type",
    sorter: (left, right) =>
      stringSorter(left.data.type ?? "", right.data.type ?? ""),
    render: (node) => {
      const value = resourceLabels(node.data).type;
      return (
        <GraphTooltip
          title={
            <>
              {value}
              <ShowHideTerm
                term={`resource=type:"${node.data.type}"`}
                name="resource types"
                {...props}
              />
            </>
          }
        >
          {value}
        </GraphTooltip>
      );
    },
  },
];

import { Button, Modal } from "antd";
import { ErrorDisplay } from "components/Error";
import { JiraIcon } from "components/Integrations/Jira/Jira";
import { VerticalSpacedDiv } from "components/divs";
import pluralize from "pluralize";
import { useCallback, useMemo, useState } from "react";
import { Finding } from "shared/types/assessment/finding";

import { useAuthFetch } from "../../../components/Login/hook";
import { FirestoreDoc } from "../../../providers/FirestoreProvider";
import { MonitorActionProps } from "../pages/MonitorResults";
import { useFindingsStateUpdates } from "./finding/useFindingsStateUpdates";

/** Bulk create issues */
export const FindingsAssignModal: React.FC<MonitorActionProps> = ({
  actOn,
  state,
}) => {
  const [staged, setStaged] = useState<FirestoreDoc<Finding>[]>([]);
  const [error, setError] = useState<string>();
  const authFetch = useAuthFetch(setError);

  const { assignFindings } = useFindingsStateUpdates(authFetch);

  const assignable = useMemo(() => actOn.filter((f) => !f.data.issue), [actOn]);

  const openAssignModal = useCallback(
    () => setStaged(assignable),
    [assignable]
  );
  const closeAssignModal = useCallback(() => setStaged([]), []);

  const submitAssign = useCallback(async () => {
    setError(undefined);
    const response = await assignFindings(staged);
    if (response?.ok) setStaged([]); // otherwise 'error' is set
  }, [assignFindings, staged]);

  return (
    <>
      <Button onClick={openAssignModal} disabled={assignable.length == 0}>
        {JiraIcon}
        &nbsp;Assign
      </Button>
      <Modal
        footer={false}
        maskClosable={false}
        open={staged.length > 0}
        onCancel={closeAssignModal}
        style={state === "open" ? { minWidth: "600px" } : {}}
      >
        {error && (
          <ErrorDisplay title="Could not assign findings" error={error} />
        )}
        <VerticalSpacedDiv>
          <div>
            Assign {staged.length} {pluralize("finding", staged.length)}?
          </div>
          <div>
            <Button onClick={submitAssign}>OK</Button>
          </div>
        </VerticalSpacedDiv>
      </Modal>
    </>
  );
};

import { AwsSpec } from "../integrations/resources/aws/accesses";
import { AzureSpec } from "../integrations/resources/azure/accesses";
import { GroupSpec } from "../integrations/resources/directory-group/types";
import { GcloudSpec } from "../integrations/resources/gcloud/types";
import { KubernetesSpec } from "../integrations/resources/kubernetes/types";
import { PgSpec } from "../integrations/resources/postgres/types";
import { SnowflakeSpec } from "../integrations/resources/snowflake/types";
import { SshSpec } from "../integrations/resources/ssh/accesses";
import { DistributedOmit } from "../types/util";
import { ClientError, UpstreamError } from "./error";
import { Evidence } from "./evidence";
import { RequestStatus } from "./request-status";
import { RoutingRule } from "./workflow/types";

export type PermissionType = Permission["type"];

export type BasePermission<T extends Permission> = T & {
  reason?: string;
  requestedTimestamp: number;
  requestedDuration?: string;
};

export type ExtractStagedPermission<T extends PermissionType> = Extract<
  StagedPermission,
  { type: T }
>;

export type StagedPermission =
  | AwsSpec
  | AzureSpec
  | GcloudSpec
  | GroupSpec
  | KubernetesSpec
  | PgSpec
  | SnowflakeSpec
  | SshSpec;

export type Permission = DistributedOmit<StagedPermission, "generated">;

export type PermissionSpec<
  K extends string,
  P extends { type: string },
  G extends object
> = {
  type: K;
  permission: P;
  generated: G;
};

export const isPermissionSpec = <
  P extends PermissionSpec<string, { type: string }, any>
>(
  type: P extends PermissionSpec<infer K, infer _P, infer _G> ? K : never,
  subType: P extends PermissionSpec<infer _K, infer S, infer _G>
    ? S["type"]
    : never,
  spec: PermissionSpec<string, { type: string }, any>
): spec is P =>
  spec.type === type &&
  "type" in spec.permission &&
  spec.permission.type === subType;

export type RequestId<T extends Permission> = T & {
  requestId: string;
};

export interface PermissionRequestError {
  type?: ClientError["type"] | UpstreamError["type"];
  message: string;
}

export type MsTeamsNotifications = {
  /** A universally accessible URL for the message */
  approvalConversationUrl?: string;
  // TODO: use the actual type "Activity" from the MS Teams SDK.
  /** An activity reference to the channel message */
  approvalActivity?: any;
  /** An activity reference to the requestor's direct message */
  requestorActivity?: any;
};

export type SlackNotifications = {
  /** Integration identifier for the approval message
   *
   * In Slack, this is `message_ts`
   */
  approvalMessageId?: string;
  /** A universally accessible URL for the message */
  approvalConversationUrl?: string;
  /** Info about all the approver notifications that were sent via DM, if applicable */
  approverNotifications?: SlackMessage[];
  /** Channel ID of the requestor's DM */
  requestorChannelId?: string;
  /** Message ID of the requestor conversation */
  requestorMessageId?: string;
};

export type SlackMessage = { channelId: string; messageId: string };

// capture the approver details irrespective of where it was approved from
// in future the approval even if the approval happens from the web
// this removes the need for conditional checks to understand how the request got approved.
export type ApprovalDetails = {
  /** Source ID of the entity that approved this request */
  id: string;
  /** Name of the entity that approved this request */
  name?: string;
  /** Email of the user that approved this request */
  email?: string;
  /** Timestamp when the approver clicked the Approve button */
  approvedTimestamp?: number;
  /** Any additional information about how this request was approved. May or may
   * not be present depending on the approval source.
   */
  extra?: Record<string, string | undefined>;
  approvalSource: "evidence" | "pagerduty" | "persistent" | "slack";
};

export type EscalationDetails = {
  pagerduty: string[];
};

export type RequestNotifications = {
  msTeams?: MsTeamsNotifications;
  slack?: SlackNotifications;
};
export type RequestNotificationKey = keyof RequestNotifications;

export type RequestData = {
  error?: PermissionRequestError;
  expiryTimestamp?: number;
  grantTimestamp?: number; // Timestamp when the request was granted
  revokedTimestamp?: number; // Timestamp when the request was expired
  // Only those documents are retrieved by the expiry service where `isAwaitingExpiry`is true.
  // This field is not set (undefined) before a permission is granted in the target resource (DONE status).
  isAwaitingExpiry?: boolean;
  evidence?: Evidence[];
  lastUpdatedByUser?: string;
  lastUpdatedTimestamp?: number;
  notifications?: RequestNotifications;
  principal: string;
  requestor: string;
  status: RequestStatus;
  approvalDetails?: ApprovalDetails;
  canEscalate?: boolean;
  escalationDetails?: EscalationDetails;

  // Contains a snapshot of routing rules which applied to the given request, populated when the request is approved,
  // for audit purposes.
  routingRulesSnapshot?: RoutingRule[];
};
export type StagedPermissionRequest<
  T extends StagedPermission = StagedPermission
> = BasePermission<T> & RequestData;

export type UnstagedPermission<T extends PermissionSpec<any, any, any>> =
  DistributedOmit<T, "generated">;

export type PermissionRequest<T extends StagedPermission = StagedPermission> =
  BasePermission<DistributedOmit<T, "generated">> & RequestData;

// In order to update a nested path without overriding that path's parents, must
// use dot notation (see DocumentReference.update)
export type PermissionRequestUpdates<
  T extends StagedPermission = StagedPermission
> = Partial<
  Omit<PermissionRequest<T>, "isAwaitingExpiry" | "notifications"> & {
    // TODO: use the actual type "Activity" from the MS Teams SDK. The SDK is not available in the shared package.
    "notifications.msTeams.requestorActivity": any;
    "notifications.msTeams.approvalActivity": any;
    "notifications.msTeams.approvalByUserId": string;
    "notifications.msTeams.approvalByEmail": string;
    "notifications.msTeams.approvedTimestamp": number;
    "notifications.msTeams.approvalConversationUrl": string;
    "notifications.slack.approvalMessageId": string;
    "notifications.slack.approvalByUserId": string;
    "notifications.slack.approvalByEmail": string;
    "notifications.slack.approvedTimestamp": number;
    "notifications.slack.approvalConversationUrl": string;
    "notifications.slack.requestorChannelId": string;
    "notifications.slack.approverNotifications": SlackMessage[];
    "notifications.slack.requestorMessageId": string;
    "approvalDetails.id": string;
    "approvalDetails.name": string;
    "approvalDetails.email": string;
    "approvalDetails.approvalSource": string;
    "approvalDetails.approvedTimestamp": number;
  }
>;

export type PermissionEvent<T extends Permission = Permission> =
  BasePermission<T> & {
    email: string;
    uid: string;
    status: "NEW";
    to?: string;
    notifications?: RequestNotifications;
  };

export const PermissionKeys = ["permission", "type", "access"];

import { CaretDownFilled, CaretRightFilled } from "@ant-design/icons";
import { ReactNode, useCallback, useState } from "react";

import { ExpandableList } from "./ExpandableList";

/** An expandable summary of an aggregate */
export const Expando: React.FC<{
  items: ReactNode[];
  header: ReactNode;
  width?: number;
}> = ({ items, header, width }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: isOpen ? "flex-start" : "center",
        gap: "0.3em",
      }}
    >
      {items.length <= 1 ? null : isOpen ? (
        <CaretDownFilled onClick={toggle} />
      ) : (
        <CaretRightFilled onClick={toggle} />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {isOpen ? <ExpandableList items={items} width={width} /> : header}
      </div>
    </div>
  );
};

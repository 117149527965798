import moment from "moment";

export const FormattedDateTime: React.FC<{
  timestamp: number;
}> = ({ timestamp }) => {
  return (
    <>
      <div>{moment(timestamp).format("ll")}</div>
      <div className="sub-text">{moment(timestamp).format("LTS")}</div>
    </>
  );
};

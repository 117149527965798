import { DeleteOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useCallback, useState } from "react";

import "./ApiKey.less";

export type apiKeyData = {
  created: Date;
  id: string;
  name: string;
};

type apiKeyProps = apiKeyData & {
  handleDelete: (keyId: string) => void;
  isSandbox?: boolean;
};

export const ApiKey: React.FC<apiKeyProps> = ({
  created,
  handleDelete,
  id,
  isSandbox = false,
  name,
}) => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const handleClickDelete = useCallback(() => {
    setDeleting(true);
    handleDelete(id);
  }, [handleDelete, id]);

  return (
    <div className="api-key">
      <div className="data-container">
        <LockOutlined className="lock-icon" />
        <div>
          <Typography.Title level={5}>{name}</Typography.Title>
          <Typography.Paragraph type="secondary">
            {`Created ${created.toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })} at ${created.toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            })}`}
          </Typography.Paragraph>
        </div>
      </div>
      <div className="controls">
        <Button
          icon={<DeleteOutlined />}
          loading={deleting}
          onClick={handleClickDelete}
          disabled={isSandbox}
        />
      </div>
    </div>
  );
};

import { UserAuthz } from "components/Login";
import { useAuthFetch } from "components/Login/hook";
import { useContext, useEffect } from "react";
import { useDirectoriesStore } from "store/resources/directoriesStore";

export const useInstalledDirectories = () => {
  const authFetch = useAuthFetch();
  const userAuthz = useContext(UserAuthz);
  const {
    updateAvailableGroups,
    availableDirectories,
    availableGroups,
    setDirectoryGroupsState,
  } = useDirectoriesStore();

  const allAvailableGroups = useDirectoriesStore((state) =>
    state.getSquashedGroups()
  );
  const isLoadingGroups = useDirectoriesStore((state) => state.isLoading());

  useEffect(() => {
    if (
      !userAuthz.has("owner") ||
      !availableDirectories ||
      availableDirectories.state !== "loaded"
    ) {
      return;
    }
    for (const directory of availableDirectories.directories) {
      const storedGroups = availableGroups[directory];
      // Only fetch groups if they haven't been fetched in the last minute (can be configured in follow-ups if needed)
      if (
        (storedGroups.state === "loaded" &&
          storedGroups.lastUpdated > Date.now() - 60000) ||
        storedGroups.state === "loading"
      ) {
        continue;
      }

      setDirectoryGroupsState(directory, "loading");

      authFetch("command/ls", {
        method: "POST",
        json: {
          argv: ["ls", directory, "membership", "group", "--size", "-1"],
        },
      }).then((response) => {
        response?.json().then((data) => {
          updateAvailableGroups(
            directory,
            data.items.map((i: { key: string; value: string }) => ({
              id: i.key,
              label: i.value,
              directory,
            }))
          );
        });
      });
    }
  }, [
    availableDirectories,
    updateAvailableGroups,
    authFetch,
    userAuthz,
    availableGroups,
    setDirectoryGroupsState,
  ]);

  return {
    allAvailableGroups,
    availableDirectories,
    availableGroups,
    isLoadingGroups,
  };
};

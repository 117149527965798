import { awsIamAssessmentInstaller } from "./iam-assessment";
import { awsIamWriteInstaller } from "./iam-write";
import { awsInventoryInstaller } from "./inventory";
import { AwsInstaller } from "./types";

export const awsInstaller: AwsInstaller = {
  base: { items: {} },
  "iam-assessment": awsIamAssessmentInstaller,
  "iam-write": awsIamWriteInstaller,
  inventory: awsInventoryInstaller,
};

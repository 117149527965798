import { Col, Collapse, Row, Tag, Typography } from "antd";
import { noop } from "lodash";
import { useCallback } from "react";

import { Page } from "../App/Page";
import { Heading } from "../Heading/Heading";
import { ApiKeysPanel } from "../Settings/ApiKeysPanel";
import { ApprovalRequirements } from "../Settings/ApprovalRequirements";
import { TagInput } from "../TagInput";

/*
 * With some refactoring of the Settings page, ideally we can use the same
 * view component for both the sandbox and non-sandbox versions of the page.
 * For now, we'll just duplicate the view code.
 */
export const SettingsSandbox: React.FC = () => {
  const tagToElement = useCallback(
    (tag: string) => (
      <Tag key={tag} closable>
        {tag}
      </Tag>
    ),
    []
  );

  return (
    <Page title="Manage Your P0 Settings">
      <Heading title="Manage Your P0 Settings" />
      <div style={{ maxWidth: 600, marginBottom: 25 }}>
        <Typography.Paragraph>
          All owners of your P0 account can manage your P0 settings. You can
          assign users to owner, approver, or IAM viewer roles, designate
          whether to allow one-party approvals (if you aren&apos;t using the
          routing rules API), and create and manage your P0 API keys.
        </Typography.Paragraph>
        <Typography.Paragraph>
          By default, all users with an email in your domain are viewers (they
          can view past requests), and they can make requests in Slack for the
          resources you&apos;ve configured. In additon, you can invite users as
          either owners (admins of your P0 account), approvers (who can approve
          requests), or IAM viewers (who can view IAM audit info but not take
          action on it).
        </Typography.Paragraph>
      </div>
      <Collapse defaultActiveKey={["access_control", "workflows", "apikeys"]}>
        <Collapse.Panel header="Access control" key="access_control">
          <Row gutter={[0, 32]}>
            <Col span={24}>
              <Typography.Title level={4}>Owners</Typography.Title>
              <Typography.Paragraph>
                Owners can add integrations and alter settings.
              </Typography.Paragraph>
              <TagInput
                tags={exampleOwners}
                tagToElement={tagToElement}
                onUpdate={noop}
                disabled
              />
            </Col>
            <Col span={24}>
              <Typography.Title level={4}>Approvers</Typography.Title>
              <Typography.Paragraph>
                Approvers can approve and revoke access requests.
              </Typography.Paragraph>
              <TagInput
                tags={exampleApprovers}
                tagToElement={tagToElement}
                onUpdate={noop}
                disabled
              />
            </Col>
            <Col span={24}>
              <Typography.Title level={4}>IAM Viewers</Typography.Title>
              <Typography.Paragraph>
                IAM Viewers can view iam audits.
              </Typography.Paragraph>
              <TagInput
                tags={[]}
                tagToElement={tagToElement}
                onUpdate={noop}
                disabled
              />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Workflows" key="workflows">
          <ApprovalRequirements isSandbox />
        </Collapse.Panel>
        <Collapse.Panel header="API Keys" key="apikeys">
          <ApiKeysPanel isSandbox />
        </Collapse.Panel>
      </Collapse>
    </Page>
  );
};

const exampleOwners = ["admin.example@sand.box"];

const exampleApprovers = [
  "approver1.example@sand.box",
  "approver2.example@sand.box",
];
